import React, { createContext, useState } from 'react'


export const DataContext = createContext();

export const DataProvider = ({children}) => {
    const [invoiceId,setInvoiceId] = useState(null)
    return (
        <DataContext.Provider value={{invoiceId,setInvoiceId}}>
          {children}
        </DataContext.Provider>
      );
}
