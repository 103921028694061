import styled from 'styled-components';

const colors = {
  white: '#ffffff',
  black: '#000000',
  blue: '#4669E8',
  lightblue: '#E7E9F0',
  peach: '#dedede',
  'background-blue': '#ECEFFF',
  grey: '#f6f6f6',
  light_grey: '#b3b1b1',
  line: '#cccccc',
  red: '#ff0000',
  yellow: '#ffdf00',
  lightAsh: '#f8f8f8',
  green: '#45b163',
  ash: '#797979',
  medium_gray: '#dfdfdf',
  reds: '#F32120',
  simple_grey:'#EFEFEF'
};

const fonts = {
  fontWeight1: 'bold',
  fontWeight2: 200,
  fontWeight3: 400,
  fontWeight4: 700,
  fontWeight5: 500,
  fontWeight6: 600,
};
const NoDisplay = styled.div`
  font-style: italic;
  color: ${colors.ash};
  display:flex;
  justify-content: center;
  align-items: center;
  margin-top:3rem;
`;

export { colors, fonts, NoDisplay };
