import React, { useEffect, useState } from 'react';
// import {
//   BrandImage,
//   ButtonLeft,
//   ButtonRight,
//   ButtonsDiv,
//   ContentWrapper,
//   DetailKey,
//   ExchangeButton,
//   ExchangeButtons,
//   H1,
//   LeftDec,
//   Main,
//   OfferSection,
//   OfferTitle,
//   PopLeft,
//   PopRight,
//   ProductCard,
//   ProductDetails,
//   ProductGrid,
//   ProductImage,
//   ProductImageSection,
//   ProductTitle,
//   RightDec,
//   RowDetails,
//   SectionBanner,
//   TextBlue,
// } from './ExchangeStyle';
import banner from './../../assets/Images/png/exhangebannerbackground.png';
import popLeft from './../../assets/Images/png/explos-left.png';
import popRight from './../../assets/Images/png/explos-right.png';
import pickUp from './../../assets/Images/png/Pickup.png';
import priceButton from './../../assets/Images/png/PriceButton.png';
import leftButtonDec from './../../assets/Images/png/leftdec.png';
import rightButtonDec from './../../assets/Images/png/rightdec.png';
import OfferBackground from './../../assets/Images/png/offerBackground.png';
import Laptop from './../../assets/Images/png/laptop3.png';
import Brand from './../../assets/Images/png/brandImage.png';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ExchangeTandC from './ExchangeTandC';
import { colors } from '../../consts/themes';
const userid = localStorage.getItem('userid');


const Exchange = () => {
  const navigation = useNavigate();
  const [termsModal, setTermsModal] = useState(false);
  const [check, setCheck] = useState(0);

  function handleclick(value) {
    setCheck(value);
    check === value && setCheck(0);
  }

  return (
    <Main>
      <SectionBanner>
        <img src={banner} alt='banner' />
        <Wrapper>
          <Title1>EXCHANGE</Title1>
          <Title2>Upgrade hassle-free, swap with ease</Title2>
        </Wrapper>
      </SectionBanner>

      <TcSection>

        <Check>
          <input type='checkbox' onClick={() => handleclick(1)} />
          <p> I agree with the <a onClick={() => setTermsModal(true)} > Terms & conditions</a>
            {termsModal && <ExchangeTandC close={setTermsModal} />}
          </p>
        </Check>

        <BaseButton disabled={check === 0} onClick={() => navigation('/exchange/options')}>Proceed</BaseButton>
      </TcSection>
    </Main>
  );
};
export default Exchange;




{/* <Check>
<input type='checkbox' onClick={() => handleclick(1)} />
<p>
  I agree with the
  <a onClick={() => setTermsModal(true)} > Terms & conditions</a>
  {termsModal && <InvoiceTandCModal close={setTermsModal} />}
</p>
</Check>
<Button disabled={check === 0}>
<button disabled={check === 0} onClick={handlePay}>Proceed to pay</button>
</Button>
 */}





const TcSection = styled.div`
 `;





const Check = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  /* /* margin-top: 10px; */
  input {
    accent-color: ${colors.blue};
    width: 17px;
  }
  a {
    text-decoration: none;
    color: ${colors.blue};
  }
  p {
    font-size: 1rem;
    font-size: small;
  }
  @media screen and (max-width: 800px) {
    width: 80%;
    justify-content: center;
    /* align-items: center; */
    p {
      /* padding-top:10px; */
      /* align-items: center; */
    }
    a {
      text-decoration: none;
      color: ${colors.blue};
    }
  }
  @media screen and (max-width: 500px) {
    width: 80%;
  }
`;







const Main = styled.div`
*{
    box-sizing: border-box;
}

display:flex;
flex-direction:column;
align-items:center;
gap:1.6rem;
height:100vh;
`;



const SectionBanner = styled.section`
min-height: 500px;
position: relative;
margin-top: 80px;

width:100%;
display:flex;
flex-direction:column;
justify-content: flex-end;
align-items: center;

padding-bottom:3.6rem;

  img {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    height: 100%;
    left: 0;
    bottom: 0;
    width: 100%;
    object-fit: cover;
  }
  @media (max-width: 1100px) {
    min-height: 400px;
  }
  @media (max-width: 767px) {
    min-height: 250px;
  }
`;



const Wrapper = styled.div`
display:flex;
flex-direction: column;
align-items: center;
position: relative;
z-index: 1;
padding:0 0.5rem;
`;



const Title1 = styled.div`
font-weight:400;
font-size:4rem;
color:white;
@media only screen and (min-width: 250px) and (max-width: 767px) {font-size:2rem}
@media only screen and (min-width: 768px) and (max-width: 1023px) {font-size:3rem}
`;


const Title2 = styled.div`
font-weight:400;
font-size:2.8rem;
color:#2B4FD0;
@media only screen and (min-width: 250px) and (max-width: 767px) {font-size:1rem}
@media only screen and (min-width: 768px) and (max-width: 1023px) {font-size:1.5rem}
`;


const BaseButton = styled.button`
  background-color: ${props => props.disabled ? '#D5E2FE' : '#4669E8'};
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    color: ${colors.white};
font-family: Poppins;
font-size: 1rem;
text-align: center;
color:white;
/* background-color: #4669E8; */
border:none;
border-radius: 5px;


@media only screen and (min-width: 250px) and (max-width: 399px) {
  font-size: 0.8rem;
  padding:1rem;
}

@media only screen and (min-width: 250px) and (max-width: 767px) {padding:0.8rem 2rem;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0.8rem 4rem;}
@media only screen and (min-width: 1024px)  {padding:1.2rem 6.8rem;}
`


const ProceedButton = styled(BaseButton)`
  background-color: ${(props) => (props.enableBuy ? '#4669E8' : '#BCBCBC')};
`;











// old code
// const Exchange = () => {


//   const navigation = useNavigate();
//   useEffect(() => {
//     if (localStorage.getItem('userid')) {

//     } else {
//       navigation('/login')
//     }
//   }, [])
//   return (
//     <Main>
//       <SectionBanner>
//         <img src={banner} alt='banner' />
//         <H1>Clikekart gives services for all IT products</H1>
//       </SectionBanner>
//       <ExchangeButtons>
//         <PopLeft>
//           <img src={popLeft} alt='' />
//         </PopLeft>
//         <PopRight>
//           <img src={popRight} alt='' />
//         </PopRight>
//         <ButtonsDiv>
//           <ButtonLeft>
//             <LeftDec src={leftButtonDec} alt='' />
//             <img src={priceButton} alt='' />
//           </ButtonLeft>
//           <ButtonRight>
//             <RightDec src={rightButtonDec} alt='' />
//             <img src={pickUp} alt='' />
//           </ButtonRight>
//         </ButtonsDiv>
//       </ExchangeButtons>
//       <OfferSection style={{ backgroundImage: `url('${OfferBackground}')` }}>
//         <OfferTitle>BEST OFFER PRICES</OfferTitle>
//         <ContentWrapper>
//           <ProductGrid>
//             <ProductCard>
//               <ProductImageSection>
//                 <BrandImage src={Brand} />
//                 <ProductImage src={Laptop} />
//               </ProductImageSection>
//               <ProductDetails>
//                 <ProductTitle>Dell New Inspiron 7415</ProductTitle>
//                 <RowDetails>
//                   <DetailKey>Current Price</DetailKey>
//                   <p>₹ 5235485</p>
//                 </RowDetails>
//                 <RowDetails>
//                   <DetailKey>Additional Off on Exchange</DetailKey>
//                   <p>₹ 5235485</p>
//                 </RowDetails>
//                 <strong>Total Savings*</strong>
//                 <TextBlue>15000 +No Cost EMI Benefit</TextBlue>
//               </ProductDetails>
//             </ProductCard>
//             <ProductCard>
//               <ProductImageSection>
//                 <BrandImage src={Brand} />
//                 <ProductImage src={Laptop} />
//               </ProductImageSection>
//               <ProductDetails>
//                 <ProductTitle>Dell New Inspiron 7415</ProductTitle>
//                 <RowDetails>
//                   <DetailKey>Current Price</DetailKey>
//                   <p>₹ 5235485</p>
//                 </RowDetails>
//                 <RowDetails>
//                   <DetailKey>Additional Off on Exchange</DetailKey>
//                   <p>₹ 5235485</p>
//                 </RowDetails>
//                 <strong>Total Savings*</strong>
//                 <TextBlue>15000 +No Cost EMI Benefit</TextBlue>
//               </ProductDetails>
//             </ProductCard>
//             <ProductCard>
//               <ProductImageSection>
//                 <BrandImage src={Brand} />
//                 <ProductImage src={Laptop} />
//               </ProductImageSection>
//               <ProductDetails>
//                 <ProductTitle>Dell New Inspiron 7415</ProductTitle>
//                 <RowDetails>
//                   <DetailKey>Current Price</DetailKey>
//                   <p>₹ 5235485</p>
//                 </RowDetails>
//                 <RowDetails>
//                   <DetailKey>Additional Off on Exchange</DetailKey>
//                   <p>₹ 5235485</p>
//                 </RowDetails>
//                 <strong>Total Savings*</strong>
//                 <TextBlue>15000 +No Cost EMI Benefit</TextBlue>
//               </ProductDetails>
//             </ProductCard>
//             <ProductCard>
//               <ProductImageSection>
//                 <BrandImage src={Brand} />
//                 <ProductImage src={Laptop} />
//               </ProductImageSection>
//               <ProductDetails>
//                 <ProductTitle>Dell New Inspiron 7415</ProductTitle>
//                 <RowDetails>
//                   <DetailKey>Current Price</DetailKey>
//                   <p>₹ 5235485</p>
//                 </RowDetails>
//                 <RowDetails>
//                   <DetailKey>Additional Off on Exchange</DetailKey>
//                   <p>₹ 5235485</p>
//                 </RowDetails>
//                 <strong>Total Savings*</strong>
//                 <TextBlue>15000 +No Cost EMI Benefit</TextBlue>
//               </ProductDetails>
//             </ProductCard>
//           </ProductGrid>
//           <ExchangeButton
//             onClick={() => {
//               if (userid) {
//                 navigation('/exchange/options');
//               } else {
//                 navigation('/login');
//               }
//             }}
//           >
//             Exchange Now
//           </ExchangeButton>
//         </ContentWrapper>
//       </OfferSection>
//     </Main>
//   );
// };