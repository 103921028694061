import React, { useEffect, useState } from "react";
import { IoAdd, IoRemove } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import LoadingSpinner from "../../../components/Button/Loading/LoadingSpinner";
import Help from "../../../components/Help/help";
import GotoCartModal from "../../../components/Modals/GotoCartModal";
import MainAccModal from "../../../components/Modals/MainAccModal";
import UpsModal from "../../../components/Modals/UpsModal";
import { AuthContext } from "../../../consts/AuthContext";
import axiosInstance from "../../../consts/axiosInstance";
import { colors, fonts } from "../../../consts/themes";

import { FaRegCircleXmark } from "react-icons/fa6";
import { TiTickOutline } from "react-icons/ti";
import NormalInstall from "../../../components/Modals/NormalInstall";
import PrinterAccModal from "../../../components/Modals/printer/PrinterAccModal";
import WarrentyDetailsModal from "../../../components/Modals/WarrentyDetailsModal";
import Swal from "sweetalert2";


const ProductDetails = () => {

  const navigation = useNavigate();

  //id of  product
  const productdetailid = JSON.parse(localStorage.getItem("Productid"));
  //color id of main catageory , lap , mob etc
  const categorycolor = JSON.parse(localStorage.getItem("productcode"));


  const printerids = JSON.parse(localStorage.getItem("printerid"));
  const desktopid = JSON.parse(localStorage.getItem("desktopid"));
  //id of catageory mob , tab , pc etc
  const product_category_id = JSON.parse(localStorage.getItem("productid"));
  const purchaseTypeId = JSON.parse(localStorage.getItem("codeid"));

  //details of product
  const responses1 = JSON.parse(localStorage.getItem("response"));
  const [modalOpen, setModalOpen] = useState(false);
  const [backup, setBackup] = useState(false);
  const [upsModal, setUpsModal] = useState(false);
  const [printer, setPrinter] = useState(false);
  const [openComments, setOpenComments] = useState(false);
  const [authUser, setAuthUser] = React.useContext(AuthContext);

  const [IsCartId, setIsCartId] = useState(false);
  const [myCartId, setMyCartId] = useState('')
  const [cartId, setCartId] = useState()
  const [details, setDetails] = useState([]);
  const [description, setDescription] = useState('');
  const [selectedImg, setSelectedImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [chart, setChart] = useState(false);
  const [warrentyModal, setWarrentyModal] = useState(false);

  const [itemQuantity, setItemQuantity] = useState(1);  // Tracks the user-selected quantity
  const [stock, setStock] = useState(null); // Tracks the current stock
  const [stockMessage, setStockMessage] = useState(""); // Stock message display


  const Explaindetails = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`getProductDetailedDisplay?id=${productdetailid}`);
      localStorage.setItem("productid", JSON.stringify(response?.data?.product_category_id))
      setDetails(response?.data);
      console.log(response?.data);
      setDescription(response?.data?.description);
      response.data?.images && setSelectedImg(JSON?.parse(response?.data?.images)[0]);
      localStorage.setItem("response", JSON.stringify(response?.data));

      // Set stock from API response
      setStock(response?.data?.stock); // Set the stock from the response
      updateStockMessage(response?.data?.stock, itemQuantity);

      if (response?.data?.customer_category_id) {
        const parsedIds = JSON.parse(response?.data?.customer_category_id);
        if (parsedIds.includes(91)) {
          setChart(true);
        }
      }

    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    Explaindetails();
  }, [productdetailid]);


  async function getallcart() {
    try {
      const response = await axiosInstance.get(`getAllCart/${authUser?.user?.id}`);
      if (Array.isArray(response.data)) {
        if (response?.data?.length === 0) {
          setIsCartId(false);
          // console.log("-> no cart id present")

        } else if (response?.data[0]?.id) {
          localStorage.setItem("cartpid", JSON.stringify(response.data[0].id))
          setMyCartId(response.data[0].id);
          // setIsCartId(!!response.data[0]?.id);
          setIsCartId(true);
          // console.log("-> cart id present")
        }
      }
    } catch (error) {
      console.error("Error fetching cart:", error);
    }
  }

  useEffect(() => {
    getallcart();
  }, []);



  // quantity change button
  let [num, setNum] = useState(1);




  const handleAddQuantity = () => {
    if (itemQuantity < stock) {
      const newQuantity = itemQuantity + 1;
      if (newQuantity <= 10) {
        setItemQuantity(newQuantity);
        updateStockMessage(stock, newQuantity); // Update stock message
      } else {
        setStockMessage("limit reached")
      }
    }
  };

  const handleRemoveQuantity = () => {
    if (itemQuantity > 1) {
      const newQuantity = itemQuantity - 1;
      setItemQuantity(newQuantity);
      updateStockMessage(stock, newQuantity); // Update stock message
    }
  };
  // Function to update stock message dynamically
  const updateStockMessage = (stock, quantity) => {
    const remainingStock = stock - quantity;
    if (remainingStock <= 0) {
      setStockMessage("Out of Stock");
    } else if (remainingStock <= 4) {
      setStockMessage(`Only ${remainingStock} left`);
    } else {
      setStockMessage(""); // No message if more than 4 in stock
    }
  };

  useEffect(() => {
    updateStockMessage(stock, itemQuantity);
  }, [stock, itemQuantity]);


  //customer catagory & sub catagory
  const custcatid = JSON.parse(localStorage.getItem("ccatid"));
  const custsubcatid = JSON.parse(localStorage.getItem("cscatid"));
  const [isProductInCart, setIsProductInCart] = useState(false)
  //console.log(isProductInCart, "status code")
  const [openMainModal, setOpenMainModal] = useState(false);
  const [openUpsModal, setOpenUpsModal] = useState(false);
  const [installModalOpen, setInstallModalOpen] = useState(false);
  const [printerAccModalOpen, setPrinterAccModalOpen] = useState(false);

  const insertcart = async (install) => {

    // details?.offer_price * itemQuantity

    try {
      setLoading(true);
      // console.log(" insert cart api call")
      const formDatas = {
        // amount: details?.offer_price ? details?.offer_price : details?.price,
        customer_category_id: custcatid ? custcatid : 1,
        customer_sub_category_id: custsubcatid ? custsubcatid : 1,
        order_status: "order placed",
        product_category_id: details?.product_category_id,
        product_id: details?.id,
        purchaseTypeId: purchaseTypeId ? purchaseTypeId : 1,
        qnty: itemQuantity,
        rewards: details?.reward_points,
        // total_amount: details?.offer_price ? details?.offer_price * itemQuantity : details?.price * itemQuantity,
        user_id: authUser.user.id,
        referral_code: isCouponValid ? couponCode : null,
        installation: install,
        servicepack: "",
        shipping_address: '',
        custom_sale_id: "",
        custom_sale_report_id: "",
        // installation_id: "",
        // installation_amount: "",
      };

      const options = {
        method: "POST",
        url: 'insertCart',
        headers: {
          "Content-Type": "application/json",
        },
        data: formDatas,
      };

      await axiosInstance
        .request(options)
        .then((response) => {
          // console.log(response?.data?.cart_id, "new cart id")
          if (response?.data?.code === 406) {
            console.log(' payment pending for previous order')
          } else if (response?.data?.code === 404) {
            Swal.fire({
              position: 'center',
              icon: 'error',
              color: '#4169e1',
              text: `${response?.data?.message}`,


            })
          }
          else {
            console.log('response?.data?.cart_id', response?.data)
            localStorage.setItem("cartpid", JSON.stringify(response?.data?.cart_id))
            if (categorycolor === '001' || categorycolor === '002' || categorycolor === '003' || categorycolor === '004') {
              setOpenMainModal(true);
            } else if (categorycolor === '010') {
              setOpenUpsModal(true);
            } else {
              navigation("/sales/shoppingcart")
            }
          }
        })
        .catch(function (error) {
          console.error(error);
          console.log("insert error", error);
        });

    } catch (error) {
      setError(error);
      console.log("update error ", error);

    } finally {
      setLoading(false);
    }
  };





  const [couponCode, setCouponCode] = useState('');
  const [isCouponValid, setIsCouponValid] = useState(null);

  const handleChange = (event) => {
    setCouponCode(event.target.value);
    setIsCouponValid(null);
  };



  const handleSubmit = async () => {
    try {
      setLoading(true);

      const response = await axiosInstance.get('referralcheck', {
        params: {
          referral_code: couponCode
        }
      });

      if (response.data.code == 200) {
        setIsCouponValid(true);
      } else if (response.data.code == 404) {
        setIsCouponValid(false);
      }

    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };



  const handleAddToCart = () => {
    if (stock === 0) {
      // Show SweetAlert for out-of-stock
      Swal.fire({
        icon: 'error',
        title: 'Out of Stock',
        text: 'Sorry, this item is currently out of stock.',
        confirmButtonText: 'OK',
        confirmButtonColor: '#4669E8', // Custom color for the confirm button
      });
    } else {
      if (details?.product_category_id === 11) {
        setInstallModalOpen(true);
      } else {
        insertcart(0);
      }
    }
  };

  const handleModalConfirm = (installValue) => {
    setInstallModalOpen(false);
    insertcart(installValue);
  };


  if (loading) {
    // Loading Spinner Component
    return <LoadingSpinner />
  }

  // if (error) {
  //   // Error Component
  //   return <div>Error: {error}</div>;
  // }

  return (
    <Screen>
      {openUpsModal && <UpsModal close={setOpenUpsModal} />}
      {/* {isProductInCart && <GotocartModal close={setIsProductInCart} />} */}
      {/* {openMainModal && <MainModal close={setOpenMainModal} />} */}

      {isProductInCart && <GotoCartModal close={setIsProductInCart} nav='/sales/shoppingcart' />}
      {openMainModal && <MainAccModal close={setOpenMainModal} />}
      {warrentyModal && <WarrentyDetailsModal data={details?.warranty_details} close={setWarrentyModal} />}
      {installModalOpen && <NormalInstall onConfirm={handleModalConfirm} close={setInstallModalOpen} />}
      {printerAccModalOpen && <PrinterAccModal close={setPrinterAccModalOpen} />}
      <ScreenContent>
        <Sub1>
          <SliderDiv>
            <Img src={selectedImg}></Img>
            <Sub>
              {details?.images &&
                JSON.parse(details?.images)?.map((i, k) => {
                  return (
                    <div
                      key={k}
                      style={{
                        width: "0.8rem",
                        height: "0.8rem",
                        borderRadius: "0.8rem",
                        background: selectedImg === i ? "#4669E8" : "#D9D9D9",
                      }}
                      onClick={() => setSelectedImg(i)}></div>
                  );
                })}
            </Sub>
          </SliderDiv>
        </Sub1>

        <Sub2>
          <ContentDiv>
            <ContMain>
              <Heading>
                {details?.title}
              </Heading>
              <KeyF>
                <p>Key Features</p>
                {/* { description?.split('\n').map((i, k) => { return (<li key={k}>{i.replace(":", " : ")}</li>)})} */}
                <span>{details?.description}</span>
              </KeyF>
              {chart &&
                <Link onClick={() => navigation('/ups/chart')}>
                  <img src="/image/icons/blu-arrow.png" />
                  <span> Product Information</span>
                </Link>
              }
              <Warrenty>
                <div style={{ fontSize: '1rem', fontWeight: 500 }} >{responses1?.warranty} Year warrenty</div>
                <div style={{ color: '#4669E8', fontSize: "0.75rem" }} onClick={() => setWarrentyModal(true)}>View Details</div>
              </Warrenty>
              {stockMessage && (
                <StockMessage className="mt-2">
                  <div>{stockMessage}</div>
                </StockMessage>
              )}
              <NumerAdd>
                <button className="flex justify-center items-center" onClick={handleRemoveQuantity}>
                  <IoRemove />
                </button>
                <QtyBox>{itemQuantity}</QtyBox>
                <button className="flex justify-center items-center" onClick={handleAddQuantity}>
                  <IoAdd />
                </button>
              </NumerAdd>
              <FooterDiv>
                <Cards1>
                  <Cash>
                    <Price>
                      <Cash1>
                        <span>₹</span><p>{responses1?.offer_price * itemQuantity}</p>
                      </Cash1>
                    </Price>
                    <MRP>
                      <p>M.R.P</p><p> : {responses1?.price * itemQuantity}</p>
                    </MRP>
                    <MRP>
                      <p>Clikeprice</p><p> : {responses1?.offer_price * itemQuantity}</p>
                    </MRP>
                    <MRP>
                      <p>Clikepoints</p><p> : {responses1?.reward_points * itemQuantity} </p>
                    </MRP>
                  </Cash>
                  <Button>
                    <button onClick={() => {
                      handleAddToCart();
                      // insertcart();
                      // setOpenUpsModal(true);
                    }}>Add to cart</button>
                  </Button>
                  <RefContainer>
                    <RefWraper>
                      <Input>
                        <input
                          type='text'
                          placeholder='referral code'
                          onChange={handleChange}
                          value={couponCode}
                        />
                      </Input>
                      <ApplyBTn onClick={handleSubmit}>Apply</ApplyBTn>
                    </RefWraper>
                    {isCouponValid === true && <ApplyIcon style={{ color: 'green' }}><span>applied</span><TiTickOutline size={15} /> </ApplyIcon>}
                    {isCouponValid === false && <ApplyIcon style={{ color: 'red' }}><span>not applicable</span><FaRegCircleXmark size={15} /></ApplyIcon>}
                  </RefContainer>
                </Cards1>
              </FooterDiv>
            </ContMain>
          </ContentDiv>
        </Sub2>
      </ScreenContent>
      <Help />
    </Screen>
  );
};

export default ProductDetails;





const ApplyIcon = styled.div`
display:flex;
align-items:center;
gap:0.3rem;
font-size: 0.8rem;
`;

const ApplyBTn = styled.button`
  background-color: #e1ecf4;
  border-radius: 3px;
  border: 1px solid #7aa7c7;
  box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #39739d;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", "Liberation Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding:0.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
`



const Input = styled.div`
input{
  box-sizing: border-box;
  width: 100%;
  height: 2rem;
  border: 0.1px solid ${colors.line};
  border-radius: 5px;
  padding:0 0.5rem;
  &:focus {
      border-color: #587ef5;
      outline: 0;
    }
  }
`





const RefContainer = styled.div`
margin-top: 1rem;
display: flex;
flex-direction: column;
gap:0.5rem;
`;


const RefWraper = styled.div`
display: flex;
align-items: center;
gap:0.5rem;
`;

const StockMessage = styled.p`
  color: ${colors.red};
  font-weight: bold;
  /* margin-bottom: 10px; */
`;




















const Link = styled.div`
margin-top:0.5rem;
color: #4669E8;
font-weight: 500;
font-size:0.9rem;
display: flex;
align-items: center;
image{
  width:24px;
  height:24px;
}
`;





const Screen = styled.div`
  margin-top: 5rem;
  height: calc(100vh - 5rem);
  max-height: calc(100vh - 5rem);
  overflow-y: auto;
`;

const ScreenContent = styled.div`
display: grid;
grid-template-columns: repeat(12, minmax(0, 1fr));
`;


const Sub1 = styled.div`
grid-column: span 5 / span 5;
@media only screen and (min-width:250px) and (max-width:1023px) {grid-column: span 12 / span 12;}
`;


const SliderDiv = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding-top:5rem;
padding-bottom:1rem;
@media only screen and (min-width:250px) and (max-width:1023px) {
padding-top:1rem;
padding-bottom:1rem;
}
`



const Sub = styled.div`
display: flex;
gap:0.625rem;
justify-content:center;
flex-direction: row;
margin-top: 1rem;
`;


const Sub2 = styled.div`
grid-column: span 7 / span 7; 
 /* background-color:orange; */
@media only screen and (min-width:250px) and (max-width:1023px) {grid-column: span 12 / span 12;}
`;


const Img = styled.img`
  width: 400px;
  height: 400px;
  object-fit: contain;
  @media only screen and (min-width:768px) and (max-width:1023px) {
  width: 300px;
  height: 300px;
  }
  @media only screen and (min-width:450px) and (max-width:767px) {
  width: 250px;
  height: 250px;
  }
  @media only screen and (min-width:250px) and (max-width:449px) {
  width: 220px;
  height: 220px;
  }
`;

const ContentDiv = styled.div`
display: flex;
flex-direction: column;
padding-top:5rem;
padding-bottom:1rem;
padding-left:1rem;
padding-right:1rem;
@media only screen and (min-width:250px) and (max-width:1023px) {
align-items:center;
padding:1rem 1rem;
}
`

const ContMain = styled.div`
`;


const Heading = styled.div`
font-size: 1.5rem;
font-weight: ${fonts.fontWeight5};
`;


const KeyF = styled.div`
display:flex;
flex-direction:column;
width: 100%;
max-width:750px;
  p {
    margin: 0px;
    padding:0.5rem 0;
    font-size:1.1rem;
    font-weight: ${fonts.fontWeight5};
  }
  span{
    margin:0;
    font-size:1rem;
    font-weight:300;
    @media only screen and (min-width:768px) and (max-width:1023px) {
        font-size:0.9rem;
}
    @media only screen and (min-width:250px) and (max-width:767px) {
        font-size:0.8rem;
}
  }
`;

const WarDiv = styled.div`
margin-top:1.563rem;
padding:0.75rem 0.813rem;
display:flex;
justify-content:space-between;
align-items:center;
border:1px solid #00000033;
max-width:22.625rem;
width:100%;
background-color:lightgrey;
`;


const NumerAdd = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0 0;
  margin-top:1.563rem;

  // justify-content: space-evenly;
  button {
    background-color: ${colors.white};
    border: 0.5px solid ${colors.ash};
    width: 35px;
    height: 35px;
  }
  input {
    background-color: ${colors.white};
    border: 0.5px solid ${colors.ash};
 
    height: 31px;
    width: 5.125rem;
    margin: 0 10px;
  
    text-align: center;
  }
`;

const Cash = styled.div`
  margin-top: 1rem;
`;
export const Mrp = styled.div`
  width: fit-content;
  p {
    width: 9rem;
  }
  display: flex;
  p {
    color: ${colors.ash};
    font-size: 13px;
    margin: 0;
    font-weight: ${fonts.fontWeight5};
  }
`;




const QtyBox = styled.div`
border: 0.5px solid ${colors.ash};
height: 2.188rem;
width:5.125rem;
margin: 0 10px;
text-align: center;
display:flex;
justify-content:center;
align-items:center;
`;




const FooterDiv = styled.div``;

const Cards1 = styled.div``;

const Price = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Cash1 = styled.div`
display: flex;
flex-direction: row;
gap: 1rem;
align-items: center;
font-weight: 600;
font-size:1.5rem;
padding-bottom:0.938rem;
color:#4669E8;
  span {
    cursor: pointer; 
  }
  p{
     margin:0;
  }
`;

const MRP = styled.div`
display:flex;
flex-direction:row;
gap:1rem;
 color: ${colors.ash};
p{
  margin:0;
  font-weight:400;
  font-size:1rem;
}
`;

export const Button = styled.div`
  button {
    max-width: 12.75rem;
    padding: 12px 57px;
    background-color: ${colors.blue};
    color: ${colors.white};
    border: none;
    margin-top: 1.313rem;
  }
`;


const Warrenty = styled.div`
display:flex;
justify-content: space-between;
align-items: center;
border:1px solid #00000033;
max-width:22.625rem;
padding:0.75rem 0.813rem;
margin-top:1.563rem
`;

