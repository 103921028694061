import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useContext } from 'react';
import { getOrderDetails, getRewardList } from '../../../api/api';
import { AuthContext } from '../../../consts/AuthContext';
import { CART_DATA } from '../../../MappingDatas/ProductCartData';
import { Box, MyCustomDate, DetailFlex, Details, Heading, Image, Item, Product, ProductP, Quantity, Review, Rewardss, Select, Table, Title } from '../Orders/MyOrdersStyle';
import { ClikPointDiv, HeadingWish, ItemWish, PointsDiv } from './RewardsPStyle';
import clikeLogo from '../../../assets/Images/png/clicke.png'
import axiosInstance from '../../../consts/axiosInstance';
import LoadingSpinner from '../../../components/Button/Loading/LoadingSpinner';

const RewardsP = () => {

  const [cartData, setCartData] = useState([]);
  const [authUser, setAuthUser] = useContext(AuthContext)
  const [orderData, setOrderData] = useState([])

  const [rewardData, setRewardData] = useState({})

  useEffect(() => {
    setCartData([...CART_DATA])
    getRewardPoints()
    getOrderD()
  }, [authUser?.user?.id])

  const getRewardPoints = () => {

    axiosInstance.get(`getRewardList/${authUser?.user?.id}`).then(resp => {
      setRewardData(resp?.data)
    }).catch(e => {
      console.log(e, "error")
    })


  }


  const getOrderD = async () => {

    try {
      const resp = await axiosInstance.get(`getOrderDetails/${authUser?.user?.id}`)
      console.log('re', resp?.data)
      setOrderData(resp?.data)
    } catch (error) {
      console.log('error', error)
    }



  }





  return (
    <div>
      <Title>Reward Points</Title>

      {/* <Select onChange={(e) => setSortBy(e.target.value)}>
        <option value='latest'>Latest</option>
        <option value='oldest'>Oldest</option>
      </Select> */}

      <PointsDiv>
        <ClikPointDiv>1 x <img src={clikeLogo} /> Clike Points = ₹ 10</ClikPointDiv>
        <ClikPointDiv>Available Clike Points <img src={clikeLogo} /> x {rewardData?.rewards}</ClikPointDiv>
      </PointsDiv>

      <Table>
        <thead>
          <HeadingWish>
            <ProductP>Product</ProductP>
            <Details>Details</Details>
            <Quantity>Quantity</Quantity>
            <Rewardss>Clike Points</Rewardss>
          </HeadingWish>
        </thead>
        <tbody>
          {orderData && <div>
            {orderData && orderData?.map((order, index) => (
              <div key={index}>
                {/* Map through cart products */}
                {order.cart.map((cartItem) => (
                  <div key={cartItem.id}>
                    {cartItem.productArray.map((product) => (
                      <ItemWish key={product.productId}>
                        <Product>
                          <Box>
                            <Image src={JSON.parse(product.productImages)[0]} alt={product.title} />
                          </Box>
                        </Product>
                        <Details>
                          <DetailFlex>
                            {product.title}
                          </DetailFlex>
                        </Details>
                        <Quantity>
                          {product.qnty}
                        </Quantity>
                        <Rewardss> <img src={clikeLogo} alt="logo" /> x {product.reward_points}</Rewardss>
                      </ItemWish>
                    ))}
                  </div>
                ))}

                {/* Map through custom PC products */}
                {/* <h2>Custom PC Products</h2> */}
                {/* {order.custompc.map((pcItem) => (
                  <div key={pcItem.id}>
                    {pcItem.products.map((product) => (
                      <ItemWish key={product.id}>
                        <Product>
                          <Box>
                            <Image src={JSON.parse(product.images)[0]} alt={product.title} />
                          </Box>
                        </Product>
                        <Details>
                          <DetailFlex>
                            {product.title}
                            <Date>
                            </Date>
                          </DetailFlex>
                        </Details>
                        <Quantity>
                          {product.quantity}
                        </Quantity>
                        <Rewardss> <img src={clikeLogo} alt="logo" /> x {product.reward_points}</Rewardss>
                      </ItemWish>
                    ))}
                  </div>
                ))} */}

                {/* Map through CCTV products */}
                {/* {order.cctv.map((cctvItem) => (
                  <div key={cctvItem.id}>
                    {cctvItem.products.map((product) => (
                      <ItemWish key={product.id}>
                        <Product>
                          <Box>
                            <Image src={JSON.parse(product.images)[0]} alt={product.title} />
                          </Box>
                        </Product>
                        <Details>
                          <DetailFlex>
                            {product.title}
                            <Date>
                            </Date>
                          </DetailFlex>
                        </Details>
                        <Quantity>
                          {product.quantity}
                        </Quantity>
                        <Rewardss> <img src={clikeLogo} alt="logo" /> x {product.reward_points}</Rewardss>
                      </ItemWish>
                    ))}
                  </div>
                ))} */}
              </div>
            ))}
          </div>}
















          {/* {orderData && orderData?.map((item, id) => {
            return (
              <ItemWish key={id}>
                <Product>
                  <Box>
                    <Image src={item?.images[0]} />
                  </Box>
                </Product>
                <Details>
                  <DetailFlex>
                    {item?.title}
                    <Date>

                    </Date>
                  </DetailFlex>
                </Details>
                <Quantity>
                  {item?.quantity}

                </Quantity>

                <Rewardss> <img src={clikeLogo} alt="logo" /> x {item?.reward_points}</Rewardss>
              </ItemWish>
            )
          })} */}

        </tbody>
      </Table>
    </div >
  )
}

export default RewardsP