import React, { useContext } from 'react'
import { GrFormClose } from 'react-icons/gr';
import { ModalContainer, Modal, Overlay } from "../../../components/modal";
import Icon from '../../../assets/Images/png/Vector.png'
import { ModalButton, ModalDiv, ThankDiv } from './ClaimModalStyle';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AuthContext } from '../../../consts/AuthContext';
import { exchangeclikepoints } from '../../../api/api';
import axios from 'axios';
import axiosInstance from '../../../consts/axiosInstance';


const ClaimModal = ({ onClose, priceId, clikePoints }) => {

  const [authUser, setAuthUser] = useContext(AuthContext);



  const claimHandler = async () => {
    try {
      const formData = {
        userid: authUser?.user?.id,
        price_id: priceId && priceId,
      }

      const options = {
        method: 'POST',
        url: 'exchangeclikepoints',
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
        },
        data: formData,
      };

      await axiosInstance.request(options).then((response) => {
        console.log("🚀 ~ bulk order update response", response);
      })

    } catch (error) {
      console.log("🚀 ~ update cart ", error);
    }
    finally {
      onClose();
    }
  }





  return (
    <ModalContainer>
      <Modal>
        <Overlay>
          <ModalDiv>
            <ImgContainer>
              <img src='/image/coin-group.png' />
            </ImgContainer>
            <TextContainer>
              <Text1>You have successfully completed the exchange process</Text1>
              <Text2>Your exchange value has been converted to Clikepoints</Text2>
            </TextContainer>

            <PointContainer>
              <Points>{clikePoints}</Points>
              <Text1>points</Text1>
            </PointContainer>
            <ModalButton>
              <button onClick={claimHandler}>Claim</button>
            </ModalButton>
          </ModalDiv>
        </Overlay>
      </Modal>
    </ModalContainer>
  )
}

export default ClaimModal;





const ImgContainer = styled.div`
img{
    width:100%;
    height:auto;
}
`;


const PointContainer = styled.div`
padding:0;
margin:0;
text-align: center;
`;



const TextContainer = styled.div`
text-align: center;
width: 100%;
padding:0;
margin:0;
`;


const Text1 = styled.p`
font-weight:400;
font-size:1rem;
line-height:1.5rem;
padding:0;
margin:0;
`;


const Text2 = styled.p`
color:#4669E8;
font-weight:400;
font-size:1.5rem;
line-height:2rem;
padding:0;
margin:0;
`;


const Points = styled.div`
text-align: center;
color:#4669E8;
font-weight:700;
font-size:3rem;
line-height:4rem;
padding:0;
margin:0;
`;







// const SubmitModal = ({setIsOpen}) => {
//     const navigate = useNavigate()
//   return (
//     <ModalContainer>
//         <Modal>
//             <Overlay>
//                 <ModalDiv>
//                 <div>
//                     <img src={Icon}/>
//                 </div>
//                 <ThankDiv>
//                     <p>Thank you, Clikekart will contact you.</p>
//                 </ThankDiv>
//                 <ModalButton>
//                     <button onClick={() => {setIsOpen(false) ; navigate('/exchange/confirmation')} }>Close</button>
//                 </ModalButton>
//                 </ModalDiv>

//             </Overlay>
//         </Modal>
//     </ModalContainer>
//   )
// }

// export default SubmitModal



