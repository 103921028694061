import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalContainer, Modal, Overlay } from "../modal"
import styled from 'styled-components';
import { IoClose } from "react-icons/io5";
import { Button, ButtonContainer, CloseContainer, Container, Head1, InnerConatiner } from './ModalStyled';


const CctvAccModal = ({ close, submit }) => {

  return (
    <ModalContainer>
      <Modal Modal>
        <Overlay>
          <Container>
            <CloseContainer>
              <IoClose size={22} onClick={() => close(false)} />
            </CloseContainer>

            <InnerConatiner>
              <Head1>Do you need Installation ?</Head1>
              <ButtonContainer>
                <Button onClick={() => submit(0)}>No</Button>
                <Button bg={1} txt={1} onClick={() => submit(1)}>Yes</Button>
              </ButtonContainer>
            </InnerConatiner>

          </Container>
        </Overlay>
      </Modal>
    </ModalContainer>
  )
}

export default CctvAccModal;

