import axios from 'axios';
import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { RiExchangeFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { list_estimatedprice, productcategories } from '../../../api/api';
import { AuthContext } from '../../../consts/AuthContext';
import { colors, fonts } from '../../../consts/themes';
import LoadingSpinner from '../../../components/Button/Loading/LoadingSpinner';
import ErrorComponent from '../../../components/Button/error/ErrorComponent';
import axiosInstance from '../../../consts/axiosInstance';
import styled from "styled-components";



const Options = () => {

  const navigation = useNavigate();

  const [category, setCategory] = useState([]);
  const [authUser, setAuthUser] = useContext(AuthContext)
  const [id, setId] = useState('')
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  // product categories
  const product_categories = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('getProductCategory');
      setCategory(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  //exchange summary 
  const getPrice = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('list_estimatedprice', {
        params: {
          user_id: authUser?.user?.id,
        }
      });
      setData(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };




  useEffect(() => {
    product_categories();
    getPrice();
  }, []);



  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }

  return (

    <Screen>

      {data.length > 0 && <ExchangeSumDiv ><ExchangeSumButtn onClick={() => navigation('/exchange/confirmation')}>
        Exchange Summary <RiExchangeFill size={25} /></ExchangeSumButtn></ExchangeSumDiv>}

      <ScreenCon>

        {category?.map((item) => {

          if (item.color === '001' || item.color === '003' || item.color === '008' ||
            item.color === '010' || item.color === '004' || item.color == '013') {
            return (
              <CardImg key={item.id}>
                <ImgCard
                  style={{ background: `linear-gradient(45deg,{item.color.start} 0%, {item.color.end} 100%)`, }}
                  onClick={() => {
                    navigation(`/exchange/${(item?.product_category_name).toLowerCase()}`, { state: { id: item?.id } })
                  }}>
                  <img src={item?.product_category_image} alt={item?.product_category_name} />
                </ImgCard>
                <p style={{ color: "black" }}>{item?.product_category_name}</p>
              </CardImg>
            );
          }
        })}
      </ScreenCon>
    </Screen>
  );
};

export default Options;









export const ExchangeSumDiv = styled.div`
width:100%;
display: flex;
justify-content: flex-end;
`

export const ExchangeSumButtn = styled.button`
    display: flex;
    justify-content:center;
    align-items: center;
    border: none;
    padding: 12px 10px;
    border-radius: 8px;
    background: rgb(70, 105, 232);
    color: white;
    margin: 1rem;
`


const Screen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:0;
  padding-top:5rem;
  height: calc(100vh - 5rem);
`;

const ScreenCon = styled.div`
display: grid;
grid-template-columns: repeat(12, minmax(0, 1fr));
overflow-y: scroll;
margin-top:1rem;
@media only screen and (min-width: 250px) and (max-width: 350px) {gap: 0.5rem;}
@media only screen and (min-width: 351px) and (max-width: 768px) {gap: 1rem;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {gap: 1.5rem;}
@media only screen and (min-width: 1024px) {gap: 1.5rem;}
`;

const CardImg = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
@media only screen and (min-width: 250px) and (max-width: 354px) {grid-column: span 12 / span 12;}
@media only screen and (min-width: 355px) and (max-width: 768px) {grid-column: span 6 / span 6;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {grid-column: span 4 / span 4;}
@media only screen and (min-width: 1024px) {grid-column: span 3 / span 3;}
`;


const ImgCard = styled.div`
background-color: #ccc; 
width: 190px;
height: 190px;
background-color:#ccc;
border: .5px solid #ccc;
img{
    width: 190px;
    height: 190px;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    transition :all 0.3s ;
}
p{
    color: white;
    font-size: 16px;
    margin-top: 0px;
    padding-inline:10px;
    text-align:center;
    font-weight: ${fonts.fontWeight5};
    letter-spacing: 0.04rem;
}

@media screen and (max-width: 500px){
    justify-content: center;
    align-items: center;
    justify-content: space-around; 
    width: 140px;
    height: 140px;
    img{
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
}
p{
    color: white;
    font-size: 1rem;
    font-weight: 450;
    margin-top: 0px;
}
}
@media screen and (max-width: 320px){
justify-content: center;
align-items: center;
justify-content: space-around; 
width: 130px;
height: 130px;
img{
justify-content: center;
align-items: center;
width: 130px;
height: 130px;
}
p{
color: white;
font-size: 1rem;
font-weight: 450;
margin-top: 0px;
}
}
`