import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import LoadingSpinner from '../../../components/Button/Loading/LoadingSpinner';
import { AuthContext } from '../../../consts/AuthContext';
import axiosInstance from '../../../consts/axiosInstance';
import { colors } from '../../../consts/themes';
import {MyCustomDate, DetailFlex, Details, Heading, Item, Product, ProductP, Quantity, Review, Select, Table, Title } from './MyOrdersStyle.js';

const MyOrders = () => {
  const [cartData, setCartData] = useState([]);
  const [authUser, setAuthUser] = useContext(AuthContext);
  const [details, setDetails] = useState({});
  const [isOpen, setOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [myData, setMyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState('latest'); // State for sorting
  const navigate = useNavigate();

  const parseJSON = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      console.error("Failed to parse JSON:", error);
      return [];
    }
  };

  const getOrderItems = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`getOrderDetails/${authUser?.user?.id}`);
      const data = response.data;
      setProducts(data);
      processProducts(data);
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const processProducts = (data) => {
    const combinedData = data.map((item) => {
      const cartProducts = item.cart[0]?.productArray || [];
      const cctvProducts = item.cctv[0]?.products || [];
      const custompcProducts = item.custompc[0]?.products || [];
      const customBidProducts = item.bidinitial[0]?.images || [];
      const customBidWinProducts = item.bidwin[0]?.images || [];
// console.log(customBidProducts)
      let images = [];
      const usedImages = new Set();

      const addImage = (image) => {
        if (image && !usedImages.has(image)) {
          images.push(image);
          usedImages.add(image);
        }
      };

      if (cartProducts.length > 0) {
        const cartImage = parseJSON(cartProducts[0]?.productImages)[0] || "";
        addImage(cartImage);
      }

      if (cctvProducts.length > 0) {
        const cctvImage = parseJSON(cctvProducts[0]?.images)[0] || "";
        addImage(cctvImage);
      }

      if (custompcProducts.length > 0) {
        const custompcImage = parseJSON(custompcProducts[0]?.images)[0] || "";
        addImage(custompcImage);
        console.log(custompcImage);
      }

      if (customBidProducts.length > 0) {
        console.log("entered")
        const custombidImage = parseJSON(customBidProducts) || "";
        addImage(custombidImage);
        console.log(custombidImage);
      }

      if (customBidWinProducts.length > 0) {
        console.log("entered")
        const custombidWinImage = parseJSON(customBidWinProducts) || "";
        addImage(custombidWinImage);
        console.log(custombidWinImage);
      }

      return {
        images,
        details: item,
        imagesCount: images.length,
      };
    });

    setMyData(combinedData);
  };

  
  const parsePurchaseDate = (dateString) => {
    const parsedDate = new Date(dateString.replace(/(\d{1,2}) (\w+) (\d{4})/, '$2 $1, $3'));
    // console.log(`Parsed date: ${parsedDate}, Original: ${dateString}`); // Log the parsed date
    return parsedDate;
  };
  
  const sortData = (data, order) => {
    return data.sort((a, b) => {
      const idA = a.details.invoice_id;
      const idB = b.details.invoice_id;
  
      // Sort numerically if invoice IDs are numbers; otherwise, alphabetically
      if (order === 'asc') {
        return idA < idB ? -1 : idA > idB ? 1 : 0;
      } else {
        return idA > idB ? -1 : idA < idB ? 1 : 0;
      }
    });
  };
  useEffect(() => {
    if (authUser?.user?.id) {
      getOrderItems();
    }
  }, [authUser?.user?.id]);
  

  // Use effect to sort myData whenever sortOrder changes
  const sortedData = sortData([...myData], sortOrder);
  console.log("Sorted Data:", sortedData); // Log sorted data
  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <Title>My Orders</Title>
      <Select
  value={sortOrder}
  onChange={(e) => setSortOrder(e.target.value)} // Update sortOrder on selection
>
  <option value='asc'>Oldest</option>
  <option value='desc'>Latest </option>
</Select>
      <Table>
        <thead>
          <Heading>
            <ProductP>Product</ProductP>
            <Details>Invoice</Details>
            <Quantity>Amount</Quantity>
            <Review>Details</Review>
            <Review>Pdf</Review>
          </Heading>
        </thead>
        <tbody>
          {sortedData.map((item, index) => (
            <Item key={index}>
             <Product>
  <Boxx images={item.images.slice(0, 4)} imagesCount={Math.min(item.images.length, 4)}>
    {item.images.slice(0, 4).map((image, i) =>
      image ? (
        <Imagee
          key={i}
          src={image}
          alt={`Product ${i + 1}`}
          className="image"
        />
      ) : null
    )}
  </Boxx>
</Product>
              <Details>
                <DetailFlex>
                  Invoice {item.details.invoice_id}
                  <span >Purchased on {item.details.purchase_date}</span>
                </DetailFlex>
              </Details>
              <Quantity>₹ {item.details.total}</Quantity>
              <Review
                onClick={() => {
                  navigate("/order/details", { state: { data: item.details } });
                }}
              >
                view
              </Review>
              <Review>
                {item.details.invoice_pdf ? (
                  <a
                    href={item.details.invoice_pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {item.details.invoice_pdf.split("/").pop()}
                  </a>
                ) : (
                  <span style={{ color: 'black' }}>No PDF available</span>
                )}
              </Review>
            </Item>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default MyOrders;

// Styled components (Boxx and Imagee)


export const Boxx = styled.div`
  display: grid;
  border: 1px solid ${colors.medium_gray};
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  width: 10rem; /* Set fixed width */
  height: 10rem; /* Set fixed height */
 
  ${({ imagesCount }) => {
    switch (imagesCount) {
      case 4:
        return `
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-template-areas:
            "img1 img2"
            "img3 img4";
          .image:nth-child(1) {
            grid-area: img1;
          }
          .image:nth-child(2) {
            grid-area: img2;
          }
          .image:nth-child(3) {
            grid-area: img3;
          }
          .image:nth-child(4) {
            grid-area: img4;
          }
        `;
      case 3:
        return `
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: 1fr 1fr;
          grid-template-areas:
            "img1 img2"
            "img3 img3";
          .image:nth-child(1) {
            grid-area: img1;
          }
          .image:nth-child(2) {
            grid-area: img2;
          }
          .image:nth-child(3) {
            grid-area: img3;
          }
        `;
      case 2:
        return `
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-template-areas:
            "img1 img2"
            "img1 img2";
          .image:nth-child(1) {
            grid-area: img1;
          }
          .image:nth-child(2) {
            grid-area: img2;
          }
        `;
      case 1:
        return `
          grid-template-columns: 1fr;
          grid-template-rows: 1fr;
          .image {
            grid-column: 1 / -1;
            grid-row: 1 / -1;
          }
        `;
      default:
        return "";
    }
  }}
`;
export const Imagee = styled.img`
  width: 100%;
  height: 100%;
  padding: 0.2rem;
  border: 0.5px solid ${colors.medium_gray};
  object-fit: contain; /* Ensure the entire image fits within the cell while preserving aspect ratio */
  display: block; /* Remove default inline spacing */
  box-sizing: border-box; /* Include border and padding in element dimensions */
  overflow: hidden; /* Ensure no overflow of content */
`;















































//before merging the cart 
// const MyOrders = () => {

//   const [cartData, setCartData] = useState([]);
//   const [authUser, setAuthUser] = useContext(AuthContext)
//   const [details, setDetails] = useState({});
//   const [isOpen, setOpen] = useState(false);

//   const [products, setProducts] = useState([]);
//   const [reversedProducts, setReversedProducts] = useState([]);
//   const [sortOrder, setSortOrder] = useState('latest');
//   const [loading, setLoading] = useState(false);


//   const getOrderItems = async () => {
//     try {
//       setLoading(true);
//       const response = await axiosInstance.get(`getOrderDetails/${authUser?.user?.id}`);
//       const data = response.data;
//       setProducts(data);
//       setReversedProducts([...data].reverse());
//     } catch (error) {
//       console.error(error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     getOrderItems()
//   }, [])


//   const reverseProducts = () => {
//     setReversedProducts(sortOrder === 'latest' ? [...products].reverse() : [...products]);
//   };

//   const handleSortChange = (e) => {
//     setSortOrder(e.target.value);
//   };


//   useEffect(() => {
//     reverseProducts();
//   }, [sortOrder, products]);




//   if (loading) {
//     return <LoadingSpinner />
//   }




//   return (
//     <div>
//       {isOpen && <FeedbackModal setOpens={setOpen} details={details} />}

//       <Title>My Orders</Title>
//       <Select onChange={handleSortChange}>
//         <option value='latest'>Latest</option>
//         <option value='oldest'>Oldest</option>
//       </Select>

//       <Table>
//         <thead>
//           <Heading>
//             <ProductP>Product</ProductP>
//             <Details>Details</Details>
//             <Quantity>Quantity</Quantity>
//             <Review></Review>
//           </Heading>
//         </thead>
//         <tbody>
//           {reversedProducts.map((item) => {
//             const date = new window.Date(item.purchase_date);
//             return (
//               <Item>
//                 <Product>
//                   <Box>
//                     <Image src={item?.images[0]} />
//                   </Box>
//                 </Product>
//                 <Details>
//                   <DetailFlex>
//                     {item?.title}
//                     <Date>
//                       Purchased on
//                       {'  ' + date?.toDateString()}
//                     </Date>
//                   </DetailFlex>
//                 </Details>
//                 <Quantity>
//                   {item?.quantity}

//                 </Quantity>
//                 {/* <Review onClick={() => [setOpen(true), setDetails(item)]}>Write product review</Review> */}
//               </Item>
//             )
//           })}

//         </tbody>
//       </Table>
//     </div>
//   )
// }

// export default MyOrders















// import axios from 'axios';
// import React, { useEffect, useState } from 'react'
// import { useContext } from 'react';
// import { AuthContext } from '../../../consts/AuthContext';
// import FeedbackModal from './FeedbackModal';
// import axiosInstance from '../../../consts/axiosInstance';
// import LoadingSpinner from '../../../components/Button/Loading/LoadingSpinner';
// import { colors, fonts } from '../../../consts/themes';
// import styled from 'styled-components';


// const MyOrders = () => {

//   const [cartData, setCartData] = useState([]);
//   const [authUser, setAuthUser] = useContext(AuthContext)
//   const [details, setDetails] = useState({});
//   const [isOpen, setOpen] = useState(false);

//   const [products, setProducts] = useState([]);
//   const [reversedProducts, setReversedProducts] = useState([]);
//   const [sortOrder, setSortOrder] = useState('latest');
//   const [loading, setLoading] = useState(false);


//   const getOrderItems = async () => {
//     try {
//       setLoading(true);
//       const response = await axiosInstance.get(`getOrderDetails/${authUser?.user?.id}`);
//       const data = response.data;
//       setProducts(data);
//       setReversedProducts([...data].reverse());
//     } catch (error) {
//       console.error(error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     getOrderItems()
//   }, [])


//   const reverseProducts = () => {
//     setReversedProducts(sortOrder === 'latest' ? [...products].reverse() : [...products]);
//   };

//   const handleSortChange = (e) => {
//     setSortOrder(e.target.value);
//   };


//   useEffect(() => {
//     reverseProducts();
//   }, [sortOrder, products]);




//   if (loading) {
//     return <LoadingSpinner />
//   }




//   return (
//     <div>
//       {isOpen && <FeedbackModal setOpens={setOpen} details={details} />}

//       <Title>My Orders</Title>
//       <Select onChange={handleSortChange}>
//         <option value='latest'>Latest</option>
//         <option value='oldest'>Oldest</option>
//       </Select>

//       <Table>
//         <thead>
//           <Heading>
//             <ProductP>Product</ProductP>
//             <Details>Details</Details>
//             <Quantity>Address Details</Quantity>
//             <Review>re</Review>
//           </Heading>
//         </thead>
//         <tbody>
//           {reversedProducts.map((item) => {
//             const date = new window.Date(item.purchase_date);
//             return (
//               <Item>
//                 <Product>
//                   <Box>
//                     <Image src={item?.images[0]} />
//                   </Box>
//                 </Product>
//                 <Details>
//                   <DetailFlex>
//                     {item?.title}
//                     <Date>
//                       Purchased on
//                       {'  ' + date?.toDateString()}
//                     </Date>
//                   </DetailFlex>
//                 </Details>
//                 <Quantity>
//                   {item?.quantity}

//                 </Quantity>
//                 <Review onClick={() => [setOpen(true), setDetails(item)]}>Write product review</Review>
//               </Item>
//             )
//           })}

//         </tbody>
//       </Table>
//     </div>
//   )
// }

// export default MyOrders;







// const Select = styled.select`
//     background: ${colors.white};
//     padding: .5rem .4rem;
//     border: 1px solid ${colors.medium_gray};
//     border-radius: 10px;
//     cursor:pointer;
//     width:8rem;
// `

// const Table = styled.table`
//     padding-block:2rem;
//     @media (max-width: 1200px) {
//         width:100%;
//     }
// `

// const Product = styled.td`
//     width:10.5rem;
//     display: flex;
//     align-items: center;
//     @media (max-width:1200px){
//         width:20%
//     }
//     @media (max-width:1140px){
//         width:25%
//     }
//     @media (max-width:700px){
//         order:0;
//         width:50%;
//     }
// `

// const ProductP = styled(Product)`
// border:1px solid blue;
//     padding-left:2rem;
// `
// const Title = styled.h4`
//     font-size:24px;
//     color:${colors.black};
//     font-weight:${fonts.fontWeight5};
//     margin-bottom: 1rem;
    
// `

// const Heading = styled.tr`
// border:1px solid red;
//     display:flex;
//     /* border-top:1px solid ${colors.medium_gray};
//     border-bottom:1px solid ${colors.medium_gray}; */
//     height: 3rem;
//     @media (max-width:1140px) {
//         display:none;
//     }
// `

// const Details = styled.td`
// border:1px solid green;
//     width: 27rem;
//     display:flex;
//     align-items: center;
//     justify-content: center;
//     @media (max-width:1500px) {
//         width:16rem;
//     }
//     @media (max-width:1200px){
//         width: 50%;
//     }
//     @media (max-width:1140px){
//         width: 70%;
//     }
//     @media (max-width:700px){
//         order:2;
//         width:100%;
//     }
// `
// const Item = styled.tr`
//     display:flex;
//     padding-block: 0.6rem;
//     border-bottom:1px solid ${colors.medium_gray};
//     @media (max-width:1140px){
//         flex-wrap:wrap;
//         row-gap: 1rem;
//         padding-top: 2rem;
//     }
// `

// const Quantity = styled.td`
// border:1px solid orange;
//     width:13rem;
//     display:flex;
//     align-items: center;
//     justify-content: center;
//     @media (max-width:1500px) {
//         width:8rem;
//     }
//     @media (max-width:1140px){
//         width: 25%;
//         justify-content: flex-start;
//         padding-left: 4.3rem;
//     }
//     @media (max-width:700px){
//         order:1;
//         width: 50%;
//         justify-content: flex-end;
//         padding-right: 2rem;
//     }
// `
// const Rewardss = styled.td`
//     width: 24rem;
//     display:flex;
//     align-items: center;
//     justify-content: center;
//     @media (max-width:1500px) {
//         width:16rem;
//     }
//     @media (max-width:1200px){
//         width: 50%;
//     }
//     @media (max-width:1140px){
//         width: 70%;
//     }
//     @media (max-width:700px){
//         order:2;
//         width:100%;
//     }
//     img{
//     width: 20px;
//     height: 20px;
//     margin: 10px;
// }
// `

// const Review = styled.td`
// border:1px solid red;
//     width:18rem;
//     display:flex;
//     color:${colors.blue};
//     align-items: center;
//     justify-content:center;
//     cursor: pointer;
//     @media (max-width:1500px) {
//         width:13rem;
//     }
//     @media (max-width:1140px){
//         width: 50%;
//         padding-right: 2rem;
//         justify-content: flex-start;
//     }
//     @media (max-width:700px){
//         order:4;
//         width:100%;
//     }
// `
// const Image = styled.img`
//     height:8rem;
//     width:8rem;
//     object-fit:cover;
// `
// const Box = styled.div`
//     height: 9rem;
//     width: 9rem;
//     display:flex;
//     justify-content:center;
//     align-items:center;
//     border:1px solid ${colors.medium_gray};
//     border-radius:10px;
// `

// const Date = styled.span`
//     display:block;
//     color:${colors.light_grey};

// `

// const DetailFlex = styled.div`
//     display:flex;
//     flex-direction:column;
//     gap:1rem
// `

