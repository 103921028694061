import styled from "styled-components";
import { colors } from "../../consts/themes";

export const MainDiv = styled.div`
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
margin-top:80px;
`
export const SubDiv = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`
export const Heading = styled.div`
width: 100%;
height: 50vh;
display: flex;
align-items: center;
justify-content: center;
/* background-color: blueviolet; */
`
export const Head = styled.div`
width: 55%;
color: ${colors.white};
@media screen and (max-width: 1050px){
// width: 75%;
text-align:center;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
    
}
@media screen and (max-width: 800px){
    width: 90%;
    p{
        font-size: 12px;
    }
}
@media screen and (max-width: 500px){
    font-size: 12px;
}
@media screen and (max-width: 350px){
    
    width:100%;
    P{
        padding: 0 2px 0 2px;
       }
}
`
export const ContentDiv = styled.div`
width: 80%;
height: 98%;
align-items: center;
justify-content: center;
display: flex;
flex-direction: column;
@media screen and (max-width: 500px){
    
    width:90%;
    font-size: 12px;

}
@media screen and (max-width: 400px){
    
    width:100%;
    font-size: 12px;

}
`
export const RewardsDiv = styled.div`
width: 100%;
height: fit-content;
display: flex;
justify-content: space-between;
padding: 30px 0 30px 0;

img{
    width: 400px;
    height: 17vh;
}
@media screen and (max-width: 1500px){
    img{
        width: 300px;
    }
}
@media screen and (max-width: 1050px){
    img{
        width: 250px;
    }
}
@media screen and (max-width: 800px){
    img{
        width: 200px;
    }
}
@media screen and (max-width: 500px){
flex-direction: column;
img{
    width: 350px;
    height: 10vh;
}
}
@media screen and (max-width: 400px){
flex-direction: column;
img{
    width: 300px;
}
}
`
export const Reward1 = styled.div`
display: flex;
flex-direction: row;
position:relative;
width: 400px;
justify-content: space-around;
@media screen and (max-width: 1500px){
    width: 300px;
    
}
@media screen and (max-width: 500px){
    height: 15vh;
    width: 380px;
}
@media screen and (max-width: 400px){
    width:350px;
}
@media screen and (max-width: 350px){
    width:320px;
}
`
export const Details = styled.div`

width: 300px;
height: 10vh;
display: flex;
flex-direction: column;
align-items: center;
position: absolute;
top: 10px;
justify-content: center;
font-size: 1rem;
img{
    width: 15px;
    height: 15px;
}
@media screen and (max-width: 1500px){
width: 400px;
    
}
@media screen and (max-width: 1050px){
    
        width: 300px;
        font-size: 12px;
    
}
@media screen and (max-width: 800px){
    img{
        width: 12px;
        height: 12px;
    }
}
@media screen and (max-width: 500px){
    img{
        width: 15px;
    height: 15px;
    }

}
`
export const Redeemed = styled.div`
display: flex;
width: 90%;
flex-direction: row;
align-items: center;
justify-content: center;
color: ${colors.white};
@media screen and (max-width: 1500px){
width: 63%;
font-size: 14px;

}
@media screen and (max-width: 500px){
width: 100%;
    font-size: 1rem;
}

`
export const Cicon = styled.div`
height: 35%;
width: 10%;
`
export const Div = styled.div`
color: ${colors.white};
width: 70%;
text-align: right;
font-size: small;
position: absolute;
padding-top: 15%;
padding-right: 15px;
@media screen and (max-width: 1500px){
width: 60%;
    padding-top: 10%;
    padding-left: 10px;
}
@media screen and (max-width: 1050px){
    
    width: 80%;
    font-size: 12px;

}
@media screen and (max-width: 800px){
    
    width:60%;
    padding-top: 15%;
    padding-right: 2px;

}
@media screen and (max-width: 500px){
    
    width:100%;
}
@media screen and (max-width: 500px){
    
    width:90%;
}
`
export const ShopEarn = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`
export const Ptag = styled.div`
width: 65%;
text-align: center;
`
export const Slider = styled.div`
width: 100%;
display: flex;
align-items: center;
flex-direction: column;
`
export const MainSliderContainer = styled.div`
display: flex;
width: 100%;
justify-content: center;
align-items: center;
cursor: pointer;
`
export const Icon = styled.div`
background-color: #e7e9f0;
border-radius: 50px;
margin: 0px;
@media screen and (max-width: 800px){
    display: none;
}
`
export const SliderContent = styled.div`
width: 100%;
height: 100%;
padding-top: 1px;
align-items: center;
white-space: nowrap;
scroll-behavior: smooth;
overflow: scroll;
overflow-y: hidden;
-ms-overflow-style: none;
::-webkit-scrollbar{
    display: none;
}
`
export const CardSlide = styled.div`
width: 250px;
height: 250px;
justify-content: center;
align-items: center;
border-radius: 10px;
display: inline-block;
margin-right: 47px;
text-align:center;
border: 1px solid ${colors.ash};
img{
    width: 150px;
    height: 150px;
}
`
export const Style = styled.div`
width: 100%;
p{
    font-size: 1.5rem;
    width: 100%; 
   text-align: center; 
   border-bottom: 1px solid ${colors.ash}; 
   line-height: 0.1em;
   margin: 10px 0 20px; 
}
span{
    background:#fff; 
    padding:0 10px; 
}
`
export const Button = styled.div`
width: 100%;
padding-top: 10px;
margin-top: 15px;
display:flex;
align-items:center;
justify-content:center;
padding-bottom:20px;
button{
    background-color: ${colors.blue};
    border: none;
    width: 16%;
    height: 36px;
    border-radius: 9px;
    color: ${colors.white};
    font-size: 1rem;
}
@media screen and (max-width: 1050px){
    button{
        width: 40%;
    }
}
`