import styled from "styled-components"




export const Container = styled.div`
display: flex; 
flex-direction:column;
justify-content: center; 
align-items:center;
padding:1.5rem;
gap:1rem;
width:410px;
max-width:100%;
@media only screen and (min-width: 460px) and (max-width: 570px) {width:350px;}
@media only screen and (min-width: 390px) and (max-width: 459px) {width:300px;}
@media only screen and (min-width: 340px) and (max-width: 389px) {width:250px;}
@media only screen and (min-width: 250px) and (max-width: 339px) {width:230px;}
`

export const InnerConatiner = styled.div`
display:flex;
flex-direction: column;
gap:1.5rem;
`

export const CloseContainer = styled.div`
width:100%;
display:flex;
justify-content: flex-end;
`

export const Head1 = styled.div`
font-size:16px;
font-weight:400;

`

export const Button = styled.div`
background-color: ${props => props.bg === 1 ? '#4669E8' : '#E7E9F0'};
color: ${props => props.txt === 1 ? '#FFFFFF' : '#4669E8'};
max-width:6rem;
width:6rem;
padding: 10px 16px;
height: 40px;
  border-radius: 5px;
  border-style: none;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  font-family: "Haas Grot Text R Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  margin: 0;
  outline: none;
  text-align: center;
  text-decoration: none;
`


export const ButtonContainer = styled.div`
width:100%;
display:flex;
justify-content: center;
align-items: center;
gap:1.5rem;
`

export const ErrorIcon = styled.div`
display:flex;
flex-direction:row
`