import React from "react";
import { Modal, ModalContainer, Overlay } from "../modal";
import { Purchasetypemain, Purchasetypecontent, Purchasetypecontentcontainer, Purchasebutton, Purchasebuttonone, Purchasebuttontwo } from './Purchasetype.styled'
function Purchasetype() {
    return (
        // <Purchasetypemain>

        //         <Purchasetypecontentcontainer>
        <ModalContainer>
            <Modal>
                <Overlay>
                    <Purchasetypecontent>
                        <p>What kind of purchase are you looking for ?</p>
                        <Purchasebutton>
                            <Purchasebuttonone>
                                Wholesale Purchase
                            </Purchasebuttonone>
                            <Purchasebuttontwo>
                                Retail Purchase
                            </Purchasebuttontwo>
                        </Purchasebutton>
                    </Purchasetypecontent>
                </Overlay>

            </Modal>
        </ModalContainer>

    )
}
export default Purchasetype;