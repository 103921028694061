import styled from "styled-components"
import Colors from "../../consts/Colors"


export const Container = styled.div`
display: flex; 
flex-direction:column;
justify-content: center; 
align-items:center;
padding:1.5rem;
width:480px;
max-width:100%;
@media only screen and (min-width: 540px) and (max-width: 570px) {width:450px;}
@media only screen and (min-width: 495px) and (max-width: 539px) {width:410px;}
@media only screen and (min-width: 470px) and (max-width: 494px) {width:380px;}
@media only screen and (min-width: 420px) and (max-width: 469px) {width:340px;}
@media only screen and (min-width: 395px) and (max-width: 439px) {width:310px;}
@media only screen and (min-width: 350px) and (max-width: 394px) {width:280px;}
@media only screen and (min-width: 300px) and (max-width: 349px) {width:250px;}
`

export const Head1 = styled.div`
font-size:1.1rem;
font-weight:500;
padding-bottom:0.5rem;
text-align: center;
`

export const InnerConatiner = styled.div`
display:flex;
flex-direction: column;
gap:0.5rem;
align-items: center;
`

export const ItemContainer = styled.div`
display: flex;
width: 100%;
justify-content: center;
align-items: center;
gap:1.5rem;
`

export const Img = styled.div`
border: .5px solid #ccc;
padding: 10px;

img{
width:170px;
height:170px;
background: ${Colors.business};
color: ${Colors.business};
@media only screen and (min-width: 461px) and (max-width: 494px) {height: 150px; width: 150px;}
@media only screen and (min-width: 350px) and  (max-width: 460px) {height: 120px; width: 120px;}
@media only screen and (min-width: 300px) and  (max-width: 449px) {height: 100px; width: 100px;}
}
`

export const Text = styled.div`
font-size:0.9rem;
font-weight:400;
@media only screen and (min-width: 340px) and (max-width: 439px) {font-size:0.8rem;}
@media only screen and (min-width: 300px) and (max-width: 349px) {font-size:0.7rem;}
`

export const ImgContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap:0.5rem;
`