import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import ErrorComponent from '../../../components/Button/error/ErrorComponent';
import LoadingSpinner from '../../../components/Button/Loading/LoadingSpinner';
import { colors } from '../../../consts/themes';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../../consts/axiosInstance';

const UserGuide = () => {

  const navigation = useNavigate();
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getProductCategories = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('getProductCategory');
      setCategory(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProductCategories();
  }, [])

  if (loading) {
    return <LoadingSpinner />
  }
  if (error) {
    return <ErrorComponent error={error.message} />
  }

  return (
    <ScreenDiv>
      <ScreenCont>

        {category?.map((val, i) => {
          return (
            <Button key={i} onClick={() => { navigation(`/user/guide/${val.id}`) }}>
              <p>{val?.product_category_name}</p>
            </Button>
          );
        })}

      </ScreenCont>
    </ScreenDiv>
  )
}
export default UserGuide;


const ScreenDiv = styled.div`
  padding-top: 5rem;
  height: calc(100vh - 5rem); 
`;

const ScreenCont = styled.div`
display: grid;
grid-template-columns: repeat(12, minmax(0, 1fr));
overflow-y: scroll;
  @media only screen and (min-width: 250px) and (max-width: 350px) {padding: 1rem ;gap: 0.5rem;}
  @media only screen and (min-width: 351px) and (max-width: 768px) {padding: 1rem ;gap: 1rem;}
  @media only screen and (min-width: 768px) and (max-width: 1023px) {padding: 1.5rem ;gap: 1.5rem;}
  @media only screen and (min-width: 1024px) {padding: 2rem;gap: 2rem;}
`;

const Button = styled.div`
cursor:pointer;
display:flex;
justify-content:center;
background-color:${colors.simple_grey};
border-radius: 10px;
@media only screen and (min-width: 250px) and (max-width: 354px) {grid-column: span 12 / span 12;}
@media only screen and (min-width: 355px) and (max-width: 768px) {grid-column: span 6 / span 6;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {grid-column: span 4 / span 4;}
@media only screen and (min-width: 1024px) {grid-column: span 3 / span 3;}
max-width:350px;
`;

// import React from 'react'
// import { Contaniner, SubContainer, Button, Tips, Sub, Button1 } from './UserGuideStyle'
// import { useState } from 'react';

// const array = [
//   { tag: 'Laptop' },
//   { tag: 'Desktop' },
//   { tag: 'Mobile' },
//   { tag: 'Tabs' },
//   { tag: 'CCTV' },
//   { tag: 'Speakers' },
//   { tag: 'Monitor' },
//   { tag: 'Printer' },
//   { tag: 'Components' },
//   { tag: 'Networking Products' },
//   { tag: 'UPS' },
//   { tag: 'Accessories' },

// ];


// function UserGuide() {
//   const [isActive, setIsActive] = useState(false);
//   const handleClick = () => {
//     setIsActive(current => !current);
//   };
//   return (
//     <Contaniner>
//       <SubContainer>
//         {array.map((item) => (

//           <Button>

//             <button
//               //          style={{

//               //   // backgroundColor: isActive === false ? 'blue':''
//               // }}
//               onClick={handleClick}>
//               {item?.tag}

//             </button>

//           </Button>

//         ))}
//       </SubContainer>

//       <Tips>
//         <Sub><span>Tips</span></Sub>
//         <p>Spreadsheets provide a great way to organize data. Think of a spreadsheet as a table with rows and columns. Spreadsheets also provide mathematical functions, such as means and standard deviations. Each row holds details about one entity. </p>
//       </Tips>

//     </Contaniner>
//   )
// }

// export default UserGuide;


