import styled from "styled-components";
import Colors from "../../../consts/Colors";
import { colors } from "../../../consts/themes";

export const PointsDiv = styled.div`
    display: flex;
    gap: 2rem;
justify-content: space-evenly;
@media screen and (max-width: 620px) {
    flex-direction: column;
    align-items: center;
}

`
export const ClikPointDiv = styled.div`
min-width: 280px;
/* width: 350px; */
height: 6rem;
border: 1px solid ${colors.line};
display: flex;
justify-content: center;
align-items: center;

img{
    width: 20px;
    height: 20px;
    margin: 10px;
}
@media screen and (max-width: 768px) {
    width: 280px;
}
`
export const HeadingWish = styled.div`
    display:flex;
    border-top:1px solid ${colors.medium_gray};
    border-bottom:1px solid ${colors.medium_gray};
    height: 3rem;
    @media (max-width:1140px) {
        /* display:none; */
    }
`
export const ItemWish = styled.div`
    
    display:flex;
    padding-block: 0.6rem;
    border-bottom:1px solid ${colors.medium_gray};
    @media (max-width:1140px){
        /* flex-wrap:wrap; */
        row-gap: 1rem;
        padding-top: 2rem;
    }
`