import styled from "styled-components";
import Colors from "../../consts/Colors";
import Fonts from "../../consts/Font";
import { colors, fonts } from "../../consts/themes";

export const Purchasetypemain = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100vh;
backdrop-filter: blur(0.5px);
opacity: 5;

`
export const Purchasetypecontentcontainer = styled.div`

position: absolute;
top: 150px;
right: calc(50% - 160px);
padding: 20px;
border-radius:5px;
width:25%;
height:25%;
background: ${Colors.white};
border:1px solid white;
border-radius:10px;
border: 1px solid white;
box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1);

@media(max-width:2560px)
{
    margin-top:10vw;
  
   
}
@media(max-width:1440px)
{
    margin-top:unset;
}
@media(max-width:768px)
{
    width:40%;
}
@media(max-width:425px)
{
    width:60%;
}
@media(max-width:375px)
{
    width:70%;
}
@media(max-width:320px)
{
    right: calc(50% - 150px);
    width:80%;
}
`
export const Purchasetypecontent = styled.div`

display:flex;
flex-direction:column;
justify-content:center;
row-gap:20px;
padding-block: 4rem;
    padding-inline: 2rem;
@media(max-width:2560px)
{
    row-gap:40px;
    padding-block: 5rem;
    padding-inline: 4rem;
}
@media(max-width:1440px)
{
    row-gap:20px;
}

@media(max-width:768px)
{
    row-gap:40px;
}
@media (max-width: 450px)
{
    row-gap: 40px;
    padding-block: 5rem;
    padding-inline: 1rem;
}


p
{
    font-size:1rem;
    color: ${colors.black};
    font-weight: ${fonts.fontWeight5};
    text-align:center;
    
}
`
export const Purchasebutton = styled.div`

display:flex;
flex-direction:row;
column-gap:20px;
justify-content:center;
@media(max-width:375px)
{
    flex-direction:column;
}

`
export const Purchasebuttonone = styled.div`
font-size: 0.8rem;
    width: 8vw;
    /* padding: 10px; */
background:${Colors.white}; 
cursor: pointer;
border-radius: 8px;
align-self: center;
text-align: center;
padding: 10px;
/* box-shadow: 2px 2px 25px 2px rgb(204 204 204 / 45%); */
box-shadow: 2px 2px 25px 2px rgba(204,204,204,0.45);

@media(max-width:2560px)
{
 
    width:7vw;
    /* height:20px; */
}
@media(max-width:1440px)
{
 
    width:10vw;
    /* height:20px; */
}

@media(max-width:1050px)
{
    width:17vw;
}
@media(max-width: 765px)
{
    width:22vw;
}
@media(max-width: 600px)
{
    width:26vw;
}
@media(max-width:450px)
{
    width:32vw;
}
@media (max-width: 380px)
{
    width: 43vw;
    padding-block: 1rem;
    margin-block: 1rem;
}
`
export const Purchasebuttontwo = styled.div`
font-size: 0.8rem;
    width: 8vw;
/* padding: 10px; */
background:${Colors.white};
/* text-align:center; */
cursor: pointer;
/* border: 1px solid white; */
/* box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1); */
border-radius: 8px;
align-self: center;
padding-block: 10px;
box-shadow: 2px 2px 25px 2px rgba(204,204,204,0.45);
background-color: transparent;
text-align:center;

@media(max-width:2560px)
{
   
    width:8vw;
    /* height:30px; */
}
@media(max-width:1440px)
{
    
    width:10vw;
    /* height:20px; */
}
@media(max-width:1050px)
{
    width:17vw;
}
@media(max-width: 765px)
{
    width:22vw;
}
@media(max-width: 600px)
{
    width:30vw;
}
@media(max-width:450px)
{
    width:35vw;
}
@media (max-width: 380px)
{
    width: 50vw;
    padding-block: 1rem;
    margin-block: 1rem;
}
`