import styled from "styled-components";
import Colors from "../../consts/Colors";

import { colors } from "../../consts/themes";

export const Installationmodal_main = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  /* height: 100vh; */
  backdrop-filter: blur(1.5px);
  opacity: 5;

  @media screen and (max-width: 500px) {
    justify-content: center;
    align-items: center;
    height: 150%;
  }
`;
export const DIv = styled.div`
  width: 100%;
  cursor: pointer;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  // background-color:blue;
`;

export const Installationmodal_container = styled.div`
  position: absolute;
  top: 150px;
  border-radius: 12px;
  // right: calc(50% - 160px);
  padding: 20px;

  width: 25%;
  // height:25%;
  padding: 40px 15px;
  background: ${Colors.white};
  border: thin solid #e3e3e3;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 2560px) {
    top: 15vw;
    right: calc(50% - 290px);
  }
  @media (max-width: 1440px) {
    top: 150px;
    right: calc(50% - 160px);
  }

  @media (max-width: 826px) {
    width: 30%;
  }
  @media (max-width: 768px) {
    right: calc(50% - 100px);
    width: 30%;
  }
  @media (max-width: 698px) {
    width: 35%;
  }
  @media (max-width: 590px) {
    width: 40%;
  }
  @media (max-width: 522px) {
    width: 45%;
  }
  @media (max-width: 458px) {
    width: 50%;
    right: calc(50% - 130px);
  }
  @media (max-width: 425px) {
    width: 50%;
    right: calc(50% - 130px);
  }

  @media (max-width: 413px) {
    width: 60%;
  }
  @media (max-width: 375px) {
    width: 60%;
  }
  @media (max-width: 345px) {
    width: 70%;
    right: calc(50% - 140px);
  }
  @media (max-width: 320px) {
    width: 70%;
  }
`;
export const Installationmodal_content = styled.div`
  display: flex;
  padding: 20px 50px 50px;
  flex-direction: column;
  align-items: center;
  // margin-top:5vw;

  row-gap: 3vw;
  @media (max-width: 2560px) {
    row-gap: 2vw;
  }
  @media (max-width: 1440px) {
    row-gap: 2vw;
  }
  @media (max-width: 425px) {
    row-gap: 5vw;
  }
  @media (max-width: 320px) {
    row-gap: 7vw;
  }
  img {
    width: 8vw;
    height: auto;

    @media (max-width: 768px) {
      width: 12vw;
    }
    @media (max-width: 558px) {
      width: 20vw;
    }
    @media (max-width: 425px) {
      width: 23vw;
      row-gap: 10vw;
    }
    @media (max-width: 320px) {
      width: 26vw;
    }
  }
`;

export const Installation_text = styled.div`
  font-size: 18px;
  padding-top: 20px;

  @media (max-width: 2560px) {
    font-size: 18px;
  }
  @media (max-width: 1440px) {
    font-size: 18px;
  }
`;

export const Installation_Button = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 10px;
`;
export const Installation_Button_one = styled.div`
  cursor: pointer;
  width: 10vw;
  height: auto;
  border: none;
  // height:40px;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  background-color: ${colors.lightblue};
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  color: ${colors.blue};

  @media (max-width: 558px) {
    width: 20vw;
  }
  @media (max-width: 522px) {
    width: 20vw;
  }

  @media (max-width: 458px) {
    width: 20vw;
  }
  @media (max-width: 425px) {
    width: 20vw;
  }
`;

export const Installation_Button_two = styled.div`
  width: 10vw;
  height: auto;
  cursor: pointer;
  border: none;
  // height:40px;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  background-color: ${colors.blue};
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  color: ${colors.white};

  @media (max-width: 558px) {
    width: 20vw;
  }
  @media (max-width: 522px) {
    width: 20vw;
  }
  @media (max-width: 458px) {
    width: 20vw;
  }
  @media (max-width: 425px) {
    width: 20vw;
  }
`;
