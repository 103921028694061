import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalContainer, Modal, Overlay } from "../modal.js";
import {
  Installationmodal_main,
  DIv,
  Installationmodal_container,
  Installationmodal_content,
  Installation_text,
  Installation_Button,
  Installation_Button_one,
  Installation_Button_two,
} from "./Installationmodal.styled";
import { GrFormClose } from "react-icons/gr";



const GotoCartCustomPcModal = ({ close, type }) => {
  //type 1 : product already exisyts i cart
  //type 2 : custom pc request already submitted;
  const navigation = useNavigate();
  return (
    <ModalContainer>
      <Modal Modal>
        <Overlay>
          <Installationmodal_content>
            <DIv>
              <GrFormClose size={20} onClick={() => {
                close(false)
              }} />
            </DIv>

            {type === 1 && <Installation_text>Product Already Exists in Cart</Installation_text>}
            {type === 2 && <Installation_text>Please wait for the confirmation for the previous request</Installation_text>}
            <>
              <Installation_Button>

                <Installation_Button_one onClick={() => close(false)} >
                  Back
                </Installation_Button_one>


                <Installation_Button_two
                  onClick={() => navigation('/custompc/home')}
                >Go to Catagory</Installation_Button_two>
              </Installation_Button>
            </>
          </Installationmodal_content>
        </Overlay>
      </Modal>
    </ModalContainer>
  )
}

export default GotoCartCustomPcModal;