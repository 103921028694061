export const CART_DATA = [
    {
        id: 1,
        name: 'Dell Inspiron 3511 Laptop, Intel i3-1115G4, 8GB, 512GB SSD, Win 11',
        date: '2022-07-11',
        quantity: 1,
        img: require('../assets/Images/png/Laptop.png'),
        rating: 5
    },
    {
        id: 2,
        name: 'Dell Inspiron 3511 Laptop, Intel i3-1115G4, 8GB, 512GB SSD, Win 11',
        date: '2018-06-13',
        quantity: 2,
        img: require('../assets/Images/png/Laptop.png'),
        rating: 4
    },
    {
        id: 3,
        name: 'Dell Inspiron 3511 Laptop, Intel i3-1115G4, 8GB, 512GB SSD, Win 11',
        date: '2018-05-08',
        quantity: 1,
        img: require('../assets/Images/png/Laptop.png'),
        rating: 2
    },
    {
        id: 4,
        name: 'Dell Inspiron 3511 Laptop, Intel i3-1115G4, 8GB, 512GB SSD, Win 11',
        date: '2020-06-22',
        quantity: 3,
        img: require('../assets/Images/png/Laptop.png'),
        rating: 5
    },
]