import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axiosInstance from "../../../../consts/axiosInstance";
import { fonts } from "../../../../consts/themes";
import LoadingSpinner from "../../../../components/Button/Loading/LoadingSpinner";
import ErrorComponent from "../../../../components/Button/error/ErrorComponent";
import { GrServices } from "react-icons/gr";
import { AuthContext } from "../../../../consts/AuthContext";
import CctvCustomizeModal from "./modals/CctvCustomizeModal";


function CctvCategories() {

  const navigation = useNavigate();
  const [authUser, setAuthUser] = useContext(AuthContext);
  const [cctvs, setCctvs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const navHandler = (category) => {
    localStorage.setItem("cctvCategoryId", JSON.stringify(category?.id));
    if (category.channel_status == 1) {
      navigation('/cctvchannels');
    }
    // else {
    //   localStorage.setItem("cctvChannelId", JSON.stringify(0));
    //   navigation('/cctcustomproduct');
    // }
  }


  const cctvcate = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('displayCCTVCategories');
      // console.log(response.data, " response of cctv ")
      setCctvs(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  const openCustomizationHandler = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`cctvtemporarycart/${authUser?.user?.id}`);
      const responseData = response.data;
      if (responseData.length === 0) {
        setShowPopup(true);
      } else {
        const parentStatus = responseData[0]?.products.some(product => product.parent_status === 1);
        if (parentStatus) {
          navigation('/cctvcustomization');
        }
      }
      // setCustomCctvId(response?.data[0]?.id)
      // setCctvCart(response?.data[0]?.products || []);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    cctvcate();
  }, []);


  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }


  return (

    <Screen>
      {showPopup && <CctvCustomizeModal close={setShowPopup} />}
      {<ExchangeSumDiv ><ExchangeSumButtn onClick={openCustomizationHandler}>
        <GrServices size={25} />Customization </ExchangeSumButtn></ExchangeSumDiv>}
      <ScreenCon>
        <>
          {cctvs?.filter(item => item.cctv_category !== "Wifi Cameras")
            .map((item) => {
              return (
                <CardImg key={item.id}>
                  <ImgCard
                    style={{ background: `linear-gradient(45deg,{item.color.start} 0%, {item.color.end} 100%)`, }}
                    onClick={() => navHandler(item)}>
                    <img src={item?.image}></img>
                  </ImgCard>
                  <p style={{ color: "black" }}>{item?.cctv_category}</p>
                </CardImg>
              );
            })}
        </>
      </ScreenCon>
    </Screen>
  );
}
export default CctvCategories;



export const ExchangeSumDiv = styled.div`
width:100%;
display: flex;
justify-content: flex-end;
`

export const ExchangeSumButtn = styled.button`
    display: flex;
    justify-content:center;
    align-items: center;
    border: none;
    padding: 12px 10px;
    border-radius: 8px;
    background: rgb(70, 105, 232);
    color: white;
    margin: 1rem;
    gap:0.5rem;
`


const Screen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:0;
  padding-top:5rem;
  height: calc(100vh - 5rem);
`;

const ScreenCon = styled.div`
display: grid;
grid-template-columns: repeat(12, minmax(0, 1fr));
overflow-y: scroll;
margin-top:1rem;
@media only screen and (min-width: 250px) and (max-width: 350px) {gap: 0.5rem;}
@media only screen and (min-width: 351px) and (max-width: 768px) {gap: 1rem;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {gap: 1.5rem;}
@media only screen and (min-width: 1024px) {gap: 1.5rem;}
`;

const CardImg = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
@media only screen and (min-width: 250px) and (max-width: 354px) {grid-column: span 12 / span 12;}
@media only screen and (min-width: 355px) and (max-width: 768px) {grid-column: span 6 / span 6;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {grid-column: span 4 / span 4;}
@media only screen and (min-width: 1024px) {grid-column: span 3 / span 3;}
`;


const ImgCard = styled.div`
background-color: #ccc; 
width: 190px;
height: 190px;
background-color:#ccc;
border: .5px solid #ccc;

img{
  object-fit: contain;
    width: 190px;
    height: 190px;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    transition :all 0.3s ;
}
p{
    color: white;
    font-size: 16px;
    margin-top: 0px;
    padding-inline:10px;
    text-align:center;
    font-weight: ${fonts.fontWeight5};
    letter-spacing: 0.04rem;
}

@media screen and (max-width: 500px){
    justify-content: center;
    align-items: center;
    justify-content: space-around; 
    width: 140px;
    height: 140px;
    img{
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
}
p{
    color: white;
    font-size: 1rem;
    font-weight: 450;
    margin-top: 0px;
}
}
@media screen and (max-width: 320px){
justify-content: center;
align-items: center;
justify-content: space-around; 
width: 130px;
height: 130px;
img{
justify-content: center;
align-items: center;
width: 130px;
height: 130px;
}
p{
color: white;
font-size: 1rem;
font-weight: 450;
margin-top: 0px;
}
}
`











