import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import { colors, fonts } from '../../../consts/themes';
import axiosInstance from '../../../consts/axiosInstance';
import { AuthContext } from '../../../consts/AuthContext';
import line from "../../../assets/Images/png/Line44.png";
import Fonts from '../../../consts/Font';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../../../consts/DataContext';


const ServiceCart = ({ activeProductCategoryId }) => {

  const navigation = useNavigate();
  const [authUser, setAuthUser] = useContext(AuthContext);
  const [toggleState, setToggleState] = useState(1);
  const [buy, setBuy] = useState([]);
  const [newData, setNewData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);
  const {invoiceId,setInvoiceId} = useContext(DataContext)
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const buyProducts = async () => {
    try {
      const response = await axiosInstance.get(`getProducts/${authUser?.user?.id}`);
      setBuy(response.data);
      setNewData(response.data);
      console.log('response.data', response.data)

    } catch (error) {
      console.log(error);
    }
  };

  const filterProducts = () => {
    setFilteredProducts([])
    const filteredData   = newData?.filter(data => data.product_category_id == JSON.parse(activeProductCategoryId));
    setFilteredProducts(filteredData);
  };
  
  

  useEffect(() => {
    filterProducts();
  }, [activeProductCategoryId, buy]);


  //service registered  products
  const getServiceRegs = async () => {
    try {
      const response = await axiosInstance.get(`getServiceReg/${authUser?.user?.id}`);
      setServices(response.data);

    } catch (error) {
      console.log(error);
    }
  };

  const filterServices = () => {
    
    const filtered = services.filter(service => service.product_category_id == String(activeProductCategoryId));
    // console.log('filtered', filtered)
    setFilteredServices(filtered);
  };

  useEffect(() => {
    filterServices();
  }, [activeProductCategoryId, services]);

  useEffect(() => {
    buyProducts();
    getServiceRegs();
  }, [authUser?.user?.id]);

  const truncate = (string) => {
    return string?.length > 150 ? string?.substr(0, 150 - 1) + '...' : string;
  };


  const handleReservice = (item) => {
    navigation("/service/re-registration", {
      state: {
        cartid: item?.cart_id,
        productsid: item?.product_id,
        brand: item?.brand,
        slno: item?.slno,
        year: item?.yearOfPurchase,
        warrantyExp: item?.warrantyExpiryDate,
        category: item?.product_category_id,
        category_name: item?.product_category_name,
        warranty: item?.warranty,
        serviceTypes: item?.serviceTypes,
      },
    })
  }

  return (
    <Container>
      <Title><section>Order History</section> </Title>
      <ReqContainer
        onClick={() => {
          setInvoiceId(0);
          navigation("/service/registration", {
              state: {
                  cartid: 0,
                  productsid: 0,
              },
          });
        }}

      >Register for another products</ReqContainer>
      <Switchbtn>
        <Products onClick={() => toggleTab(1)}
          style={toggleState === 1 ? { backgroundColor: "#4669E8", color: "#ffffff" } : { backgroundColor: "#E7E9F0", color: "#000000" }}>
          <p>Products</p>
        </Products>
        <Services onClick={() => toggleTab(2)}
          style={toggleState === 2 ? { backgroundColor: "#4669E8", color: "#ffffff" } : { backgroundColor: "#E7E9F0", color: "#000000" }}>
          <p>Services</p>
        </Services>
      </Switchbtn>
      <img src={line} alt="line" style={{ width: "98%" }} />

      <ProductContainer>
        {toggleState === 1 ? (
          <CartItems>
            <CartItemsContainer>
              {filteredProducts.length === 0 ? (<NoDisplay>no products available</NoDisplay>) : (
                filteredProducts.map((item, index) => (
                  
                    <div key={index}>
                      <CartItemsConatiner>
                        <div>
                          <ItemImg src={item?.productImages && (JSON.parse(item?.productImages)[0])} />
                        </div>

                        <CartDetailsConatiner>
                          <ItemTitle>{item?.title}</ItemTitle>

                          <ItemDetails>
                            <ItemDetail>{truncate(item?.description)}</ItemDetail>
                            {item?.requested === 0 && <Button
                              onClick={() => [
                                navigation("/service/registration", {
                                  state: {
                                    cartid: item?.cart_id,
                                    productsid: item?.productId,
                                    productname: item?.title,
                                    category: item?.product_category_id,
                                    brand: 0,
                                    slno: 0,
                                    year: 0,
                                    warrenty: 0,
                                    complaints: 0,
                                    warrentys: 0,
                                    shipping: item?.shipping_address,
                                    productImage: JSON.parse(item?.productImages)[0],
                                  },
                                }),
                                localStorage.setItem("categoryidd", JSON.stringify(item?.product_category_id)),
                                setInvoiceId(item.invoiceid)
                              ]}
                            >Request Service</Button>}
                          </ItemDetails>

                        </CartDetailsConatiner>
                      </CartItemsConatiner>
                      <img src={line} alt="line" style={{ width: "98%" }}></img>
                    </div>
                  
                ))
              )}
            </CartItemsContainer>
          </CartItems>
        ) : (
          <CartItems>
            <CartItemsContainer>
              {filteredServices.length === 0 ? (<NoDisplay>no service available</NoDisplay>) : (
                filteredServices.map((item) => (
                  <div key={item.productId}>
                    <CartItemsConatiner>
                      <ProductCard>
                        <p>Service</p>
                      </ProductCard>
                      <CartDetailsConatiner>
                        <ItemTitle>{item?.ticket_no}</ItemTitle>
                        <ItemDetails>
                          <ItemDetail>{truncate(item?.complaint)}</ItemDetail>
                          <Button
                            onClick={() => handleReservice(item)}
                          >Request Re Service</Button>
                        </ItemDetails>

                      </CartDetailsConatiner>
                    </CartItemsConatiner>
                    <img src={line} alt="line" style={{ width: "98%" }}></img>
                  </div>
                ))
              )}
            </CartItemsContainer>
          </CartItems>
        )}
      </ProductContainer>
    </Container>
  );
};

export default ServiceCart;




const Mydiv = styled.div`
border: 1px solid green;
`

const ReqContainer = styled.div`
background-color: rgb(231, 233, 240);
padding:0.5rem;
font-size:12px;
border-radius:10px;
color: ${colors.black};
font-weight: ${fonts.fontWeight5};
`

const ProductCard = styled.div`
    height:60px;
    width:100px;
    background-color:${colors.blue};
    justify-content:center;
    border-radius:5px;
    border: 1px solid ${colors.light_grey};
    display: flex;
    align-items: center;
    p{
        font-size:14px;
        font-weight:${Fonts.fontWeight3};
        color:${colors.white}
    }
`

const NoDisplay = styled.div`
display: flex;
align-items:center;
justify-content:center;
font-size:12px;
margin:20px;
color:${colors.ash};
`

const Button = styled.button`
width:150px;

@media only screen and (min-width: 300px) and (max-width: 1599px) {margin-top:0.5rem;}
padding:0.5rem;
height:2.5rem;
/* width:10rem; */
cursor:pointer;
background-color:${colors.blue};
    color:${colors.white};
    border:none;
    border-radius:8px;
    font-size:12px;
    font-weight:${fonts.fontWeight3};
`
const CartItems = styled.div`
`
const CartItemsContainer = styled.div`
margin-top:1rem;
margin-bottom:5rem;
`;
const CartItemsConatiner = styled.div`
display: flex;
align-items: center;
gap:0.5rem;
padding:0 0.3rem;
margin-top: 0.5rem;
`
const ItemImg = styled.img`
height:5rem;
width:5rem;
background-color: white;
`
const CartDetailsConatiner = styled.div`
flex-grow: 1;
`
const ItemTitle = styled.div`
font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 19px;
`

const ItemDetails = styled.div`
padding-top:0.2rem;
@media only screen and (min-width:1600px){
display:flex;
justify-content: space-between;
align-items: center;
gap:0.5rem;
}
`
const ItemDetail = styled.div`
font-size: 12px;
width: 100%;
/* @media only screen and (min-width:250px) and (max-width: 1600px) {
max-width:280px;
} */
`

const ProductContainer = styled.div`
width:100%;
height: calc(100vh - 10rem);
overflow: scroll;
`;

const Switchbtn = styled.div`
max-width:430px;
    width:100%;
    padding:3px;
    background-color:${colors.lightblue};
    border-radius:12px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    `

const Products = styled.div`
width:50%;  
height:34px;
border-radius:12px;
display:flex;
align-items:center;
justify-content:center;
cursor:pointer;

p{
    font-size:15px;
    font-weight:${fonts.fontWeight5};
    
}
@media(max-width:1100px) {
    p{
        font-size:15px;
    }
}
@media (min-width:2000px) {
    height:55px;
    p{
        font-size:18px;
        font-weight:${fonts.fontWeight5};
        
    }
}
`

const Services = styled.div`
width:50%;
height:34px;
background-color:${colors.lightblue};
display:flex;
align-items:center;
justify-content:center;
border-radius:12px;
cursor:pointer;
p{
    font-size:15px;
    font-weight:${fonts.fontWeight5};
    
}
@media(max-width:1100px) {
    p{
        font-size:15px;
    }
}
@media (min-width:2000px) {
    height:55px;
    p{
        font-size:18px;
        font-weight:${fonts.fontWeight5};
        
    }
}
`

const InputSection = styled.div`
max-width:430px;
width:100%;
align-items:flex-end;
flex-direction:column;
display:flex;
`;
const SInput = styled.input`
outline:none;
font-size:12px;
height:40px;
border-radius:10px;
border:1px solid ${colors.lightblue} ;
`;
const Search = styled.div`
    width:20px;
    height:20px;
    display:flex;
    align-self:flex-end;
    z-index:1;
    margin-top:-30px;
    `

const Container = styled.div`
margin-top: 2rem;
height: calc(100vh - 7rem);
padding: 0 0.8rem;
display: flex;
flex-direction: column;
align-items: center;
gap:1rem;
`;

const Title = styled.div`
max-width:430px;
section{
  font-size: 18px;
  font-weight: 600;
}
span{
  font-size: 14px;
  font-weight: 400;
}
`;