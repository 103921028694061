
import { useEffect, useState } from 'react'
import styled from 'styled-components';
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import axiosInstance from '../../../../consts/axiosInstance';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../../../components/Button/Loading/LoadingSpinner';


const PdfReport = () => {
  const { id } = useParams();
  const [details, setDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  const getCustomSaleReport = async () => {
    try {
      setLoading(true);
      const res = await axiosInstance.get(`getCustomSaleReportById/${id}`);
      setDetails(res.data[0]);
    } catch (error) {
      console.log(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCustomSaleReport();
  }, []);


  const downlaodPDF = () => {
    const doc = new jsPDF();

    // Add heading and created at date
    doc.setFontSize(18);
    doc.text("CLICKEKART", 105, 20, { align: 'center' });
    doc.setFontSize(12);
    const createdAt = details?.created_at ? new Date(details.created_at).toLocaleDateString() : '';
    doc.text(`Created at: ${createdAt}`, 105, 30, { align: 'center' });

    // Add the table
    autoTable(doc, {
      html: "#my-table",
      startY: 40, // start the table after the heading
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
      theme: "plain",
      bodyStyles: { lineColor: [0, 0, 0], lineWidth: 0.1 },
      didParseCell: (data) => {
        if (data.cell.raw.style && data.cell.raw.style.color) {
          const color = data.cell.raw.style.color;
          data.cell.styles.textColor = color === 'red' ? [255, 0, 0] : data.cell.styles.textColor;
        }
        if (data.cell.raw.style && data.cell.raw.style.textAlign) {
          const textAlign = data.cell.raw.style.textAlign;
          data.cell.styles.halign = textAlign === 'center' ? 'center' : data.cell.styles.halign;
        }
        if (data.cell.raw.style && data.cell.raw.style.fontSize) {
          const fontSize = parseInt(data.cell.raw.style.fontSize);
          data.cell.styles.fontSize = fontSize;
        }
        if (data.cell.raw.style && data.cell.raw.style.fontWeight) {
          const fontWeight = data.cell.raw.style.fontWeight;
          data.cell.styles.fontStyle = fontWeight >= 500 ? 'bold' : 'normal';
        }
      },
    });

    doc.save("report.pdf");
  };




  if (loading) {
    return <LoadingSpinner />
  }

  return (

    <Container>
      <PdfContainer>
        <header>
          <button onClick={downlaodPDF}>Download Pdf</button>
        </header>
        <section>
          <div id="header-content">
            <div style={{ textAlign: 'center', fontSize: '18px', fontWeight: 500 }}>CLICKEKART</div>
            <div style={{ textAlign: 'center' }}>Created at {details?.created_at ? new Date(details.created_at).toLocaleDateString() : ''}</div>
          </div>
          <PdfTable border="1" id="my-table">
            <tr>
              <th>No</th>
              <th>Model</th>
              <th>Item Description</th>
              <th>Warranty</th>
              <th>Qty</th>
              <th>Unit Price</th>
              <th>Total Price</th>
            </tr>
            <tr>
              <td>1</td>
              <td>{details?.product[0]?.model}</td>
              <td>{details?.product[0]?.title}</td>
              <td>{details?.product[0]?.warranty}</td>
              <td>{details?.product[0]?.qnty}</td>
              <td>{details?.product[0]?.unit_price}</td>
              <td>{details?.product[0]?.total}</td>
            </tr>

            <tr>
              <td colSpan={7}>DELIVERY CHARGES</td>
            </tr>
            <tr>
              <td colSpan={2}>ITEM</td>
              <td colSpan={1}>QTY</td>
              <td colSpan={2}>UNIT PRICE</td>
              <td colSpan={2}>TOTAL PRICE</td>
            </tr>
            {details?.delivery_charges?.map((item, index) => (
              <tr>
                <td colSpan={2}>{item?.title}</td>
                <td colSpan={1}>{item?.qnty}</td>
                <td colSpan={2}>{item?.unit_price}</td>
                <td colSpan={2}>{item?.total}</td>
              </tr>
            ))}
            <tr>
              <td colSpan={7}>INSTALLATION CHARGES</td>
            </tr>
            <tr>
              <td colSpan={2}>ITEM</td>
              <td colSpan={1}>QTY</td>
              <td colSpan={2}>UNIT PRICE</td>
              <td colSpan={2}>TOTAL PRICE</td>
            </tr>
            {details?.installation_charges?.map((item, index) => (
              <tr>
                <td colSpan={2}>{item?.title}</td>
                <td colSpan={1}>{item?.qnty}</td>
                <td colSpan={2}>{item?.unit_price}</td>
                <td colSpan={2}>{item?.total}</td>
              </tr>
            ))}

            <tr>
              <td colSpan={5} style={{ textAlign: 'center', color: 'red' }} >TOTAL</td>
              <td colSpan={2} style={{ textAlign: 'center', color: 'red' }} >{details?.mega_total}</td>
            </tr>

            <tr>
              <td colSpan={2}>TAX-GST</td>
              <td colSpan={3}>CGST</td>
              <td colSpan={1}>{Number(details?.tax_charges?.cgst)}%</td>
              <td colSpan={1}>{details?.tax_charges?.cgst_amount}</td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td colSpan={3}>SGST</td>
              <td colSpan={1}>{Number(details?.tax_charges?.sgst)}%</td>
              <td colSpan={1}>{details?.tax_charges?.sgst_amount}</td>
            </tr>
            <tr>
              <td colSpan={2}></td>
              <td colSpan={3}>CESS</td>
              <td colSpan={1}>{Number(details?.tax_charges?.cess)}%</td>
              <td colSpan={1}>{details?.tax_charges?.cess_amount}</td>
            </tr>
            <tr>
              <td colSpan={5} style={{ textAlign: 'center', color: 'red' }}>TAX TOTAL</td>
              <td colSpan={2} style={{ textAlign: 'center', color: 'red' }}>{details?.tax_total}</td>
            </tr>

            {/* <tr>
              <td colSpan={5}>PRODUCT PRICE</td>
              <td colSpan={2}>{details?.product_total}</td>
            </tr>
            <tr>
              <td colSpan={5}>Taxes</td>
              <td colSpan={2}>{details?.tax_total}</td>
            </tr> */}


            {/* <tr>
              <td colSpan={5}>DELIVERY CHARGES</td>
              <td colSpan={2}>{details?.delivery_charges_total}</td>
            </tr>
            <tr>
              <td colSpan={5}>INSTALLATION CHARGES</td>
              <td colSpan={2}>{details?.installation_charges_total}</td>
            </tr> */}
            <tr>
              <td colSpan={5} style={{ textAlign: 'center', color: 'red' }}>GRAND TOTAL</td>
              <td colSpan={2} style={{ textAlign: 'center', color: 'red' }}> {details?.grand_total}</td>
            </tr>
            <tr>
              <td colSpan={3}>AFTER SERVICE</td>
              <td colSpan={4}>{details?.vendor_details}</td>
            </tr>
            <tr>
              <td colSpan={3}>PAYMENT</td>
              <td colSpan={4}>{details?.payment}</td>
            </tr>
            <tr>
              <td colSpan={3}>DELIVERY TIME</td>
              <td colSpan={4}>{details?.delivery} Days</td>
            </tr>
            <tr>
              <td colSpan={3}>QUATATION VALIDITY TIME</td>
              <td colSpan={4}>{details?.quotation_validity} Days</td>
            </tr>
            <tr>
              <td colSpan={7}>BANK DETAILS</td>
            </tr>
            <tr>
              <td colSpan={3}>ACCOUNT NAME</td>
              <td colSpan={4}>{details?.bank_details?.account_name}</td>
            </tr>
            <tr>
              <td colSpan={3}>ACCOUNT NO</td>
              <td colSpan={4}>{details?.bank_details?.account_no}</td>
            </tr>
            <tr>
              <td colSpan={3}>BANK NAME</td>
              <td colSpan={4}>{details?.bank_details?.bank_name}</td>
            </tr>
            <tr>
              <td colSpan={3}>BANK BRANCH</td>
              <td colSpan={4}>{details?.bank_details?.bank_branch}</td>
            </tr>
            <tr>
              <td colSpan={3}>IFSC CODE</td>
              <td colSpan={4}>{details?.bank_details?.ifsc_code}</td>
            </tr>
            <tr>
              <td colSpan={2}>PREPARED BY</td>
              <td>{details?.prepared_by?.name}</td>
              <td colSpan={2}>{details?.prepared_by?.designation}</td>
              <td colSpan={2}>{details?.prepared_by?.phone}</td>
            </tr>
            <tr>
              <td colSpan={2}>CHECKED BY</td>
              <td>{details?.checked_by?.name}</td>
              <td colSpan={2}>{details?.checked_by?.designation}</td>
              <td colSpan={2}>{details?.checked_by?.phone}</td>
            </tr>
            <tr>
              <td colSpan={2}>HOD</td>
              <td>{details?.hod?.name}</td>
              <td colSpan={2}>{details?.hod?.designation}</td>
              <td colSpan={2}>{details?.hod?.phone}</td>
            </tr>
          </PdfTable>
        </section>
      </PdfContainer>
    </Container>
  );
};

export default PdfReport;


const Container = styled.div`
  /* position: relative;
  margin-left: 15%;
  margin-top: 80px;
  width: 85vw;
  @media screen and (max-width: 768px) {
    width: 100vw;
    margin: 50px 0px !important;
  }
  @media screen and (max-width: 1025px) {
    width: 100vw;
    margin-left: 0px;
  } */

margin-top: 6rem;
padding:1rem;

`;



const PdfContainer = styled.div`
 section{
  overflow-x: auto;
 }
  header {
    /* width: 80%; */
    margin: auto;
    text-align: right;

    button {
      all: unset;
      background-color: #4669e8;
      border-radius: 10px;
      cursor: pointer;
      color: white;
      padding: 10px 24px;
      font-size: 14px;
      margin-bottom: 10px;
      &:active  {
        background: #6381ee;
      }
    }
  }
`;

const PdfTable = styled.table`
  border-collapse: collapse;
  /* width: 80%; */
  width :794px;
  margin: auto;
  /* border: 1px solid blue; */
  th,
  td {
    text-align: left;
    border: 0.5px solid rgba(0, 0, 0, 0.5);
    padding: 8px;
  }
`;




// old code

// import { useEffect, useState } from 'react'
// import styled from 'styled-components';
// import jsPDF from "jspdf";
// import autoTable from "jspdf-autotable";
// import axiosInstance from '../../../../consts/axiosInstance';
// import { useParams } from 'react-router-dom';
// import LoadingSpinner from '../../../../components/Button/Loading/LoadingSpinner';


// const PdfReport = () => {
//   const { id } = useParams();
//   const [details, setDetails] = useState(null);
//   const [loading, setLoading] = useState(false);


//   const getCustomSaleReport = async () => {
//     try {
//       setLoading(true);
//       const res = await axiosInstance.get(`getCustomSaleReportById/${id}`);
//       setDetails(res.data[0]);
//     } catch (error) {
//       console.log(error.message);
//     } finally {
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     getCustomSaleReport();
//   }, []);


//   const downlaodPDF = () => {
//     const doc = new jsPDF();

//     // Add heading and created at date
//     doc.setFontSize(18);
//     doc.text("CLICKEKART", 105, 20, { align: 'center' });
//     doc.setFontSize(12);
//     const createdAt = details?.created_at ? new Date(details.created_at).toLocaleDateString() : '';
//     doc.text(`Created at: ${createdAt}`, 105, 30, { align: 'center' });

//     // Add the table
//     autoTable(doc, {
//       html: "#my-table",
//       startY: 40, // start the table after the heading
//       tableLineColor: [0, 0, 0],
//       tableLineWidth: 0.1,
//       theme: "plain",
//       bodyStyles: { lineColor: [0, 0, 0], lineWidth: 0.1 },
//       didParseCell: (data) => {
//         if (data.cell.raw.style && data.cell.raw.style.color) {
//           const color = data.cell.raw.style.color;
//           data.cell.styles.textColor = color === 'red' ? [255, 0, 0] : data.cell.styles.textColor;
//         }
//         if (data.cell.raw.style && data.cell.raw.style.textAlign) {
//           const textAlign = data.cell.raw.style.textAlign;
//           data.cell.styles.halign = textAlign === 'center' ? 'center' : data.cell.styles.halign;
//         }
//         if (data.cell.raw.style && data.cell.raw.style.fontSize) {
//           const fontSize = parseInt(data.cell.raw.style.fontSize);
//           data.cell.styles.fontSize = fontSize;
//         }
//         if (data.cell.raw.style && data.cell.raw.style.fontWeight) {
//           const fontWeight = data.cell.raw.style.fontWeight;
//           data.cell.styles.fontStyle = fontWeight >= 500 ? 'bold' : 'normal';
//         }
//       },
//     });

//     doc.save("report.pdf");
//   };




//   if (loading) {
//     return <LoadingSpinner />
//   }

//   return (

//     <Container>
//       <PdfContainer>
//         <header>
//           <button onClick={downlaodPDF}>Download Pdf</button>
//         </header>
//         <section>
//           <div id="header-content">
//             <div style={{ textAlign: 'center', fontSize: '18px', fontWeight: 500 }}>CLICKEKART</div>
//             <div style={{ textAlign: 'center' }}>Created at {details?.created_at ? new Date(details.created_at).toLocaleDateString() : ''}</div>
//           </div>
//           <PdfTable border="1" id="my-table">
//             <tr>
//               <th>No</th>
//               <th>Model</th>
//               <th>Item Description</th>
//               <th>Warranty</th>
//               <th>Qty</th>
//               <th>Unit Price</th>
//               <th>Total Price</th>
//             </tr>
//             <tr>
//               <td>1</td>
//               <td>{details?.product[0]?.model}</td>
//               <td>{details?.product[0]?.title}</td>
//               <td>{details?.product[0]?.warranty}</td>
//               <td>{details?.product[0]?.qnty}</td>
//               <td>{details?.product[0]?.unit_price}</td>
//               <td>{details?.product[0]?.total}</td>
//             </tr>
//             <tr>
//               <td colSpan={5} style={{ textAlign: 'center', color: 'red' }} >TOTAL</td>
//               <td colSpan={2} style={{ textAlign: 'center', color: 'red' }} >{details?.product_total}</td>
//             </tr>
//             <tr>
//               <td colSpan={2}>TAX-GST</td>
//               <td colSpan={3}>CGST</td>
//               <td colSpan={1}>{Number(details?.tax_charges?.cgst)}%</td>
//               <td colSpan={1}>{details?.tax_charges?.cgst_amount}</td>
//             </tr>
//             <tr>
//               <td colSpan={2}></td>
//               <td colSpan={3}>SGST</td>
//               <td colSpan={1}>{Number(details?.tax_charges?.sgst)}%</td>
//               <td colSpan={1}>{details?.tax_charges?.sgst_amount}</td>
//             </tr>
//             <tr>
//               <td colSpan={2}></td>
//               <td colSpan={3}>CESS</td>
//               <td colSpan={1}>{Number(details?.tax_charges?.cess)}%</td>
//               <td colSpan={1}>{details?.tax_charges?.cess_amount}</td>
//             </tr>
//             <tr>
//               <td colSpan={5} style={{ textAlign: 'center', color: 'red' }}>TOTAL</td>
//               <td colSpan={2} style={{ textAlign: 'center', color: 'red' }}>{details?.tax_total}</td>
//             </tr>
//             <tr>
//               <td colSpan={7}>DELIVERY CHARGES</td>
//             </tr>
//             <tr>
//               <td colSpan={2}>ITEM</td>
//               <td colSpan={1}>QTY</td>
//               <td colSpan={2}>UNIT PRICE</td>
//               <td colSpan={2}>TOTAL PRICE</td>
//             </tr>
//             {details?.delivery_charges?.map((item, index) => (
//               <tr>
//                 <td colSpan={2}>{item?.title}</td>
//                 <td colSpan={1}>{item?.qnty}</td>
//                 <td colSpan={2}>{item?.unit_price}</td>
//                 <td colSpan={2}>{item?.total}</td>
//               </tr>
//             ))}
//             <tr>
//               <td colSpan={7}>INSTALLATION CHARGES</td>
//             </tr>
//             <tr>
//               <td colSpan={2}>ITEM</td>
//               <td colSpan={1}>QTY</td>
//               <td colSpan={2}>UNIT PRICE</td>
//               <td colSpan={2}>TOTAL PRICE</td>
//             </tr>
//             {details?.installation_charges?.map((item, index) => (
//               <tr>
//                 <td colSpan={2}>{item?.title}</td>
//                 <td colSpan={1}>{item?.qnty}</td>
//                 <td colSpan={2}>{item?.unit_price}</td>
//                 <td colSpan={2}>{item?.total}</td>
//               </tr>
//             ))}

//             <tr>
//               <td colSpan={5}>PRODUCT PRICE</td>
//               <td colSpan={2}>{details?.product_total}</td>
//             </tr>
//             <tr>
//               <td colSpan={5}>Taxes</td>
//               <td colSpan={2}>{details?.tax_total}</td>
//             </tr>
//             {/* <tr>
//               <td colSpan={5}>DELIVERY CHARGES</td>
//               <td colSpan={2}>{details?.delivery_charges_total}</td>
//             </tr>
//             <tr>
//               <td colSpan={5}>INSTALLATION CHARGES</td>
//               <td colSpan={2}>{details?.installation_charges_total}</td>
//             </tr> */}
//             <tr>
//               <td colSpan={5} style={{ textAlign: 'center', color: 'red' }}>GRAND TOTAL</td>
//               <td colSpan={2} style={{ textAlign: 'center', color: 'red' }}>
//                 {details?.product_total +
//                   details?.tax_total +
//                   details?.delivery_charges_total +
//                   details?.installation_charges_total
//                 }
//               </td>
//             </tr>
//             <tr>
//               <td colSpan={3}>AFTER SERVICE</td>
//               <td colSpan={4}>{details?.vendor_details}</td>
//             </tr>
//             <tr>
//               <td colSpan={3}>PAYMENT</td>
//               <td colSpan={4}>{details?.payment}</td>
//             </tr>
//             <tr>
//               <td colSpan={3}>DELIVERY TIME</td>
//               <td colSpan={4}>{details?.delivery} Days</td>
//             </tr>
//             <tr>
//               <td colSpan={3}>QUATATION VALIDITY TIME</td>
//               <td colSpan={4}>{details?.quotation_validity} Days</td>
//             </tr>
//             <tr>
//               <td colSpan={7}>BANK DETAILS</td>
//             </tr>
//             <tr>
//               <td colSpan={3}>ACCOUNT NAME</td>
//               <td colSpan={4}>{details?.bank_details?.account_name}</td>
//             </tr>
//             <tr>
//               <td colSpan={3}>ACCOUNT NO</td>
//               <td colSpan={4}>{details?.bank_details?.account_no}</td>
//             </tr>
//             <tr>
//               <td colSpan={3}>BANK NAME</td>
//               <td colSpan={4}>{details?.bank_details?.bank_name}</td>
//             </tr>
//             <tr>
//               <td colSpan={3}>BANK BRANCH</td>
//               <td colSpan={4}>{details?.bank_details?.bank_branch}</td>
//             </tr>
//             <tr>
//               <td colSpan={3}>IFSC CODE</td>
//               <td colSpan={4}>{details?.bank_details?.ifsc_code}</td>
//             </tr>
//             <tr>
//               <td colSpan={2}>PREPARED BY</td>
//               <td>{details?.prepared_by?.name}</td>
//               <td colSpan={2}>{details?.prepared_by?.designation}</td>
//               <td colSpan={2}>{details?.prepared_by?.phone}</td>
//             </tr>
//             <tr>
//               <td colSpan={2}>CHECKED BY</td>
//               <td>{details?.checked_by?.name}</td>
//               <td colSpan={2}>{details?.checked_by?.designation}</td>
//               <td colSpan={2}>{details?.checked_by?.phone}</td>
//             </tr>
//             <tr>
//               <td colSpan={2}>HOD</td>
//               <td>{details?.hod?.name}</td>
//               <td colSpan={2}>{details?.hod?.designation}</td>
//               <td colSpan={2}>{details?.hod?.phone}</td>
//             </tr>
//           </PdfTable>
//         </section>
//       </PdfContainer>
//     </Container>
//   );
// };

// export default PdfReport;


// const Container = styled.div`
//   /* position: relative;
//   margin-left: 15%;
//   margin-top: 80px;
//   width: 85vw;
//   @media screen and (max-width: 768px) {
//     width: 100vw;
//     margin: 50px 0px !important;
//   }
//   @media screen and (max-width: 1025px) {
//     width: 100vw;
//     margin-left: 0px;
//   } */

// margin-top: 6rem;
// padding:1rem;

// `;



// const PdfContainer = styled.div`
//  section{
//   overflow-x: auto;
//  }
//   header {
//     /* width: 80%; */
//     margin: auto;
//     text-align: right;

//     button {
//       all: unset;
//       background-color: #4669e8;
//       border-radius: 10px;
//       cursor: pointer;
//       color: white;
//       padding: 10px 24px;
//       font-size: 14px;
//       margin-bottom: 10px;
//       &:active  {
//         background: #6381ee;
//       }
//     }
//   }
// `;

// const PdfTable = styled.table`
//   border-collapse: collapse;
//   /* width: 80%; */
//   width :794px;
//   margin: auto;
//   /* border: 1px solid blue; */
//   th,
//   td {
//     text-align: left;
//     border: 0.5px solid rgba(0, 0, 0, 0.5);
//     padding: 8px;
//   }
// `;

