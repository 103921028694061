import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalContainer, Modal, Overlay } from "../../components/modal"
import { Button, ButtonContainer, CloseContainer, Container, ErrorIcon, Head1, InnerConatiner } from './ModalStyled';
import { IoClose } from "react-icons/io5";
import errorcircle from '../../assets/Images/svg/errorcircle.svg'

const ErrorModal = ({ text, close, nav }) => {
  const navigation = useNavigate();
  return (
    <ModalContainer>
      <Modal Modal>
        <Overlay>
          <Container>
          <ErrorIcon>
                <img src={errorcircle}/>
                </ErrorIcon>

            <InnerConatiner>
              <Head1>{text}</Head1>
              <ButtonContainer>
                {/* <Button onClick={() => close(false)}>No</Button> */}

                <Button bg={1} txt={1} onClick={() => navigation(`${nav}`)}>Go Home</Button>
              </ButtonContainer>
            </InnerConatiner>
          </Container>
        </Overlay>
      </Modal>
    </ModalContainer>
  )
}

export default ErrorModal;


