import React from "react";
import Colors from "../../consts/Colors";
import styled from 'styled-components';
function Footer(){

    return(
        <FooterContainer>
            <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-5 text-left md:text-center px-2 gap-y-3">
                <a  href="/terms" className="">Terms and conditions</a>
                <a style={anchorTags} href="/returnpolicy">Return policy</a>
                <a style={anchorTags} href="/privacypolicy">Privacy policy</a>
                <a style={anchorTags} href="/aboutus">About us</a>
                <a style={anchorTags} href="/contactus">Contact us</a>
            </div>
        </FooterContainer>

    )
}
const FooterContainer = styled.footer`
  background-color: ${Colors.royalblue};
  color: white;
  text-align: center;
  padding: 20px 0;
  position: relative;
  width: 100%;
  bottom: 0;
`;
const menu ={
    display:'flex',
    justifyContent:'space-evenly',
}
const anchorTags ={
    color:'white',
    textDecoration:'none'
}
export default Footer