import { React, useState, useEffect } from "react";
import Assistance from "../../components/Modal/Assistance";
import Login from "../../components/Modal/Login";
import Helppage from "../../components/Modal/Helppage";
import { useLocation, useNavigate } from "react-router-dom";
import PrinterType from "../../components/Modal/PrinterType";
import LoadingSpinner from "../../components/Button/Loading/LoadingSpinner";
import ErrorComponent from "../../components/Button/error/ErrorComponent";
import axios from "axios";
import styled from "styled-components";
import { fonts } from "../../consts/themes";
import axiosInstance from "../../consts/axiosInstance";
import AssistModal from "../../components/Modals/AssistModal";
import PrinterTypeModal from "../../components/Modals/PrinterTypeModal";


function Subcategory() {

  const navigation = useNavigate();
  let location = useLocation();
  const [isOpen, setOpen] = useState(location.state === false ? false : true);
  const [subCategory, setIsSubCategory] = useState([]);
  //light or high printer selection modal 
  const [printerTypeModal, setPrinterTypeModal] = useState(false);
  const [cctvs, setCctvs] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [access, setAccess] = useState([]);
  const [printerCategories, setPrinterCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  //id of mob , tab , pc etc
  const productsid = JSON.parse(localStorage.getItem("productid") || "[]");
  //color of mob , tab , pc etc
  const productscode = JSON.parse(localStorage.getItem("productcode") || "[]");
  //id of common retail , bulk etc
  const purchasetype = JSON.parse(localStorage.getItem("codeid") || "[]");


  //items having two sub catageory
  const sub_category = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`getCustomerCategory/${productsid}/${purchasetype}`);
      // console.log(response.data, "response of items having two sub catageory")
      setIsSubCategory(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  //items having only one sub catageory
  // accessories 1st sub catageory
  const accessoriescate = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('getAccessoriesTypes');
      // console.log(response.data, " response ofitems having single sub catageory")
      setAccess(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  //network product 1st sub category 
  const networkingcate = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('getNetworkingProducts');
      // console.log(response.data, " response of network products")
      setNetworks(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };



  //printer 1st sub category
  const printercate = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('getPrinterTypes');
      // console.log(response.data, " response of printer products")
      setPrinterCategories(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {
    sub_category();
    networkingcate();
    accessoriescate();
    printercate();
  }, []);


  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }


  const nav = '/sales/help';
  const text = 'Do you need our help to choose the right product ?'


  return (
    <Screen className="h-screen">
      <div className="overflow-y-scroll p-10">
        {productscode === "001" &&
          <h3 className="text-xl xl:text-3xl mb-10" style={{ textAlign: 'center' }}>Choose Who You Are ?</h3>}

        {/* {printerTypeModal === true ? <PrinterType data={setPrinterTypeModal}
        navi={'/sales/productcard/users/products'} /> : ""} */}
        {/* {isOpen === true ? <Assistance close={setOpen} /> : ""} */}


        {printerTypeModal && <PrinterTypeModal close={setPrinterTypeModal} navi={'/sales/productcard/users/products'} />}
        {isOpen && <AssistModal close={setOpen} nav={nav} text={text} />}



        <ScreenCon>

          {/* network products categories */}
          {productscode === "011" ?
            <>
              {networks?.map((item, i) => {
                return (
                  <CardImg key={item.id}>
                    <ImgCard
                      style={{ background: `linear-gradient(45deg,{item.color.start} 0%, {item.color.end} 100%)`, }}
                      onClick={() => [
                        navigation("/sales/productcard/users/products"),
                        localStorage.setItem("Typeids", JSON.stringify(item?.id)),
                      ]}>
                      <img src={item?.networking_image} alt={item?.networking_type_name} />
                    </ImgCard>
                    <P>{item?.networking_type_name}</P>
                  </CardImg>
                );
              })}
            </>
            :
            productscode === "012" ?
              <>
                {/* accessories category */}
                {access?.map((item, i) => {
                  return (
                    <CardImg key={item.id}>
                      <ImgCard
                        style={{ background: `linear-gradient(45deg,{item.color.start} 0%, {item.color.end} 100%)`, }}
                        onClick={() => [
                          navigation("/sales/productcard/users/products"),
                          localStorage.setItem("Typeids", JSON.stringify(item?.id)),
                        ]}>
                        <img src={item?.accessory_image} alt={item?.accessory_type_name} />
                      </ImgCard>
                      <P>{item?.accessory_type_name}</P>
                    </CardImg>
                  );
                })}
              </>
              :
              productscode === "005" ?
                <>
                  {/* printer caegories */}
                  {printerCategories?.map((item, i) => {
                    return (
                      <CardImg key={item.id}>
                        <ImgCard
                          style={{ background: `linear-gradient(45deg,{item.color.start} 0%, {item.color.end} 100%)`, }}
                          onClick={() => [
                            setPrinterTypeModal(true),
                            localStorage.setItem("Typeids", JSON.stringify(item?.id)),
                          ]}>
                          <img src={item?.printer_image} alt={item?.printer_type_name} />
                        </ImgCard>
                        <P>{item?.printer_type_name}</P>
                      </CardImg>
                    );
                  })}
                </>
                :

                <>
                  {subCategory?.map((item, i) => {
                    return (
                      <CardImg key={item.id}>
                        <ImgCard
                          style={{ background: `linear-gradient(45deg,{item.color.start} 0%, {item.color.end} 100%)`, }}
                          onClick={

                            // monitor
                            productscode === "008"
                              ? () => [
                                // changefunction(),
                                localStorage.setItem("id", JSON.stringify(item?.id)),
                                localStorage.setItem("subcateid", JSON.stringify(item?.id)),
                                localStorage.setItem("cussid", (item?.color)),
                                localStorage.setItem("ccatid", (item?.id)),

                                navigation("/sales/productcard/users/products"),
                              ]
                              : () => [
                                // lap or pc
                                navigation("/sales/productcard/users/subusers"),
                                localStorage.setItem("id", JSON.stringify(item?.id)),
                                localStorage.setItem("cussid", (item?.color)),
                                localStorage.setItem("ccatid", (item?.id)),
                              ]}>
                          <img src={item?.category_image} alt={item?.category_name} />
                        </ImgCard>
                        <P>{item?.category_name}</P>
                      </CardImg>
                    );
                  })}
                </>
          }
        </ScreenCon>
      </div>
    </Screen>
  );
}
export default Subcategory;




const P = styled.p`
font-weight: 500;
font-size:12px;
@media only screen and (min-width: 250px) and (max-width: 550px) {font-size:10px;}
`;

const Screen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:0;
`;

const ScreenCon = styled.div`
display: grid;
grid-template-columns: repeat(12, minmax(0, 1fr));
overflow-y: scroll;
margin-top:1rem;
@media only screen and (min-width: 250px) and (max-width: 350px) {gap: 0.5rem;}
@media only screen and (min-width: 351px) and (max-width: 768px) {gap: 1rem;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {gap: 1.5rem;}
@media only screen and (min-width: 1024px) {gap: 1.5rem;}
`;

const CardImg = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
@media only screen and (min-width: 250px) and (max-width: 768px) {grid-column: span 6 / span 6;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {grid-column: span 4 / span 4;}
@media only screen and (min-width: 1024px) {grid-column: span 3 / span 3;}
`;


const ImgCard = styled.div`
background-color: #ccc; 
width: 230px;
height: 230px;
background-color:#ccc;
border: .5px solid #ccc;
img{
    width: 230px;
    height: 230px;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    transition :all 0.3s ;
}
p{
    color: white;
    font-size: 16px;
    margin-top: 0px;
    padding-inline:10px;
    text-align:center;
    font-weight: ${fonts.fontWeight5};
    letter-spacing: 0.04rem;
}

@media screen and (max-width: 500px){
    justify-content: center;
    align-items: center;
    justify-content: space-around; 
    width: 140px;
    height: 140px;
    img{
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
}
p{
    color: white;
    font-size: 1rem;
    font-weight: 450;
    margin-top: 0px;
}
}

@media screen and (max-width: 320px){
justify-content: center;
align-items: center;
justify-content: space-around; 
width: 130px;
height: 130px;
img{
justify-content: center;
align-items: center;
width: 130px;
height: 130px;
}
p{
color: white;
font-size: 1rem;
font-weight: 450;
margin-top: 0px;
}
}
`











