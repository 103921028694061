// // src/pages/ContactUs.js
// import React from 'react';
// import styled from 'styled-components';
// import Swal from 'sweetalert2';

// const Wrapper = styled.div`
//   padding:30px;
//   margin-top: 60px;
//   max-width: 800px;
//   margin: 70px auto;
//   line-height: 1.6;
// `;

// const Header = styled.h1`
//   color: #333;
// `;

// const Header4 = styled.h4`
//   color: #595959;
// `;

// const Header2 = styled.h2`
//   color: #555;
// `;

// const Paragraph = styled.p`
//   color: #595959;
//   font-size: 14px;
// `;

// const ContactForm = styled.form`
//   display: flex;
//   flex-direction: column;
//   gap: 20px;

//   label {
//     font-weight: bold;
//     color: #555;
//   }

//   input, textarea {
//     width: 100%;
//     padding: 10px;
//     border: 1px solid #ccc;
//     border-radius: 4px;
//   }

//   textarea {
//     height: 150px;
//   }

//   button {
//     background-color: #4669e8;
//     color: white;
//     border: none;
//     padding: 10px 20px;
//     border-radius: 4px;
//     cursor: pointer;
//     transition: background-color 0.3s ease;

//     &:hover {
//       background-color: #0056b3;
//     }
//   }
// `;

// const ContactUs = () => (
//   <Wrapper>
//     <Header>Contact Us</Header>
//     <Header4>Last Updated 31 August 2024</Header4>

//     <Header2>Get in Touch</Header2>
//     <Paragraph>
//       We value your feedback and inquiries. Whether you have questions, suggestions, or need support,
//       you can reach out to us through the following methods:
//     </Paragraph>

//     <Header2>Our Contact Information</Header2>
//     <Paragraph>
//       <strong>Email:</strong> clikekart@gmail.com<br />
//       <strong>Phone:</strong> +91-123-456-7890<br />
//       <strong>Address:</strong> 123 Tech Street, Innovation City, India
//     </Paragraph>

//     <Header2>Send Us a Message</Header2>
//     <Paragraph>
//       Please use the contact form below to send us a message. We'll get back to you as soon as possible.
//     </Paragraph>

//     <ContactForm>
//       <label htmlFor="name">Name</label>
//       <input type="text" id="name" name="name" required />

//       <label htmlFor="email">Email</label>
//       <input type="email" id="email" name="email" required />

//       <label htmlFor="subject">Subject</label>
//       <input type="text" id="subject" name="subject" required />

//       <label htmlFor="message">Message</label>
//       <textarea id="message" name="message" required></textarea>

//       <button type="submit">Send Message</button>
//     </ContactForm>
//   </Wrapper>
// );

// export default ContactUs;

// src/pages/ContactUs.js
import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Swal from 'sweetalert2';
import { server_api } from '../../../../consts/api';

const Wrapper = styled.div`
  padding:30px;
  margin-top: 60px;
  max-width: 800px;
  margin: 70px auto;
  line-height: 1.6;
`;

const Header = styled.h1`
  color: #333;
`;

const Header4 = styled.h4`
  color: #595959;
`;

const Header2 = styled.h2`
  color: #555;
`;

const Paragraph = styled.p`
  color: #595959;
  font-size: 14px;
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;

  label {
    font-weight: bold;
    color: #555;
  }

  input, textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  textarea {
    height: 150px;
  }

  button {
    background-color: #4669e8;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #0056b3;
    }
  }
`;

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(`${server_api}user/contactform`, formData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,

        }
      });

      if (response.status === 200) {
        Swal.fire('Success', 'Your message has been sent!', 'success');
        setFormData({ name: '', email: '', subject: '', message: '' });
      } else {
        Swal.fire('Error', 'There was an issue sending your message. Please try again.', 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'There was an error sending your message.', 'error');
      console.error('Error sending message:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Header>Contact Us</Header>
      <Header4>Last Updated 31 August 2024</Header4>

      <Header2>Get in Touch</Header2>
      <Paragraph>
        We value your feedback and inquiries. Whether you have questions, suggestions, or need support,
        you can reach out to us through the following methods:
      </Paragraph>

      <Header2>Our Contact Information</Header2>
      <Paragraph>
        <strong>Email:</strong> clikekart@gmail.com<br />
        <strong>Phone:</strong> +91-123-456-7890<br />
        <strong>Address:</strong> 123 Tech Street, Innovation City, India
      </Paragraph>

      <Header2>Send Us a Message</Header2>
      <Paragraph>
        Please use the contact form below to send us a message. We'll get back to you as soon as possible.
      </Paragraph>

      <ContactForm onSubmit={handleSubmit}>
        <label htmlFor="name">Name</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />

        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label htmlFor="subject">Subject</label>
        <input
          type="text"
          id="subject"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
          required
        />

        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>

        <button type="submit" disabled={loading}>
          {loading ? 'Sending...' : 'Send Message'}
        </button>
      </ContactForm>
    </Wrapper>
  );
};

export default ContactUs;
