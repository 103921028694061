import React from "react";
import { Bg, Div } from "./helpStyle";
import Group from "../../assets/Images/png/Group.png";
import { useNavigate } from "react-router-dom";

function Help({ onClick }) {
  const navigation = useNavigate();
  return (
    <Div onClick={onClick}>
      <Bg onClick={() => navigation("/sales/help")}>
        <img src={Group}></img>
      </Bg>
    </Div>
  );
}

export default Help;
