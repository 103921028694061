import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalContainer, Modal, Overlay } from "../modal"
import styled from 'styled-components';
import { IoClose } from "react-icons/io5";
import { Button, ButtonContainer, CloseContainer, Container, Head1, InnerConatiner } from './ModalStyled';


const CctvModal = ({ close, submit }) => {
  const navigation = useNavigate();

  const productCode = JSON.parse(localStorage.getItem("productcode"));


  const [installModal, setInstallModal] = useState(false);


  return (
    <ModalContainer>
      <Modal Modal>
        <Overlay>
          <Container>
            <CloseContainer>
              <IoClose size={22} onClick={() => close(false)} />
            </CloseContainer>
            {installModal === true ?
              <InnerConatiner>
                <Head1>Do you need Installation ?</Head1>
                <ButtonContainer>
                  <Button onClick={() => submit(0)}>No</Button>
                  <Button bg={1} txt={1} onClick={() => submit(1)}>Yes</Button>
                </ButtonContainer>
              </InnerConatiner> : (
                <InnerConatiner>
                  <Head1>Do you need Accessories ?</Head1>
                  <ButtonContainer>
                    <Button onClick={() => setInstallModal(true)}>No</Button>
                    <Button bg={1} txt={1} onClick={() => navigation('/cctvacc/home')}>Yes</Button>
                  </ButtonContainer>
                </InnerConatiner>
              )}
          </Container>
        </Overlay>
      </Modal>
    </ModalContainer>
  )
}

export default CctvModal;


