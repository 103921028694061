import React, { useContext, useEffect, useState } from 'react'
import { ComponentDiv, ComponentHeading, Dropdown, DropdownDiv, ExchangeContainer, ExchangeContents, Exchangediv, ExHeading, ItemName, ItemNameDiv, ItemsDiv, ItemsDiv1, RadioDiv, SingleItem, SpecificationDiv, SubmitButtonDiv, SwitchDiv, SwitchDiv1, WorkingCondition } from './LaptopExchangeStyle'
// import Switch from "react-switch";
import Switch from 'react-switch';
import { BsUpload } from 'react-icons/bs';
import SubmitModal from '../Modals/SubmitModal';
import OnOffSwitch from '../Switch/Switch';
import axios from 'axios';
import { getCityList, getDistrict, getGraphics, getProcessors, getRams, getReason, getStorage, insertExchangeform, listComplaint, listDisplay, list_estimatedprice } from '../../../api/api';
import Multiselect from 'multiselect-react-dropdown';
import { AuthContext } from '../../../consts/AuthContext';
import YearPicker from "react-year-picker";
import { useLocation, useNavigate } from 'react-router-dom';
import { RiExchangeFill } from 'react-icons/ri';
import { InputField, TextArea } from '../MobileExchange/MobileExchangeStyle';
import axiosInstance from '../../../consts/axiosInstance';




const LaptopExchange = () => {

    const { state } = useLocation()

    const [complaints, setComplaints] = useState([])
    const [display, setDisplay] = useState([])
    const [graphics, setGraphics] = useState([])
    const [processor, setProcessor] = useState([])
    const [ram, setRam] = useState([])
    const [reason, setReason] = useState([])
    const [storage, setStorage] = useState([])
    const [isOnProcessor, setIsOnProcessor] = useState(false);

    const [isOnKeyboard, setIsOnKeyboard] = useState(false);
    const [isOnDisplay, setIsOnDisplay] = useState(false);
    const [isOnTouchpad, setIsOnTouchpad] = useState(false);
    const [isOnWifi, setIsOnWifi] = useState(false);
    const [isOnSpeakers, setIsOnSpeakers] = useState(false);
    const [isOnWebcam, setIsOnWebcam] = useState(false);
    const [isOnUsb, setIsOnUsb] = useState(false);
    const [isOnCharger, setIsOnCharger] = useState(false);
    const [isOnCase, setIsOnCase] = useState(false);
    const [items, setItems] = useState([])
    const [authUser, setAuthUser] = useContext(AuthContext)
    const [isOpen, setIsOpen] = useState(false)
    const [exchangeData, setExchangeData] = useState([])
    const navigate = useNavigate()


    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 8;
    const endYear = currentYear;
    const years = [];

    for (let i = startYear; i <= endYear; i++) {
        years.push(i);

    }


    //district lit
    const [district, setDistrict] = useState([]);
    const [selectedDistrictId, setSelectedDistrictId] = useState('')
    //city list based on district
    const [cityList, setCityList] = useState([]);



    const [values, setValues] = useState({
        product_cat_id: JSON.stringify(state?.id),
        user_id: JSON.stringify(authUser?.user?.id),
        processor: '',
        processor_status: 0,
        ram: '',
        storage: '',
        graphics: '',
        graphics_size: 0,
        display: 0,
        display_size: '',
        keyboard_working: 0,
        keyboard_type: '',
        touchpad_working: 0,
        touchpad_type: '',
        wifi_working: 0,
        wifi: '',
        speaker_working: 0,
        speaker: '',
        webcam: '',
        webcam_working: 0,
        usb_hdmi_working: 0,
        usb_hdmi: '',
        battery_backup: '',
        charger_powercode_working: 0,
        charger_powercode: '',
        carry_case: '',
        physical_condition: '',
        working_condition: '',
        purchase_year: '',
        complaint_id: '',
        reason_for_exchange_id: '',
        district_id: '',
        city_id: '',
        address: '',
        pincode: '',
    })

    console.log('items: ', values);

    const options =
        complaints.map(item => {
            return (
                {
                    name: item?.complaint,
                    id: item?.id
                }
            )
        })

    const handleSelect = (selectedList) => {

        setValues({
            ...values,
            complaint_id: JSON.stringify(selectedList)
        })
    };

    const handleRemove = (selectedList) => {
        setValues({
            ...values,
            complaint_id: JSON.stringify(selectedList)
        })
    };


    const listComplaints = async () => {
        try {
            const response = await axiosInstance.get(`getAllComplaint?product_cat_id=${state?.id}`)
            setComplaints(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const listDisplays = async () => {
        try {
            const response = await axiosInstance.get(`getAllDisplay?product_cat_id=${state?.id}`)
            setDisplay(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const listGraphics = async () => {
        try {
            const response = await axiosInstance.get(`getAllGraphics?product_cat_id=${state?.id}`)
            setGraphics(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const listProcessor = async () => {
        try {
            const response = await axiosInstance.get(`getAllProcessor?product_cat_id=${state?.id}`)
            setProcessor(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const listRam = async () => {
        try {
            const response = await axiosInstance.get(`getAllRam?product_cat_id=${state?.id}`)
            setRam(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const listReason = async () => {
        try {
            const response = await axiosInstance.get(`getReasonOfEcxhange?product_cat_id=${state?.id}`)
            setReason(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const listStorage = async () => {
        try {
            const response = await axiosInstance.get(`getAllStorage?product_cat_id=${state?.id}`)
            setStorage(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    useEffect(() => {
        listComplaints()
        listDisplays()
        listGraphics()
        listProcessor()
        listRam()
        listReason()
        listStorage()
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }




    const handleSubmit = () => {
        axiosInstance.post('insertexchangeform', values).then(resp => {
            console.log(resp, "insertExchange")
            if (resp?.data.code == 200) {
                setIsOpen(true)
            } else {
                alert(resp.data.message)
            }
        })
    }






    const getDistrictdata = async () => {
        try {
            const response = await axiosInstance.get('getDistrict');
            setDistrict(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        getDistrictdata();
    }, [])




    useEffect(() => {
        const getAvailableCity = async () => {
            try {
                if (selectedDistrictId) {
                    const response = await axiosInstance.get(`getCity/${selectedDistrictId}`);
                    setCityList(response?.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        getAvailableCity();
    }, [selectedDistrictId]);



    const handleDistrictChange = (e) => {
        setValues({ ...values, district_id: e.target.value, city_id: '' });
        setSelectedDistrictId(e.target.value);
    }


    //graphics onchnages
    const handleGraphicsChange = (value) => {
        setValues((prevValues) => ({
            ...prevValues,
            graphics: value,
            graphics_size: value === 0 ? 0 : prevValues.graphics_size, // Reset graphicsOption when switching to inbuilt
        }));

        if (value === 1) {
            setValues((prevValues) => ({
                ...prevValues,
                graphics_size: '',
            }))
        };
    }


    //wifi onchnages
    const handleWifiChange = (value) => {
        setValues((prevValues) => ({
            ...prevValues,
            wifi: value,
            wifi_working: value === 0 ? 0 : prevValues.wifi_working,
        }));
        if (value === 0) {
            setIsOnWifi(false);
        }
    };


    //speaker onchnages
    const handleSpeakerChange = (value) => {
        setValues((prevValues) => ({
            ...prevValues,
            speaker: value,
            speaker_working: value === 0 ? 0 : prevValues.speaker_working,
        }));
        if (value === 0) {
            setIsOnSpeakers(false);
        }
    };


    //webcam onchnages
    const handleWebcamChange = (value) => {
        setValues((prevValues) => ({
            ...prevValues,
            webcam: value,
            webcam_working: value === 0 ? 0 : prevValues.webcam_working,
        }));
        if (value === 0) {
            setIsOnWebcam(false);
        }
    };


    //usb-hdmi onchnages
    const handleUsbHdmicamChange = (value) => {
        setValues((prevValues) => ({
            ...prevValues,
            usb_hdmi: value,
            usb_hdmi_working: value === 0 ? 0 : prevValues.usb_hdmi_working,
        }));
        if (value === 0) {
            setIsOnUsb(false);
        }
    };

    //charger powercode onchnages
    const handleChargerChange = (value) => {
        setValues((prevValues) => ({
            ...prevValues,
            charger_powercode: value,
            charger_powercode_working: value === 0 ? 0 : prevValues.usb_hdmi_working,
        }));
        if (value === 0) {
            setIsOnCharger(false);
        }
    };

    const isSubmitButton = Object.values(values).every((value) => value !== '')

    return (
        <ExchangeContainer>
            {isOpen && <SubmitModal setIsOpen={setIsOpen} />}
            <Exchangediv>
                <ExHeading>
                    <div>
                        <p style={{ marginBottom: "0" }}>LAPTOPS</p>
                        <label>*only can exchange within the 7 years limit</label>
                    </div>
                    {/* { exchangeData.length > 0 && <SubmitButtonDiv style={{display: 'flex' }}>
                    <button style={{width: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' 
                }} onClick={() => navigate('/exchange/confirmation')}>Exchange Cart <RiExchangeFill size={25}/></button>
                </SubmitButtonDiv>} */}

                </ExHeading>
                <ExchangeContents>
                    <ComponentHeading>
                        <ComponentDiv>
                            <h5>Components</h5>

                        </ComponentDiv>
                        <SpecificationDiv>
                            <h5>Specifications</h5>

                        </SpecificationDiv>
                        <WorkingCondition>
                            <h5>Working condition (Yes/No)</h5>


                        </WorkingCondition>
                    </ComponentHeading>

                    <ItemsDiv>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>PROCESSOR</ItemName></ItemNameDiv>
                            <DropdownDiv>
                                <Dropdown name="processor" id="processor" onChange={(e) => setValues({
                                    ...values,
                                    processor: e.target.value
                                })}>
                                    <option value=''>select processor</option>

                                    {processor.map((item) => {
                                        return (
                                            <option value={item?.processor_type}>{item?.processor_type}</option>
                                        )
                                    })}
                                </Dropdown>
                            </DropdownDiv>
                            <SwitchDiv>
                                <Switch
                                    onChange={() => {
                                        setIsOnProcessor(prev => {
                                            const newProgress = !prev;
                                            setValues({ ...values, processor_status: newProgress ? 1 : 0 });
                                            return newProgress;
                                        });
                                    }}
                                    checked={isOnProcessor}
                                    height={25}
                                    width={45}
                                    handleDiameter={20}
                                    offColor="#ff0000"
                                    onColor="#4669E8"
                                    offHandleColor="#ffffff"
                                    onHandleColor="#ffffff"
                                />
                            </SwitchDiv>

                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>RAM</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                <Dropdown name="ram" id="ram" onChange={(e) => setValues({
                                    ...values,
                                    ram: e.target.value
                                })}>
                                    <option>select Ram</option>

                                    {ram.map((item) => {
                                        return (
                                            <option value={item?.ram_type}>{item?.ram_type}</option>
                                        )
                                    })}
                                </Dropdown>
                            </DropdownDiv>
                            <SwitchDiv> </SwitchDiv>

                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>STORAGE</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                <Dropdown name="storage" id="storage" onChange={(e) => setValues({
                                    ...values,
                                    storage: e.target.value
                                })}>
                                    <option>select storage</option>
                                    {storage.map((item) => {
                                        return (
                                            <option value={item?.storage}>{item?.storage}</option>
                                        )
                                    })}
                                </Dropdown>
                            </DropdownDiv>
                            <SwitchDiv>

                            </SwitchDiv>



                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>GRAPHICS</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv>
                                    <input type="radio" id="inbuilt" name="graphics" value={0} onChange={() => handleGraphicsChange(0)} />
                                    <label for="html">Inbuilt</label>
                                    <input type="radio" id="dedicated" name="graphics" value={1} onChange={() => handleGraphicsChange(1)} />
                                    <label for="css">Dedicated</label></RadioDiv>
                                {values.graphics === 1 &&
                                    <div style={{ paddingTop: "10px" }}>
                                        <select style={{ width: "100px", height: "40px", borderRadius: "10px", borderColor: "gray", color: "gray" }} name="graphics_size" id="graphics" onChange={(e) => setValues({
                                            ...values,
                                            graphics_size: e.target.value
                                        })}>
                                            <option value=''>select</option>
                                            {graphics.map((item) => {
                                                return (
                                                    <option value={item?.size}>{item?.size}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                }
                            </SwitchDiv1>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>DISPLAY</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                <Dropdown name="display_size" id="display" onChange={(e) => setValues({
                                    ...values,
                                    display_size: e.target.value
                                })}>
                                    <option>select</option>
                                    {display.map((item) => {
                                        return (
                                            <option value={item?.size}>{item?.size}</option>
                                        )
                                    })}
                                </Dropdown>
                            </DropdownDiv>
                            <SwitchDiv>
                                <Switch
                                    onChange={() => {
                                        setIsOnDisplay(prev => {
                                            const newProgress = !prev;
                                            setValues({ ...values, display: newProgress ? 1 : 0 });
                                            return newProgress;
                                        });
                                    }}
                                    checked={isOnDisplay}
                                    height={25}
                                    width={45}
                                    handleDiameter={20}
                                    offColor="#ff0000"
                                    onColor="#4669E8"
                                    offHandleColor="#ffffff"
                                    onHandleColor="#ffffff"
                                />
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>KEYBOARD</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv>
                                    <input type="radio" id="ordinary" name="keyboard_type" value={0} onChange={(e) => handleChange(e)} />
                                    <label for="html">Ordinary</label>
                                    <input type="radio" id="backlight" name="keyboard_type" value={1} onChange={(e) => handleChange(e)} />
                                    <label for="css">Backlight</label></RadioDiv>
                            </SwitchDiv1>
                            <SwitchDiv>
                                <Switch
                                    onChange={() => {
                                        setIsOnKeyboard(prev => {
                                            const newProgress = !prev;
                                            setValues({ ...values, keyboard_working: newProgress ? 1 : 0 });
                                            return newProgress;
                                        });
                                    }}
                                    checked={isOnKeyboard}
                                    height={25}
                                    width={45}
                                    handleDiameter={20}
                                    offColor="#ff0000"
                                    onColor="#4669E8"
                                    offHandleColor="#ffffff"
                                    onHandleColor="#ffffff"
                                />
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>TOUCHPAD</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv id="touchpad" >
                                    <input type="radio" id="single" name="touchpad_type" value={0} onChange={(e) => handleChange(e)} />
                                    <label for="single">Single</label>
                                    <input type="radio" id="dual" name="touchpad_type" value={1} onChange={(e) => handleChange(e)} />
                                    <label for="dual">Dual</label></RadioDiv>

                            </SwitchDiv1>
                            <SwitchDiv>
                                <Switch
                                    onChange={() => {
                                        setIsOnTouchpad(prev => {
                                            const newProgress = !prev;
                                            setValues({ ...values, touchpad_working: newProgress ? 1 : 0 });
                                            return newProgress;
                                        });
                                    }}
                                    checked={isOnTouchpad}
                                    height={25}
                                    width={45}
                                    handleDiameter={20}
                                    offColor="#ff0000"
                                    onColor="#4669E8"
                                    offHandleColor="#ffffff"
                                    onHandleColor="#ffffff"
                                />
                            </SwitchDiv>
                        </SingleItem>

                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>WIFI</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv id="wifi" >
                                    <input type="radio" id="yes" name="wifi" value={1} onChange={() => handleWifiChange(1)} />
                                    <label for="Yes">Yes</label>
                                    <input type="radio" id="no" name="wifi" value={0} onChange={() => handleWifiChange(0)} />
                                    <label for="No">No</label></RadioDiv>
                            </SwitchDiv1>
                            <SwitchDiv>
                                {values.wifi == 1 &&
                                    <Switch
                                        onChange={() => {
                                            setIsOnWifi(prev => {
                                                const newProgress = !prev;
                                                setValues({ ...values, wifi_working: newProgress ? 1 : 0 });
                                                return newProgress;
                                            });
                                        }}
                                        checked={isOnWifi}
                                        height={25}
                                        width={45}
                                        handleDiameter={20}
                                        offColor="#ff0000"
                                        onColor="#4669E8"
                                        offHandleColor="#ffffff"
                                        onHandleColor="#ffffff"
                                    />}
                            </SwitchDiv>

                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>SPEAKERS</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>

                                <RadioDiv id="speakers" >
                                    <input type="radio" id="yes" name="speaker" value={1} onChange={() => handleSpeakerChange(1)} />
                                    <label for="Yes">Yes</label>
                                    <input type="radio" id="no" name="speaker" value={0} onChange={() => handleSpeakerChange(0)} />
                                    <label for="No">No</label></RadioDiv>
                            </SwitchDiv1>

                            <SwitchDiv>
                                {values.speaker == 1 &&
                                    <Switch
                                        onChange={() => {
                                            setIsOnSpeakers(prev => {
                                                const newProgress = !prev;
                                                setValues({ ...values, speaker_working: newProgress ? 1 : 0 });
                                                return newProgress;
                                            });
                                        }}
                                        checked={isOnSpeakers}
                                        height={25}
                                        width={45}
                                        handleDiameter={20}
                                        offColor="#ff0000"
                                        onColor="#4669E8"
                                        offHandleColor="#ffffff"
                                        onHandleColor="#ffffff"
                                    />}
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>WEBCAM</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv id="webcam" >
                                    <input type="radio" id="yes" name="webcam" value={1} onChange={() => handleWebcamChange(1)} />
                                    <label for="Yes">Yes</label>
                                    <input type="radio" id="no" name="webcam" value={0} onChange={() => handleWebcamChange(0)} />
                                    <label for="No">No</label></RadioDiv>
                            </SwitchDiv1>
                            <SwitchDiv>
                                {values.webcam == 1 &&
                                    <Switch
                                        onChange={() => {
                                            setIsOnWebcam(prev => {
                                                const newProgress = !prev;
                                                setValues({ ...values, webcam_working: newProgress ? 1 : 0 });
                                                return newProgress;
                                            });
                                        }}
                                        checked={isOnWebcam}
                                        height={25}
                                        width={45}
                                        handleDiameter={20}
                                        offColor="#ff0000"
                                        onColor="#4669E8"
                                        offHandleColor="#ffffff"
                                        onHandleColor="#ffffff"
                                    />}
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>USB/HDMI PORT</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv id="usb" >
                                    <input type="radio" id="yes" name="usb_hdmi" value={1} onChange={() => handleUsbHdmicamChange(1)} />
                                    <label for="Yes">Yes</label>
                                    <input type="radio" id="no" name="usb_hdmi" value={0} onChange={() => handleUsbHdmicamChange(0)} />
                                    <label for="No">No</label></RadioDiv>
                            </SwitchDiv1>
                            <SwitchDiv>
                                {values.usb_hdmi == 1 &&
                                    <Switch
                                        onChange={() => {
                                            setIsOnUsb(prev => {
                                                const newProgress = !prev;
                                                setValues({ ...values, usb_hdmi_working: newProgress ? 1 : 0 });
                                                return newProgress;
                                            });
                                        }}
                                        checked={isOnUsb}
                                        height={25}
                                        width={45}
                                        handleDiameter={20}
                                        offColor="#ff0000"
                                        onColor="#4669E8"
                                        offHandleColor="#ffffff"
                                        onHandleColor="#ffffff"
                                    />}
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>BATTERYBACK UP</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv id="backup" >
                                    <input type="radio" id="30" name="battery_backup" value="0" onChange={(e) => handleChange(e)} />
                                    <label for="30mins">Below 30 mins</label>
                                    <input type="radio" id="1" name="battery_backup" value="1" onChange={(e) => handleChange(e)} />
                                    <label for="onehour">Above 1 Hour</label></RadioDiv>

                            </SwitchDiv1>
                            <SwitchDiv>

                            </SwitchDiv>

                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>CHARGE + POWERCODE</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv id="charger">
                                    <input type="radio" id="yes" name="charger_powercode" value={1} onChange={() => handleChargerChange(1)} />
                                    <label for="Yes">Yes</label>
                                    <input type="radio" id="no" name="charger_powercode" value={0} onChange={() => handleChargerChange(0)} />
                                    <label for="No">No</label>
                                </RadioDiv>
                            </SwitchDiv1>

                            <SwitchDiv>
                                {values.charger_powercode == 1 &&
                                    <Switch
                                        onChange={() => {
                                            setIsOnCharger(prev => {
                                                const newProgress = !prev;
                                                setValues({ ...values, charger_powercode_working: newProgress ? 1 : 0 });
                                                return newProgress;
                                            });
                                        }}
                                        checked={isOnCharger}
                                        height={25}
                                        width={45}
                                        handleDiameter={20}
                                        offColor="#ff0000"
                                        onColor="#4669E8"
                                        offHandleColor="#ffffff"
                                        onHandleColor="#ffffff"
                                    />}
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>CARRY CASE</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv id="case" >
                                    <input type="radio" id="yes" name="carry_case" value={1} onChange={(e) => handleChange(e)} />
                                    <label for="Yes">Yes</label>
                                    <input type="radio" id="no" name="carry_case" value={0} onChange={(e) => handleChange(e)} />
                                    <label for="No">No</label></RadioDiv>
                            </SwitchDiv1>
                            <SwitchDiv>
                                {/* <Switch
                                    onChange={() => { setIsOnCase(!isOnCase); setValues({ ...values, charger_powercode_working: isOnCase ? '1' : '0' }) }}
                                    checked={isOnCase}
                                    height={25}
                                    width={45}
                                    handleDiameter={20}
                                    offColor="#ff0000"
                                    onColor="#4669E8"
                                    offHandleColor="#ffffff"
                                    onHandleColor="#ffffff"
                                /> */}
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>

                                <ItemName>PHYSICAL CONDITION</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv id="condition">
                                    <input type="radio" id="good" name="physical_condition" value="0" onChange={(e) => handleChange(e)} />
                                    <label for="good">Good</label>
                                    <input type="radio" id="fair" name="physical_condition" value="1" onChange={(e) => handleChange(e)} />
                                    <label for="fair">Fair</label>
                                    <input type="radio" id="excellent" name="physical_condition" value="2" onChange={(e) => handleChange(e)} />
                                    <label for="excellent">Excellent</label></RadioDiv>
                            </SwitchDiv1>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>WORKING CONDITION</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv id="condition" >
                                    <input type="radio" id="good" name="working_condition" value="0" onChange={(e) => handleChange(e)} />
                                    <label for="good">Good</label>
                                    <input type="radio" id="fair" name="working_condition" value="1" onChange={(e) => handleChange(e)} />
                                    <label for="fair">Fair</label>
                                    <input type="radio" id="excellent" name="working_condition" value="2" onChange={(e) => handleChange(e)} />
                                    <label for="excellent">Excellent</label></RadioDiv>
                            </SwitchDiv1>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>PURCHASE YEAR</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                {/* <Dropdown>
                                    <option>2022</option>
                                    <option>2022</option>
                                    <option>2022</option>
                                    <option>2022</option>
                                    <option>2022</option>
                                </Dropdown> */}
                                {/* <YearPicker onChange={(e) => setValues({...values, purchase_year: e.target.value})} /> */}

                                <Dropdown
                                    onChange={(e) => setValues({ ...values, purchase_year: e.target.value })}
                                >
                                    <option value='' >Select purchase year</option>

                                    {years.map((year) => {
                                        return (<option value={year}>{year}</option>)
                                    })}
                                </Dropdown>
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>COMPLAINT</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                {/* <Dropdown name="complaint" id="complaint" onChange={(e) => setValues({
                    ...values,
                    complaint: e.target.value
                })}>
                                    <option>select</option>
                                    {complaints.map((item) => {
                                        return (
                                            <option value={item?.complaint}>{item?.complaint}</option>
                                        )
                                    })}

                                </Dropdown> */}
                                <Multiselect
                                    options={options}
                                    selectedValues={items}
                                    onSelect={handleSelect}
                                    onRemove={handleRemove}
                                    displayValue="name"
                                />
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>REASON OF EXCHANGE</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                <Dropdown name="reason" id="reason" onChange={(e) => setValues({
                                    ...values,
                                    reason_for_exchange_id: e.target.value
                                })}>
                                    <option value=''>select</option>
                                    {reason.map((item) => {
                                        return (
                                            <option value={item?.id}>{item?.reason}</option>
                                        )
                                    })}
                                </Dropdown>
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>


                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>CHOOSE DISTRICT</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                <Dropdown name="district" id="district" onChange={handleDistrictChange}>
                                    <option value="">select</option>
                                    {district?.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.district_name}</option>
                                    ))}
                                </Dropdown>
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>


                        {selectedDistrictId &&
                            <SingleItem>
                                <ItemNameDiv>
                                    <ItemName>CHOOSE CITY</ItemName>
                                </ItemNameDiv>
                                <DropdownDiv>
                                    <Dropdown name="city" id="city" onChange={(e) => setValues({
                                        ...values,
                                        city_id: e.target.value
                                    })} >
                                        <option value="">select</option>
                                        {cityList?.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.city_name}</option>
                                        ))}
                                    </Dropdown>
                                </DropdownDiv>
                                <SwitchDiv>
                                </SwitchDiv>
                            </SingleItem>
                        }

                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>ADDRESS</ItemName></ItemNameDiv>
                            <DropdownDiv>
                                <TextArea name='address' type='text' rows={'6'} onChange={(e) => handleChange(e)} />
                            </DropdownDiv>
                            <SwitchDiv>

                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>PIN CODE</ItemName></ItemNameDiv>
                            <DropdownDiv>
                                <InputField
                                    name='pincode'
                                    type='text'
                                    maxLength='6'
                                    onChange={(e) => {
                                        // Only allow numeric input
                                        if (/^\d*$/.test(e.target.value)) {
                                            handleChange(e);
                                        }
                                    }}
                                />
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                    </ItemsDiv>
                </ExchangeContents>
                {isSubmitButton &&
                    <SubmitButtonDiv>
                        <button onClick={handleSubmit}>Submit</button>
                    </SubmitButtonDiv>
                }
            </Exchangediv>
        </ExchangeContainer>
    )
}

export default LaptopExchange