import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import LoadingSpinner from '../../components/Button/Loading/LoadingSpinner';
import ErrorComponent from '../../components/Button/error/ErrorComponent';
import { AuthContext } from '../../consts/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import QuizCountDown from './QuizCountDown';
import axiosInstance from '../../consts/axiosInstance';
import Swal from 'sweetalert2';

const QuizQues = () => {
  const { end } = useParams();
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [authUser, setAuthUser] = useContext(AuthContext);
  const [quizId, setQuizId] = useState(null);
  const [questions, setQuestions] = useState([]); // Store questions fetched from API
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0); // Index of the current question being displayed
  const [selectedOptions, setSelectedOptions] = useState({}); // Store the selected option for the current question


  const handleOptionSelect = (option) => {
    const updatedOptions = { ...selectedOptions, [currentQuestionIndex]: option };
    setSelectedOptions(updatedOptions); // Update selected options
  };

  const handleNextButtonClick = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
  };

  const handlePreviousButtonClick = () => {
    setCurrentQuestionIndex((prevIndex) => prevIndex - 1);
  };



  // Function to format form data for submission
  const formatFormData = () => {
    const formData = questions.map((question, index) => {
      const answerKey = selectedOptions[index];
      return {
        user_id: authUser?.user?.id,
        quiz_no: question.questionid,
        answer: answerKey,
        quiz_id: question.quiz_id,
      };
    });
    return formData;
  };


  const handleSubmit = async () => {
    try {
      const formData = formatFormData();
      const response = await axiosInstance.post('quizsubmissionweb', formData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log("submit res quiz", response.data);

      // Check if the response is OK (status 200)
      if (response.status === 200) {
        // Check if the response data code is 200
        if (response.data.code === 200) {
          navigation(`/quiz/end/${quizId && quizId}`);
        }
        // If the response data code is 401 (quiz already submitted)
        else if (response.data.code === 401) {
          Swal.fire({
            icon: 'error',
            title: 'Unauthorized',
            html: response.data.message + '. Please <a href="/" style="color: #3085d6">Go to Home </a> to continue.' || 'You are not authorized to perform this action.',
          });
        } else {
  console.error('Submission failed:', response.data.message);
}
      } else {
  console.error('Unexpected error:', response);
}
    } catch (error) {
  console.error('Error submitting quiz:', error);
  Swal.fire({
    icon: 'error',
    title: 'Submission Error',
    text: 'Something went wrong while submitting the quiz. Please try again.',
  });
}
  };



const getQuesions = async () => {
  try {
    setLoading(true);
    const response = await axiosInstance.get('list_quizquestions');
    setQuestions(response.data);
  } catch (error) {
    setError(error);
  } finally {
    setLoading(false);
  }
};


const getQuizId = async () => {
  try {
    setLoading(true);
    const response = await axiosInstance.get('list_quizids');
    setQuizId(response.data[0].id);
  } catch (error) {
    setError(error);
  } finally {
    setLoading(false);
  }
};

useEffect(() => {
  getQuesions();
  getQuizId();
}, [])


if (loading) {
  return <LoadingSpinner />
}
if (error) {
  return <ErrorComponent error={error.message} />
}

return (
  <Main>

    <SubOne>
      <BgImg imageUrl={`/image/bg-quiz.png`}>
        <BgSubContainer>
          <BgHeadContainer>
            <BgHead>Super Coins</BgHead>
            <BgHead> Awaits you</BgHead>
            <BgP>Unlock Rewards with every question</BgP>
          </BgHeadContainer>
          <CoinImg src='/image/quiz-coin.png' />
        </BgSubContainer>
      </BgImg>
    </SubOne>

    <SubTwo>
      <QuesWrapper>
        <QuesContainer>
          {questions.length > 0 && (
            <Questionscontainer>

              <Questions>
                <Question>
                  {questions[currentQuestionIndex].question}
                </Question>

                <Answercontainer>

                  <Answer>
                    <input
                      type="radio"
                      id="option_a"
                      name="option"
                      value={1}
                      checked={selectedOptions[currentQuestionIndex] === 1}
                      onChange={() => handleOptionSelect(1)}
                    />
                    <label htmlFor={2}>{questions[currentQuestionIndex].option_a}</label>
                  </Answer>

                  <Answer>
                    <input
                      style={{ cursor: 'pointer' }} type='radio'
                      name="option"
                      value={2}
                      checked={selectedOptions[currentQuestionIndex] === 2}
                      onChange={() => handleOptionSelect(2)}
                    />
                    <label htmlFor={2}>{questions[currentQuestionIndex].option_b}</label>
                  </Answer>

                  <Answer>
                    <input
                      style={{ cursor: 'pointer' }} type='radio'
                      name="option"
                      value={3}
                      checked={selectedOptions[currentQuestionIndex] === 3}
                      onChange={() => handleOptionSelect(3)}
                    />
                    <label htmlFor={3}>{questions[currentQuestionIndex].option_c}</label>
                  </Answer>

                  <Answer>
                    <input
                      type="radio"
                      id="option_d"
                      name="option"
                      value={4}
                      checked={selectedOptions[currentQuestionIndex] === 4}
                      onChange={() => handleOptionSelect(4)}
                    />
                    <label htmlFor={4}>{questions[currentQuestionIndex].option_d}</label>
                  </Answer>

                </Answercontainer>
              </Questions>

              <p>{currentQuestionIndex + 1} out of {questions.length}</p>
            </Questionscontainer>
          )}

        </QuesContainer>
      </QuesWrapper>

      <NextWrap>
        {/* Previous button */}
        {currentQuestionIndex > 0 && (
          <NextBtn onClick={handlePreviousButtonClick}>Previous</NextBtn>
        )}

        {/* Show Next or Submit button based on current question */}
        {currentQuestionIndex < questions.length - 1 && selectedOptions[currentQuestionIndex] && (
          <NextBtn onClick={handleNextButtonClick}>next</NextBtn>
        )}

        {currentQuestionIndex === questions.length - 1 && selectedOptions[currentQuestionIndex] && (
          <NextBtn onClick={handleSubmit}>Submit</NextBtn>
        )}
      </NextWrap>

      <QuizCountDown endTime={end} />

    </SubTwo>
  </Main>
)
}

export default QuizQues;

const Main = styled.div`
  margin-top: 5rem;
  height: calc(100vh - 5rem);
  background-color:#F8F8F8;
  display:flex;
  flex-direction: column;
  gap:1rem;
  overflow:scroll;
`

const SubOne = styled.div`
`

const SubTwo = styled.div`
${'' /* flex:1; */}
display:flex;
flex-direction:column;
${'' /* justify-content:space-between; */}
height:fit-content;
margin-bottom:60px;
gap:1rem;
`

const BgImg = styled.div`
  width: 100%;
  height:fit-content; 
  background-image: url(${props => props.imageUrl});
  background-size: cover;
  background-position: center;
`

const BgHeadContainer = styled.div`
color:white;
`

const BgSubContainer = styled.div`
display:flex;
gap: 1rem;
padding:1.1rem 3.1rem;

@media only screen and (min-width: 250px) and (max-width: 767px) {
  display:flex;
  justify-content:space-between;
  align-items:center;
}
`

const BgHead = styled.div`
font-weight:600;
font-size:2.8rem;
@media only screen and (min-width: 250px) and (max-width: 500px) {font-size:1rem;}
@media only screen and (min-width: 500px) and (max-width: 767px) {font-size:2rem;}
`
const BgP = styled.div`
font-size:1.5rem;
@media only screen and (min-width: 250px) and (max-width: 500px) {font-size:0.7rem;}
@media only screen and (min-width: 500px) and (max-width: 767px) {font-size:1rem;}
`

const CoinImg = styled.img`
width:fit-content;
height:fit-content;
@media only screen and (min-width: 250px) and (max-width: 599px) {width:7rem;height:5rem;}
@media only screen and (min-width: 600px) and (max-width: 767px) {width:9rem;height:5rem;}
`
const QuesWrapper = styled.div`
display:flex;
justify-content:center;
`
const QuesContainer = styled.div`
background-color:#FFFFFF;
border-radius:0.5rem;
max-width:600px;
@media only screen and (min-width: 768px) {
width:600px;
}
@media only screen and (min-width: 425px) and (max-width: 767px) {
width:400px;
}
@media only screen and (min-width: 250px) and (max-width: 424px) {
width:300px;
}
`
const BaseButton = styled.button`
font-family: Poppins;
text-align: center;
color:white;
border:none;
display:flex;
justify-content: center;
border-radius: 5px;
max-width:7.5rem;
width:100%;
@media only screen and (min-width: 250px) and (max-width: 399px) {font-size: 0.8rem;padding:1rem;}
@media only screen and (min-width: 250px) and (max-width: 767px) {padding:0.6rem 3rem;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0.6rem 4rem;}
@media only screen and (min-width: 1024px)  {padding:0.6rem 5rem;}
`
const NextWrap = styled.div`
display:flex;
justify-content:center;
gap:1rem;
@media only screen and (min-width: 250px) and (max-width:768px) {
gap:0.5rem;
}
`;

const NextBtn = styled(BaseButton)`
  background-color: #4669E8;
  color:white;
  font-size: 1rem;
  font-weight: 400;
`;

export const Questionscontainer = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;

export const Questions = styled.div`
width: 95%;
padding: 30px 0;
`;

export const Question = styled.div`
`;

export const Answercontainer = styled.div`
display: flex;
flex-direction: column;
gap: 30px;
padding-top: 20px;
`;

export const Answer = styled.div`
display: flex;
gap: 5px;
input[type="radio"] {
opacity: 0.7;
cursor: pointer;
}
`;