import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import { GrFormClose } from "react-icons/gr";
import { RiSearch2Line } from "react-icons/ri";
import { BsArrowRight } from "react-icons/bs";
import { colors, fonts } from '../../../consts/themes';
import axiosInstance from '../../../consts/axiosInstance';
import { AuthContext } from '../../../consts/AuthContext';
import line from "../../../assets/Images/png/Line44.png";
import Fonts from '../../../consts/Font';
import { useNavigate } from 'react-router-dom';
import { DataContext } from '../../../consts/DataContext';


const ServiceCartMob = ({ onClose, activeProductCategoryId }) => {
  const navigation = useNavigate();
  const [authUser, setAuthUser] = useContext(AuthContext);
  const [toggleState, setToggleState] = useState(1);
  const {invoiceId,setInvoiceId} = useContext(DataContext)

  const [buy, setBuy] = useState([]);
  const [newData, setNewData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  const [services, setServices] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);


  const toggleTab = (index) => {
    setToggleState(index);
  };


  const buyProducts = async () => {
    try {
      const response = await axiosInstance.get(`getProducts/${authUser?.user?.id}`);
      setBuy(response.data);
      setNewData(response.data);

    } catch (error) {
      console.log(error);
    }
  };


  const filterProducts = () => {
    setFilteredProducts([])
    const filteredData   = newData.filter(data => data.product_category_id == JSON.parse(activeProductCategoryId));
    setFilteredProducts(filteredData);
  };


  useEffect(() => {
    filterProducts();
  }, [activeProductCategoryId, buy]);


  //service registered  products
  const getServiceRegs = async () => {
    try {
      const response = await axiosInstance.get(`getServiceReg/${authUser?.user?.id}`);
      setServices(response.data);
    } catch (error) {
      console.log(error);
    }
  };


  const filterServices = () => {
    const filtered = services.filter(service => service.product_category_id === String(activeProductCategoryId));
    setFilteredServices(filtered);
  };


  useEffect(() => {
    filterServices();
  }, [activeProductCategoryId, services]);


  useEffect(() => {
    buyProducts();
    getServiceRegs();
  }, [authUser?.user?.id]);


  const truncate = (string) => {
    return string?.length > 150 ? string?.substr(0, 150 - 1) + '...' : string;
  };

  const handleReservice = (item) => {
    navigation("/service/re-registration", {
      state: {
        cartid: item?.cart_id,
        productsid: item?.product_id,
        brand: item?.brand,
        slno: item?.slno,
        year: item?.yearOfPurchase,
        warrantyExp: item?.warrantyExpiryDate,
        category: item?.product_category_id,
        category_name: item?.product_category_name,
        warranty: item?.warranty,
        serviceTypes: item?.serviceTypes,
      },
    })
  }

  return (
    <MobWrapper>
      <MobHeader>
        <MobClsBtn onClick={onClose}>
          <GrFormClose size={25} />
        </MobClsBtn>
      </MobHeader>
      <Container>
        <Title>Order History</Title>
        <ReqContainer
          onClick={() =>
            navigation("/service/registration", {
              state: {
                cartid: 0,
                productsid: 0,
              },
            })
          }
        >Register for another products</ReqContainer>
        <Switchbtn>
          <Products onClick={() => toggleTab(1)}
            style={toggleState === 1 ? { backgroundColor: "#4669E8", color: "#ffffff" } : { backgroundColor: "#E7E9F0", color: "#000000" }}>
            <p>Products</p>
          </Products>
          <Services onClick={() => toggleTab(2)}
            style={toggleState === 2 ? { backgroundColor: "#4669E8", color: "#ffffff" } : { backgroundColor: "#E7E9F0", color: "#000000" }}>
            <p>Services</p>
          </Services>
        </Switchbtn>

        <ProductContainer>
          {toggleState === 1 ? (
            <CartItems>
              <CartItemsContainer>
                {filteredProducts.length === 0 ? (<NoDisplay>no products available</NoDisplay>) : (
                  filteredProducts.map((item, index) => (
                    
                      <div key={item.productId}>
                        <CartItemsConatiner>
                          <ItemImg src={item?.productImages && (JSON.parse(item?.productImages)[0])} />
                          <CartDetailsConatiner>
                            <ItemTitle>{item?.title}</ItemTitle>
                            <ItemDetail>{truncate(item?.description)}</ItemDetail>
                            {item?.requested === 0 && <Button
                              onClick={() => [
                                navigation("/service/registration", {
                                  state: {
                                    cartid: item?.cart_id,
                                    productsid: item?.productId,
                                    productname: item?.title,
                                    category: item?.product_category_id,
                                    brand: 0,
                                    slno: 0,
                                    year: 0,
                                    warrenty: 0,
                                    complaints: 0,
                                    warrentys: 0,
                                    shipping: item?.shipping_address,
                                    productImage: JSON.parse(item?.productImages)[0],
                                  },
                                }),
                                localStorage.setItem("categoryidd", JSON.stringify(item?.product_category_id)),
                                setInvoiceId(item.invoiceid)
                              ]}
                            >Request Service</Button>}
                          </CartDetailsConatiner>

                        </CartItemsConatiner>
                        <img src={line} alt="line" style={{ width: "98%" }}></img>
                      </div>
                  
                  ))
                )}
              </CartItemsContainer>
            </CartItems>
          ) : (
            <CartItems>
              <CartItemsContainer>
                {filteredServices.length === 0 ? (<NoDisplay>no service available</NoDisplay>) : (
                  filteredServices.map((item) => (
                    <div key={item.id}>
                      <CartItemsConatiner>
                        <Mo>Service</Mo>
                        <CartDetailsConatiner>
                          <ItemTitle>{item?.ticket_no}</ItemTitle>
                          <ItemDetail>{truncate(item?.complaint)}</ItemDetail>
                          <Button
                            onClick={() => handleReservice(item)}
                          >Request Re Service</Button>
                        </CartDetailsConatiner>
                      </CartItemsConatiner>
                      <img src={line} alt="line" style={{ width: "98%" }}></img>
                    </div>
                  )
                  ))}
              </CartItemsContainer>
            </CartItems>
          )}
        </ProductContainer>
      </Container>
    </MobWrapper>
  )
}
export default ServiceCartMob;

const Mo = styled.div`
background-color:${colors.blue};
padding: 1.5rem;
color:white;
font-size:12px;
font-weight:400;
`;

const MobWrapper = styled.div`
position: fixed;
top: 0;
right: 0;
bottom: 0;
width: 98%;
background-color:#F8F8F8;
z-index: 2;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
padding-top: 5rem;
height: calc(100vh - 5rem);
`;

const MobHeader = styled.div`
display:flex;
justify-content: space-between;
align-items: center;
`;

const MobClsBtn = styled.button`
  color: #fff;
  border: none;
  cursor: pointer;
  margin:1rem;
  font-size:0.8rem;
  font-weight: bold;
  display:flex;
  justify-content: center;
  align-items: center;
  gap:0.5rem;
  border-radius:5px;
  background-color: #0095ff;
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
  height:2rem;
  padding:0 0.5rem;
`;



const Container = styled.div`
margin:0 0.3rem;
border:2px solid black;
padding: 0 0.8rem;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap:0.5rem;
`;

const Title = styled.div`
font-size: 14px;
font-weight: 600;
`;

const ReqContainer = styled.div`
background-color: rgb(231, 233, 240);
padding:0.5rem;
font-size:10px;
border-radius:10px;
color: ${colors.black};
font-weight: ${fonts.fontWeight5};
`

const Switchbtn = styled.div`
max-width:380px;
    width:100%;
    padding:3px;
    background-color:${colors.lightblue};
    border-radius:12px;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;
    `;


const Products = styled.div`
width:50%;  
height:30px;
border-radius:12px;
display:flex;
align-items:center;
justify-content:center;
cursor:pointer;
p{
    font-size:12px;
    font-weight:300;
}

`;

const Services = styled.div`
width:50%;
height:30px;
background-color:${colors.lightblue};
display:flex;
align-items:center;
justify-content:center;
border-radius:12px;
cursor:pointer;
p{
    font-size:12px;
    font-weight:400;
}
`;



const ProductContainer = styled.div`
/* border:1px solid purple; */
width:100%;
height: calc(100vh - 10rem);
overflow: scroll;
`;

const CartItemsContainer = styled.div`
margin-top:1rem;
margin-bottom:5rem;
`;
const CartItemsConatiner = styled.div`
display: flex;
align-items: center;
gap:0.5rem;
padding:0 0.3rem;
`;



const ItemImg = styled.img`
height:5rem;
width:5rem;
background-color: white;
`
const CartDetailsConatiner = styled.div`
flex-grow: 1;
display: flex;
flex-direction:column;
gap:0.3rem;
`
const ItemTitle = styled.div`
font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 19px;
`

const ItemDetails = styled.div`
padding-top:0.2rem;
`
const ItemDetail = styled.div`
font-size: 12px;
width: 100%;
`

const ProductCard = styled.div`
    height:60px;
    width:100px;
    background-color:${colors.blue};
    justify-content:center;
    border-radius:5px;
    border: 1px solid ${colors.light_grey};
    display: flex;
    align-items: center;
    p{
        font-size:14px;
        font-weight:${Fonts.fontWeight3};
        color:${colors.white}
    }
`

const NoDisplay = styled.div`
display: flex;
align-items:center;
justify-content:center;
font-size:12px;
margin:20px;
color:${colors.ash};
`;


const CartItems = styled.div`
`;


const Button = styled.button`
width:fit-content;
padding:0.5rem;
cursor:pointer;
background-color:${colors.blue};
color:${colors.white};
border:none;
border-radius:8px;
font-size:12px;
font-weight:400;
`