import React, { useEffect, useState } from 'react'
import styled from "@emotion/styled";
import { useNavigate } from 'react-router-dom';

const QuizCountDown = ({ endTime }) => {

  const navigation = useNavigate();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);
    return () => clearInterval(timerInterval);
  }, []);

  useEffect(() => {
    if (timeRemaining.hours === 0 && timeRemaining.minutes === 0 && timeRemaining.seconds === 0) {
      navigation('/')
    }
  }, [timeRemaining, navigation]);


  function calculateTimeRemaining() {
    const now = new Date();
    const end = new Date(`${now.toDateString()} ${endTime}`);
    const difference = end - now;

    if (difference <= 0) {
      // Timer expired
      return { hours: 0, minutes: 0, seconds: 0 };
    }

    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { hours, minutes, seconds };
  }

  return (
    <Container>
      <p>Timer :</p>
      <span>{timeRemaining.hours}hr</span>
      <span>{timeRemaining.minutes}min</span>
      <span>{timeRemaining.seconds}sec</span>
      <p>time left</p>
    </Container>
  )
}

export default QuizCountDown;



const Container = styled.div`
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 40px;
    background: rgba(64, 123, 255, 1);
    overflow: hidden;
    color:white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding-left: 40px;
   
    span{
        font-size: larger;
        font-weight: 500;
    }
    

`;