
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalContainer, Modal, Overlay } from "../modal"
import styled from 'styled-components';
import { IoClose } from "react-icons/io5";
import { Button, ButtonContainer, CloseContainer, Head1 } from './ModalStyled';
import Group from "../../assets/Images/png/Group 348.png"
import { colors } from '../../consts/themes';
import { CleaningServices } from '@mui/icons-material';
import axiosInstance from '../../consts/axiosInstance';


const rad = [
  {
    id: 1,
    name: 'Accept',
  },
  {
    id: 0,
    name: 'Reject',
  },
]


const BulkConfirmModal = ({ close, id, refresh }) => {

  const [verifyStatusId, setVerifyStatusId] = useState(null);
  const [comments, setComments] = useState('');

  const handleRadioChange = (id) => {
    setVerifyStatusId(id);
  };
  const handleCommentsChange = (e) => {
    setComments(e.target.value);
  };


  const submitModal = async () => {
    try {
      const formData = {
        status: verifyStatusId,
        comment: comments,
        id: id,
      };
      const options = {
        method: 'POST',
        url: 'updateStatusCustomSaleReport',
        headers: {
          'Content-Type': 'application/json',
        },
        data: formData,
      };
      const response = await axiosInstance.request(options);
      response?.data?.code === 200 && refresh() && close(false)
    } catch (error) {
      console.error('Error occured', error);
    }
  };


  return (
    <ModalContainer>
      <Modal Modal>
        <Overlay>
          <Container>
            <CloseContainer>
              <IoClose size={22} onClick={() => close(false)} />
            </CloseContainer>

            <InnerConatiner>
              <ImgTick src={Group} alt='tick' />

              <RadioContainer>
                {rad.map((item) => (
                  <Radio key={item.id}>
                    <input
                      type="radio"
                      id={item.name}
                      name="status"
                      value={item.id}
                      checked={verifyStatusId === item.id}
                      onChange={() => handleRadioChange(item.id)}
                    />
                    <label htmlFor={item.name}>{item.name}</label>
                  </Radio>
                ))}

              </RadioContainer>
              <textarea
                placeholder="Enter comments"
                value={comments}
                onChange={handleCommentsChange}
              />
              <SubmitBtn><button onClick={submitModal}>Submit</button></SubmitBtn>
            </InnerConatiner>

          </Container>
        </Overlay>
      </Modal>
    </ModalContainer>
  )
}

export default BulkConfirmModal;


const SubmitBtn = styled.div`
button{
  max-width: 10rem;
    padding: 10px 30px;
    background-color:#4669E8;
    color:white;
    border: none;
}
`;

const Container = styled.div`
display: flex; 
flex-direction:column;
justify-content: center; 
align-items:center;
padding:1.5rem;
gap:1rem;
width:250px;
max-width:100%;
`

const InnerConatiner = styled.div`
display:flex;
flex-direction: column;
justify-content: center;
align-items: center;
textarea {
    width: 200px;
    display: flex;
    height: 10px;
    border: 0.1px solid ${colors.line};
    border-radius: 6px;
    background-color: ${colors.white};
    font-size: 12px;
    padding-left: 10px;
    margin-top: 10px;
    ::-webkit-input-placeholder {
      font-size: 12px;
    }
  }
`

const ImgTick = styled.img`
width:70px;
height:70px;
`

const RadioContainer = styled.div`
padding:0.5rem;
display: flex;
justify-content: center;
align-items: center;
`
const Radio = styled.div`
padding: 0.3rem;
display: flex;
gap: 0.1rem;
`
