
import { ModalContainer, Modal, Overlay } from "../modal"
import styled from 'styled-components';
import { IoClose } from "react-icons/io5";
import { CloseContainer } from './ModalStyled';
import { Container, Head1, Img, ImgContainer, InnerConatiner, Text } from './TypeModalStyle';

const WarrentyDetailsModal = ({ data, close }) => {
  return (
    <ModalContainer>
      <Modal Modal>
        <Overlay>
          <Container>
            <CloseContainer>
              <IoClose size={22} onClick={() => close(false)} />
            </CloseContainer>
            <InnerConatiner>

              <Head1>Warrenty Details</Head1>
              <ItemContainer>
                <ItemDec>{data}</ItemDec>
              </ItemContainer>
            </InnerConatiner>
          </Container>
        </Overlay>
      </Modal>
    </ModalContainer>
  )
}

export default WarrentyDetailsModal;




const ItemContainer = styled.div`
display: flex;
width: 100%;
justify-content: center;
align-items: center;
gap:1.5rem;
flex-wrap: wrap;
height: fit-content;
max-height: 240px;
overflow:scroll;
`;


const ItemDec  = styled.div`
font-size: 0.9rem;
font-weight: 300;
`;



