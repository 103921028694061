import styled from "styled-components";
import { colors } from "../../consts/themes";





// export const Div = styled.div`
//   align-self: flex-end;
//   margin-bottom: 1rem;
//   z-index: 10000;
// `;

// export const Bg = styled.div`
//   display: flex;
//   background-color: ${colors.blue};
//   width: 80px;
//   height: 80px;
//   border-radius: 50px;
//   position: fixed;
//   bottom: 0;
//   right: 0;
//   align-items: "center";
//   display: flex;
//   flex-direction: row;
//   justify-content: "center";
//   float: right;
//   margin-right: 1rem;
//   margin-bottom: 0.8rem;
//   /* margin-top: 2rem; */
//   img {
//     height: 46%;
//     width: 50%;
//     justify-content: center;
//     align-self: center;
//     margin-inline: 1.3rem;
//   }


//   @media screen and (max-width: 768px) {
//     width: 80px;
//     height: 80px;
//     margin-right: 1rem;
//     img {
//       margin-inline: 1.3rem;
//     }
//   }
// `;





// new chat bot 
export const Div = styled.div`
  ${'' /* align-self: flex-end;
  margin-bottom: 1rem;
  z-index: 10000; */}

  z-index: 10000;
`;



export const Bg = styled.div`
  background-color: ${colors.blue};
  width: 110px;
  height: 110px;
  border-radius: 50%;
  position: fixed;
  display: none;
  /* bottom: 0;
  right: 0; 
  margin-right: 2rem;
  margin-bottom: 3rem; */

  /* display: flex; */
  align-items: center;
  justify-content: center;

img {
width: 50%;
height: 50%; 
  }


  @media screen and (max-width: 768px) {
    width: 80px;
    height: 80px;
    margin-right: 1rem;
    ${'' /* img {
      margin-inline: 1.3rem;
    } */}
  }




`;
























export const HeaderContainer = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  height: 80px;
  background-color: ${colors.blue};
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderChild = styled.div`
  background-color: ${colors.blue};
  // display: flex;
  margin-left: 50px;
  @media screen and (max-width: 425px) {
    margin-left: 30px;
  }
  @media screen and (max-width: 320px) {
    margin-left: 10px;
  }
  @media screen and (max-width: 768px) {
    margin-left: 30px;
  }
`;

export const HeaderRightChild = styled.div`
  margin-right: 50px;
  background-color: ${colors.blue};
  display: flex;
  @media screen and (max-width: 425px) {
    margin-right: 15px;
  }
  @media screen and (max-width: 768px) {
    margin-right: 10px;
  }
  @media screen and (max-width: 375px) {
    margin-right: 15px;
  }
`;

export const HeaderLogo = styled.div`
  display: flex;
  img {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    padding-left: ${(props) => props.pl};
    padding-top: ${(props) => props.pt};
  }
  @media screen and (max-width: 768px) {
    img {
      width: 110px;
      height: 20px;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 425px) {
    img {
      width: 100px;
      height: 20px;
    }
  }
`;

export const NavbarLink = styled.div`
  display: flex;
  margin-right: 20px;
  cursor: pointer;
  img {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
  }
  @media screen and (max-width: 768px) {
    img {
      width: 20px;
      height: 20px;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 425px) {
    img {
      width: 20px;
      height: 20px;
    }
  }
`;
export const CartIcon = styled.div`
  display: flex;
  margin-right: 20px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img {
    width: 23px;
    height: 21px;
  }
  @media screen and (max-width: 768px) {
    img {
      width: 18px;
      height: 16px;
    }
  }
`;
