import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { AuthContext } from '../../consts/AuthContext';
import LoadingSpinner from '../../components/Button/Loading/LoadingSpinner';
import ErrorComponent from '../../components/Button/error/ErrorComponent';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../consts/axiosInstance';
// import clikeLogo from '../../../assets/Images/png/clicke.png'
import clikeLogo from '../../assets/Images/png/clicke.png'



const QuizEnd = () => {
  const { id } = useParams();
  // console.log("quizId", id)
  const [authUser, setAuthUser] = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [winners, setWinners] = useState([]);
  const [userId, setUserId] = useState('');
  const [isWinner, setIsWinner] = useState(false);
  const [matchingWinner, setMatchingWinner] = useState(null);



  const getWinner = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`display_winner?quiz_id=${id}`);
      setWinners(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getWinner();
  }, [id]);


  // useEffect(() => {
  //   if (winners.some(winner => winner.user_id === authUser?.user?.id)) {
  //     setIsWinner(true);
  //   } else {
  //     setIsWinner(false);
  //   }
  // }, [winners, authUser?.user?.id]);


  useEffect(() => {
    const winner = winners.find(winner => winner.user_id === authUser?.user?.id);
    if (winner) {
      setIsWinner(true);
      setMatchingWinner(winner); // Set the matching winner
    } else {
      setIsWinner(false);
    }
  }, [winners, authUser?.user?.id]);



  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }

  return (
    <Main>

      {isWinner ? (
        <Container>
          {/* <img src='/image/winner.png' style={{ width: '100%', height: 'auto' }} /> */}
          <ImgWin src='/image/winner.png' alt='winner' />
          <H1>Congratulations</H1>
          <H2>You’ve answered all Questions Correctly</H2>
          {/* <H3>Clickepoints added to your account</H3> */}
          {matchingWinner && <H3>{matchingWinner.clikepoints}<img src={clikeLogo} alt='coin-img' />   Clickepoints added to your account</H3>}
        </Container>
      ) :
        (
          <Container>
            <Img src='/image/simle-quiz.png' />
            <H1>Better Luck next time!</H1>
            <H2>You’ve failed to attend all answers Correctly</H2>
          </Container>
        )
      }
    </Main>
  )
}

export default QuizEnd;


const Main = styled.div`
padding-top: 5rem;
height: calc(100vh - 5rem);
display:flex;
justify-content: center;
align-items: center;
`;


const Container = styled.div`
width:fit-content;
height:fit-content;
display:flex;
flex-direction: column;
align-items: center;
margin:0.5rem;
`;








const Img = styled.img`
  width:10rem;
  height:10rem;
  @media only screen and (min-width: 300px) and (max-width:530px){
    width:8rem;
  height:8rem;
  }
`;
const H1 = styled.div`
font-size:1.5rem;
font-weight:400;
@media only screen and (min-width: 300px) and (max-width:530px){font-size:1rem;
font-weight:500;}
`;



const H2 = styled.div`
font-size:1rem;
font-weight:400;
color:#4669E8;
@media only screen and (min-width: 300px) and (max-width:530px){font-size:0.8rem;}
`;



const H3 = styled.div`
display: flex;
justify-content: center;
align-items:center;
font-size:1rem;
font-weight:400;
@media only screen and (min-width: 300px) and (max-width:530px){font-size:0.8rem;}
  img {
    width: 18px;
    height: 18px;
    padding-inline: 5px;
@media only screen and (min-width:300px) and (max-width:767px) {
width:12px;
height:12px;
}
  }

`;


const ImgWin = styled.img`
object-fit:contain;
width:25rem;
height:20rem;

@media only screen and (min-width: 500px) and (max-width:1023px) {
width:20rem;
height:15rem;
}
@media only screen and (min-width: 300px) and (max-width:499px) {
width:10rem;
height:8rem;
}

`;






const ImgFail = styled.img`
img{
  width:10rem;
  height:10rem;
}
`;