import React, { useEffect, useRef, useState } from 'react'
import axiosInstance from '../../../consts/axiosInstance';
import { useNavigate, useParams } from "react-router-dom";
import LoadingSpinner from '../../../components/Button/Loading/LoadingSpinner';
import styled from "styled-components";

const OrderStatus = () => {
    const { transactionId, } = useParams();
    const [error,setError] = useState(false)
    const [notFound,setNotFound] = useState(false)
    const [count, setCount] = useState(0)
    const [amount,setAmounts] = useState(0)
  const navigation = useNavigate();
    const [loading,setLoading] = useState(true)
    useEffect(()=>{
        getOrderDetails();
    },[])

    const getOrderDetails = async() =>{
        try {
            const response = await axiosInstance.get(`fetch-order/${transactionId}`)
            console.log('response.data.amount', response.data.amount)
            setAmounts(response?.data?.amount)
            setLoading(false)
        } catch (error) {
            console.log('error.response.status', error.response.status)
            if(error.response.status === 400){
                setError(true)
                // navigation('/sales/shoppingcart')
            }else{
                setNotFound(true)

            }
            setLoading(false)
        }
    }
    if (loading) {
        return <LoadingSpinner />
    }

    const nav =() =>{
        navigation('/profile/myorders')
    }
  return (
    <div class="ordercontainer">
         {notFound ? null : <div class="printer-top"></div>}

        {notFound?
        <div class="err"> 
            404 Not Found
        </div>
        :
        <div>
        {!error ?
            <div class="paper-container">
                <div class="printer-bottom"></div>

                <div class="paper">
                    <div class="main-contents">
                        <div class="success-icon">&#10004;</div>
                        <div class="success-title">
                            Payment Complete
                        </div>
                        <div class="success-description">
                            Thank you for completing the payment! Your order has been placed successfully.
                        </div>
                        <div class="order-details">
                            <div class="order-number-label">Transaction ID</div>
                            <div class="order-number">{transactionId}</div>
                        </div>
                        <div class="order-details">
                            <div class="order-number-label">Amount</div>
                            <div class="order-number">{amount}</div>
                            <div class="complement">Thank you</div>
                            <CloseButton onClick={nav}>See Orders</CloseButton>

                        </div>

                    </div>
                    <div class="jagged-edge"></div>

                </div>
            </div>
            :
            <div class="paper-container">
            <div class="printer-bottom"></div>

            <div class="paper">
                <div class="main-contents">
                    {/* <div class="success-icon">&#10004;</div> */}
                    <div class="success-title">
                        Payment Failed!
                    </div>
                    <div class="success-description">
                    Unfortunately, your payment didn't go through. But don't worry—this happens sometimes! <br></br>

                    💡 Tip: Double-check your payment details or try using a different payment method. <br/>

                    We're here to help you complete your purchase. Your cart is still waiting for you, filled with all the items you love. Don’t give up now—your next click could be the one!
                    </div>
                    <div class="order-details">
                       
                        <CloseButton onClick={()=>navigation('/sales/shoppingcart')}>Go To Cart</CloseButton>

                    </div>

                </div>
                {/* <div class="jagged-edge"></div> */}

            </div>
            </div>
        }
        </div>
        }
    </div>
  )
}

export default OrderStatus

const CloseButton = styled.button`
  color: #fff;
  border: none;
  cursor: pointer;
  margin:1rem;
  padding:10px;
  font-size:0.8rem;
  font-weight: bold;
  display:flex;
  justify-content: center;
  align-items: center;
  gap:0.5rem;
  border-radius:5px;
  background-color: #0095ff;
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
`;