import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../../consts/themes';
import { IoArrowBackOutline } from "react-icons/io5";
import LoadingSpinner from '../../../components/Button/Loading/LoadingSpinner';
import axiosInstance from '../../../consts/axiosInstance';

const OrderDetails = () => {
  const location = useLocation();
  const { state } = location;
  const { data } = state || {};
  console.log(data?.bidwin)

  const [invoiceAddress, setInvoiceAddress] = useState('');
  const [shippingAddress, setShippingAddress] = useState('');
  const [loading, setLoading] = useState(false);



  const getAddress = async (id, setAddress) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`getCustomerAddressById/${id}`);
      setAddress(response.data[0]);
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  const renderBidInitialRows = () => {
    console.log("winbid render started"); // Debugging log
    console.log("data.inital:", data.bidinitial); // Check data structure
    return data.bidinitial?.map((item, index) => {
      const productImage = item.images;
      const imageSrc = productImage && JSON.parse(productImage)[0] ? JSON.parse(productImage)[0] : null;

      return (
        <TableRow key={`initial-${index}`}>
          <TableCell>{index + 1}</TableCell>
          <TableCell>
            <ImgDiv>
              {imageSrc ? (
                <img src={imageSrc} alt="Initial Product" />
              ) : (
                <span>No Image Available</span>
              )}
            </ImgDiv>
          </TableCell>
          <TableCell>
            {item.title} <br />
            <span style={{ color: "green" }}>Initial Product</span>
          </TableCell>
          <TableCell>1</TableCell>
          <TableCell>₹ {item.offer_price}</TableCell>
        </TableRow>
      );
    });
  };

  const renderBidWinRows = () => {
    console.log("winbid render started"); // Debugging log
    console.log("data.bidwin:", data?.bidwin); // Check data structure
    return data?.bidwin?.map((item, index) => {
      const winProductImage = item.images;
      console.log(winProductImage, " hai")
      const winImageSrc = winProductImage && JSON.parse(winProductImage)[0] ? JSON.parse(winProductImage)[0] : null;

      return (
        <TableRow key={`win-${index}`}>
          <TableCell>{index + 1}</TableCell>
          <TableCell>
            <ImgDiv>
              {winImageSrc ? (
                <img src={winImageSrc} alt="Winning Product" />
              ) : (
                <span>No Image Available</span>
              )}
            </ImgDiv>
          </TableCell>
          <TableCell>
            {item.title} <br />
            <span style={{ color: "blue" }}>Winning Product</span>
          </TableCell>
          <TableCell>1</TableCell>
          <TableCell>₹ {item.amount}</TableCell>
        </TableRow>
      );
    });
  };


  useEffect(() => {
    if (data.shipping_address) {
      getAddress(data.shipping_address, setShippingAddress);
    }

    if (data.billing_address) {
      getAddress(data.billing_address, setInvoiceAddress);
    }
  }, [data.shipping_address, data.billing_address]);


  console.log(data)

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <Screen>

      <DetailContainer>
        {/* 
        <Button><IoArrowBackOutline size={24} /><span> order details</span></Button> */}


        <Heading>Order Details</Heading>

        <Details>
          <Detail><p>Invoice id</p>:<span>{data.invoice_id}</span></Detail>
          <Detail><p>Purchase date</p>:<span>{data.purchase_date}</span></Detail>
          <Detail><p>Total amount</p>:<span>{data.total}</span></Detail>
          <Detail><p>Payment Method</p>:<span>{data?.payment_details?.payment_method}</span></Detail>
          <Detail><p>Payment Status</p>:<span>{data?.payment_details?.status}</span></Detail>


          <Detail>
            {data?.payment_details?.card_details && JSON.parse(data?.payment_details?.card_details).last_four_digits && (
              <p>Last 4 digit of Card: <span>{JSON.parse(data?.payment_details?.card_details).last_four_digits}</span></p>
            )}
          </Detail>

          <Detail>
            {data?.payment_details?.card_details && JSON.parse(data?.payment_details?.card_details).card_type && (
              <p>Card type: <span>{JSON.parse(data?.payment_details?.card_details).card_type}</span></p>
            )}
          </Detail>
        </Details>

        <AddressContainer>
          <Address>
            <Button><span>Shipping Address</span></Button>
            <div>
              <Detail><p>name</p>:<span>{shippingAddress.name}</span></Detail>
              <Detail><p>house name</p>:<span>{shippingAddress.house_name}</span></Detail>
              <Detail><p>street name</p>:<span>{shippingAddress.street_name}</span></Detail>
              <Detail><p>district</p>:<span>{shippingAddress.district}</span></Detail>
              <Detail><p>state</p>:<span>{shippingAddress.state}</span></Detail>
              <Detail><p>pincode</p>:<span>{shippingAddress.pincode}</span></Detail>
              <Detail><p>Mobile 1</p>:<span>{shippingAddress.mob1}</span></Detail>
              <Detail><p>Mobile 2</p>:<span>{shippingAddress.mob2}</span></Detail>
            </div>
          </Address>

          <Address>
            <Button><span>Billing Address</span></Button>
            <div>
              <Detail><p>name</p>:<span>{invoiceAddress.name}</span></Detail>
              <Detail><p>house name</p>:<span>{invoiceAddress.house_name}</span></Detail>
              <Detail><p>street name</p>:<span>{invoiceAddress.street_name}</span></Detail>
              <Detail><p>district</p>:<span>{invoiceAddress.district}</span></Detail>
              <Detail><p>state</p>:<span>{invoiceAddress.state}</span></Detail>
              <Detail><p>pincode</p>:<span>{invoiceAddress.pincode}</span></Detail>
              <Detail><p>Mobile 1</p>:<span>{invoiceAddress.mob1}</span></Detail>
              <Detail><p>Mobile 2</p>:<span>{invoiceAddress.mob2}</span></Detail>
            </div>
          </Address>
        </AddressContainer>
      </DetailContainer>


      <Container>
        {data.cart.length > 0 && data.cart[0].productArray.length > 0 && (
          <Tables>
            <TableCell><Head>Products</Head></TableCell>
            <TableHeader>
              <TableCell>Sl no</TableCell>
              <TableCell>Item</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Amount</TableCell>
            </TableHeader>

            <Items>
              {data.cart.length > 0 && data.cart[0].productArray.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <ImgDiv>
                      <img src={JSON.parse(item.productImages)[0]} />
                    </ImgDiv>
                  </TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.qnty}</TableCell>
                  <TableCell>₹ {item.qnty * (item.parent_product_id ? item.comboPrice :
                    item.offer_price && item.offer_price !== 0 ? item.offer_price :
                      item.price)}</TableCell>
                </TableRow>
              ))}
            </Items>
          </Tables>
        )}

        {data.custompc.length > 0 && (
          <Tables>
            <TableCell><Head>Custom Pc</Head></TableCell>
            <TableHeader>
              <TableCell>Sl no</TableCell>
              <TableCell>Item</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Amount</TableCell>
            </TableHeader>
            <Items>
              {data.custompc.length > 0 && data.custompc[0].products.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <ImgDiv>
                      <img src={JSON.parse(item.images)[0]} />
                    </ImgDiv>
                  </TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>{item.amount}</TableCell>
                </TableRow>
              ))}
            </Items>
          </Tables>
        )}






        {data.cctv.length > 0 && (
          <Tables>
            <TableCell><Head>Cctv Customization</Head></TableCell>
            <TableHeader>
              <TableCell>Sl no</TableCell>
              <TableCell>Item</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Amount</TableCell>
            </TableHeader>
            <Items>
              {data.cctv.length > 0 && data.cctv[0].products.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <ImgDiv>
                      <img src={JSON.parse(item.images)[0]} />
                    </ImgDiv>
                  </TableCell>
                  <TableCell>{item.title}</TableCell>
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>{item.amount}</TableCell>
                </TableRow>
              ))}
            </Items>
          </Tables>
        )}

        {(data.bidinitial?.length > 0 || data.bidwin?.length > 0) && (
          <Tables>
            <TableCell><Head>Bid Product</Head></TableCell>
            <TableHeader>
              <TableCell>Sl no</TableCell>
              <TableCell>Item</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Amount</TableCell>
            </TableHeader>
            <Items>
              {data.bidinitial?.map((item, index) => (
                <TableRow key={`initial-${index}`}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <ImgDiv>
                      <img src={JSON.parse(item.images)[0]} alt="Initial Product" />
                    </ImgDiv>
                  </TableCell>
                  <TableCell>{item.title} <br /><span style={{ color: "green" }}>Initial Product</span></TableCell>
                  <TableCell>1</TableCell>
                  <TableCell>₹ {item.offer_price}</TableCell>
                </TableRow>
              ))}
              {data.bidwin?.map((item, index) => (
                <TableRow key={`win-${index}`}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <ImgDiv>
                      <img src={JSON.parse(item.images)[0]} alt="Winning Product" />
                    </ImgDiv>
                  </TableCell>
                  <TableCell>{item.title} <br /><span style={{ color: "blue" }}>Winning Product</span></TableCell>
                  <TableCell>1</TableCell>
                  <TableCell>₹ {item.amount}</TableCell>
                </TableRow>
              ))}
            </Items>
          </Tables>
        )}
      </Container>
    </Screen>
  )
}

export default OrderDetails;


const Details = styled.div`
  border:1px solid ${colors.line};
  padding:10px;
`;

const Heading = styled.div`
font-size: 1.5rem;
font-weight: 500;
`;

const Detail = styled.div`
display: flex;
align-items: center;
gap:0.3rem;
font-size: 0.9rem;
p{
  padding: 0;
  margin:0;
  font-weight: 500;
}
`;

const DetailContainer = styled.div`
margin:1rem;
margin-top:6rem;
display: flex;
flex-direction: column;
gap: 2rem;
`;


const AddressContainer = styled.div`
display: flex;
justify-content: space-between;
`;


const Address = styled.div`
display: flex;
flex-direction:column;
gap:0.8rem;
`;


const Button = styled.div`
display: flex;
align-items: center;
gap:0.5rem;
span{
  font-weight: 500;
  font-size: 1rem;
}
`;

const Container = styled.div`
border-radius: 8px;
margin:2rem 1rem;
display: flex;
flex-direction: column;
gap:2.5rem;
`;

const Items = styled.div`
display:flex;
flex-direction: column;
gap:1rem;
padding:1rem;
`;

const Head = styled.div`
font-size: 1rem;
font-weight: 500;
`;

const Screen = styled.div`
  margin-top: 5rem;
  // height:100vh;
`;


const Tables = styled.div`
  display: flex;
  flex-direction: column;
  background:#F3F4F7;
  border-radius: 8px;
  border:1px solid ${colors.line}
`;


const TableHeader = styled.div`
display: flex;
color: ${colors.blue};
font-weight: 500;
`;

const TableRow = styled.div`
  display: flex;
  background: #FBFBFB;
  border-radius: 8px;
`;

const TableCell = styled.div`
  flex: 1;
  padding: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #ddd; */
`;




const Style = styled.div`
  width: 100%;
  hr {
    height: 1px;
    background-color: ${colors.line};
    border: none;
  }
`;




export const ImgDiv = styled.div`
  /* background-color: aquamarine; */
  /* width: 12%; */
  /* flex-shrink:0; */
  /* width: 12%; */
  /* flex-shrink:0; */
  /* margin-bottom: 5px; */
  justify-content: center;
  align-items: center;
  img {
    width:70px;
    height:70px;
  }

`;