import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalContainer, Modal, Overlay } from "../modal.js";
import {
  Installationmodal_main,
  DIv,
  Installationmodal_container,
  Installationmodal_content,
  Installation_text,
  Installation_Button,
  Installation_Button_one,
  Installation_Button_two,
} from "./Installationmodal.styled";
import { GrFormClose } from "react-icons/gr";







const AccessoriesGoBackModal = ({ close, catgId, openInstall }) => {

  const navigation = useNavigate()
  // console.log(catgId, " catgid")


  const changeFunction = () => {
    if (catgId === 18) {
      navigation("/sales/accessories");
    } else if (catgId === 11) {
      navigation("/printer");
    }
  }



  return (
    <ModalContainer>
      <Modal Modal>

        <Overlay>

          <Installationmodal_content>

            <DIv>
              <GrFormClose size={20} onClick={() => {
                close(false)
              }} />
            </DIv>

            <Installation_text>Product Already Exists in Cart</Installation_text>
            <>
              <Installation_Button>



                {catgId === 11}

                <Installation_Button_one onClick={() => close(false)} >
                  Back
                </Installation_Button_one>











                <Installation_Button_two onClick={changeFunction}>
                  {catgId === 18 ? " Buy Another Accessory" : "Buy Another Printer"}
                </Installation_Button_two>

              </Installation_Button>

            </>


          </Installationmodal_content>
        </Overlay>
      </Modal>
    </ModalContainer>
  )
}

export default AccessoriesGoBackModal;