import { React, useState, useEffect } from "react";
import { Styledmain, Styledmaincontent, Styledclickart, ModalContainer, Modal } from './Styled/Subcategory.styled'

// import Colors from '../consts/Colors'
import Assistance from '../../components/Modal/Assistance';
import { CardImag, ImgCard, ScreenCont, ScreenDiv } from "./Styled/Subcategory.styled";
import Login from "../../components/Modal/Login";
import Helppage from "../../components/Modal/Helppage";
import { useNavigate } from "react-router-dom";
import { CustomerCategory } from "../../api/api";
import PrinterType from "../../components/Modal/PrinterType";
import LoadingSpinner from "../../components/Button/Loading/LoadingSpinner";
import axios from "axios";
import ErrorComponent from "../../components/Button/error/ErrorComponent";

import styled from 'styled-components';
import axiosInstance from "../../consts/axiosInstance";




const Screendiv = styled.div`
  width: 100%;
  display: flex;
  height:100vh;
  justify-content: center;

  @media screen and (max-width: 1500px) {
    width: 100%;
  }

  @media screen and (max-width: 1100px) {
    width: 100%;
  }

  @media screen and (max-width: 800px) {
    width: 100%;
    margin-top: 4rem;
  }

  @media screen and (max-width: 500px) {
    margin-top: 0rem;
  }
`;







function Category() {


    const navigation = useNavigate();
    const [isOpen, setOpen] = useState(true);
    const [subCategory, setIsSubCategory] = useState([]);
    const [printertype, setPrntertype] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    //product catagopry id lap, mob etc
    const productcodes = JSON.parse(localStorage.getItem('productid'));

    //type of sale id bulk , common retail etc
    const purchasetype = JSON.parse(localStorage.getItem('codeid'));



    const sub_category = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get(`getCustomerCategory/${productcodes}/${purchasetype}`);
            console.log(response.data, " response of sub category")
            setIsSubCategory(response.data);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };


    const categoryHandler = (item) => {
        localStorage.setItem("categoryId", JSON.stringify(item?.id))
        navigation('/purchase/form')
    }


    useEffect(() => {
        sub_category();
    }, []);


    if (loading) {
        return <LoadingSpinner />
    }

    if (error) {
        return <ErrorComponent error={error.message} />
    }




    return (
        <Screendiv>
            <ScreenCont>
                {
                    subCategory?.map((item, i) => {
                        return (
                            <CardImag >
                                <ImgCard onClick={() => categoryHandler(item)}>
                                    {/* <p style={{color:"black"}}>{item?.name}</p> */}
                                    {/* <img src={item?.category_image}></img> */}
                                    <img src={item?.category_image && item?.category_image}></img>
                                </ImgCard>
                                <p style={{ color: "black" }}>{item?.category_name}</p>
                            </CardImag>
                        )
                    })
                }
            </ScreenCont>
        </Screendiv>
    )
}
export default Category;

