import React, { useContext, useEffect, useState } from 'react'
import { Btn, Div, Dot, Dots, Main, Row, RowDiv, RowDivs } from './priceStyle'
import { colors } from '../../../consts/themes'
import Img1 from '../../../assets/sale/Group 297.png';
import { useNavigate } from "react-router-dom";
import { getCart } from '../../../api/api';
import { AuthContext } from '../../../consts/AuthContext';

const arr = [
    {
        amount: "36290"
    }
]






function Price({ buttonclick, dis, price, rewardsUsed, total, activeAddress, insertInvoiceFn }) {

    const [authUser, setAuthUser] = useContext(AuthContext)
    const navigation = useNavigate();




    const proceed = () => {
        buttonclick();
        dis === 200 && navigation('/sales/invoice')
    }

    return (
        <Main>
            <p>Price Details</p>

            <Div />
            {/* {arr.map(item => {
                return ( */}

            <Row>
                <p>Price</p>
                <p>₹ {total}</p>
                {/* <img src={Img1} /> 
                        <p>x{item.reward}</p> */}
            </Row>


            <RowDiv>
                <p>Discount</p>

                <RowDivs>
                    <img src={Img1} />
                    <p>x 15</p>
                </RowDivs>
            </RowDiv>
            <Row>
                <p>Discount Amount</p>
                {/* <p>₹ {(rewardsUsed * 10)}</p> */}
                <p>₹ </p>
            </Row>

            <Row>
                <p>Delivery Charge</p>
                <p style={{ color: '#27ad4d' }}>Free</p>
            </Row>

            <Dot />

            <Row>
                <h2>Grand Total</h2>
                {/* <h2>₹ {price - (rewardsUsed * 10)}</h2> */}
                <h2>₹ {total}</h2>

            </Row>


            {/* <Dots /> */}

            {/* {activeAddress.length !== 0 &&
                <Btn onClick={insertInvoiceFn} >
                    <p >Proceed to pay</p>
                </Btn>
            } */}

        </Main >
    )
}

export default Price




// return (
//     <Main>
//         <p>Price Details</p>
//         {/* {console.log("data2222", isSubCategory)} */}
//         <Div />
//         {/* {arr.map(item => {
//             return ( */}

//         <Row>
//             <p>Price</p>
//             <p>₹ {price}</p>
//             {/* <img src={Img1} />
//                     <p>x{item.reward}</p> */}
//         </Row>


//         <RowDiv>
//             <p>Discount</p>
//             {/* {console.log(parseInt(arr?.map(item => item?.amount)))} */}
//             <RowDivs>
//                 {/* <p>₹{parseInt(isSubCategory?.total_amount) - parseInt(arr?.map(item => item?.amount))} +</p> */}
//                 <img src={Img1} />
//                 <p>x {rewardsUsed}</p>
//             </RowDivs>
//         </RowDiv>
//         <Row>
//             <p>Discount Amount</p>
//             {/* <p>₹ {(rewardsUsed * 10)}</p> */}
//             <p>₹ {total - price}</p>
//         </Row>

//         <Row>
//             <p>Delivery Charge</p>
//             <p style={{ color: '#27ad4d' }}>Free</p>
//         </Row>

//         <Dot />

//         <Row>
//             <h2>Grand Total</h2>
//             {/* <h2>₹ {price - (rewardsUsed * 10)}</h2> */}
//             <h2>₹ {total}</h2>

//         </Row>
//         <Dots />
//         <Btn ><p
//             onClick={() => navigation('/sales/invoice')}
//         >Proceed to pay</p></Btn>
//     </Main >
// )






