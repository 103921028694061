import styled from "styled-components";
import Colors from "../../consts/Colors";
import { colors, fonts } from "../../consts/themes";


export const Helppagemain = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100vh;
backdrop-filter: blur(0.5px);
opacity: 5;


`
export const Helppagecontentcontainer = styled.div`
position: absolute;
top: 150px;
right: calc(50% - 190px);
padding: 20px;
border-radius:5px;
width:auto;
height:auto;
background: ${Colors.white};
border:1px solid white;
border-radius:10px;
border: 1px solid white;
border: thin solid #e3e3e3 ;
box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1);

@media(max-width:2560px)
{
    right: calc(50% - 20vw);
    top: 14vw;
}
@media(max-width:1440px)
{
    right: calc(50% - 18vw);
    top: 150px;
}
@media(max-width:768px)
{
    right: calc(50% - 22vw);
}
// @media(max-width:425px)
// {
//     right: calc(50% - 45vw);
// }
@media(max-width:375px)
{
    right: calc(50% - 49vw);
    width:85vw;
}

@media(max-width:320px)
{
    right: calc(50% - 147px);
    width:78vw;
}

`
export const Helppagecontent = styled.div`

display:flex;
flex-direction:column;
row-gap:30px;
height:100vh;
padding-block: 8rem;

@media (max-width: 425px)
{
padding-block: 6rem;
}
`
export const Helppagetextone = styled.div`
font-size: 18px;
text-align:center;
font-weight: ${fonts.fontWeight5};
`

export const Helppagetexttwo = styled.div`

font-size: 12px;
width:30vw;
padding-inline: 2rem;
color :${colors.light_grey};
text-align:center;
align-self: center;

@media(max-width:1440px)
{
    padding-inline: 6rem;
}
@media(max-width:1024px)
{
width: 40vw;
}

@media (max-width: 768px)
{
    width: 52vw;
}
@media (max-width: 425px)
{
    width: 55vw;
    p{
        width: 70vw;
        margin-left: -2rem;
    }
}
 @media (max-width: 375px)
 {
    width: 49vw;
    p{
    width: 70vw;
    margin-left: -2.5rem;
}
 }
 @media (max-width: 320px)
 {
 width: 40vw;
 p{
    width: 70vw;
    margin-left: -3rem;
}
 }
`
export const Div = styled.div`
    margin-left: 95%;
    margin-top: -8%;
    color: gray;
    // @media(max-width: 425px){
    //     margin-left: 75%;
    //     margin-top: -8%;
    // }
`
export const Images = styled.div`

display:flex;
flex-direction:row;
column-gap:35px;
margin-top: 2rem;
justify-content:center;
position:relative;

@media (max-width: 500px)
{
    flex-direction: column;
    row-gap: 30px;
    align-items: center;
    margin-top: 0rem;
}
img
{
    width:8vw;
    height:8vw;
    display:block;
 
}

`


export const Imagecall = styled.div`
       width: 190px;
       box-shadow: 2px 2px 25px 2px rgba(204,204,204,0.45);
        height: 190px;
        /* padding: 1.3rem; */
        border-radius: 8px;
        /* margin-inline: 1rem; */
        // background-color: "linear-gradient(45deg, #529F7F 0%, #7AC9C2 100%)"
        // #529F7F;
        @media (max-width: 800px)
        {
            /* width: 10rem;
            height: 10rem; */
        }
img
{
    width: 190px;
    height: 190px;
    object-fit:cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
    
    @media (max-width: 800px)
        {
            width: 190px;
            height: 190px;
        }
    }
 
`

export const Imagecall1 = styled.div`
       width: 190px;
       box-shadow: 2px 2px 25px 2px rgba(204,204,204,0.45);
        height: 190px;
        /* padding: 1.3rem; */
        border-radius: 8px;
        /* margin-inline: 1rem; */
        // background-color: "linear-gradient(45deg, #529F7F 0%, #7AC9C2 100%)"
        // #529F7F;

        @media (max-width: 800px)
        {
            width: 190px;
    height: 190px;
        }
img
{
    width: 190px;
    height: 190px;
    object-fit:cover;
    display: block;
    margin-left: auto;
    margin-right: auto;
   
    @media (max-width: 800px)
        {
            width: 190px;
    height: 190px;
        }
    }
`

export const Imagetext = styled.div`
font-size:12px;
margin-top: 1rem;
padding-left: 4px;
/* text-align:center; */
color :${colors.black};
`
export const Imagechat = styled.div`

img
{
    width: 2.5rem;
    height: 2.4rem;
    position: absolute;
  top: 30px;
  right: 15.5vw;
   
  @media(max-width:2560px)
  {
    top:2.7vw;
  }
  @media(max-width:1440px)
{
    top: 45px;
    right: 17.2vw;
}
@media(max-width:1440px)
{
top: 40px;
    right: 26vw;
    width: 2.2rem;
    height: 2rem;
}
@media(max-width:768px)
{
    width:3vw;
    height:3vw;
    right:20vw;
}
// @media(max-width:425px)
// {
//     width:4vw;
//     height:4vw;
//     right:40vw;
//     top:20px;
// }
@media(max-width:375px)
{
    width:5vw;
    height:5vw;
    right:43vw;
    top:22px;
}
@media(max-width:320px)
{
    right:38vw;
}
}
`
export const Imagevdo = styled.div`

img
{
    width: 2.3rem;
    height: 2.2rem;
    position: absolute;
  top: 30px;
  right: 6vw;

  @media(max-width:2560px)
  {
    right:7vw;
    top:2.8vw;
  }
  @media(max-width:1440px)
  {
    right: 6.5vw;
    top: 45px;
  }
    @media(max-width:1024px)
    {
        right: 10vw;
    top: 40px;
    width: 2.2rem;
    height: 2rem;
    }
    @media(max-width:768px)
    {
        width:3vw;
        height:3vw;
        right:4vw;
    }
    // @media(max-width:425px)
    // {
    //     width:4vw;
    //     height:4vw;
    //     right:11vw;
    //     top:20px;
    // }
    @media(max-width:375px)
    {
        width:5vw;
        height:5vw;
        top:22px;
    }
    @media(max-width:320px)
   
    {
        right:5vw;
    }
}

`

export const Imagecircleone = styled.div`
    /* width: 4rem;
    height: 4rem;    */
    width: 190px;
    height: 190px;
    margin-bottom: 1rem;
    align-self: center;
`
export const Imagecircletwo = styled.div`

    align-self: center;
    width: 190px;
    height: 190px;
    margin-bottom: 1rem;

`
export const Imagecirclethree = styled.div`

align-self: center;
width: 190px;
    height: 208px;

`

export const Button = styled.div`
    display: flex;
    flex-direction: row;
    height: 1.5rem;
    border-radius: 8px;
    cursor:pointer;
    color: #4669E8;
    font-size: 1rem;
    font-weight: ${fonts.fontWeight5};
    justify-content: center;
    padding-top: 3rem;
p{
    justify-content: center;
    align-self: center;
}
`