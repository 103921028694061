import styled from "styled-components";
import { colors, fonts } from "../../../consts/themes";

export const MainDiv = styled.div`
  margin-top: 80px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
  //   @media screen and (max-width: 500px) {
  //   display: flex;
  //   flex-direction: column;

  // }
  
`;
export const Div1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  /* justify-content: center; */
  padding-left: 30px;
  @media screen and (max-width: 1024px) {
    margin-top: 2rem;
  }
  @media screen and (max-width: 800px) {
    /* height: 90vh; */
    padding-left: 0px;

   

  }

  // @media screen and (max-width: 500px) {
  //   width: 100%;
  // }

  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;
export const InvoiceDiv = styled.div`
  width: 90%;
  margin-top: 0px;
  /* background-color: antiquewhite; */
  border: 0.5px solid ${colors.line};
  @media screen and (max-width: 1500px) {
    /* height: 90vh; */
  }
  @media screen and (max-width: 800px) {
    width: 95%;
  }
  @media screen and (max-width: 500px) {
    height: 105vh;
  }
  @media screen and (max-width: 400px) {
    height: 110vh;
  }
  @media screen and (max-width: 350px) {
    height: 112vh;
  }
`;
export const Header = styled.div`
  background-color: ${colors.blue};
  width: 90.1%;
  height: 4vh;
  @media screen and (max-width: 800px) {
    width: 95%;
  }
`;
export const Heads = styled.div`
  align-items: center;
  height: 4vh;
  justify-content: center;
  /* background-color: antiquewhite; */
  display: flex;
  flex-direction: row;
  padding-left: 80%;
  img {
    width: 18px;
    height: 18px;
  }
  p {
    padding-left: 10px;
    flex-shrink: 0;
    padding-right: 2rem;
    font-size: 1rem;
    color: ${colors.white};
    @media screen and (max-width: 320px) {
      padding-left: 0px;
    }
  }
  @media screen and (max-width: 1050px) {
    padding-left: 70%;
  }
  @media screen and (max-width: 800px) {
    padding-left: 65%;
  }
  @media screen and (max-width: 500px) {
    padding-left: 55%;
  }
  @media screen and (max-width: 400px) {
    padding-left: 45%;
  }
`;
export const ImageName = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
  /* background-color: antiquewhite; */
  img {
    width: 100px;
    height: 18px;
  }
  p {
    /* background-color: aquamarine; */
    padding-left: 5px;
    width: 10%;
    font-size: 1.5rem;
    font-weight: ${fonts.fontWeight5};
  }
  @media screen and (max-width: 1500px) {
    height: 5vh;
  }
  @media screen and (max-width: 1500px) {
    height: 10vh;
  }
`;
export const Details = styled.div`
  /* background-color: aqua; */
  padding-left: 8px;
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 1500px) {
    height: 10vh;
  }
  @media screen and (max-width: 1500px) {
    height: 8vh;
  }
  @media screen and (max-width: 500px) {
    width: 98%;
    height: 10vh;
    display: flex;
    flex-direction: column;
  }
`;
export const IDname = styled.div`
  /* background-color: azure; */
  width: 30%;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 1500px) {
    height: 10vh;
  }
  @media screen and (max-width: 1050px) {
    width: 30%;
    height: 8vh;
  }

  @media screen and (max-width:  1300px) {
    height: 6vh;
    p {
      margin-top: 0px;
      margin-bottom: 0px;
      font-size:12px;

    }
  }
  @media screen and (max-width: 800px) {
    width: 50%;
  }
  @media screen and (max-width: 500px) {
    width: 60%;
  }
  @media screen and (max-width: 400px) {
    width: 70%;
  }
`;
export const Id = styled.div`
  /* background-color:blue; */
  text-align: left;
  width: 40%;
  p {
    font-size: 1rem;
    font-weight: 500;
  }
  @media screen and (max-width: 1500px) {
    /* height: 10vh; */
    p {
      margin-top: 0px;
    }
  }
  @media screen and (max-width: 1250px) {
    height: 6vh;
    p {
      margin-top: 0px;
      margin-bottom: 0px;
      font-size:12px;

    }
  }
`;
export const IdDetails = styled.div`
  p {
    text-align: left;
  }
  @media screen and (max-width: 1500px) {
    /* height: 10vh; */
    p {
      margin-top: 0px;
    }
  }
  @media screen and (max-width:  1300px) {
    height: 6vh;
    p {
      margin-top: 0px;
      margin-bottom: 0px;
      font-size:12px;

    }
  }
`;
export const Date = styled.div`
  /* background-color: bisque; */
  width: 14%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  p {
    font-weight: ${fonts.fontWeight5};
  }
  @media screen and (max-width: 1500px) {
    width: 20%;
    /* justify-content: space-evenly; */
  }

  @media screen and (max-width: 1300px) {

   
    height: 35vh;


    p {
      font-size:12px;

    }

  }
  @media screen and (max-width: 1050px) {
    width: 27%;

  }
  @media screen and (max-width: 800px) {
    width: 35%;
  }
  @media screen and (max-width: 500px) {
    width: 47%;
    p {
      // margin-top: 5px;
    }
    /* justify-content: space-evenly; */
  }
  @media screen and (max-width: 400px) {
    width: 54%;
  }
  @media screen and (max-width: 350px) {
    width: 59%;
  }
`;
export const Ptg = styled.div`
  p {
    font-size: 1rem;
    font-weight: ${fonts.fontWeight3};
  }

  @media screen and (max-width: 1300px) {

   
    height: 35vh;


    p {
      font-size:12px;

    }

  }
`;
export const Addres = styled.div`
  /* background-color: brown; */
  width: 85%;
  padding-left: 8px;
  @media screen and (max-width: 1050px) {
    height: 20vh;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    height: 30vh;
    /* padding-inline: 10px; */
  }
  @media screen and (max-width: 400px) {
    height: 35vh;
  }
`;
export const Adds = styled.div`
  /* background-color: aquamarine; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    height: 20vh;
  }
  @media screen and (max-width: 500px) {
    height: 40vh;
    flex-direction: column;
  }
`;
export const InvoiceAdd = styled.div`
  /* background-color: beige; */
  text-align: left;
  p {
    font-weight: ${fonts.fontWeight5};
  }
  @media screen and (max-width: 800px) {
    p {
      margin-top: 0px;
      margin-bottom: 3px;
    }
  }
  @media screen and (max-width: 500px) {
    p {
      margin-top: 10px;
      margin-bottom: 0px;
    }
  }
`;

export const ShippingAdd = styled.div`
  /* background-color: blanchedalmond; */
  text-align: left;
  p {
    font-weight: ${fonts.fontWeight5};
  }
  @media screen and (max-width: 800px) {
    p {
      margin-top: 0px;
      margin-bottom: 3px;
    }
  }
  @media screen and (max-width: 500px) {
    p {
      margin-top: 10px;
      margin-bottom: 0px;
    }
  }
`;
export const Add = styled.div`
  width: 80%;
  /* background-color: aliceblue; */
  p {
    font-size: small;
    color: ${colors.black};
    font-weight: ${fonts.fontWeight3};
    max-width: 80%;
    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }
  @media screen and (max-width: 500px) {
    p {
      margin-top: 5px;
      margin-bottom: 0px;
    }
  }
`;

export const Table = styled.div`
  /* background-color: antiquewhite; */
  padding-left: 8px;
  padding-top: 2rem;
  @media screen and (max-width: 500px) {
    padding-inline: 10px;
  }
  @media screen and (max-width: 400px) {
    padding-left: 4px;
  }
`;
export const TitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 88%;
  padding-left: 20px;
  @media screen and (max-width: 1050px) {
    width: 95%;
  }
  @media screen and (max-width: 400px) {
    justify-content: space-between;
    padding-left: 0px;
  }
`;
export const TableContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: yellowgreen; */
  width: 90%;
  @media screen and (max-width: 1050px) {
    width: 98%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
  @media screen and (max-width: 400px) {
    width: 95%;
  }
`;
export const TableDetails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content:space-between;
  justify-content:space-between;
  /* background-color: antiquewhite; */
  p {
    /* background-color: antiquewhite; */
    text-align: center;
  }

 
`;
export const ImgDiv = styled.div`
  /* background-color: aquamarine; */
  width: 12%;
  flex-shrink:0;
  width: 12%;
  flex-shrink:0;
  margin-bottom: 5px;
  justify-content: center;
  align-items: center;
  img {
    width: 70px;
  }
  @media screen and (max-width: 1050px) {
    width: 15%;
  }
  @media screen and (max-width: 800px) {
    width: 18%;
  }
  @media screen and (max-width: 500px) {
    width: 22%;
  }
  @media screen and (max-width: 400px) {
    width: 20%;
    img {
      width: 55px;
    }
  }
`;
export const Div = styled.div`
  /* background-color: pink; */
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink:0;
  flex-shrink:0;
`;
export const Box = styled.div`
  /* background-color: azure; */
  width: 16%;
  display:flex;
  justify-content:center;
  align-items:center;
  width: 16%;
  display:flex;
  justify-content:center;
  align-items:center;
  height: 7vh;
  /* border: 2px dashed ${colors.line}; */
  @media screen and (max-width: 800px) {
    /* width: 30%; */
  }
  @media screen and (max-width: 500px) {
    width: 18%;
  }
  @media screen and (max-width: 500px) {
    width: 15%;
  }

`;
export const QtyDiv = styled.div`
  /* background-color: aqua; */
  width:15%;
  flex-shrink:0;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 1rem;
    width: fit-content;
    align-items: center;
  }

  @media screen and (max-width: 800px) {

    p{
      font-size:20px;

    }
  }

`;

export const WtyDiv = styled.div`
  /* background-color: aqua; */
  width:16%;
  flex-shrink:0;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-size: 1rem;
    width: fit-content;
    width: fit-content;
    align-items: center;
  }
`;
export const UnitDiv = styled.div`
  width: 16%;
  display: flex;
  padding-left:8px;
  flex-shrink:0;
  justify-content: center;
  p{
    width:fit-content;
  }

  /* background-color: blue; */
  @media screen and (max-width: 500px) {
    width: 33%;
  }
`;
export const AmountDiv = styled.div`
  width: 16%;
  width: 16%;
  text-align:center;
  /* background-color: bisque; */
`;
export const Style = styled.div`
  /* background-color: antiquewhite; */
  width: 100%;
  hr {
    height: 1px;
    background-color: ${colors.line};
    border: none;
  }
`;

export const FS = styled.div`
flex-shrink:0;

@media screen and (max-width: 1050px) {
font-size:15px}
`
export const TableBox = styled.div`
  /* border: .5px solid; */
  border-block: 0.5px solid ${colors.line};
  border-right: 0.5px solid ${colors.line};
  width: 90%;
  margin-top: -9px;
  display: flex;
  justify-content: space-between;
  padding-left: 15px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  @media screen and (max-width: 1050px) {
    width: 95%;
  }

  // @media screen and (max-width: 800px) {
  //   width: 88%;
  // }
`;
export const TotalValue = styled.div`
  /* background-color: antiquewhite; */
  display: grid;
  grid-template-columns: auto auto;
  margin-right: 1rem;
  margin-top: 0px;
  p {
    margin-top: 2px;
    margin-bottom: 3px;
    text-align: right;
    font-size: 1rem;
    font-weight: ${fonts.fontWeight5};
  }
`;
export const Foot = styled.div`
  /* background-color: blueviolet;  */
  p {
    font-size: 1rem;
    font-size: small;
    text-align: center;
    font-weight: ${fonts.fontWeight5};
  }
`;
export const Div2 = styled.div`
  // width: 30%;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  // @media (min-width: 500px ) {
  //   position: fixed;
  //   right: 0;
  //   bottom: 0;
  //   top: 80px;
  // }

  
  /* background-color: pink; */
  @media screen and (max-width: 500px) {
    width: 100%;
    /* height: 100vh; */
  }

  @media screen and (max-width: 700px) {
 
    width: 100%;

  }
`;
export const SqureBox = styled.div`
  border: 0.5px solid ${colors.line};
  width: 90%;
  height: fit-content;
  margin: 2rem;
  border-radius: 8px;
  padding-inline: 0rem;
  @media screen and (max-width: 800px) {
    width: 80%;
    height: auto;
  }
`;
export const Delivery = styled.div`
  /* background-color: antiquewhite; */
  height: 8vh;
  padding: 1rem;
  text-align: left;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;
export const DeliveryDetails = styled.div`
  /* background-color: aquamarine; */
  height: 4vh;
  P {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
    p {
      text-align: left;
    }
  }
  @media screen and (max-width: 500px) {
    p {
      text-align: left;
    }
    @media screen and (max-width: 500px) {
      p {
        max-width: 80%;
      }
    }
  }
`;
export const Add2 = styled.div`
  width: 80%;
  /* background-color: aliceblue; */
  p {
    font-size: small;
    color: ${colors.black};
    font-weight: ${fonts.fontWeight3};
  }
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;
export const Check = styled.div`
  display: flex;
  flex-direction: row;
  width: 1000%;
  /* /* margin-top: 10px; */
  input {
    accent-color: ${colors.blue};
    width: 17px;
  }
  a {
    text-decoration: none;
    color: ${colors.blue};
  }
  p {
    font-size: 1rem;
    font-size: small;
  }
  @media screen and (max-width: 800px) {
    width: 100%;
    justify-content: center;
    /* align-items: center; */
    p {
      /* padding-top:10px; */
      /* align-items: center; */
    }
    a {
      text-decoration: none;
      color: ${colors.blue};
    }
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;
export const Date1 = styled.div`
  /* background-color: bisque; */
  width: 14%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  p {
    font-weight: ${fonts.fontWeight5};
  }
  @media screen and (max-width: 1500px) {
    width: 20%;
    /* justify-content: space-evenly; */
  }

  @media screen and (max-width: 1300px) {

   
    height: 35vh;


    p {
      font-size:12px;

    }

  }
  @media screen and (max-width: 1050px) {
    width: 27%;

  }
  @media screen and (max-width: 800px) {
    width: 35%;
  }
  @media screen and (max-width: 500px) {
    width: 47%;
    p {
      // margin-top: 5px;
    }
    /* justify-content: space-evenly; */
  }
  @media screen and (max-width: 400px) {
    width: 54%;
  }
  @media screen and (max-width: 350px) {
    width: 59%;
  }
`

export const Button = styled.div`
  width: 50%;
  button {
  background-color: ${props => props.disabled ? '#D5E2FE' : '#4669E8'};
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    color: ${colors.white};
    border: none;
    width: 100%;
    height: 5vh;
    border-radius: 8px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    margin-bottom: 2rem;
  }
`;