import styled from "styled-components";
import { colors } from "../../../consts/themes";

// export const Main = styled.div`
//     display: flex;
//     flex-direction: column;
//     border: .5px solid ${colors.line};
//     width: 90%; 
//     height: auto;
//     border-radius: 8px;
//     margin-right: 5rem;
//     margin-block: 1rem;
//     font-size: 1rem;
//     @media(max-width: 1024px){
//         margin-right: 2rem;
//         width: 94%; 
//     }
//     @media (max-width: 768px)
//     {
//         margin-inline: 0rem;
//         width: 100%;
//         // padding-top:50px;
//     }
//     @media (max-width: 425px)
// {
//     margin-inline: 0rem;
// }
//     p{
//         color: ${colors.light_grey};
//         padding-inline: 20px;
//         text-align: left;
//     }
//     h2{
//         text-align: left;
//         padding-inline: 20px;
//     }
// `
// export const Div = styled.div`
//     border-bottom: .5px solid ${colors.line};
//     width: 100%;
//     margin-bottom: 1rem;
//     // margin-inline: 5.2rem;

// `
// export const Row = styled.div`
// display: flex;
// flex-direction: row;
// justify-content: space-between;

// p{
//     color: ${colors.black};
//     font-size: 12px;
//     margin-top: 0px;
// }
// img{
//     width: 15px;
//     height: 15px;
// }
// h2{
//     font-size: 12px;
// }
// `
// export const RowDiv = styled.div`
// display: flex;
// flex-direction: row;
// justify-content: space-between;
// padding-inline: 20px;
// p{

//     color: ${colors.black};
//     font-size: 12px;
//     margin-top: 0px;
//     padding-inline: 0px;
// }
// img{
//     width: 15px;
//     height: 15px;
//     margin-top: 4px;
// }

// `
// export const RowDivs = styled.div`
// display: flex;
// flex-direction: row;
// margin-left: 3rem;
// p{
//     padding-inline: 2px;
// }
// img{
//     margin-top: -0.5px;
// }
// `
// export const Dot = styled.div`
// border-color: ${colors.line};
//  border-style: dashed;
//     border-width: 3px 0px 0px 0px;
// margin-inline: 1rem;

// `
// export const Dots = styled.div`
// border-color: ${colors.line};
//  border-style: dashed;
//     border-width: 3px 0px 0px 0px;
// margin-inline: 1rem;
// @media (max-width:800px){
//     margin-bottom:80px;
// }
// `

// export const Btn = styled.div`
// background-color: ${colors.blue};
// width: 100%;
// height: 50px;
// border-radius: 6px;
// cursor:pointer;
// width: 90%;
// height: 50px;
// align-self: center;
// margin-block: 2rem;
// display: flex;
// justify-content: center;
// align-items: center;
// p{
//     font-size: 12px;
//     margin-top:10px;
//     // align-self: center;
//     color: ${colors.white};
//     text-align: center;
// }
// `





// new

export const Main = styled.div`
    border: .5px solid ${colors.line};
    ${'' /* width: 90%;  */}
    height: auto;
    border-radius: 8px;
    font-size: 1rem;
    
    p{
        color: ${colors.light_grey};
        padding-inline: 20px;
        text-align: left;
    }
    h2{
        text-align: left;
        padding-inline: 20px;
    }
`



export const Div = styled.div`
    border-bottom: .5px solid ${colors.line};
    margin-bottom: 1rem;
`




export const Row = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;


p{
    color: ${colors.black};
    font-size: 12px;
    margin-top: 0px;
}
img{
    width: 15px;
    height: 15px;
}
h2{
    font-size: 12px;
}
`

export const RowDiv = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
padding-inline: 20px;
p{
    
    color: ${colors.black};
    font-size: 12px;
    margin-top: 0px;
    padding-inline: 0px;
}
img{
    width: 15px;
    height: 15px;
    margin-top: 4px;
}

`
export const RowDivs = styled.div`
display: flex;
flex-direction: row;
margin-left: 3rem;
p{
    padding-inline: 2px;
}
img{
    margin-top: -0.5px;
}
`


export const Dot = styled.div`
border-color: ${colors.line};
border-style: dashed;
border-width: 3px 0px 0px 0px;
margin-inline: 1rem;
`


export const Dots = styled.div`
border-color: ${colors.line};
 border-style: dashed;
    border-width: 3px 0px 0px 0px;
margin-inline: 1rem;

}
`



export const Btn = styled.div`
background-color: ${colors.blue};
width: 100%;
height: 50px;
border-radius: 6px;
cursor:pointer;
width: 90%;
height: 50px;
align-self: center;
margin-block: 2rem;
display: flex;
justify-content: center;
align-items: center;
p{
    font-size: 12px;
    margin-top:10px;
    // align-self: center;
    color: ${colors.white};
    text-align: center;
}
`
