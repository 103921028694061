import styled from "styled-components";
import Fonts from "../../../consts/Font";
import { colors, fonts } from "../../../consts/themes";

export const Container = styled.div`
position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
`
export const ModalPs = styled.div`
z-index: 1;
  width: 400px;
//   height: 340px;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative; 
  box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.5);
  @media screen and (max-width: 400px){
    width: 335px;
  }  
  @media screen and (max-width: 350px){
    width: 300px;
  }  
  p{
    font-size:18px;
    
    font-weight:${Fonts.fontWeight4};
    color:${colors.black}
  }
  img {
    width:50px;
    height:50px;
    margin:8px 0 0;
  }


`
export const ModalP = styled.div`
z-index: 1;
  width: 380px;
//   height: 340px;
  background: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  position: relative; 
  box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.5);
  @media screen and (max-width: 400px){
    width: 335px;
  }  
  @media screen and (max-width: 350px){
    width: 300px;
  }  
`
export const MainDiv = styled.div`
/* background-color: antiquewhite; */
width: 100%;
// height: 80%;
align-items: center;
justify-content: center;
text-align: center;
padding-bottom:10px;

a{
    text-decoration: none;
    font-size: 16px;
    color: ${colors.blue};
    font-size: small;
    cursor:pointer;
}
`

export const MainDiv1 = styled.div`
/* background-color: antiquewhite; */
width: 100%;
flex-direction:column;
// height: 80%;

align-items: center;
justify-content: center;
text-align: center;
padding-bottom:10px;

// a{
//     text-decoration: none;
//     font-size: 16px;
//     color: ${colors.blue};
//     font-size: small;
//     cursor:pointer;
// }
`
export const RowDiv = styled.div`
display:flex;
flex-direction:row;
margin:0 15px;


p{
  font-size:12px;
  margin:5px 8px 0 0;
  font-weight:${Fonts.fontWeight3};
  color:${colors.black},
  
}
`

export const ColumnDiv = styled.div`
display:flex;
flex-direction:Column;
align-items:flex-start;
margin:0 15px;
p{
  font-size:12px;
  margin:5px 0;
  font-weight:${Fonts.fontWeight3};
  color:${colors.black}
}
textarea {
  width:90%;
  margin:5px 0;
  min-height:60px;
  max-height:100px;
}
`
export const Close = styled.div`
display:flex;
margin:10px 0;
width:90%;
align-items:flex-end;
justify-content:flex-end;
cursor:pointer;
`
export const Submits = styled.div`
height:30px;
`

export const Submit = styled.div`
align-items:center;
justify-content:center;

button{
  padding:8px 20px;
  align-items:center;
  justify-content:center;
  background-color:${colors.blue};
  color:${colors.white};
  font-size:13px;
  font-weight:${fonts.fontWeight3};
  border-radius:8px;
  border:none;
  margin:10px 0;
  corsor:pointer;
}
`
export const Star = styled.div`
color:${colors.yellow};
`
export const InputDiv = styled.div`
width:90%;
  margin:5px 0;
  min-height:60px;
  max-height:100px;
  border:1px solid ${colors.lightblue};
  border-radius:8px;
  align-items:center;
  justify-content:flex-start;
  text-align:left;
  p{
    font-size:12px;
    margin:0 8px;
    
  }

`
export const Checking = styled.div`
   display:flex;
  //  width:125px;
  //  height:125px;
   justify-content:center;
  //  background-color:green;
  padding-bottom:15px;
  // input:checked{
    
  // }
`
export const Check = styled.div`
  width:60px;
  height:60px;
  // margin-bottom: $loader-size/2;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: $check-color;
  // animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  // width: $loader-size;
  // height: $loader-size;
  // background-color:${colors.blue};
  display:inline-block;
  // border: 2px solid ${colors.blue};
  border:none;
  border-radius:50%;
  animation:rotate 5.2s linear infinite;
  // animation-name:rotate;
  -webkit-animation-play-state: paused;
  // transition-property: width;
  // transition-duration: 2s;
  // transition-timing-function: linear;
  // transition-delay: 1s;
  // animation-duration:800ms;
  @keyframes rotate{
    100%{
      background-color:${colors.blue};
    }
  }
  p{
    color:${colors.white};
    // font-weight:${fonts.fontWeight3}
  }
`
export const Ptag = styled.div`
p{
   
  font-weight: ${fonts.fontWeight3};
    font-size: bold;
    color: ${colors.blue};
}
`
export const Img = styled.div`
padding-top:15px;
padding-right:15px;
display:flex;
justify-content:end;
img{
  z-index:-1;
  position:absolute;
  width:20px;
  height:20px;
  // background-color:red;
}
p{
  padding-right:5px;
  font-size:10px;
  background-color:red;
} 
`
export const Image = styled.div`
/* background-color: aqua; */
height: 100px;
display:flex;
flex-direction:column;
justify-content: center;
align-items: center;
margin-top: 20px;
img{
    width:150px;
    height:80px
    /* color:black; */
}
`

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 1rem;
    @media (max-width: 1050px) {
        justify-content: left;
    }
    
    p{
        padding-inline: 10px;
        font-size: 12px;
        align-items: flex-start;
        display: flex;
        
    }
    span{
        padding-inline: 10px;
        font-size: 14px;
    font-weight: 500;
    }
`
export const Box = styled.div`
width: 90%;
//  height:60px; 
border: 1px solid ${colors.line};
border-radius: 8px;
margin-left: 20px;
// padding-top: 10px;
// background-color:green;
padding-bottom: 20px;
p{
    font-size: 1rem;
    font-weight: ${fonts.fontWeight3};
    font-size: small;
    color: ${colors.black};
    /* margin-top: 20px; */
}
`
export const SubBox = styled.div`
width:100%;
// height:20px;
// background-color:red;
// height:10px;
 display:flex;
 flex-direction:row;
 
 
`
export const LeftSide = styled.div`
  display:flex;
  justify-content:start;
   width:60%;
   height:20px;
   padding-left:10px;
   p{
    font-weight:${fonts.fontWeight3}
   }
   
  @media (max-width:325px){
    p{
      font-size:11px;
    }
  } 

`
export const RightSide = styled.div`
  display:flex;
  justify-content:end;
  width:40%; 
  height:20px;
  padding-right:10px;
  @media (max-width:325px){
    p{
      font-size:11px;
    }
  }
//   background-color:green;
`
export const Number = styled.div`
 display:flex;
 justify-content:center;
 flex-direction:column;
 p{
  font-size:12px;
  color:${colors.black}
 }
 span{
  font-size:18px;
  color:${colors.black};
  font-weight: ${fonts.fontWeight4};
  
 }

`