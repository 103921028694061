import React, { useEffect } from 'react';
import {
    MainContainer,
    Left,
    Right,
    Inputbar,
    Inputlongbar,
    Selecting,
    Select,
    Rupees,
    Div,
    Rightline,
    Terms,
    Filter,
    Lists,
    Dot,
    Dotbar,
    Accept,
    DetailsDropDown,
    Dist,
} from '../services/modalbutton/ServiceCheckingStyled';
import { useLocation, useNavigate } from 'react-router-dom';
import computer from '../../assets/Images/png/Rectangle24.png';
import { useState } from 'react';
import { useContext } from 'react';
// import YearPicker from "react-year-picker";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import axiosInstance from '../../consts/axiosInstance';
import Help from '../../components/Help/help';
import { AuthContext } from '../../consts/AuthContext';
import { colors, fonts } from '../../consts/themes';
import { FaCalendarAlt } from "react-icons/fa";
import { DataContext } from '../../consts/DataContext';


function ServiceReg() {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const navigation = useNavigate();
    const [complaintDetails, setcomplaintDetails] = useState({});
    const {invoiceId} = useContext(DataContext)

    const { state } = useLocation();
    // const {
    //     cartid,
    //     productsid,
    //     category,
    //     productname,
    //     brand,
    //     slno,
    //     year,
    //     warrenty,
    //     complaints,
    //     warrentys,
    //     shipping,
    //     productImage,
    // } = state;


    const {
        cartid = 0,
        productsid = 0,
        category = '',
        productname = '',
        brand = '',
        slno = '',
        year = '',
        warrenty = '',
        complaints = '',
        warrentys = '',
        shipping = '',
        productImage = computer,
    } = state || {};

    const productName = JSON.parse(localStorage.getItem('productnme'));
    const servicetypeid = JSON.parse(localStorage.getItem('servicetype'));
    const productcategory_id = JSON.parse(localStorage.getItem('categoryidd'));


    //getting details of all products 
    const [product, setProduct] = useState([]);

    //service needed product details
    const [data, setData] = useState({
        serviceCategoryId: '',
        city: '',
        district: '',
        complaint: '',
    });

    // service needed product details
    const [userInput, setUserInput] = useState({
        category: '',
        brand_name: '',
        slno: '',
        yearOfPurchase: "",
        warrantyExpiredDate: "",
    })

    //service needed product details
    const [cartproduct, setCartproduct] = useState([]);

    //getting product catageories list laptop , mobile , tablet etc...
    const [productcate, setProductcate] = useState([]);

    //setting category name of the clike product selected by user
    const [cat, setCate] = useState([]);

    const [serviceList, setServiceList] = useState('')
    //district lit
    const [district, setDistrict] = useState([]);
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedDistrictId, setSelectedDistrictId] = useState('')
    //city list based on district
    const [cityList, setCityList] = useState([]);
    const [categ, setCateg] = useState([]);
    const [servicedisplay, setServiceDisplay] = useState([]);
    const [check, setCheck] = useState(0);
    const [list, setList] = useState([]);
    const [authUser, setAuthUser] = useContext(AuthContext);
    const [activeImage, setActiveImage] = useState(productImage);
    const [activeOption, setActiveOption] = useState();
    const [selectedYear, setSelectedYear] = useState('');
    const [startDate, setStartDate] = useState(null);




    //service data
    let servData = {};

    //getting details of the product need service selected by user using cartid and product id
    const serviceNeededproductsDetails = async () => {
        try {
            const response = await axiosInstance.get(`getProductDetailsForService/${invoiceId}/${productsid}`);
            console.log('`getProductDetailsForService/${invoiceId}/${productsid}`', `getProductDetailsForService/${invoiceId}/${productsid}`)
            setCartproduct(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    //getting  all products details
    const Productlist = async () => {
        try {
            const response = await axiosInstance.get('getProductList');
            setProduct(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    //getting product catageories list laptop , mobile , tablet etc...
    const ProductlistCategory = async () => {
        try {
            const response = await axiosInstance.get('getProductCategory');
            setProductcate(response?.data);

            // if there is not product image then set the common category image 
            !productImage && response?.data?.map((item) => (item?.id === category && setActiveImage(item?.product_category_image)));

            //setting category name for the clike product selecetd by user
            response?.data?.map((item) => (item?.id === category && setCate(item?.product_category_name)));

            // response?.map(item => item?.product_category_name === data?.category?.target?.value && setCateg(item?.id))
        } catch (error) {
            console.log(error);
        }
    };

    const Servicelist = async () => {
        try {
            const response = await axiosInstance.get(`getAvailableServicesByProductId/${productcategory_id}`);
            setList(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    const servicelist = async () => {
        try {
            const response = await axiosInstance.get(`getServiceReg/${authUser?.user.id}`);
            response?.data?.map(
                (item) => item?.product_id === productsid && setServiceDisplay(item)
            );

        } catch (error) {
            console.log(error);
        }
    };

    const imageSet = (e) => {
        setActiveImage(e.target.selectedOptions[0].getAttribute('img'));
    };

    //getting available services list
    const getAvailableServicesList = async () => {
        try {
            const response = await axiosInstance.get('getAvailableServices');
            setServiceList(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    //getting district list 
    const getDistrictdata = async () => {
        try {
            const response = await axiosInstance.get('getDistrict');
            setDistrict(response.data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if(invoiceId === null){
            // console.log('!invoiceId', invoiceId)
            navigation('/service')
            }
        serviceNeededproductsDetails();

        Productlist();
        servicelist();
        ProductlistCategory();
        Servicelist();
        // getDistrictdata();
        getAvailableServicesList();
    }, []);

    const compState = () => {
        // service data for outside products
        if (cartid === 0 && productsid === 0) {
            servData = {
                product_category_id: userInput?.category,
                serviceTypes: servicetypeid,
                brand: userInput?.brand_name,
                slno: userInput?.slno,
                yearOfPurchase: userInput?.yearOfPurchase,
                warranty: "",
                warrantyExpiryDate: userInput?.warrantyExpiredDate,
                complaint: data?.complaint,
                servicePackDate: "",
                servicePackExpDate: "",
                serviceCategoryId: data?.serviceCategoryId,
                user_id: authUser?.user?.id,
                district: data?.district,
                city: data?.city,
                customer_visibility: "",
                service_validity: "",
                advance_payment: "",
                total_amount: "",
                payment_status: "",
                product_id: "",
                cart_id: 0,
                // shipping_address_id:,
                // billing_address_id:,
            };

        } else if (cartid === 0 && productsid !== 0) {

            servData = {
                product_category_id: category,
                serviceTypes: servicetypeid,
                brand: cartproduct?.brand_name,
                slno: cartproduct?.slno,
                yearOfPurchase: cartproduct?.yearOfPurchase,
                warranty: cartproduct?.warranty,
                warrantyExpiryDate: cartproduct?.warrantyExpiredDate,
                complaint: data?.complaint,
                servicePackDate: "",
                servicePackExpDate: "",
                serviceCategoryId: data?.serviceCategoryId,
                user_id: authUser?.user?.id,
                district: data?.district,
                city: data?.city,
                customer_visibility: "",
                service_validity: "",
                advance_payment: "",
                total_amount: "",
                payment_status: "",
                product_id: cartproduct?.product_id,
                cart_id: cartid,
            };
        }

    };

    function handleclick(value) {
        setCheck(value);
        check === value && setCheck(0);
    }




    const handleDistrictChange = (e) => {
        const selectedIndex = e.target.selectedIndex;
        // console.log(" selecetd index", selectedIndex);
        setSelectedDistrictId(e.target.value);
        setSelectedDistrict(selectedIndex <= 0 ? '' : district[selectedIndex - 1].district_name);
        setData((prev) => ({ ...prev, district: e.target.value }));
    }

    useEffect(() => {
        getDistrictdata();
    }, [])

    //getting city list based on district 
    useEffect(() => {
        const getAvailableCity = async () => {
            try {
                if (selectedDistrictId) {
                    const response = await axiosInstance.get(`getCity/${selectedDistrictId}`);
                    setCityList(response?.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        getAvailableCity();
    }, [selectedDistrictId]);

    const handleDataChange = (e) => {
        const { name, value } = e.target;
        if (name === 'warrantyExpiredDate') {
            const date = new Date(value);
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();
            const newDate = `${day}-${month}-${year}`;
            setUserInput((prev) => ({ ...prev, warrantyExpiredDate: newDate }))
        } else {
            setUserInput((prev) => ({ ...prev, [name]: value }));
        }
    }

    // const handleYearChange = (year) => {
    //     setSelectedYear(year);
    //     setUserInput((prev) => ({ ...prev, yearOfPurchase: year }))
    // };

    const isSubmitVisibleData = Object.values(data).every((value) => value !== '')
    const isSubmitVisibleUserInput = Object.values(userInput).every((value) => value !== '')

    const newReg = cartid === 0 && productsid === 0;
    const clickereg = cartid !== 0 && productsid !== 0;

    const newRegBtn = isSubmitVisibleData && isSubmitVisibleUserInput && check === 1;
    const clickeregBtn = isSubmitVisibleData && check === 1;











    // year picker button
    const currentYear = new Date().getFullYear();
    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <div className='calander' ref={ref} onClick={onClick}>
            {value ? value : 'Pick year'} <FaCalendarAlt />
        </div>
    ));


    const handleDateChange = (date) => {
        setStartDate(date);
        const selectedYear = date.getFullYear();
        // console.log("Selected year:", selectedYear);
        setUserInput((prev) => ({ ...prev, yearOfPurchase: selectedYear }))
    };



    return (
        <MainContainer>
            {/* {console.log("productcategory_id+++++==>",productsid)} */}
            <Left>
                {/* {
                    cartproduct ?
                        <img src={cartproduct?.productImages[0]} />
                        :
                        <img src={computer} />
                } */}
                <img src={activeImage} alt='Product Image' />
                {cartproduct &&
                    product?.map((item) => {
                        return (item?.id === cartproduct?.product_id && <p>{item?.description} </p>);
                    })}
            </Left>


            <Right>
                {cartid !== 0 && productsid !== 0 && category && cartproduct && (
                    <Inputbar value={productname}></Inputbar>)}
                <Filter>


                    {/* category name */}
                    {cartid !== 0 && productsid !== 0 && category && cartproduct && (
                        <select>
                            <option value={category}>{cat}</option>
                        </select>
                    )}

                    {cartid === 0 && productsid === 0 && (
                        <select onChange={(e) => {
                            handleDataChange({ target: { name: 'category', value: e.target.value } })
                            imageSet(e);
                        }} >
                            <option value='' img={computer}>Select</option>
                            {productcate?.map((item, i) => {
                                return (
                                    <option
                                        key={i}
                                        img={item?.product_category_image}
                                        value={item?.id}>
                                        {item?.product_category_name}
                                    </option>
                                );
                            })}
                        </select>
                    )}
                </Filter>


                {/* brand name */}
                {cartid !== 0 && productsid !== 0 && cartproduct && (<Inputbar placeholder='Brand' value={cartproduct?.brand_name}></Inputbar>)}

                {cartid === 0 && productsid === 0 && (
                    <Inputbar placeholder='Brand' onChange={(e) => handleDataChange({ target: { name: 'brand_name', value: e.target.value } })}></Inputbar>
                )}

                {/* serial number */}
                {cartid !== 0 && productsid !== 0 && cartproduct && (
                    <Inputbar placeholder='Serial number' value={cartproduct.slno}></Inputbar>
                )}

                {cartid === 0 && productsid === 0 && (
                    <Inputbar
                        placeholder='Serial number'
                        onChange={(e) => handleDataChange({ target: { name: 'slno', value: e.target.value } })}
                    ></Inputbar>
                )}


                {/* year of purcahse */}
                {cartid !== 0 && productsid !== 0 && cartproduct && (<Inputbar placeholder='Year of purchase' value={cartproduct.yearOfPurchase}></Inputbar>)}






                {/* {cartid === 0 && productsid === 0 && (
                    <YearDiv>
                        <label>Year of purchase : </label>
                        <YearPicker
                            onChange={handleYearChange}
                            value={selectedYear}
                        />
                    </YearDiv>
                )} */}




                {/* year picker */}
                {cartid === 0 && productsid === 0 && (
                    <YearDiv>
                        <label>Year of purchase : </label>
                        <DatePicker
                            selected={startDate}
                            onChange={handleDateChange}
                            showYearPicker
                            dateFormat="yyyy"
                            placeholderText="Pick year"
                            minDate={new Date('2001-01-01')}
                            maxDate={new Date(`${currentYear}-12-31`)}
                            customInput={<CustomInput />}
                            wrapperClassName="year-picker-wrapper"
                            style={{width:200}}
                        />
                    </YearDiv>
                )}


                {/* Warranty expiry date */}
                {cartid !== 0 && productsid !== 0 && cartproduct && (<Inputbar placeholder='Warranty expiry date' value={cartproduct.warrantyExpiredDate}></Inputbar>)}

                {cartid === 0 && productsid === 0 && (
                    <YearDiv>
                        <label>warranty expired date : </label>
                        <Inputbar
                            type='date'
                            placeholder='Warranty expiry date'
                            onChange={(e) => handleDataChange({ target: { name: 'warrantyExpiredDate', value: e.target.value } })}
                        ></Inputbar>
                    </YearDiv>
                )}


                {/* serivices list */}
                <Filter>
                    {serviceList &&
                        <select onChange={(e) => { setData((prev) => ({ ...prev, serviceCategoryId: e.target.value })); }}>
                            <option value=''>Service Price List</option>
                            {serviceList.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.service_name} ₹{item.price}
                                </option>
                            ))}
                        </select>
                    }
                </Filter>

                {/* district selection */}
                <Filter>
                    {district.length > 0 &&
                        <select value={selectedDistrict.districtId} onChange={handleDistrictChange}>
                            <option value=''>Choose District</option>
                            {district.map((item) => (
                                <option key={item.id} value={item.id}>
                                    {item.district_name}</option>
                            ))}
                        </select>
                    }
                </Filter>

                {/* city selection */}
                {selectedDistrict && selectedDistrictId && (
                    <Filter>
                        {cityList &&
                            <select onChange={(e) => { setData((prev) => ({ ...prev, city: e.target.value })); }}>
                                <option value=''>Choose City</option>
                                {cityList.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.city_name}
                                    </option>
                                ))}
                            </select>
                        }
                    </Filter>
                )}

                {/* complaint description */}
                <Inputlongbar
                    placeholder='Describe the problem'
                    value={data?.complaint}
                    onChange={(e) =>
                        setData((prev) => ({ ...prev, complaint: e.target.value }))
                    }
                >
                </Inputlongbar>

                <Div>
                    {/* <label onClick={() => inserted()}>Need Other Services?</label> */}
                    <label>Need Other Services?</label>
                    <Selecting>
                        <Select>
                            <Rightline>
                                <p>Checking charge</p>
                            </Rightline>

                            <Rupees>
                                <p>₹400</p>
                            </Rupees>
                        </Select>
                    </Selecting>
                </Div>


                <Terms>
                    <span>TERMS & CONDITIONS</span>
                    <Lists>
                        <Dotbar>
                            <Dot></Dot>
                        </Dotbar>
                        <p>Checking charge must be paid in advance.</p>
                    </Lists>
                    <Lists>
                        <Dotbar>
                            <Dot></Dot>
                        </Dotbar>
                        <p>The service engineer should check the product with 48hrs.</p>
                    </Lists>
                    <Lists>
                        <Dotbar>
                            <Dot></Dot>
                        </Dotbar>
                        <p>
                            And after checking the diagnosing the complaint and he will give
                            the solutions.
                        </p>
                    </Lists>
                    <Lists>
                        <Dotbar>
                            <Dot></Dot>
                        </Dotbar>
                        <p>
                            Checking charge will deducting further related service charges.
                        </p>
                    </Lists>
                    <Lists>
                        <Dotbar>
                            <Dot></Dot>
                        </Dotbar>
                        <p>
                            Checking service charge will not be returned even if the complaint
                            is not resolved.
                        </p>
                    </Lists>

                    <Accept>
                        <input type='checkbox' onClick={() => handleclick(1)} />
                        <p>Accept all terms and conditions</p>
                    </Accept>
                </Terms>

                {newReg && (
                    <Buttonbar
                        disabled={!newRegBtn}
                        onClick={() =>
                            check && [
                                compState(),
                                navigation('/service/registration/address', { state: { servData: servData, }, }),
                            ]}>Register your complaint</Buttonbar>)}

                {!newReg && (
                    <Buttonbar
                        disabled={!clickeregBtn}
                        onClick={() =>
                            check && [
                                compState(),
                                navigation('/service/registration/address', { state: { servData: servData, }, }),
                            ]} >Register your complaint</Buttonbar>)}
            </Right>
            <Help />
        </MainContainer>
    );
}
export default ServiceReg;

const YearDiv = styled.div`
display:flex;
flex-direction:column;
margin-bottom:0.2rem;
width:fit-content;

`



const Buttonbar = styled.button`
background-color: ${props => props.disabled ? '#D5E2FE' : '#4669E8'};
cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
width:250px;
   margin:10px 2px;
   outline:none;
   border:none;
   border-radius:8px;
  
   padding:12px 0;
   font-size:14px;
   font-weight:${fonts.fontWeight5};
   color:${colors.white};
   @media(min-width:2000px) {
      font-size:16px;
      margin:12px 2px;
      padding:10px 0;
   }
   @media (max-width:500px){
      margin:10px 50px;
   }
   @media (max-width:380px){
      margin:10px 28px;
   }
   `