import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Installationmodal_main,
  Installationmodal_container,
  Installationmodal_content,
  Installation_text,
  Installation_Button,
  Installation_Button_one,
  Installation_Button_two,
} from "../Installationmodal.styled";

import { GrFormClose } from "react-icons/gr";
import {
  Assistancebutton,
  Assistancecontent,
  Buttonone,
  Buttontwo,
  Close,
} from "../Assistance.styled";



function InsatallSubModal({ close }) {

  const navigation = useNavigate();

  const [change, setChange] = useState(false);

  const productsid = JSON.parse(localStorage.getItem("productcode") || "[]");




  return (


    <Installationmodal_main>
      <Installationmodal_container>
        <Close onClick={() => close(false)}>
          <GrFormClose size={20} />
        </Close>
        <Installationmodal_content>
          <Installation_text>Do you need Installation ?</Installation_text>
          <Installation_Button>
            <Installation_Button_one onClick={() => navigation("/sales/shoppingcart")}>
              No
            </Installation_Button_one>
            <Installation_Button_two
              onClick={() => navigation("/sales/products/installationpage")}
            >
              Yes
            </Installation_Button_two>
          </Installation_Button>
        </Installationmodal_content>

      </Installationmodal_container>
    </Installationmodal_main>
  );
}
export default InsatallSubModal;
