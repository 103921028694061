import styled from "styled-components";
import { colors } from "../../consts/themes";

export const HeaderContainer = styled.div`
  /* position: fixed; */
  width: 100vw;
  ${'' /* height: 80px; */}
  height: 5rem;
  top: 0;
  right: 0;
  left: 0;
  background-color: ${colors.blue};
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderChild = styled.div`
  background-color: ${colors.blue};
  // display: flex;
  margin-left: 50px;
  @media screen and (max-width: 425px) {
    margin-left: 30px;
  }
  @media screen and (max-width: 320px) {
    margin-left: 10px;
  }
  @media screen and (max-width: 768px) {
    margin-left: 30px;
  }
`;

export const HeaderRightChild = styled.div`
background-color: ${colors.blue};
display: flex;
gap:0.8rem;
margin-right:30px;
`;


export const HeaderLogo = styled.div`
  display: flex;
  img {
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    padding-left: ${(props) => props.pl};
    padding-top: ${(props) => props.pt};
  }
  @media screen and (max-width: 768px) {
    img {
      width: 110px;
      height: 20px;
    }
  }
  @media screen and (min-width: 320px) and (max-width: 425px) {
    img {
      width: 100px;
      height: 20px;
    }
  }
`;




export const NavbarLink = styled.div`
display: flex;
justify-content:center;
align-items:center;
cursor: pointer;

img{
width: 24px;
height: 24px;
}

  @media screen and (min-width: 300px) and (max-width: 767px) {
    img {
      width: 20px;
      height: 20px;
    }
  }
`;



export const CartIcon = styled.div`
  cursor: pointer;
  img {
    width: 23px;
    height: 21px;
  }
  @media screen and (max-width: 768px) {
    img {
      width: 18px;
      height: 16px;
    }
  }
`;
