import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../consts/AuthContext';
import ErrorComponent from '../../../components/Button/error/ErrorComponent';
import LoadingSpinner from '../../../components/Button/Loading/LoadingSpinner';
import styled from 'styled-components';
import { colors, fonts } from '../../../consts/themes';
import axiosInstance from '../../../consts/axiosInstance';
import ClaimModal from '../Modals/ClaimModal';



const Confirmation = () => {

  const navigate = useNavigate();
  const [authUser, setAuthUser] = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [reason, setReason] = useState('');
  const [reasons, setReasons] = useState([]);
  const [postponed, setPostponed] = useState(false);
  const [reject, setReject] = useState(false);


  // reject reason
  const getReasons = async () => {
    try {
      const response = await axiosInstance.get('getAllreason')
      setReasons(response.data)
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  // const getReasons = () => {
  //   axios.get(getAllreason).then(resp => {
  //     setReasons(resp.data)
  //   })
  // }



  const acceptPrice = async (id) => {
    try {
      const response = await axiosInstance.post('acceptestimate_price', { price_id: id })
      if (response.data.code == 200) {
        getPrice()
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // const acceptPrice = (id) => {
  //   axios.post(acceptestimate_price, { price_id: id }).then(resp => {
  //     console.log('resp: ', resp);
  //     if (resp.data.code == 200) {

  //       getPrice()
  //     }
  //   })
  // }


  const rejectPrice = async (id) => {
    try {
      const response = await axiosInstance.post('rejectestimate_price', { price_id: id, reason: reason })
      if (response.data.code == 200) {
        setReject(true)
        getPrice()
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // const rejectPrice = (id) => {
  //   axios.post(rejectestimate_price, { price_id: id, reason: reason }).then(resp => {
  //     console.log('resp: ', resp);
  //     if (resp.data.code == 200) {
  //       setReject(true)
  //       getPrice()
  //     }
  //   })
  // }

  const postpondPrice = async (id) => {
    try {
      const response = await axiosInstance.post('postponedestimate_price', { price_id: id, reason: reason })
      if (response.data.code == 200) {
        setPostponed(true)
        getPrice()
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // const postpondPrice = (id) => {
  //   axios.post(postponedestimate_price, { price_id: id, reason: reason }).then(resp => {
  //     console.log('resp: ', resp);
  //     if (resp.data.code == 200) {
  //       setPostponed(true)
  //       getPrice()
  //     }
  //   })
  // }


  const finalPrice = async (id) => {
    try {
      const response = await axiosInstance.post("acceptfinal_price", { price_id: id })
      if (response.data.code == 200) {
        getPrice()
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  // const finalPrice = (id) => {
  //   axios.post(acceptfinal_price, { price_id: id }).then(resp => {
  //     console.log('resp: ', resp);
  //     if (resp.data.code == 200) {
  //       getPrice()
  //     }
  //   })
  // }


  const getPrice = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`list_estimatedprice?user_id=${authUser?.user?.id}`);
      console.log(response.data, " response of purchase type")
      setData(response.data)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPrice()
    getReasons()
  }, [])


  const [openIndex, setOpenIndex] = useState(null);
  const [claimModal, setClaimModal] = useState(false);
  const [selectedPriceId, setSelectedPriceId] = useState(null);
  const [clikePoints, setClikePoints] = useState(null);
  const [buttonStates, setButtonStates] = useState([]);


  const toggleSection = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };


  const toggleButton = (index) => {
    setButtonStates(prevStates => {
      const newButtonStates = [...prevStates];
      newButtonStates[index] = !newButtonStates[index];

      if (newButtonStates[index]) {
        for (let i = 0; i < newButtonStates.length; i++) {
          if (i !== index) {
            newButtonStates[i] = false;
          }
        }
      }
      return newButtonStates;
    });
  };




  const handleBuyButtonClick = (priceId, points) => {
    setSelectedPriceId(priceId);
    setClikePoints(points)
    setClaimModal(true);
  };


  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }



  return (
    <ServicesWrapper className='h-[85vh]'>

      {claimModal && <ClaimModal priceId={selectedPriceId} clikePoints={clikePoints}
        onClose={() => [setClaimModal(false), setSelectedPriceId(null), setClikePoints(null), getPrice()]} />}
      <Scroll>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Product</th>
                <th>Date Of Service</th>
                <th>Price</th>
                <th>Status</th>
              </tr>
            </thead>


            <tbody>
              {data.map((item, i) => (
                <tr key={item?.exchange_id}>
                  <td>{item?.exchange_id}</td>
                  <td>{item?.product_category_name}</td>
                  <td>{item?.date}</td>

                  <td>
                    {item?.final_price ? <CellText color='#0D8FF9'>₹{item?.final_price}</CellText> : <CellText color='#0D8FF9'>₹{item?.estimated_price}</CellText>}
                    {item?.final_price ? <CellText>*Final Price</CellText> : <CellText>*Estimated cost</CellText>}
                  </td>

                  <td>

                    {item?.status == 0 && item?.estimated_price == null && <CellText>Waiting for estimated price</CellText>}
                    {item?.status == 1 && item?.final_price == null && <CellText>Waiting for the Confirmation</CellText>}
                    {item?.status == 2 && <CellText color='red'>Rejected</CellText>}


                    {/* estimate  accept section*/}
                    {item?.status == 0 && item?.estimated_price &&
                      <RedText style={{ color: 'blue', textDecoration: 'underline' }} onClick={() => [toggleSection(i), setReason(''),
                      toggleButton(i)]}>
                        {buttonStates[i] ? 'Hide' : 'View'}
                      </RedText>}

                    {openIndex === i && item?.status == 0 && item?.estimated_price &&
                      <Continer>
                        <Buttons>
                          <StyledButton onClick={() => acceptPrice(item?.price_id)} >Accept</StyledButton>
                          <StyledButton onClick={() => postpondPrice(item?.price_id)}>Postpond</StyledButton>
                        </Buttons>
                        <SelectReason onChange={(e) => setReason(e.target.value)}>
                          <option value='' selected>
                            Select a reason for rejection
                          </option>
                          {reasons?.map((item) => {
                            return (
                              <option value={item?.reason}>{item?.reason}</option>
                            )
                          })}
                        </SelectReason>
                        <Reject disabled={!reason} onClick={() => rejectPrice(item?.price_id)} >Reject</Reject>
                      </Continer>
                    }



                    {/* postpond  estimate*/}
                    {item?.status === 3 && item?.final_price === null && <RedText style={{ color: 'blue', textDecoration: 'underline' }}
                      onClick={() => [toggleSection(i), setReason(''), toggleButton(i)]}> {buttonStates[i] ? 'Hide' : 'View'}</RedText>}

                    {openIndex === i && item?.status === 3 && item?.final_price === null &&
                      <Continer>
                        <Buttons>
                          <StyledButton onClick={() => acceptPrice(item?.price_id)}>Accept</StyledButton>
                        </Buttons>
                        <SelectReason onChange={(e) => setReason(e.target.value)}>
                          <option value='' selected>
                            Select a reason for rejection
                          </option>
                          {reasons?.map((item) => {
                            return (
                              <option value={item?.reason}>{item?.reason}</option>
                            )
                          })}
                        </SelectReason>
                        <Reject disabled={!reason} onClick={() => rejectPrice(item?.price_id)} >Reject</Reject>
                      </Continer>
                    }



                    {/* final  accept section*/}
                    {item?.status == 1 && item?.final_price && <RedText style={{ color: 'blue', textDecoration: 'underline' }}
                      onClick={() => [toggleSection(i), setReason(''), toggleButton(i)]}>View</RedText>}
                    {openIndex === i && item?.status == 1 && item?.final_price &&
                      <Continer>
                        <Buttons>
                          <Button onClick={() => finalPrice(item?.price_id)}>Accept</Button>
                          <Button onClick={() => postpondPrice(item?.price_id)}>Postpond</Button>
                        </Buttons>
                        <SelectReason onChange={(e) => setReason(e.target.value)}>
                          <option value='' selected>
                            Select a reason for rejection
                          </option>
                          {reasons?.map((item) => {
                            return (
                              <option value={item?.id}>{item?.reason}</option>
                            )
                          })}
                        </SelectReason>
                        <Reject disabled={!reason} onClick={() => rejectPrice(item?.price_id)}>Reject</Reject>
                      </Continer>
                    }

                    {/* postpond  final*/}
                    {item?.status === 3 && item?.final_price !== null && <RedText style={{ color: 'blue', textDecoration: 'underline' }}
                      onClick={() => [toggleSection(i), setReason(''), toggleButton(i)]}>View</RedText>}

                    {openIndex === i && item?.status === 3 && item?.final_price !== null && <Continer>
                      <Buttons>
                        <StyledButton onClick={() => finalPrice(item?.price_id)}>Accept</StyledButton>
                      </Buttons>
                      <SelectReason onChange={(e) => setReason(e.target.value)}>
                        <option value='' selected>
                          Select a reason for rejection
                        </option>
                        {reasons?.map((item) => {
                          return (
                            <option value={item?.reason}>{item?.reason}</option>
                          )
                        })}
                      </SelectReason>
                      <Reject disabled={!reason} onClick={() => rejectPrice(item?.price_id)} >Reject</Reject>
                    </Continer>
                    }


                    {item?.status === 4 && item?.clikekart_points === 0 && item?.claim_status === 0 &&
                      <CellText>Waiting for confirmation</CellText>}



                    {item?.status == 4 && item.clikekart_points !== 0 && item?.claim_status === 0 && <>
                      <RedText style={{ color: 'blue', textDecoration: 'underline' }}
                        onClick={() => handleBuyButtonClick(item?.price_id, item?.clikekart_points)}>Redeem</RedText>
                    </>}


                    {item?.claim_status === 1 && <RedText style={{ color: 'green' }}>
                      {item?.clikekart_points} points claimed </RedText>}

                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      </Scroll>

    </ServicesWrapper>
  );
};



export default Confirmation;




const Continer = styled.div`
display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   width: fit-content;
   margin: auto; /* Center horizontally */
`



const Table = styled.table`
margin: 0 auto;
max-width:1900px;
width:100%;
border-collapse:collapse;
thead{box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;background-color:#8FC2FF;}
tbody tr:nth-child(even) {background:#F8FBFB;}
tbody tr:nth-child(odd) {background:#EFF3F3;}
td,th{text-align:center;padding:0.8rem;}
td,th{
  font-weight:normal;
  font-size:0.9rem;
  @media only screen and (min-width: 250px) and (max-width: 1023px) 
  { font-size:0.7rem;}
}
`;

const Cell = styled.div`
display:flex;
justify-content:center;
flex-direction:column;
align-items:center;
gap:0.5rem;
`;

const CellBtn = styled.div`
background-color:#FFDC13;
border-radius:5px;
width:fit-content; 
padding:0.2rem 1rem;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
cursor:pointer;
`;

const CellText = styled.div`
${'' /* color:#0D8FF9; */}
font-weight:500;
color: ${props => props.color || ''};
`;

const TableContainer = styled.div`
overflow: auto; 
margin:1rem;
`;


const StyledButton = styled.button`
width:5rem;
  background-color: #8FC2FF;
  border-radius: 3px;
  border: 1px solid #7aa7c7;
  color:black;
  cursor: pointer;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15385;
  outline: none;
  padding:0.5rem;
  text-align: center;
  text-decoration: none;
`;

const ServicesWrapper = styled.div`
    width:100%;
    /* margin-top:5rem; */
`

const Span = styled.span`
    font-size:18px;
    color:${colors.black};
    font-weight:${fonts.fontWeight5};
    margin-bottom:2rem;
    display:block;
    @media (max-width:1200px){
    margin-top:2rem;
    }
`
const Show = styled.div`
    display: none;
`



const Scroll = styled.div`
    overflow-x:scroll;
`



const MainDiv = styled.section`
  padding-top: 120px;
  * {
    box-sizing: border-box;
  }
`;

const ContentWrapper = styled.div`
  max-width: 1600px;
  margin: auto;
  padding: 0 15px;
`;
const ID = styled.div`
  width: 130px;
  flex-shrink: 0;
`;

const Product = styled.div`
  width: 330px;
  padding: 0 30px 0 0;
  flex-shrink: 0;
  @media (max-width: 1540px) {
    width: 30%;
  }
  @media (max-width: 980px) {
    width: 100%;
  }
`;

const Date = styled.div`
  width: 230px;
  flex-shrink: 0;
  @media (max-width: 980px) {
    width: 100%;
  }
`;

const Row = styled.div`
  border-bottom: 1px solid black;
  display: flex;
  padding-block: 20px;
  flex-wrap: wrap;
  row-gap: 2rem;
  @media (max-width: 1540px) {
    justify-content: space-between;
  }
`;
const RowHead = styled.div`
  border-bottom: 1px solid black;
  display: flex;
  padding-block: 20px;
  flex-wrap: wrap;
  row-gap: 2rem;
  @media (max-width: 1540px) {
    justify-content: space-between;
  }
  @media (max-width: 980px) {
    display: none;
  }
`;

const Status = styled.div`
${'' /* background-color:red; */}
  flex-shrink: 0;
  @media (max-width: 1540px) {
    width: 30%;
  }
  @media (max-width: 1300px) {
    width: 40%;
  }
  @media (max-width: 980px) {
    width: 100%;
  }
`;

const Pricing = styled.div`
${'' /* background-color:yellow; */}
  width: 425px;
  padding: 0 30px 0 0;
  
  @media (max-width: 1540px) {
    width: 50%;
  }
  @media (max-width: 980px) {
    width: 100%;
  }
`;

const ProductDesc = styled.p`
  color: rgba(0, 0, 0, 0.6);
`;
const TextLight = styled.p`
padding:0;
margin:0;
  color: rgba(0, 0, 0, 0.6);
`;

const Button = styled.button`
  border: none;
  border-radius: 10px;
  background-color: ${colors.blue};
  color: white;
  padding: 13px 65px;
  cursor: pointer;
  margin-top:0.5rem;
`;


const Buttons = styled.div`
display:flex;
gap:1rem;
justify-content: center;
margin-top: 0.5rem;
`;

const SelectReason = styled.select`
  border: 1px solid #000000;
  border-radius: 10px;
  width:fit-content;
  outline: none;
  margin-top: 30px;
  padding:0.5rem;
  font-size: 13px;
`;


const Reject = styled.button`
 background-color: ${(props) => (props.disabled ? '#ccc' : 'red')};
  border: none;
  width: 5rem;
  padding:0.5rem;
  border-radius: 8px;
  margin-top: 25px;
  color: white;
  cursor: pointer;
  font-size: 17px;
`;

const RedText = styled.p`
  color: ${colors.red};
  padding:0;
  margin: 0;
  cursor: pointer;
`;


