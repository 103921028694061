import { style } from "@mui/system";
import styled from "styled-components";
import Colors from "../../../consts/Colors";
import { colors, fonts } from "../../../consts/themes";

export const MainDiv = styled.div`
  /* height: 100vh; */
  display: flex;
  flex-direction: row;
  position: fixed;
  justify-content: center;
  position: relative;
  padding-top: 5rem;
  @media screen and (max-width: 768px) {
    width: auto;
    height: auto;
    flex-direction: column;
    position: unset;
  }
`;
export const Button = styled.div`
  background-color: ${colors.blue};
  padding: 0.8rem;
  text-align: center;
  font-size: 12px;
  margin: 1rem;
  border-radius: 10px;
  color: ${colors.white};
  /* display: none; */
`;
export const ButtonDiv = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    justify-content: right;
  }
`;

export const Show = styled.div`
  display: flex;
  width: 40%;
  @media (max-width: 1024px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const SubDiv = styled.div`
  width: 100%;
  /* height: 90%; */
  margin: 30px;
  flex-wrap: wrap;
  flex-direction: row;
  display: flex;
   /* background-color: blue;  */
  /* justify-content: center; */

  grid-template-columns: auto auto auto auto;
  /* justify-content: space-between; */
  overflow: scroll;
  overflow-x: hidden;
  @media screen and (max-width: 1050px) {
    width: 80%;
    grid-template-columns: auto auto auto;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
    /* margin-top: 6rem; */
  }
  @media screen and (max-width: 500px) {
    grid-template-columns: auto auto;
    width: 100%;
    margin: 5px;
  }
`;
export const Div = styled.div`
  display: flex;
  width: auto;
  flex-direction: column;
   /* background-color: aliceblue; */
  margin-block: 5px;
  margin-inline: 15px;

  @media screen and (max-width: 320px) {
    width: 100px;
    height: 120px;
  }
  p {
    font-size: 12px;
    font-weight: ${fonts.fontWeight5};
    color: ${Colors.black};
    /* margin-bottom:; */
    @media screen and (max-width: 500px) {
      align-self: center;
      text-align: center;
      max-width: 8rem;
    }
  }
`;

export const Images = styled.div`
  display: block;
  /* top:0; */
  /* bottom: 0;  */
  /* right: 300;
top: 190px; */
  text-align: center;
  /* background-color: ${colors.white}; */
  justify-content: center;
  width: 190px;
  cursor: pointer;
  position: absolute;
  @media screen and (max-width: 800px) {
    width: 0px;
  }

  span {
    display: none;
    flex-direction: column;
    transition: all 0.3s;
    img {
      opacity: 1;
      width: 100px;
      height: 100px;
      object-fit: contain;
    }
  }

  p {
    /* position: absolute; */
    align-items: center;
    text-align: center;
  }
  img {
    opacity: 0;
    width: 190px;
    height: 190px;
    object-fit: contain;
    /* z-index: 0; */
    /* position: absolute; */

    @media screen and (max-width: 500px) {
      width: 120px;
      height: 120px;
    }
    @media screen and (max-width: 375px) {
      width: 100px;
      height: 100px;
    }
    @media screen and (max-width: 320px) {
      width: 60px;
      height: 60px;
    }
  }

  img:hover + span {
    display: block;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2000;
    position: absolute;
    top: 0;
    background-color: white;
    border-radius: 8px;
    text-align: left;
    margin-top: 5px;
    margin-left: 60%;
    width: 100%;
    font-size: 12px;
    border-color: ${colors.black};
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    padding: 10px 18px;
    pointer-events: none;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
`;

export const ImageCard = styled.div`
  /* background-color: yellow; */
  /* position: sticky; */
  cursor: pointer;
  width: 190px;
  height: 190px;
  justify-content: center;
  display: flex;
  background-color: ${colors.white};
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

  &:hover ${Images} {
    display: block;
    background-color: ${colors.black};
  }
  img {
    width: 190px;
    height: 190px;
    object-fit: contain;

    @media screen and (max-width: 500px) {
      width: 150px;
      height: 150px;
    }
    @media screen and (max-width: 375px) {
      width: 120px;
      height: 120px;
    }
    @media screen and (max-width: 320px) {
      width: 100px;
      height: 100px;
    }

    &:hover ${Images} {
      display: block;
    }
  }
  @media screen and (max-width: 500px) {
    margin-inline: 15px;
    width: 150px;
    height: 150px;
  }
  @media screen and (max-width: 375px) {
    margin-inline: 10px;
    width: 120px;
    height: 120px;
  }
  @media screen and (max-width: 320px) {
    width: 100px;
    height: 100px;
  }
`;
