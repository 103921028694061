import { colors, fonts } from "../../../consts/themes";
import styled from "styled-components";
import Colors from "../../../consts/Colors";

const MainDiv = styled.div`
  z-index:1;
  width:400px;
  height:500px;
  border-radius:12px;
  display:flex;
  flex-direction:column;
  padding-inline:15px;
  /* aligin-items:center; */
  /* justify-content:center; */
  overflow:scroll;
  background-color:${colors.white};
  @media (max-width: 425px) {
    width:300px;
  }
  @media (max-width: 320px) {
    width: 250px;
  }
  `
const Submain = styled.div`
  width:96%;
  cursor:pointer;
  align-items:flex-end;
  justify-content:flex-end;
  display:flex;
  font-size:25px;
  margin:20px 0;
  
  `
const Head = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  width:100%;
  
  label{
    font-size:16px;
    font-weight:${fonts.fontWeight6};
  }
  
  `
const Addressbar = styled.div`
  width:90%;
  border:1px solid ${colors.line};
  border-radius:8px;
  height:100px;
  cursor: pointer;
  margin:10px 20px 10px;
  
  span{
    margin:10px;
    font-size:14px;
    font-weight:${fonts.fontWeight3}
  }
  p{
    margin: 5px 10px;
    font-size:13px;
    font-weight:${fonts.fontWeight3}
  }
  
  @media (max-width: 425px) {
  margin: 10px 15px 10px;
  }
  `
const ADD = styled.div`
  width:90%;
  cursor:pointer;
  border:1px dashed ${Colors.royalblue};
  border-radius:8px;
  height:60px;
  margin:10px 0 5px;
  display:flex;
  align-items:center;
  justify-content:center;
  p{
    font-size:14px;
    margin:0;
    font-weight:${fonts.fontWeight3}
  }
  `
const Select = styled.div`
 display:flex;
 flex-direction:column;
 overflow:scroll;
 `
export {
  MainDiv,
  Submain,
  Head,
  Addressbar,
  ADD,
  Select
}