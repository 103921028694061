import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Help from "../../../components/Help/help";
import AccessoriesModal from "../../../components/Modal/AccessoriesModal";
import {
  Button,
  Card,
  Cards1,
  Cash,
  ContentDiv,
  ContMain,
  FooterDiv,
  Heading,
  KeyF,
  MainDiv,
  Mrps,
  Rupees,
  Rupees1,
  SliderDiv
} from "./AccessoriesDetailsStyle";
// import CardsAdd from '../../sale/product/CardsAdd'

import { GrNext, GrPrevious } from "react-icons/gr";
import CardsAdd from "../../sale/product/CardsAdd";
import { Divss, Sub } from "./ProductDetailStyle";

import ErrorComponent from "../../../components/Button/error/ErrorComponent";
import LoadingSpinner from "../../../components/Button/Loading/LoadingSpinner";
import AccessoriesGoBackModal from "../../../components/Modal/AccessoriesGoBackModal";
import InsatallSubModal from "../../../components/Modal/NewModals/InstallSubModal";
import AccGotoCartModal from "../../../components/Modals/AccGotoCartModal";
import { AuthContext } from "../../../consts/AuthContext";
import axiosInstance from "../../../consts/axiosInstance";



const ProductDetails = () => {


  const location = useLocation();
  const { path } = location.state;


  console.log("path alex", path)



  const navigation = useNavigate();
  // const imgss = localStorage.getItem('imgss')
  // const aresponses = JSON.parse(localStorage.getItem('aresponse') || '[]')
  const [modalOpen, setModalOpen] = useState(false);
  const [details, setDetails] = useState([]);
  const [getDetails, setGetDetails] = useState([]);
  const [printermod, setprintermod] = useState(false);
  const [accessories, setAccessories] = useState([]);
  const [quanty, setQuanty] = useState(false);
  const [backup, setBackup] = useState(false);

  const [isProductInCart, setIsProductInCart] = useState(false);


  const [productInCartCategoryId, setProductInCartCategoryId] = useState(null);
  // console.log(productInCartCategoryId, "productInCartCategoryId")
  const printerid = JSON.parse(localStorage.getItem("printerid") || "[]");
  const product_category_id = JSON.parse(localStorage.getItem("productid") || "[]");
  const [authUser, setAuthUser] = React.useContext(AuthContext);
  const prodCat = JSON.parse(localStorage.getItem("productcat") || "[]");
  const desktopid = JSON.parse(localStorage.getItem("desktopid") || "[]");
  const Cartpid = JSON.parse(localStorage.getItem("cartpid"));
  const totalpr = JSON.parse(localStorage.getItem("totalp") || "[]");
  //color id of lap , mob etc
  const productsid = JSON.parse(localStorage.getItem("productcode"));
  const purchaseTypeId = JSON.parse(localStorage.getItem("codeid") || "[]");
  const customer_category_id = JSON.parse(localStorage.getItem("id") || "[]");
  const customer_sub_category_id = JSON.parse(localStorage.getItem("subcateid") || "[]");
  const responses1 = JSON.parse(localStorage.getItem("response") || "[]");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);








  // accessory product detail
  const productaccessorieslist = async () => {
    try {
      setLoading(true);
      //accessoriesid  is the product id of the accessory that we selecetd
      const response = await axiosInstance.get(`getProductDetailedDisplay?id=${accessoriesid}`);
      // console.log(response.data, " response of product details")
      setAccessories(response.data);

      response?.data?.images && setSelectedImg(JSON?.parse(response?.data?.images)[0]);
      localStorage.setItem("response", JSON.stringify(response));


    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };









  const [selectedImg, setSelectedImg] = useState(
    accessories?.productImages && JSON.parse(accessories?.productImages)[0]
  );
  // console.log(getCart + "/" + Cartpid + '/' + authUser?.user?.id)



  // async function viewcart() {
  //   await fetch(getCart + "/" + Cartpid + '/' + authUser?.user?.id)
  //     .then((response) => response.json())
  //     .then((response) => {
  //       // console.log("response==> mth", response);
  //       setDetails(response);
  //     });
  // }








  useEffect(() => {
    productaccessorieslist();
    // viewcart();
  }, []);










  const nextSlide = () => {
    let imgArray = accessories[0]?.productImages
      ? [...JSON.parse(accessories[0]?.productImages)]
      : [];
    if (imgArray.length > 0) {
      const id = imgArray.indexOf(selectedImg);
      if (id < imgArray.length - 1) {
        setSelectedImg(imgArray[id + 1]);
      }
    }
  };




  const prevSlide = () => {
    let imgArray = accessories[0]?.productImages
      ? [...JSON.parse(accessories[0]?.productImages)]
      : [];
    if (imgArray.length > 0) {
      const id = imgArray.indexOf(selectedImg);
      if (id > 0) {
        setSelectedImg(imgArray[id - 1]);
      }
    }
  };



  let [num, setNum] = useState(1);
  let incNum = () => {
    if (num < 10) {
      setNum(Number(num) + 1);
    }
  };
  let decNum = () => {
    if (num > 1) {
      setNum(num - 1);
    }
  };
  let handleChange = (e) => {
    setNum(e.target.value);
  };




  const functioninstall = () => {
    setprintermod(true);
    // updatecart();
  };








  const [openInstallModal, setOpenInstallModal] = useState(false)



  const printerChangeFunction = () => {
    console.log("accessory is printer");
    if (productsid === "001" || productsid === "004") {
      setOpenInstallModal(true);
    } else {
      navigation("/sales/shoppingcart")
    }
  }



  const accessoryChangeFunction = () => {
    console.log("normal accessory is slected");
    navigation("/sales/accessories");
  }




  const [myCartId, setMyCartId] = useState('')
  // console.log("my cart id in usestate ", myCartId)






  const getallcart = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`getAllCart/${authUser?.user?.id}`);
      // console.log('Response: cart id of user', response.data[0].id);
      setMyCartId(response.data[0].id);
      // setIsCartId(response.data[0].id ? true : false)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    getallcart();
  }, [])




  const [itemQuantity, setItemQuantity] = useState(1);

  const handleAddQuantity = () => {
    if (itemQuantity < 10) {
      setItemQuantity(prevQuantity => prevQuantity + 1);
    }
  };

  const handleRemoveQuantity = () => {
    if (itemQuantity > 1) {
      setItemQuantity(prevQuantity => prevQuantity - 1);
    }
  };


  //product id of the main product that we selected
  const productdetailid = JSON.parse(localStorage.getItem("Productid"));

  // product id of the accessory that we selecetd
  const accessoriesid = JSON.parse(localStorage.getItem("accids"));

  console.log("accessoriesid ", accessoriesid)


  //customer catagory id of accessories that we selected
  const accessoriescatid = JSON.parse(localStorage.getItem("accid"));
  //customer catagory & sub catagory
  const custcatid = JSON.parse(localStorage.getItem("ccatid"));
  const custsubcatid = JSON.parse(localStorage.getItem("cscatid"));


  //product already exists in cart handler
  const productExistsandler = (id) => {
    //for checking  printer or accessory
    // console.log(id, " pdt cat id");
    setProductInCartCategoryId(id);
    setIsProductInCart(true);
  }












  // async function insertcart() {

  //   console.log("update cart api call for accessories...")



  //   const formDatas = {
  //     amount: accessories.comboPrice ? accessories.comboPrice : accessories.offer_price,
  //     custom_sale_report_id: 0,
  //     customer_category_id: custcatid ? custcatid : 1,
  //     customer_sub_category_id: custsubcatid ? custsubcatid : 1,
  //     cart_id: myCartId,
  //     order_status: "order placed",
  //     product_category_id: accessories.product_category_id,
  //     product_id: accessories.id,
  //     purchaseTypeId: accessories.purchaseTypeId,
  //     qnty: itemQuantity,
  //     shipping_address: null,
  //     total_amount: accessories.comboPrice,
  //     user_id: authUser.user.id,
  //     installation_id: "",
  //     installation_amount: "",
  //     parent_product_id: productdetailid,
  //   };


  //   const options = {
  //     method: "POST",
  //     url: 'updateCart',
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: formDatas,
  //   };


  //   await axiosInstance
  //     .request(options)
  //     .then((response) => {
  //       console.log("🚀 ~ update accessory  cart response", response);

  //       if (response.data.code === 404) {
  //         productExistsandler(response.data.productcategoryid)

  //       } else if (accessories?.product_category_id === 11) {
  //         // if selected accessory is printer
  //         printerChangeFunction();
  //       } else {
  //         accessoryChangeFunction();
  //       }

  //     })
  //     .catch(function (error) {
  //       console.error(error);
  //       console.log("🚀 ~ update cart error", error);
  //     });

  // }




  const NavChangeHandler = () => {
    if (path === "accessory") {
      navigation('/accessories/home')
    } else if (path === "printer") {
      navigation('/printer/categories')
    }
  }


  async function insertcart() {

    console.log("update cart api call for accessories...")

    const formDatas = {
      amount: accessories?.comboPrice,
      custom_sale_report_id: 0,
      customer_category_id: custcatid ? custcatid : 1,
      customer_sub_category_id: custsubcatid ? custsubcatid : 1,
      cart_id: myCartId,
      order_status: "order placed",
      product_category_id: accessories.product_category_id,
      product_id: accessories.id,
      purchaseTypeId: accessories.purchaseTypeId,
      qnty: itemQuantity,
      shipping_address: null,
      total_amount: accessories?.comboPrice,
      user_id: authUser.user.id,
      installation_id: "",
      installation_amount: "",
      parent_product_id: productdetailid,
    };


    const options = {
      method: "POST",
      url: 'insertCart',
      headers: {
        "Content-Type": "application/json",
      },
      data: formDatas,
    };

    await axiosInstance
      .request(options)
      .then((response) => {

        if (response.data.code === 404) {
          setIsProductInCart(true);

        } else if (response.data.code === 200) {
          NavChangeHandler();
        }

      })
      .catch(function (error) {
        console.error(error);
        console.log("🚀 ~ update cart error", error);
      });

  }





  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }

  return (
    <>
      <MainDiv>

        {openInstallModal && <InsatallSubModal close={setOpenInstallModal} />}

        {productInCartCategoryId && isProductInCart && <AccessoriesGoBackModal
          close={setIsProductInCart} catgId={productInCartCategoryId} openInstall={setOpenInstallModal} />}




        {isProductInCart && <AccGotoCartModal close={setIsProductInCart} path={path} />}




        <SliderDiv>
          <Card>
            <Divss>
              <GrPrevious
                style={{
                  alignSelf: "center",
                  fontSize: "30px",
                }}
                onClick={prevSlide}
              />

              <img src={selectedImg}></img>

              <GrNext
                style={{
                  alignSelf: "center",
                  fontSize: "30px",
                }}
                onClick={nextSlide}
              />
            </Divss>

            <Sub>
              {accessories?.images &&
                JSON.parse(accessories?.images)?.map((i, k) => {
                  return (
                    <img
                      src={i}
                      style={{
                        border: selectedImg === i ? "1px solid #4669E8" : "",
                      }}
                      onClick={() => setSelectedImg(i)}
                    />
                  );
                })}
            </Sub>
          </Card>
        </SliderDiv>

        {modalOpen === true ? <AccessoriesModal /> : ""}

        <ContentDiv>
          <ContMain>
            <Heading>
              <p>{accessories?.title}</p>
            </Heading>


            <KeyF>
              <p style={{ fontWeight: "500", fontSize: "18px" }}>Specifications</p>
              {
                accessories?.description?.split(',').map((i) => {
                  return (
                    <li>{i}</li>
                  )
                })
              }
            </KeyF>

            <FooterDiv>
              <Cards1>


                <Cash>
                  <Mrps>
                    <p>ComboPrice</p>
                    <Rupees>
                      <p>: ₹ {accessories?.comboPrice}</p>
                    </Rupees>
                  </Mrps>


                  <Mrps>
                    <p>Clikepoints</p>
                    <Rupees>
                      <p>: {accessories?.reward_points * num} clikepoints</p>
                    </Rupees>
                  </Mrps>

                  <Mrps>
                    <p>Warranty</p>
                    <Rupees1>
                      <p>: {accessories?.warranty} Year</p>
                      {/* <span>View Details</span> */}
                    </Rupees1>
                  </Mrps>

                </Cash>
                <Button>
                  <button
                    onClick={insertcart}
                  >
                    Add to cart
                  </button>
                  {/* <button onClick={() => setModalOpen(true)}>Add to cart</button> */}
                </Button>
              </Cards1>
              {/* card component */}
              <CardsAdd count={num} />
            </FooterDiv>
          </ContMain>
          {/* );
          })} */}
        </ContentDiv>
        {/* <Help /> */}
      </MainDiv>
      <Help />
    </>
  );
};

export default ProductDetails;









