import { SportsHockey } from "@mui/icons-material";
import styled from "styled-components";
import { colors, fonts } from "../../../consts/themes";

export const MainDiv = styled.div`
  display: flex;
  max-width: 1400px;
  padding: 8rem 1rem 4rem;
  margin: auto;
  gap: 3rem;

  /* Default height */
  height: auto; /* Set a default height for smaller screens */

  /* Apply specific height for screens above 2000px */
  @media screen and (min-width: 2000px) {
    height: 85vh;
  }

  @media screen and (max-width: 1300px) {
    flex-direction: column;
  }
`;
export const SliderDiv = styled.div``;

export const Card = styled.div`
  border: 0.5px solid ${colors.line};
  border-radius: 8px;
  padding: 20px;
  align-items: center;
  // background-color:blue;
  justify-content: center;
  /* margin-top:10px; */
  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 500px;
    height: 450px;
    @media (max-width: 680px) {
      width: 300px;
      height: 250px;
    }
  }

  @media screen and (max-width: 1440px) {
    /* height: 62vh;  */
  }
  @media screen and (max-width: 500px) {
    img {
      width: 300px;
      height: 250px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media screen and (max-width: 320px) {
    img {
      width: 250px;
      height: 200px;
    }
  }
`;
export const Sub = styled.div`
  display: flex;
  flex-direction: row;

  img {
    width: 100px;
    height: 100px;
    border: 0.5px solid ${colors.line};
    border-radius: 8px;
    margin-inline: 8px;
    @media screen and (max-width: 500px) {
      width: 50px;
      height: 50px;
    }
    @media screen and (max-width: 375px) {
      width: 40px;
      height: 40px;
    }
  }
`;
export const Divss = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentDiv = styled.div``;

export const ContMain = styled.div`
  // padding-top: 35px;
  // background-color:pink;
  // width:100%;
  @media screen and (max-width: 1500px) {
    padding-top: 0px;
  }
`;
export const Heading = styled.div`
  p {
    font-size: 18px;
    font-weight: ${fonts.fontWeight5};
    margin: 0;
  }
`;
export const Rate = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  width: fit-content;
`;
export const Icon = styled.div`
  color: ${colors.yellow};
`;
export const Rateings = styled.div`
  p {
    font-size: 12px;
    color: ${colors.blue};
  }
`;
export const KeyF = styled.div`
  display:flex;
  flex-direction:column;
  p {
    margin: 0px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;
export const Features = styled.div`
display:flex;
flex-direction:row;
align-items: flex-start;
justify-content : flex-start;
// background-color:yellow;
// padding:0 5px;


p{
  font-size:13px;
  color:${colors.black};
  font-weight:${fonts.fontWeight3};
  margin:0 0px;
}
span{
  font-size:13px;
  color:${colors.black};
  font-weight:${fonts.fontWeight4};
  margin:0 6px;
}
`
export const Pro = styled.div`
// background-color:red;
width:115px;
`
export const Spec = styled.div`
width:400px;
// background-color:red;
`

export const Table = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: flex-start;
  gap: 1rem;
  p {
    font-size: 12px;
  }
  @media screen and (max-width: 800px) {
    align-items: baseline;
    p {
      text-align: left;
      align-items: flex-start;
      font-size: 12px;
    }
  }
  @media screen and (max-width: 500px) {
    p {
      width: 90%;
      padding-left: 5px;
    }
  }
  @media screen and (max-width: 320px) {
    p {
      width: 100%;
    }
  }
`;
export const Key = styled.div`
  background-color: ${colors.black};
  width: 5px;
  height: 5px;
  margin-top: 6px;
  border-radius: 10px;
  flex-shrink: 0;
`;
export const Box = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 800px) {
    /* width: 50%; */
    width: 25%;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  @media screen and (max-width: 500px) {
    width: 55%;
  }
`;
export const YrWar = styled.div`
  background-color: ${colors["background-blue"]};
  border: none;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  margin-right: 12px;
  width: 70px;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin-top: 5px;
`;
export const Year = styled.div`
  font-size: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: ${fonts.fontWeight3};
  padding-left: 5px;
  p {
    margin: 0;
  }
  img {
    width: 19px;
    height: 19px;
    margin-bottom: 3px;
  }
`;
export const Details = styled.div`
  color: ${colors.blue};
  padding-right: 5px;
`;
export const FooterDiv = styled.div`
  display: flex;
  margin-top: 10px;
  gap: 2rem;
  flex-direction: column;
`;
export const Cards1 = styled.div``;
export const NumerAdd = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  // justify-content: space-evenly;
  button {
    background-color: ${colors.white};
    border: 0.5px solid ${colors.line};
    border-radius: 5px;
    width: 35px;
    height: 35px;
  }
  input {
    background-color: ${colors.white};
    border: 0.5px solid ${colors.line};
    border-radius: 5px;
    height: 31px;
    width: 60px;
    margin: 0 10px;
    border-radius: 5px;
    text-align: center;
  }
  @media (min-width: 1200px) and (max-width: 1440px) {
    width: 40%;
  }
  @media (min-width: 900px) and (max-width: 1100px) {
    width: 30%;
  }
  @media (min-width: 700px) and (max-width: 800px) {
    width: 25%;
  }

  @media (min-width: 2000px) and (max-width: 300px) {
    width: 22%;
  }
`;

export const Cash = styled.div``;
export const Mrp = styled.div`
  display: flex;
  flex-direction: row;
  // width: 44%;
  // width:400px;
  //  height: 2.5vh;
  width: 200px;
  margin-top: 20px;
  //  justify-content: space-between;
  p {
    width: 30px;
    color: ${colors.ash};
    font-size: 13px;
    margin: 0;
    font-weight: ${fonts.fontWeight5};
  }
  @media screen and (max-width: 300px) {
    margin-top: 0px;
  }
`;
export const Mrpss = styled.div`
  display: flex;
  flex-direction: row;
  width: 250px;
  line-height: 1;
  //  height: 2.5vh;
  line-height: 1.7;
  //  justify-content: space-between;
  p {
    color: ${colors.ash};
    font-size: 13px;
    margin: 0;
    font-weight: ${fonts.fontWeight5};
  }
`;
export const Rupee = styled.div`
  display: flex;
  flex-direction: row;
  //   background-color:${colors.blue};
  width: 400px;
  //   margin:0 0 0 32px;
  margin-left: 40px;
  margin-top: 0;
  p {
    // padding-left:10px;
    color: ${colors.ash};
    font-size: 13px;
    width: 55px;
    // margin:0 1px;
    font-weight: ${fonts.fontWeight5};
    // margin:0;
  }
  span {
    color: ${colors.blue};
    font-size: 13px;
    width: 150px;
    font-weight: ${fonts.fontWeight5};
    margin: 0 0 0 5px;
    cursor: pointer;
    line-height: 1.7;
    margin: 1px 0;
  }
`;
export const Rupees = styled.div`
  display: flex;
  flex-direction: row;
  //   background-color:${colors.blue};
  width: 400px;
  //   margin:0 0 0 32px;
  margin-left: 20px;
  margin-top: 0;
  p {
    // padding-left:10px;
    color: ${colors.ash};
    font-size: 13px;
    // margin:0 1px;
    font-weight: ${fonts.fontWeight5};
    // margin:0;
  }
  span {
    color: ${colors.blue};
    font-size: 13px;
    font-weight: ${fonts.fontWeight5};
    margin: 0 0 0 5px;
    cursor: pointer;
    line-height: 1.7;
    margin: 1px 0;
  }
`;
export const Rupeess = styled.div`
  display: flex;
  flex-direction: row;
  //   background-color:${colors.blue};
  width: 400px;
  //   margin:0 0 0 32px;
  //  margin-left:20px;
  margin-top: 0;
  p {
    // padding-left:10px;
    color: ${colors.ash};
    font-size: 13px;
    // margin:0 1px;
    font-weight: ${fonts.fontWeight5};
    // margin:0;
  }
  span {
    color: ${colors.blue};
    font-size: 13px;
    font-weight: ${fonts.fontWeight5};
    margin: 0 0 0 5px;
    cursor: pointer;
    line-height: 1.7;
    margin: 1px 0;
  }
`;

export const Rupa = styled.div`
  display: flex;
  flex-direction: row;
  // width:400px;
  // margin:0 0 0 43px;
  margin-left: 63px;
  color: ${colors.ash};
  p {
    padding-left: 2px;
    text-decoration: line-through;
    width: 60px;
    color: ${colors.ash};
  }
  @media (min-width: 600px) and (max-width: 800px) {
    // padding-left:200px;
    p {
      width: 200px;
    }
    // margin:0;
  }
  @media (min-width: 400px) and (max-width: 500px) {
    p {
      width: 200px;
    }
  }
  @media (min-width: 360px) and (max-width: 380px) {
    p {
      width: 100px;
    }
  }
  @media (min-width: 300px) and (max-width: 350px) {
    p {
      width: 100px;
    }
  }
`;
export const Price = styled.div`
  /* height: 2vh; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
export const Cash1 = styled.div`
  display: flex;
  flex-direction: row;
  /* padding-right: 3rem; */
  width: 100%;
  p {
    font-weight: ${fonts.fontWeight4};
  }
  span {
    margin-top: 1.2rem;
    font-size: 12px;
    color: ${colors.line};
    max-width: 8rem;
    @media screen and (max-width: 1440px) {
      max-width: 7rem;
    }
  }
`;
export const Point = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  p {
    font-size: 12px;
    color: ${colors.black};
    margin: 0;
  }
  img {
    width: 18px;
    height: 18px;
    // margin:0 2px;
    margin-bottom: 3px;
  }
`;
export const Button = styled.div`
  button {
    width: 320px;
    height: 5vh;
    border-radius: 10px;
    background-color: ${colors.blue};
    color: ${colors.white};
    border: none;
    margin-top: 2rem;
    @media screen and (max-width: 768px) {
      width: 90%;
    }
  }
`;

export const Cards3 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  @media screen and (max-width: 1050px) {
    width: 80%;
  }
  @media screen and (max-width: 768px) {
    margin-top: 10px;
  }
  @media screen and (max-width: 500px) {
    display: none;
  }
`;
export const Card2 = styled.div`
  display: flex;
  flex-direction: column;
  border: 0.5px solid ${colors.line};
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  width: 30%;
  height: 100%;
  padding: 5px;
  p {
    font-size: 12px;
  }
  @media screen and (max-width: 800px) {
    width: 50%;
  }
`;
export const CardImage = styled.div`
  margin: 0px;
  img {
    width: 80px;
    height: 80px;
  }
`;

export const CardName = styled.div`
  p {
    text-align: center;
    margin: 0px;
  }
`;
export const CardCash = styled.div`
  margin: 0px;
  p {
    font-size: 16px;
    font-weight: ${fonts.fontWeight4};
    margin: 0px;
  }
`;
export const Off = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  p {
    margin: 0px;
    font-size: 10px;
  }
`;
export const MrpCard = styled.div`
  p {
    color: ${colors.line};
    text-decoration: line-through;
  }
`;
export const Offer = styled.div`
  p {
    color: ${colors.red};
  }
`;
export const CardIcon = styled.div`
  align-items: center;
  justify-content: center;
  margin-top: 10vh;
  @media (min-width: 1200px) and (max-width: 1440px) {
    margin-top: 12vh;
  }
  @media screen and (max-width: 800px) {
    margin-left: 5px;
    margin-right: 5px;
  }
`;
export const Rupees1 = styled.div`
  display: flex;
  flex-direction: row;
  //   background-color:${colors.blue};
  width: 400px;
  margin: 0 0 0 32px;

  p {
    color: ${colors.ash};
    font-size: 13px;
    font-weight: ${fonts.fontWeight5};
    margin: 0;
  }
  span {
    color: ${colors.blue};
    font-size: 13px;
    font-weight: ${fonts.fontWeight5};
    margin: 0 0 0 5px;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: ${colors.blue};
  }
  @media (min-width: 600px) and (max-width: 800px) {
    // width:200px;
  }
  @media (min-width: 400px) and (max-width: 430px) {
    margin: 0;
    p {
      padding-left: 30px;
      width: 50px;
    }
    span {
      margin: 0;
      width: 100px;
    }
  }
  @media (min-width: 300px) and (max-width: 350px) {
    margin: 0;
    p {
      padding-left: 30px;
      width: 50px;
    }
    span {
      margin: 0;
      width: 100px;
    }
  }
`;
export const Rupees2 = styled.div`
  display: flex;
  flex-direction: row;
  //   background-color:${colors.blue};
  width: 600px;
  margin: 0 0 0 70px;

  p {
    color: ${colors.ash};
    font-size: 13px;
    font-weight: ${fonts.fontWeight5};
    margin: 0;
  }
  span {
    color: ${colors.blue};
    cursor: pointer;
    font-size: 13px;
    font-weight: ${fonts.fontWeight5};
    margin: 0 0 0 5px;
    text-decoration: underline;
    text-decoration-color: ${colors.blue};
  }
  @media (min-width: 600px) and (max-width: 800px) {
    p {
      // padding-left:200px;
    }
  }
  @media (min-width: 400px) and (max-width: 500px) {
    p {
      width: 15px;
    }
    span {
      width: 120px;
    }
  }

  @media (min-width: 300px) and (max-width: 350px) {
    p {
      width: 15px;
    }
    span {
      width: 120px;
    }
  }
`;
export const Mrps = styled.div`
  display: flex;
  flex-direction: row;
  width: 250px;
  //  height: 2.5vh;
  line-height: 2;
  //  justify-content: space-between;
  p {
    color: ${colors.ash};
    font-size: 13px;
    margin: 0;
    font-weight: ${fonts.fontWeight5};
  }
`;
