import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalContainer, Modal, Overlay } from "../modal.js";
import {
  Installationmodal_main,
  DIv,
  Installationmodal_container,
  Installationmodal_content,
  Installation_text,
  Installation_Button,
  Installation_Button_one,
  Installation_Button_two,
} from "./Installationmodal.styled";
import { GrFormClose } from "react-icons/gr";
import styled from 'styled-components';
import image from '../../assets/Images/png/submit-logo.png'



const CustomPcSubmitModal = () => {

  const navigation = useNavigate()


  return (
    <ModalContainer>
      <Modal Modal>

        <Overlay>
          <Container>

            <ImgTag>
              <img src={image} alt='submit-logo' />
            </ImgTag>

            <Head1>
              We will contact you soon
            </Head1>

            <Head2>
              Total prize will be updated within 30 min
            </Head2>

            <LinkDiv onClick={() => navigation('/')}>Continue shopping</LinkDiv>
          </Container>

        </Overlay>
      </Modal>
    </ModalContainer>
  )
}

export default CustomPcSubmitModal;



const Container = styled.div`
display: flex; 
flex-direction:column;
justify-content: center; 
align-items:center;
padding:2.5rem  1.5rem;
gap:0.5rem;
`

const Head1 = styled.div`
`


const Head2 = styled.div`
padding:2rem 4rem;
border: 1px solid rgba(0, 0, 0, 0.20);
border-radius: 0.625rem;
`

const LinkDiv = styled.div`
color:#4669E8;
margin-top:0.5rem;
`


const ImgTag = styled.div`
img{
  width:7rem;
  height:6rem;
  object-fit:contain;
}

`
