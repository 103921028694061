export const ImgCardData = [
    {
        img: require('../assets/Images/png/laptop1.png'),
        title: "Laptop",


    },
    {
        img: require('../assets/Images/png/tablets1.png'),
        title: "Tablets",
    },
    {
        img: require('../assets/Images/png/smartphones.png'),
        title: "Mobiles",
    },
    {
        img: require('../assets/Images/png/desktop1.png'),
        title: "Desktop",
    },
    {
        img: require('../assets/Images/png/printers.png'),
        title: "Printer",
    },
    {
        img: require('../assets/Images/png/cctv1.png'),
        title: "CCTV",
    },
    {
        img: require('../assets/Images/png/download.png'),
        title: "Speakers",
    },
    {
        img: require('../assets/Images/png/monitors1.png'),
        title: "Monitors",
    },
    {
        img: require('../assets/Images/png/components1.png'),
        title: "Components",
    },
    {
        img: require('../assets/Images/png/upsinverter.png'),
        title: "UPS/Inverter",
    },
    {

        img: require('../assets/Images/png/net.png'),
        title: "NetWorking Products",
    },
    {

        img: require('../assets/Images/png/accessories1.png'),
        title: "Accessories",
    },
];

export const colors = [
    'linear-gradient(45deg, #14567B 0%, #7AC9C2 100%)',
    'linear-gradient(45deg, #F36C9E 0%, #F090F5 100%)',
    'linear-gradient(45deg, #677BE5 0%, #7450AA 100%)',
    'linear-gradient(45deg, #FE5D53 0%, #F0971A 100%)',
    'linear-gradient(45deg, #C99280 0%, #E45CB3 100%)',
    'linear-gradient(45deg, #677BE5 0%, #7450AA 100%)',
    'linear-gradient(45deg, #FF154B 0%, #FFA292 100%)',
    'linear-gradient(45deg, #D27BD3 0%, #8E80E8 100%)',
    'linear-gradient(45deg, #181818 0%, #6A6A6A 100%)',
    'linear-gradient(45deg, #D27BD3 0%, #8E80E8 100%)',
    'linear-gradient(45deg, #3e88b3 0%, #c8e8fa 100%',
    'linear-gradient(45deg, #147B53 0%, #7AC9C2 100%)',

];