import React, { useEffect, useState } from 'react'
import LoadingSpinner from '../../../components/Button/Loading/LoadingSpinner';
import ErrorComponent from '../../../components/Button/error/ErrorComponent';
import { productGuid } from '../../../api/api';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import axiosInstance from '../../../consts/axiosInstance';

const GuideDetails = () => {

  const { id } = useParams();

  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const getProductCategories = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('productGuid', {
        params: {
          product_cat_id: id,
        },
      })

      setDetails(response.data);


    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProductCategories();
  }, [])


  if (loading) {
    return <LoadingSpinner />
  }
  if (error) {
    return <ErrorComponent error={error.message} />
  }


  return (
    <ScreenDiv>
      <ProductGuideData
        dangerouslySetInnerHTML={{ __html: details?.product_guide }}
      />
    </ScreenDiv>
  )
}

export default GuideDetails



const ScreenDiv = styled.div`
  padding-top: 5rem;
  height: calc(100vh - 5rem); 
`;

const ScreenCont = styled.div`
display: grid;
overflow-y: scroll;
  @media only screen and (min-width: 250px) and (max-width: 350px) {padding: 1rem ;gap: 0.5rem;}
  @media only screen and (min-width: 351px) and (max-width: 768px) {padding: 1rem ;gap: 1rem;}
  @media only screen and (min-width: 768px) and (max-width: 1023px) {padding: 1.5rem ;gap: 1.5rem;}
  @media only screen and (min-width: 1024px) {padding: 2rem;gap: 2rem;}
`;


const ProductGuideData = styled.div`
  padding: 10px 2rem;
  ${'' /* height: 72dvh; */}
  overflow-y: scroll;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  &::-webkit-scrollbar {
    display: none;
  }
`;