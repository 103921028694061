import styled from "styled-components";

export const InputField = styled.input`
    width: 260px;
    height: 30px;
    border: 0;
    background: #e7e7e7;
    border-radius: 10px;
    padding-left: 10px;
    ::-webkit-inner-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    }
    ::-webkit-outer-spin-button{
        -webkit-appearance: none; 
        margin: 0; 
    } 
    -moz-appearance: textfield;
`
export const TextArea = styled.textarea`
    width: 260px;
    /* height: 30px; */
    border: 0;
    background: #e7e7e7;
    border-radius: 10px;
    padding-left: 10px;
    resize: none;
`