import styled from "styled-components";
import { colors, fonts } from "../../../consts/themes";

const Bg = styled.div`
  // background-color: ${colors.blue};
  height: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: .5rem 1.5rem;
  // padding-bottom: 4rem;
  text-align: center;
  /* border: 1px solid; */
  /* padding-bottom: 10px; */
  /* box-shadow: 1px 1px #888888; */
  img {
    background-color: ${colors.blue};
    border-radius: 60px;
    padding: 8px;
  }
  h2 {
    color: ${colors.black};
    font-weight: ${fonts.fontWeight5};
    font-size: 18px;
  }
  p {
    color: ${colors.black};
    max-width: 25rem;
    padding-inline: 100px;
    line-height: 1.2rem;
    font-size: 12px;
    letter-spacing: 0.01rem;
    @media (max-width: 425px) {
      max-width: 25rem;
      padding-inline: 50px;
    }
  }
`;
const Cont = styled.div`
  color: ${colors.black};
  font-weight: ${fonts.fontWeight3};
  padding-top: 1.5rem;
  @media (max-width: 375px) {
    font-size: 0.8rem;
  }
`;
export const Div = styled.div`
  width: 95%;
  cursor: pointer;
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  margin: 1rem;
`;
const Btn = styled.div`
  display: grid;
  padding-inline: 1rem;
  grid-template-columns: auto auto auto auto auto;

  @media (max-width: 656px) {
    grid-template-columns: auto auto auto;
  }
  @media (max-width: 425px) {
    grid-template-columns: auto auto;
  }
`;
const Card = styled.div`
  background-color: ${colors.peach};
  width: 100px;
  height: 40px;
  border-radius: 10px;
  margin-inline: 10px;
  margin-block: 10px;
  p {
    font-size: 12px;
    text-align: center;
    cursor: pointer;
  }
  @media (max-width: 375px) {
    margin-inline: 5px;
  }
  @media (max-width: 320px) {
    margin-inline: 20px;
  }
`;
const Input = styled.div`
  outline: none;
  border: none;
  // margin-inline: 1rem;
  padding-block: 5rem;
  padding-inline: 1rem;
  margin-block: 1.5rem;
  width: 88%;
  // min-height: 30%;
  background-color: #f6f6f6;
  border-radius: 10px;
  p {
    color: ${colors.light_grey};
    margin-right: 17rem;
    margin-top: -4rem;
    font-size: 12px;
  }
  @media (max-width: 425px) {
    padding-block: 3rem;
    padding-inline: 0.2rem;
    margin-block: 1.5rem;
    p {
      margin-right: 4rem;
      margin-top: -2rem;
    }
  }
  @media (max-width: 320px) {
    p {
      margin-right: 3rem;
      margin-top: -2rem;
      font-size: 0.875rem;
    }
  }
`;
const Button = styled.div`
  align-items: "center";
  justify-content: "center";
  border-radius: 10px;
  background-color: ${colors.blue};
  color: ${colors.white};
  font-size: 14px;
  font-weight: ${fonts.fontWeight5};
  padding-inline: 2rem;
  padding-block: 0.8rem;
  margin-bottom: 2.2rem;
  margin-top: 0.6rem;
  cursor: pointer;
`;
const Main = styled.div`
  display: flex;
  border: 0.5px solid ${colors.line};
  width: 100px;
  height: 100px;
  border-radius: 10px;

  h1 {
    display: flex;
    color: ${colors.line};
    font-size: 0.875rem;
  }
`;

export { Bg, Cont, Btn, Card, Input, Button, Main };
