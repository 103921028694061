import styled from "styled-components";
import { colors } from "../../../consts/themes";


export const ProfileCard = styled.div`
    padding:1rem;
    width:300px;
    border: 1px solid ${colors.medium_gray};
    border-radius:10px;
    @media (max-width: 400px) {
        width:265px
    }
`
export const FlexP = styled.div`
    display:flex;
    gap:1rem;
    align-items:center;
    a{
        color:${colors.blue};
        font-weight:400;
        font-size:14px;
    }
`
export const ProfileWrap = styled.div`
    padding-top: 6rem;
    a{
        font-size:14px;
    }
`

export const Avatar = styled.img`
    height: 3rem;
    width: 3rem;
    border-radius:80px;
    object-fit:cover;
`
export const Name = styled.span`
    text-transform:capitalize;
    font-size:14px;
` 

export const NavCard = styled.div`
    width:300px;
    margin-top:2rem;
    border: 1px solid ${colors.medium_gray};
    border-radius:10px;
    @media (max-width: 400px) {
        width:265px
    }
    ul{
        list-style:none;
        padding:0;
        margin:0;
        >:last-child{
            border-bottom:none;
        }
        a{
            text-decoration:none;
            color:${colors.ash};
            text-transform:capitalize
        }
        li{
            border-bottom:1px solid ${colors.medium_gray};
            padding:.5rem 1.5rem;
        }
    }
`