import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
// import { Container, Flex, LeftSec, Navbar, RightSec, View } from './ProfileStyle'
import { AiOutlineClose, AiOutlineMenuUnfold } from 'react-icons/ai'
import UserPanel from './UserPanel.js/UserPanel'
import InvoiceModal from './InvoiceModal/InvoiceModal'
import { useContext } from 'react'
import { AuthContext } from '../../consts/AuthContext'
import axios from 'axios'
import { getUserDetailsForProfile } from '../../api/api'
import LoadingSpinner from '../../components/Button/Loading/LoadingSpinner'
import ErrorComponent from '../../components/Button/error/ErrorComponent'
import { RxHamburgerMenu } from "react-icons/rx";
import styled from "styled-components";
import { colors } from "../../consts/themes";
import { SlOptionsVertical } from "react-icons/sl";
import axiosInstance from '../../consts/axiosInstance'
import Footer from '../../components/Footer/Footer'






const Profile = () => {




  const navigate = useNavigate()

  // useEffect(() => {
  //     if (localStorage.getItem('userid')) {

  //     } else {
  //         navigate('/login')
  //     }
  // }, [])


  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false)
  const [userDetails, setUserDetails] = useState({})
  const [service, setService] = useState(false)
  const [authUser, setAuthUser] = useContext(AuthContext)


  const getUserDetails = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`getUserDetailsForProfile/${authUser?.user.id}`);
      setUserDetails(response.data)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    getUserDetails()
  }, [])




  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }


  return (
    <>
      <Container>

        <Flex>

          <LeftSec>
            <Outlet context={{ userDetails }} />
            <Hamburger onClick={() => setIsOpen(!isOpen)}><SlOptionsVertical size={20} /></Hamburger>
          </LeftSec>

          <RightSec>
            <UserPanel userDetails={authUser} />
          </RightSec>

        </Flex>

        {/* <View onClick={() => setIsOpen(!isOpen)}> <AiOutlineMenuUnfold /></View> */}

        <Navbar className={isOpen ? 'active' : ''} isOpen={isOpen} >
          <AiOutlineClose onClick={() => setIsOpen(!isOpen)} />
          <UserPanel setIsOpen={setIsOpen} userDetails={userDetails} />
        </Navbar>

      </Container>
      <Footer />
    </>
  )
}

export default Profile


const Hamburger = styled.div`
  padding: 0.4rem;
  position: absolute;
  top:0;
  right:0;
  ${'' /* border-radius: 0 6px 6px 0;
  background-color: ${colors.white};
  border: 1px solid ${colors.medium_gray}; */}
  padding-left: 1rem;
  cursor: pointer;
  display:none;
  @media (max-width: 1200px) {display: block}
`;



const Flex = styled.div`
${'' /* background-color:pink; */}
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 2rem;
  ${'' /* padding-block: 3rem; */}
  @media (max-width: 890px) {
    flex-direction: column-reverse;
  }
`;
const LeftSec = styled.div`
overflow-y: scroll;
  height: calc(100vh - 5rem);
position: relative;
  width: 80%;
  ${'' /* background-color: skyblue; */}
  @media (max-width: 1200px) {
    width: 100%;
  }
`;

const Container = styled.div`
padding-top:5rem;

${'' /* background-color:yellow; */}
padding-inline: 1rem;
max-width: 1680px;
margin: auto;
  * {
    box-sizing: border-box;
  }
`;

// const Navbar = styled.div`
//   height: 100vh;
//   background-color: ${colors.white};
//   position: fixed;
//   width: 380px;
//   left: 0;
//   top: 0;
//   z-index: 999999;
//   padding: 3rem 1rem;
//   border-right: 1px solid ${colors.medium_gray};
//   transition: all 0.5s ease-in-out;
//   padding-bottom: 7rem;
//   @media (min-width: 1200px) {
//     display: none;
//   }
//   @media (max-width: 1200px) {
//     height: 100vh;
//     overflow-y: scroll;
//   }
//   @media (max-width: 400px) {
//     width: 300px;
//   }
//   transform: ${(props) => (!props.isOpen ? "translateX(-110%)" : "translateX(0%)")};
//   svg {
//     position: absolute;
//     right: 1rem;
//     top: 1rem;
//     cursor: pointer;
//   }
// `;


export const Navbar = styled.div`
margin-top:5rem;
transform: translateX(100%);
transition:ease 0.3s;
&.active{
transform: translateX(0%)
}
  height: 100vh;
  background-color: ${colors.white};
  position: fixed;
  width: 380px;
  right: 0;
  top: 0;
  z-index: 999999;
  padding: 0 1rem;
  border-left: 1px solid ${colors.medium_gray};
  transition: all 0.5s ease-in-out;
  padding-bottom: 7rem;
  @media (min-width: 1200px) {
    display: none;
  }
  @media (max-width: 1200px) {
    height: 100vh;
    overflow-y: scroll;
  }
  @media (max-width: 400px) {
    width: 300px;
  }
  ${'' /* transform: ${(props) => (!props.isOpen ? "translateX(-110%)" : "translateX(0%)")}; */}
  svg {
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
  }
`;













const View = styled.div`
  padding: 0.4rem;
  position: absolute;
  top: 6rem;
  left: 0;
  border-radius: 0 6px 6px 0;
  background-color: ${colors.white};
  border: 1px solid ${colors.medium_gray};
  padding-left: 1rem;
  cursor: pointer;
  @media (min-width: 1200px) {
    display: none;
  }
  display: flex;
  svg {
    height: 24px;
    width: 25px;
  }
`;

const RightSec = styled.div`
  @media (max-width: 1200px) {
    display: none;
  }
`;






