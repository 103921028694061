import { useNavigate } from 'react-router-dom'
import { ModalContainer, Modal, Overlay } from "../modal"
import styled from 'styled-components';
import { IoClose } from "react-icons/io5";
import { CloseContainer } from './ModalStyled';
import axiosInstance from '../../consts/axiosInstance';
import { useEffect, useState } from 'react';
import LoadingSpinner from '../Button/Loading/LoadingSpinner';
import ErrorComponent from '../Button/error/ErrorComponent';
import { Container, Head1, Img, ImgContainer, InnerConatiner, Text } from './TypeModalStyle';


const ProcessorTypeModal = ({ close }) => {

    const navigation = useNavigate();
    const componentsTypeId = JSON.parse(localStorage.getItem("componentsTypeId"));
    const [processorBrands, setProcessorBrands] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const getProcessorBrand = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get('processorBrands', {
                params: { componentTypeId: componentsTypeId }
            })
            setProcessorBrands(response.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        getProcessorBrand();
    }, [])


    if (loading) {
        return <LoadingSpinner />
    }

    if (error) {
        return <ErrorComponent error={error.message} />
    }


    return (
        <ModalContainer>
            <Modal Modal>
                <Overlay>
                    <Container>
                        <CloseContainer>
                            <IoClose size={22} onClick={() => close(false)} />
                        </CloseContainer>
                        <InnerConatiner>
                            <Head1>Choose Processor Type</Head1>

                            <ItemContainer>
                                {processorBrands?.map((item) => (
                                    <ImgContainer key={item.id}
                                        onClick={() => [
                                            localStorage.setItem('brandId', JSON.stringify(item?.id)),
                                            navigation('/custompc/processor/list')
                                        ]}>
                                        <Img><img src={item?.brand_image} alt='processor' /></Img>
                                        <Text>{item?.brand_name}</Text>
                                    </ImgContainer>
                                ))}

                            </ItemContainer>
                        </InnerConatiner>
                    </Container>
                </Overlay>
            </Modal>
        </ModalContainer>
    )
}

export default ProcessorTypeModal;


const ItemContainer = styled.div`
display: flex;
width: 100%;
justify-content: center;
align-items: center;
gap:1.5rem;
flex-wrap: wrap;

height: fit-content;
max-height: 240px;
overflow:scroll;
`
