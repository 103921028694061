import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import Help from '../../components/Help/help';
import { colors } from '../../consts/themes';
import axiosInstance from '../../consts/axiosInstance';
import ErrorComponent from '../../components/Button/error/ErrorComponent';
import LoadingSpinner from '../../components/Button/Loading/LoadingSpinner';
import clikeLogo from '../../assets/Images/png/clicke.png'
import { useNavigate } from 'react-router-dom';

const GlobalSearch = () => {

  const navigation = useNavigate();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [input, setInput] = useState('');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  const getProductsList = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('getProductList');
      // console.log("resp", response.data);
      setProducts(response.data)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    getProductsList();
  }, []);


  const truncate = (string) => {
    return string?.length > 15 ? string?.substr(0, 200 - 1) + '...' : string
  }


  useEffect(() => {
    if (input) {
      const filtered = products.filter(product =>
        product.title.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts([]);
    }
  }, [input, products]);


  const handleInputChange = (e) => {
    setInput(e.target.value);
  };


  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }



  return (
    <Screen className='h-screen'>

      <ScreenContent>

        <Input>
          <input
            type="text"
            placeholder="Search products..."
            value={input}
            onChange={handleInputChange}
          />
        </Input>

        {input ? (
          <ItemContainer>
            {filteredProducts?.map((item) => (

              <SearchItem onClick={() => [localStorage.setItem("Productid", JSON.stringify(item?.id)),
              localStorage.setItem("productcode", JSON.stringify(item?.color)),
              navigation("/sales/products/productdetails"),
              ]}>

                <ItemImg>
                  <img src={item.images && JSON.parse(item.images)[0]} alt='img' />
                </ItemImg>

                <ItemText>
                  <ItemTitle>{item?.title}</ItemTitle>
                  <ItemDec>{truncate(item?.description)}</ItemDec>
                </ItemText>

                <ItemPrice>
                  <CutPrice>M.R.P ₹{item?.price}</CutPrice>
                  <OfferPrice>₹{item?.offer_price}</OfferPrice>
                  <Coin><img src={clikeLogo} alt='coin=img' />{item?.reward_points}Clike Coins</Coin>
                </ItemPrice>
              </SearchItem>
            ))}
          </ItemContainer>
        ) :
          (
            <div className='w-full h-full'>
              <img className='h-full w-full object-contain' src="/image/searchPlaceholder-Photoroom.png" alt="" />
            </div>
          )

        }

      </ScreenContent>

      {/* <Help /> */}
    </Screen>
  )
}

export default GlobalSearch






const Screen = styled.div`
  margin-top: 5rem;
  /* height: calc(100vh - 5rem);
  max-height: calc(100vh - 5rem); */
  /* overflow-y: auto; */
`;



const ScreenContent = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-top:7rem;
padding:0 8rem;
width: 100%;
height: 100%;
/* height:100vh; */
@media only screen and (min-width:1024px) and (max-width:1499px) {
  padding:0 4rem;
}
@media only screen and (min-width:768px) and (max-width:1023px) {
  padding:0 2rem;
}
@media only screen and (min-width:300px) and (max-width:767px) {
  padding:0 1rem;
}
`;







const ItemContainer = styled.div`
display: flex;
flex-direction: column;
gap:0.8rem;
height: calc(100vh - 13rem);
overflow-y: scroll;
margin-top:1rem;
padding-bottom: 1rem;
max-width: 70rem;
width: 100%;
`;






const SearchItem = styled.div`
border: 1px solid ${colors.line};
display: flex;
justify-content:space-between;
align-items: center;
border-radius: 10px;
`;




const ItemImg = styled.div`
flex:1;
height:100%;
display: flex;
justify-content: center;
align-items: center;
padding:0.5rem;

img{
width:9rem;
height:8rem;
object-fit: contain;
@media only screen and (min-width:768px) and (max-width:1023px) {
width:7rem;
height:6rem;
}
@media only screen and (min-width:450px) and (max-width:767px) {
width:6rem;
height:5rem;
}
@media only screen and (min-width:300px) and (max-width:449px) {
width:4rem;
height:3rem;
}
}

`;



const ItemText = styled.div`
flex:4;
height:100%;
display: flex;
flex-direction: column;
gap:0.5rem;
justify-content: center;
align-items: center;
padding:0.5rem;
`;


const ItemTitle = styled.div`
font-weight: 500;
font-size:1rem;
@media only screen and (min-width:300px) and (max-width:549px) {
font-size: 0.8rem;
}
`;




const ItemDec = styled.div`
font-size: 0.9rem;
font-weight: 300;
@media only screen and (min-width:768px) and (max-width:1023px) {
font-size: 0.8rem;
}
@media only screen and (min-width:500px) and (max-width:767px) {
font-size: 0.7rem;
}
@media only screen and (min-width:300px) and (max-width:499px) {
display: none;
}
`;





const ItemPrice = styled.div`
flex:1;
height:100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap:0.5rem;
padding:0.5rem;

@media only screen and (min-width:300px) and (max-width:499px) {
  flex:3
}
`;




const CutPrice = styled.div`
color: ${colors.light_grey};
text-decoration-line: line-through;
font-size: 0.8rem;
@media only screen and (min-width:768px) and (max-width:1023px) {
font-size:0.7rem;
}
@media only screen and (min-width:300px) and (max-width:767px) {
font-size:0.6rem;
}
`;


const OfferPrice = styled.div`
color: ${colors.blue};
font-family: Arial;
font-size: 1.25rem;
font-style: normal;
font-weight: 700;
@media only screen and (min-width:768px) and (max-width:1023px) {
font-size:1rem;
}
@media only screen and (min-width:300px) and (max-width:767px) {
font-size:0.8rem;
}
`;


const Coin = styled.div`
color: ${colors.blue};
display: flex;
align-items: center;
font-size: 0.8rem;
@media only screen and (min-width:768px) and (max-width:1023px) {
  font-size: 0.7rem;
}
@media only screen and (min-width:300px) and (max-width:767px) {
font-size:0.6rem;
}

  img {
    width: 18px;
    height: 18px;
    padding-inline: 5px;

@media only screen and (min-width:300px) and (max-width:767px) {
width:12px;
height:12px;
}
  }
`;




const Input = styled.div`
  max-width: 45rem;
  width: 100%;
  
  input {
    background-color: ${colors.blue};
    box-sizing: border-box;
    width: 100%;
    height: 4rem;
    border: 0.1px solid ${colors.line};
    border-radius: 2rem;
    color: white;
    font-size: 1.2rem; /* Change this to your desired size */
    padding: 0 1rem;
    
    &:focus {
      border-color: #587ef5;
      outline: 0;
    }
    
    /* Hide spinner controls */
    -moz-appearance: textfield; /* Firefox */
    appearance: none; /* Safari and Chrome */
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none; /* Safari */
    }
    
    /* Placeholder styling */
    &::placeholder {
      font-weight: 400;
      color: white; /* Change this to your desired color */
      font-size: 1.2rem; /* Change this to your desired size */
      opacity: 0.9; /* Optional: Adjusts the opacity of the placeholder text */
    }
  }
`;
