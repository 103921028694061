import { useNavigate } from 'react-router-dom'
import { ModalContainer, Modal, Overlay } from "../modal"
import { IoClose } from "react-icons/io5";
import { CloseContainer, InnerConatiner } from './ModalStyled';
import brandeddesktop from '../../assets/sale/brandeddesktop.png'
import customizeddesktop from '../../assets/sale/customizeddesktop.png'
import { Container, Head1, Img, ImgContainer, ItemContainer, Text } from './TypeModalStyle';

const types = [
    {
        id: 1,
        title: 'Branded Desktop',
        img: brandeddesktop,
    },
    {
        id: 3,
        title: 'Customized Desktop',
        img: customizeddesktop,
    }
]

const DesktopTypeModal = ({ close }) => {
    const navigation = useNavigate();
    return (
        <ModalContainer>
            <Modal Modal>
                <Overlay>
                    <Container>
                        <CloseContainer>
                            <IoClose size={22} onClick={() => close(false)} />
                        </CloseContainer>
                        <InnerConatiner>
                            <Head1>Choose Desktop Type</Head1>
                            <ItemContainer>
                                {types.map((item) => (
                                    <ImgContainer key={item.id}
                                        onClick={item?.id === 1 ? () => navigation('/sales/productcard/users') :
                                            () => navigation('/custompc/home')}>
                                        <Img><img src={item.img} alt='desk-type' /></Img>
                                        <Text>{item.title}</Text>
                                    </ImgContainer>
                                ))}
                            </ItemContainer>
                        </InnerConatiner>
                    </Container>
                </Overlay>
            </Modal>
        </ModalContainer>
    )
}

export default DesktopTypeModal;
