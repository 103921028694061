import React, { useContext, useEffect, useState } from "react";
import {
  BottomSubMenuContainer,
  Button,
  ChildWrapper,
  HomeContainer,
  LeftChild,
  MenuContainer,
  MiddleMenuContainer,
  SubChild,
  SubMenuContainer,
  TopSection,
} from "./HomeStyle";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../consts/AuthContext";
import axios from "axios";
import { server_api } from "../../../consts/api";
import LoadingSpinner from "../../Button/Loading/LoadingSpinner";
import ErrorComponent from "../../Button/error/ErrorComponent";
import Swal from "sweetalert2";


function Home() {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);


  const [data, setData] = useState([]);
  const navigation = useNavigate();
  const [authUser, setAuthUser] = useContext(AuthContext);
  const [quote, setQuote] = useState('')


  const sendReq = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${server_api}getallnotes`);
      // console.log(response.data, " response of quote")
      setQuote(response?.data[0]?.home_notes)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  const getThemelist = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${server_api}getThemeList`);
      // console.log(response.data, " response of theme list")
      setData(response?.data)
      console.log(response?.data)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getThemelist();
    sendReq();
  }, []);



  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }



  return (
    <>
      <HomeContainer>
        <MenuContainer>
          <SubMenuContainer>
            {data.map((item, index) => {
              if (item.code === "001") {
                return (
                  <LeftChild
                    key={index}
                    width="65%"
                    onClick={() => (
                      navigation("/typeofsale"),
                      localStorage.setItem("homeid", JSON.stringify(item?.code)))} >
                    <img src={item.image} alt="shopping" />
                    <h3>{item.layoutname}</h3>
                  </LeftChild>
                );
              }
            })}
            <ChildWrapper width="35%">
              {data.map((item, index) => {
                if (item.code === "003") {
                  return (
                    <SubChild
                      key={index}
                      primary
                      onClick={() => { navigation("/quiz"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}>
                      <h3>{item.layoutname}</h3>
                      <img src={item.image} alt="quiz" />
                    </SubChild>
                  );
                }
                if (item.code === "011") {
                  return (
                    <SubChild
                      key={index}
                      primary
                      onClick={() => { navigation("/profile/myorders"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}>
                      <h3>{item.layoutname}</h3>
                      <img src={item.image} alt="purchase-history" />
                    </SubChild>
                  );
                }
              })}
            </ChildWrapper>
          </SubMenuContainer>

          <MiddleMenuContainer>
            {data.map((item, index) => {
              if (item.code === "002") {
                return (
                  <LeftChild
                    key={index}
                    width="35%"
                    onClick={() => { navigation("/service"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}>
                    <img src={item.image} alt="service" />
                    <h3>{item.layoutname}</h3>
                  </LeftChild>
                  // <LeftChild
                  //   width="35%"
                  //   onClick={() => {Swal.fire({
                  //     position: 'center',
                  //     icon: 'warning',
                  //     color: '#4169e1',
                  //     text: `We are under maintenance. Will be back soon !`,
                  //   })}}>
                  //   <img src={item.image} alt="service" />
                  //   <h3>{item.layoutname}</h3>
                  // </LeftChild>
                );
              }
            })}


            <ChildWrapper width="28%">
              {data.map((item, index) => {
                if (item.code === "004") {
                  return (
                    <SubChild
                      key={index}
                      primary
                      onClick={() => { navigation("/auction"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }} >
                      <img src={item.image} alt="bid" />
                      <h3>{item.layoutname}</h3>
                    </SubChild>
                  );
                }
                if (item.code === "005") {
                  return (
                    <SubChild
                      key={index}
                      primary
                      onClick={() => { navigation("/exchange"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}>
                      <img src={item.image} alt="exchange" />
                      <h3>{item.layoutname}</h3>
                    </SubChild>
                  );
                }
              })}
            </ChildWrapper>

            <ChildWrapper width="35%">
              {data.map((item, index) => {
                if (item.code === "007") {
                  return (
                    <SubChild
                      primary
                      key={index}
                      onClick={() => { navigation("/profile/reward"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}>
                      <img src={item.image} alt="rewards" />
                      <h3>{item.layoutname}</h3>
                    </SubChild>
                  );
                }
                if (item.code === "008") {
                  return (
                    <SubChild
                      primary
                      key={index}
                      onClick={() => { navigation("/user/guide"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}>
                      <img src={item.image} alt="history" />
                      <h3>{item.layoutname}</h3>
                    </SubChild>
                  );
                }
              })}
            </ChildWrapper>
          </MiddleMenuContainer>

          <BottomSubMenuContainer>
            {data.map((item, index) => {
              if (item.code == "010") {
                return (
                  <SubChild
                    primary
                    key={index}
                    width="35%"
                    onClick={() => { navigation("/profile"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
                    bottom >
                    <img src={item.image} alt="profile" />
                    <h3>{item.layoutname}</h3>
                  </SubChild>
                );
              }
            })}
            {data.map((item, index) => {
              if (item.code === "006") {
                return (
                  <SubChild
                    key={index}
                    width="28%"
                    primary
                    onClick={() => { navigation("/search"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
                    bottom>
                    <img src={item.image} alt="search" />
                    <h3>{item.layoutname}</h3>
                  </SubChild>
                );
              }
            })}
            {data.map((item, index) => {
              if (item.code == "009") {
                return (
                  <SubChild
                    key={index}
                    primary
                    width="35%"
                    onClick={() => { navigation("/notifications"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
                    bottom >
                    <img src={item.image} alt="notification" />
                    <h3>{item.layoutname}</h3>
                  </SubChild>
                );
              }
            })}
          </BottomSubMenuContainer>
        </MenuContainer>
        <TopSection>
          <p>{quote}</p>
        </TopSection>
      </HomeContainer>

    </>
  );
}

export default Home;



// old
// import React, { useContext, useEffect, useState } from "react";
// import {
//   BottomSubMenuContainer,
//   Button,
//   ChildWrapper,
//   HomeContainer,
//   LeftChild,
//   MenuContainer,
//   MiddleMenuContainer,
//   SubChild,
//   SubMenuContainer,
//   TopSection,
// } from "./HomeStyle";
// import { useNavigate } from "react-router-dom";
// import { AuthContext } from "../../../consts/AuthContext";
// import axios from "axios";
// import { themeList } from "../../../api/api";
// import { server_api } from "../../../consts/api";
// import LoadingSpinner from "../../Button/Loading/LoadingSpinner";
// import ErrorComponent from "../../Button/error/ErrorComponent";


// function Home() {

//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);


//   const [data, setData] = useState([]);
//   const navigation = useNavigate();
//   const [authUser, setAuthUser] = useContext(AuthContext);
//   const [quote, setQuote] = useState('')




//   const sendReq = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get(`${server_api}getallnotes`);
//       // console.log(response.data, " response of quote")
//       setQuote(response?.data[0]?.home_notes)
//     } catch (error) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };


//   const getThemelist = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get(`${server_api}getThemeList`);
//       // console.log(response.data, " response of theme list")
//       setData(response?.data)
//     } catch (error) {
//       setError(error);
//     } finally {
//       setLoading(false);
//     }
//   };


//   useEffect(() => {
//     getThemelist();
//     sendReq();
//   }, []);



//   if (loading) {
//     return <LoadingSpinner />
//   }

//   if (error) {
//     return <ErrorComponent error={error.message} />
//   }



//   return (
//     <>
//       <HomeContainer>
//         {/* <TopSection>
//           <h1>Clikekart gives you all products that are in factory packing</h1> */}
//         {/* <Button>
//             <span>Discover</span>
//           </Button> */}
//         {/* </TopSection> */}

//         <MenuContainer>
//           <SubMenuContainer>
//             {data.map((item) => {
//               if (item.code === "001") {
//                 return (
//                   <LeftChild
//                     width="65%"
//                     onClick={() => (
//                       navigation("/typeofsale"),
//                       localStorage.setItem("homeid", JSON.stringify(item?.code)) //themelist code
//                       // localStorage.setItem('isNotFirst', false)
//                     )}
//                   // background="linear-gradient(270deg, #03EFFE 0%, #4FADFE 100%)"
//                   >
//                     <img src={item.image} alt="shopping" />
//                     <h3>{item.layoutname}</h3>
//                   </LeftChild>
//                 );
//               }
//             })}
//             <ChildWrapper width="35%">
//               {data.map((item) => {
//                 if (item.code === "003") {
//                   return (
//                     <SubChild
//                       primary
//                       onClick={() => { navigation("/quiz"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
//                     // background="linear-gradient(358.66deg,rgba(0, 0, 0, 0.5) 1.84%, rgba(0, 0, 0, 0) 99.39%)"
//                     >
//                       <h3>{item.layoutname}</h3>
//                       <img src={item.image} alt="quiz" />
//                     </SubChild>
//                   );
//                 }
//                 if (item.code === "011") {
//                   return (
//                     <SubChild
//                     // background="linear-gradient(244.64deg,rgba(19, 84, 122, 0.93) -5.02%,rgba(61, 162, 150, 0.79) 52.5%)"

//                     >
//                       <h3>{item.layoutname}</h3>
//                       <img src={item.image} alt="purchase-history" />
//                     </SubChild>
//                   );
//                 }
//               })}
//             </ChildWrapper>
//           </SubMenuContainer>

//           <MiddleMenuContainer>
//             {data.map((item) => {
//               if (item.code === "002") {
//                 return (
//                   <LeftChild
//                     width="35%"
//                     onClick={() => {
//                       navigation("/service"); localStorage.setItem("homeid", JSON.stringify(item?.code))
//                     }
//                     }
//                   // background="linear-gradient(90deg, #F55D7A 0%, #F091F5 91.67%)"
//                   >
//                     <img src={item.image} alt="service" />
//                     <h3>{item.layoutname}</h3>
//                   </LeftChild>
//                 );
//               }
//             })}


//             <ChildWrapper width="28%">
//               {data.map((item) => {
//                 if (item.code === "004") {
//                   return (
//                     <SubChild
//                       primary
//                       onClick={() => { navigation("/auction"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
//                     // background="linear-gradient(262.65deg, rgba(240, 152, 25, 0.22) 15.97%, rgba(234, 70, 70, 0.83) 99.17%), linear-gradient(270deg, rgba(0, 0, 0, 0.2) -0.08%, rgba(0, 0, 0, 0) 56.09%)"
//                     >
//                       <img src={item.image} alt="bid" />
//                       <h3>{item.layoutname}</h3>
//                     </SubChild>
//                   );
//                 }
//                 if (item.code === "005") {
//                   return (
//                     <SubChild
//                       onClick={() => { navigation("/exchange"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
//                     // background="linear-gradient(256.17deg, rgba(48, 207, 208, 0.74) 12.35%, rgba(117, 19, 236, 0.39) 98.93%)"
//                     >
//                       <img src={item.image} alt="exchange" />
//                       <h3>{item.layoutname}</h3>
//                     </SubChild>
//                   );
//                 }
//               })}
//             </ChildWrapper>

//             <ChildWrapper width="35%">
//               {data.map((item) => {
//                 if (item.code === "007") {
//                   return (
//                     <SubChild
//                       primary
//                       onClick={() => { navigation("/rewards"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
//                     // background="linear-gradient(0deg, rgba(0, 0, 0, 0.5) -0.11%, rgba(0, 0, 0, 0) 100%)"
//                     >
//                       <img src={item.image} alt="rewards" />
//                       <h3>{item.layoutname}</h3>
//                     </SubChild>
//                   );
//                 }
//                 if (item.code === "008") {
//                   return (
//                     <SubChild
//                       primary
//                       onClick={() => { navigation("/orderHistory"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
//                     // background="linear-gradient(246.46deg, rgba(13, 53, 132, 0.38) 29.19%, rgba(103, 136, 203, 0.42) 86.54%), linear-gradient(0deg, rgba(0, 0, 0, 0.5) -0.11%, rgba(0, 0, 0, 0) 100%)"
//                     >
//                       <img src={item.image} alt="history" />
//                       <h3>{item.layoutname}</h3>
//                     </SubChild>
//                   );
//                 }
//               })}
//             </ChildWrapper>
//           </MiddleMenuContainer>

//           <BottomSubMenuContainer>
//             {data.map((item) => {
//               if (item.code == "010") {
//                 return (
//                   <SubChild
//                     width="35%"
//                     onClick={() => { navigation("/profile"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
//                     // background="linear-gradient(90deg, #F55D7A 0%, #F091F5 91.67%)"
//                     bottom
//                   >
//                     <img src={item.image} alt="profile" />
//                     <h3>{item.layoutname}</h3>
//                   </SubChild>
//                 );
//               }
//             })}
//             {data.map((item) => {
//               if (item.code === "006") {
//                 return (
//                   <SubChild
//                     width="28%"
//                     primary
//                     onClick={() => { navigation("/search"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
//                     // background="linear-gradient(90deg, rgba(0, 0, 0, 0.29) 1.15%, rgba(0, 0, 0, 0) 49.89%)"
//                     bottom
//                   >
//                     <img src={item.image} alt="search" />
//                     <h3>{item.layoutname}</h3>
//                   </SubChild>
//                 );
//               }
//             })}
//             {data.map((item) => {
//               if (item.code == "009") {
//                 return (
//                   <SubChild
//                     width="35%"
//                     onClick={() => { navigation("/notifications"); localStorage.setItem("homeid", JSON.stringify(item?.code)) }}
//                     // background="linear-gradient(246.46deg, rgba(13, 53, 132, 0.76) 29.19%, rgba(103, 136, 203, 0.84) 86.54%)"
//                     bottom
//                   >
//                     <img src={item.image} alt="notification" />
//                     <h3>{item.layoutname}</h3>
//                   </SubChild>
//                 );
//               }
//             })}
//           </BottomSubMenuContainer>
//         </MenuContainer>
//         <TopSection>
//           <p>{quote}</p>
//         </TopSection>
//       </HomeContainer>
//     </>
//   );
// }

// export default Home;