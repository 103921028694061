import styled from "styled-components";
import Colors from "../../consts/Colors"
import Fonts from "../../consts/Font";
import { fonts } from "../../consts/themes";

export const Assistancemain = styled.div`
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100vh;
backdrop-filter: blur(0.5px);
opacity: 5;

@media(max-width:2560px)
{
     backdrop-filter: blur(1px);
}
@media(max-width:1440px)
{
    

    backdrop-filter: blur(0.5px);
}
`
export const Close = styled.div`

display:flex;
justify-content:flex-end;
margin-inline: 20px;
cursor:pointer;

`
export const DIv = styled.div `
width:100%;
cursor:pointer;
align-items:flex-end;
display:flex;
justify-content:flex-end;
// background-color:blue;
`

export const Assistancecontentcontainer = styled.div`

position: absolute;
top: 150px;
right: calc(50% - 160px);
padding: 20px;
border-radius:5px;
width:25%;
height:25%;
background: ${Colors.white};

@media(max-width:2560px)
{
    right: calc(50% - 250px); 
    top:13vw;
}
@media(max-width:1440px)
{
    right: calc(50% - 160px);
    top: 150px;
}
@media(max-width:1024px)
{
    right: calc(50% - 100px);
}
@media(max-width:768px)
{
    width:30%;
    right: calc(50% - 130px);
}
@media(max-width:425px)
{
    right: calc(50% - 100px);
    width:45%;
    
}
@media(max-width:375px)
{
    width:50%;
}
@media(max-width:320px)
{
    width:55%;
    right: calc(50% - 110px);
}

`
export const Assistancecontent = styled.div`
padding:2rem 2rem 3rem;
align-items:center;
img
{
    justify-content: center;
    align-self: center;
    text-align: center;
    margin-inline: 9rem;
    width: 10vw;
    height: auto;

    @media(max-width: 1440px)
    {
        width:15vw;
    }
    @media(max-width: 1024px)
    {
        width:20vw;
    }
    @media(max-width:425px)
    {
        width: 28vw;
    }
}
@media(max-width: 500px)
{
    width:100%;
    text-align:center;

    img{
        margin-inline: 0rem;
    }
}

p
{
    color :${Colors.black};
    font-size:18px;
    font-weight: ${fonts.fontWeight5};
    margin-top: 20px;
    text-align: center;
}
`
export const Assistancebutton = styled.div`

display:flex;
flex-direction:row;
justify-content:center;
column-gap:50px;
margin-top:30px;

@media(max-width:768px)
{
    column-gap:30px;
}

@media(max-width:425px)
{
    flex-direction:column;
    column-gap:10px;
    
}

`
export const Buttonone = styled.button`

border:1px solid blue;
background: ${Colors.white};
border-radius:8px;
width: 9vw;
    height: 2vw;
    padding-inline: 10px;
    font-size: 12px;

    @media(max-width:1440px)
{
    width: 13vw;
    height: 3vw;
}
@media(max-width:1024px)
{
    width: 17vw;
    height: 4vw;

}
@media(max-width:768px)
{
    width:25vw;
    height: 6vw;
}
@media(max-width:425px)
{
    align-self: center;
    width: 50vw;
    height: 10vw;
    margin-block: 1rem;
}
@media(max-width:350px)
{
    align-self: center;
    width: 55vw;
    height: 12vw;
}
`
export const Buttontwo = styled.button`
width:9vw;
height:2vw;
font-size:12px;
border-radius:8px;
border:1px solid white;
color: ${Colors.white};
background:${Colors.royalblue};

@media(max-width:1440px)
{
    width: 13vw;
    height: 3vw;
}
@media(max-width:1024px)
{
    width: 17vw;
    height: 4vw;
}
@media(max-width:768px)
{
    width:25vw;
    height: 6vw;
}
@media(max-width:425px)
{
    align-self: center;
    width: 50vw;
    height: 10vw;
}
@media(max-width:350px)
{
    align-self: center;
    width: 55vw;
    height: 12vw;
}
`



