import styled from 'styled-components';
import { IoClose } from "react-icons/io5";
import { Container, Head1, InnerConatiner, } from '../TypeModalStyle';
import { CloseContainer } from '../ModalStyled';
import { ModalContainer, Modal, Overlay } from "../../modal"

const BulkEnquiryModal = ({ data, close }) => {
  return (
    <ModalContainer>
      <Modal Modal>
        <Overlay>
          <Container>
            <CloseContainer>
              <IoClose size={22} onClick={() => close(false)} />
            </CloseContainer>
            <InnerConatiner>
              <Head1>Customer Enquiry Details</Head1>
              <ItemContainer>
                <ItemDec>{data}</ItemDec>
              </ItemContainer>
            </InnerConatiner>
          </Container>
        </Overlay>
      </Modal>
    </ModalContainer>
  )
}

export default BulkEnquiryModal;




const ItemContainer = styled.div`
display: flex;
width: 100%;
justify-content: center;
align-items: center;
gap:1.5rem;
flex-wrap: wrap;
height: fit-content;
max-height: 240px;
overflow:scroll;
`;


const ItemDec = styled.div`
font-size: 0.9rem;
font-weight: 300;
`;



