
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import logo from "../../../assets/Images/png/logoHeaderNew.png";
import image1 from "../../../assets/Images/png/Image1.png";
import { colors, fonts } from '../../../consts/themes';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { PurchaseType, getProfession, getTypeOfOrganisationById, insertCustomers, userLogin } from '../../../api/api';
import { AuthContext } from '../../../consts/AuthContext';
import { MdDelete } from "react-icons/md";
import LoadingSpinner from '../../Button/Loading/LoadingSpinner';
import ErrorComponent from '../../Button/error/ErrorComponent';
import "react-datepicker/dist/react-datepicker.css";
import Swal from 'sweetalert2';
import SignUpTandC from '../../Modals/SignUpTandC';
// import { Datepicker } from "flowbite-react";
import { DatePicker } from "antd";



const SignUp = () => {

  const navigation = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [formattedDate, setFormattedDate] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [mobError, setMobError] = useState('');
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [termsModal, setTermsModal] = useState(false);

  const isLoggedIn = () => {
    return !!localStorage.getItem('token');
  };

  useEffect(() => {
    if (isLoggedIn()) {
      navigation('/');
    }
  }, [navigation]);


  const [userDetails, setUserDetails] = useState({
    mob1: "",
    name: "",
    pincode: "",
    district: "",
    state: "",
    dob: "",

    profession: '',
    organization_name: '',
    type_of_organization: '',
    designation: '',

    street_name: '',
    house_name: '',
    gst: '',
    type_name: '',
  })


  const initialValues = {
    phonenumber: "",
    orgname: "",
    yourname: "",
    desi: "",
    pincode: "",
    district: "",
    dob: "",
    organisationname: "",
    phonenumber2: "",
    yourname2: "",
  };


  const [segment, setSegment] = useState([]);
  const [selectedSegmentId, setSelectedSegmentId] = useState(null);
  const [formValues, setFormValues] = useState(initialValues);
  const [profession, setProfesssion] = useState()
  const [typeOfOraganization, setTypeOfOrganization] = useState([]);
  const [district, setDistrict] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState({});
  const [formErrors, setFormErrors] = useState({});


  //name handler
  // const handleNameChange = (event) => {
  //   const { value } = event.target;
  //   if (value.length <= 25) {
  //     setName(value);
  //     setNameError('');
  //   } else {
  //     setName('');
  //     setNameError('Name must be 25 characters or less');
  //   }
  // };


  const handleNameChange = (e) => {
    const value = e.target.value;
    if (/^[A-Za-z\s]*$/.test(value) && value.length <= 25) {
      setName(value);
      if (value.length > 0 && value.length < 3) {
        setNameError('Name should be between 3 to 25 characters');
      } else {
        setNameError('');
      }
    }
  };


  const userDetailChange = (name, value) => {
    setUserDetails((prev) => ({ ...prev, [name]: value }));
  };



  // pincode change
  const handlePincode = (e) => {
    const { name, value } = e.target;
    setUserDetails((prev) => ({ ...prev, [name]: value }));

    if (name === "pincode" && value.length === 6) {
      fetch(`https://api.postalpincode.in/pincode/${value}`).then(response => response.json()).
        then(response => {
          if (response[0]?.Status == "Success") {
            setUserDetails((prev) => ({
              ...prev,
              "district": response[0]?.PostOffice[0]?.District,
              "state": response[0]?.PostOffice[0]?.State
            }));
          }
        })
    }
  }



  // getting segment details
  const getSegmentType = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${PurchaseType}`);
      // console.log(response.data, " response of purchase type")
      setSegment(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }


  //getting tpe of profession
  const typeOfProfession = async () => {
    try {
      const response = await axios.get(getProfession);
      setProfesssion(response?.data);
    } catch (error) {
      console.error('Error fetching data:', error.message);
    }
  }



  // getting Type Of Organization
  useEffect(() => {
    const getTypeOfOrganization = async () => {
      try {
        if (selectedSegmentId === '2' || selectedSegmentId === '3' || selectedSegmentId === '20') {
          const response = await axios.get(`${getTypeOfOrganisationById}/${selectedSegmentId}`);
          setTypeOfOrganization(response.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    getTypeOfOrganization();
  }, [selectedSegmentId]);





  const handleChange = (e) => {

    let distr = district

    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    if (name == 'pincode' && value.length == 6) {
      fetch(`https://api.postalpincode.in/pincode/${value}`)
        .then(response => response.json())
        .then(response => {
          // console.log("Res", response);
          if (response[0]?.Status == "Success") {
            // console.log("state=>", `https://api.postalpincode.in/pincode/${value}`);
            setFormValues((prev) => ({
              ...prev,
              "district": response[0]?.PostOffice[0]?.District,
              "state": response[0]?.PostOffice[0]?.State
            }))

            const dist = distr?.filter(item => item?.district_name === response[0]?.PostOffice[0]?.District)
            // console.log("DDDD", dist);
            setSelectedDistrict(dist)
          } else {
            alert("Enter a valid pincode")
          }
        })
    }

  };







  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };


  const handleDateChange = (date) => {
    if (date) {
      // Safely access date.$d only if date is not null
      const selectedDate = date.$d;
      setStartDate(selectedDate);

      // Format the date if it's defined
      const formatted = formatDate(selectedDate);
      setFormattedDate(formatted);
      console.log("Formatted date:", formatted);
    } else {
      // Handle the case when date is cleared
      setStartDate(null);
      setFormattedDate('');
      console.log("Date cleared");
    }
  };




  // secondary user section
  const [list, setList] = useState([
    {
      name: '',
      mob: '',
      mobError: '',
      nameError: ''
    }
  ]);

  const addRow = () => {
    const newList = [...list];
    newList.push({ name: '', mob: '', mobError: '', nameError: '' });
    setList(newList);
  };

  const removeRow = (key) => {
    const newList = [...list];
    newList.splice(key, 1);
    setList(newList);
  };


  // primary mob handler
  const handlePrimaryChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 10) {
      setMobileNumber(value);
    }
  };

  // primary mob validation
  const validatePrimaryMobileNumber = () => {
    let error = '';
    if (mobileNumber.length > 0 && mobileNumber.length < 10) {
      error = 'Invalid mobile number';
    }
    if (mobileNumber.length === 10) {
      list.forEach((item) => {
        if (item.mob.length === 10 && item.mob === mobileNumber) {
          error = 'Duplicate mobile number';
        }
      });
    }
    setMobError(error);
  };


  // secondary user handler
  const handleSecondaryChange = (key, e) => {
    const { name, value } = e.target;
    const newList = [...list];
    if (name === 'mob') {
      if (/^\d*$/.test(value) && value.length <= 10) {
        newList[key][name] = value;
        newList[key].mobError = '';
      }
    } else if (name === 'name') {
      if (/^[A-Za-z\s]*$/.test(value) && value.length <= 25) {
        newList[key][name] = value;
        newList[key].nameError = value.length > 0 && value.length <= 3 ? 'Name should be more than 3 characters' : '';
      }
    }
    setList(newList);
    validateSecondaryMobileNumbers(); // Trigger validation on change
  };



  // secondary user validation
  const validateSecondaryMobileNumbers = () => {
    const newList = [...list]; // Create a copy of the current list
    let listChanged = false; // Flag to track if the list has changed

    // Iterate over each item in the newList
    newList.forEach((item, index) => {
      let mobError = '';
      let nameError = '';

      // Validation logic for each item in the list
      if (item.mob.length > 0 && item.mob.length < 10) {
        mobError = 'Invalid mobile number';
      } else if (item.mob.length === 10) {
        if (item.mob === mobileNumber) {
          mobError = 'Duplicate mobile number';
        } else {
          newList.forEach((innerItem, innerIndex) => {
            if (innerIndex !== index && innerItem.mob.length === 10 && innerItem.mob === item.mob) {
              mobError = 'Duplicate mobile number';
            }
          });
        }
        if (!item.name) {
          nameError = 'Name is required';
        }
      }

      if (!item.mob && item.name) {
        mobError = 'Phone number is required';
      }

      if (item.name.length > 0 && item.name.length <= 3) {
        nameError = 'Name should be more than 3 characters';
      }

      if (item.name.length > 25) {
        nameError = 'Name should be less than 25 characters';
      }

      // Update error fields for the current item
      newList[index].mobError = mobError;
      newList[index].nameError = nameError;

      // Check if the item has changed compared to the current list
      if (JSON.stringify(newList[index]) !== JSON.stringify(list[index])) {
        listChanged = true; // Set flag to true if the item has changed
      }
    });

    // Update the list state only if it has changed
    if (listChanged) {
      setList(newList);
    }
  };


  useEffect(() => {
    validatePrimaryMobileNumber();
    validateSecondaryMobileNumbers();
  }, [mobileNumber, list]);



  //submit section
  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(userDetails));
  };


  const validate = (values) => {
    const errors = {};

    // Primary user validation
    if (!mobileNumber) {
      setMobError("Mobile Number is required!");
    }

    if (!name) {
      setNameError("Name is required!");
    }

    if (!values.pincode) {
      errors.pincode = "Pincode is required!";
    }

    if (!values.district) {
      errors.district = "District is required!";
    }

    if (!values.house_name) {
      errors.house_name = "House name is required!";
    }

    if (!values.street_name) {
      errors.street_name = "Street name is required!";
    }


    if (!values.type_name) {
      errors.type_name = "Address type is required!";
    }

    if (!formattedDate) {
      errors.dob = "Date of Birth is required!";
    }

    if (!selectedSegmentId) {
      errors.segment = "Segment is required!";
    }

    if (selectedSegmentId === '1' && !values.profession) {
      errors.profession = "Profession is required!";
    }

    if ((selectedSegmentId === '2' || selectedSegmentId === '3' || selectedSegmentId === '20') && !values.type_of_organization) {
      errors.type_of_organization = "Type of organization is required!";
    }

    if ((selectedSegmentId === '2' || selectedSegmentId === '3' || selectedSegmentId === '20') && !values.organization_name) {
      errors.organization_name = "Organization name is required!";
    }

    if ((selectedSegmentId === '2' || selectedSegmentId === '3' || selectedSegmentId === '20') && !values.designation) {
      errors.designation = "Designation name is required!";
    }


    const hasPrimaryUserError = !mobileNumber || !name || !values.pincode || !values.district || !values.house_name || !values.street_name ||
      !formattedDate || !selectedSegmentId || (selectedSegmentId === '1' && !values.profession) ||
      ((selectedSegmentId === '2' || selectedSegmentId === '3' || selectedSegmentId === '20') && (!values.type_of_organization ||
        !values.organization_name || !values.designation));

    // Secondary user validation
    if (list.some(item => item.mobError || item.nameError)) {
      return;
    } else if (nameError !== '') {
      return;
    }
    else if (!hasPrimaryUserError) {
      newUserSignUp();
    }
    return errors;
  };



  //  const handleSubmit = () => {
  //     validatePrimaryMobileNumber();
  //     validateSecondaryMobileNumbers();
  //     if (primaryMobileError || list.some(item => item.mobError || item.nameError)) {
  //       // Errors are present, they will be displayed by the state updates
  //       return;
  //     }
  //     // Proceed with form submission logic
  //     alert('Form submitted successfully!');
  //   };








  const newUserSignUp = async () => {
    // Create a copy of list state without the error key-value pair
    const cleanedList = list.map(({ mobError, nameError, ...rest }) => rest);

    // Check if all items in the cleanedList have empty name and mob
    const isAllEmpty = cleanedList.every(item => item.name === '' && item.mob === '');

    try {
      setLoading(true);
      const formData = {
        mob1: mobileNumber,
        name: name,
        pincode: userDetails.pincode,
        district: userDetails.district,
        dob: formattedDate,
        state: userDetails.state,
        profession: userDetails.profession,
        organization_name: userDetails.organization_name,
        designation: userDetails.designation,
        type_of_organization: userDetails.type_of_organization,
        gst: userDetails.gst,
        street_name: userDetails.street_name,
        house_name: userDetails.house_name,
        type_name: userDetails.type_name,
        mob2: isAllEmpty ? JSON.stringify([]) : JSON.stringify(cleanedList),
      };

      const options = {
        method: "POST",
        url: insertCustomers,
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };

      const response = await axios.request(options);

      if (response.data.code === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          color: '#4169e1',
          text: 'Account Created Successfully',
          showConfirmButton: false,
          timer: 1500
        });
        navigation('/login');
      } else if (response.data.code === 401) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          color: '#4169e1',
          text: `${response.data.message}`,
        });
        setCheck(0);
        setUserDetails({
          mob1: "",
          name: "",
          pincode: "",
          district: "",
          state: "",
          dob: "",
          profession: '',
          organization_name: '',
          type_of_organization: '',
          designation: '',
        });
        setMobileNumber('');
        setName('')
        setSelectedSegmentId(null);
        setList([
          {
            name: '',
            mob: '',
            mobError: '',
            nameError: ''
          }
        ])
      }

    } catch (error) {
      setError(error);
      console.log("🚀 ~ update cart ", error.response.data.message);
    } finally {
      setLoading(false);
    }
  };


  const [check, setCheck] = useState(0);

  function handleclick(value) {
    setCheck(value);
    check === value && setCheck(0);
  }


  useEffect(() => {
    getSegmentType();
    typeOfProfession();
  }, [])


  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }


  return (

    <Screen>
      {termsModal && <SignUpTandC close={setTermsModal} />}
      <Container>
        <LeftContainer>
          <ImageSection>
            <InputSec>
              <ImgSecLogo>
                <img src={logo} alt="logo" />
              </ImgSecLogo>
              <ImgSecImg >
                <img src={image1} alt='login-cover' />
              </ImgSecImg>
            </InputSec>
            <AccSec gp={"0.2rem"}>
              <ImgSecText fs={"1rem"} fw={"500"}>Login to Explore the clikekart World</ImgSecText>
              <ImgSecText fs={"0.8em"} fw={"300"}>rem ipsum dolor sit amet, consectetur adipiscing elit,</ImgSecText>
            </AccSec>
          </ImageSection>
        </LeftContainer>

        <RightContainer>
          <LoginSection>
            <InputSec>
              <LoginText fw={"600"} fs={"1rem"}>Sign Up</LoginText>
              <LoginText mt={"0.5rem"} fw={"600"} fs={"0.8rem"} clr={"#00000080"}>Enter details to continue</LoginText>



              <InputWraper>

                <Input>
                  <input
                    type="number"
                    placeholder='Mob Number'
                    id="mobileNumber"
                    name="mobileNumber"
                    value={mobileNumber}
                    onChange={handlePrimaryChange}
                  />
                  {mobError && <Error>{mobError}</Error>}
                </Input>

                <Input>
                  <input
                    type="text"
                    placeholder='Enter your name'
                    name="name"
                    value={name}
                    onChange={handleNameChange}
                  />
                  {nameError && <Error>{nameError}</Error>}
                </Input>



                <Input>
                  <input
                    type="text"
                    placeholder="House Name"
                    name=" house_name"
                    value={userDetails.house_name}
                    onChange={(e) => { userDetailChange('house_name', e.target.value) }}
                  />
                  {formErrors?.house_name && <Error>{formErrors?.house_name}</Error>}
                </Input>

                <Input>
                  <input
                    type="text"
                    placeholder="Street Name"
                    name="street_name"
                    value={userDetails.street_name}
                    onChange={(e) => { userDetailChange('street_name', e.target.value) }}
                  />
                  {formErrors?.street_name && <Error>{formErrors?.street_name}</Error>}
                </Input>


                <Input>
                  <input
                    type="text"
                    placeholder="gst"
                    name="gst"
                    value={userDetails.gst}
                    onChange={(e) => { userDetailChange('gst', e.target.value) }}
                  />
                  {formErrors?.gst && <Error>{formErrors?.gst}</Error>}
                </Input>



                <Input>
                  <input
                    type="text"
                    placeholder="Address type"
                    name="type_name"
                    value={userDetails.type_name}
                    onChange={(e) => { userDetailChange('type_name', e.target.value) }}
                  />
                  {formErrors?.type_name && <Error>{formErrors?.type_name}</Error>}
                </Input>



                <Input>
                  <input
                    type="number"
                    placeholder='Pincode'
                    name="pincode"
                    value={userDetails?.pincode}
                    onChange={handlePincode}
                  />
                  {formErrors?.pincode && <Error>{formErrors?.pincode}</Error>}
                </Input>


                <Input>
                  <input
                    type="text"
                    placeholder='District'
                    name="district"
                    value={userDetails?.district}
                    disabled={true}
                  />
                  {formErrors?.district && <Error>{formErrors?.district}</Error>}
                </Input>




                {/* <Input>
                  <input
                    type="date"
                    placeholder='Pincode'
                    value={userDetails.dob}
                    onChange={(e) => { userDetailChange('dob', e.target.value) }}
                  />
                  {formErrors?.dob && <Error>{formErrors?.dob}</Error>}
                </Input> */}


                {/* <In>
                  <DateContainer>
                    <DateInput>
                      <DatePicker
                        selected={startDate}
                        placeholderText="Date of Birth"
                        onChange={handleDateChange}
                        dateFormat="dd/MM/yyyy"
                        dayClassName={() => "example-datepicker-day-class"}
                        popperClassName="example-datepicker-class"
                      />
                    </DateInput>

                    <Icon>
                      <BsCalendar2Week size={16} />
                    </Icon>
                  </DateContainer>
                  {formErrors?.dob && <Error>{formErrors?.dob}</Error>}
                </In> */}

                <DatePicker
                  placeholder="Date of Birth"
                  className="custom-datepicker"
                  onChange={handleDateChange}
                  selected={startDate}
                  needConfirm
                />
                <DetailsDropDown>
                  <select onChange={(e) => {
                    setSelectedSegmentId(e.target.value);
                    setUserDetails((prev) => ({
                      ...prev,
                      profession: '',
                      organization_name: '',
                      type_of_organization: '',
                      designation: '',
                    }))
                  }}>
                    <option value="" >Choose Your Segement</option>
                    {segment?.map((item) => {
                      return (
                        <>
                          <option value={item?.id} >
                            {item?.type_name}
                          </option>
                        </>
                      );
                    })}
                  </select>
                  {formErrors?.segment && <Error>{formErrors?.segment}</Error>}
                </DetailsDropDown>


                {selectedSegmentId === `1` &&
                  <DetailsDropDown >
                    <select onChange={(e) => setUserDetails((prev) => ({ ...prev, "profession": e.target.value }))}>
                      <option value="">Type of Profession</option>

                      {profession?.map((item) => {
                        return (
                          <>
                            <option value={item?.profession_name} onChange={handleChange}>
                              {item?.profession_name}
                            </option>

                          </>
                        );
                      })}
                    </select>
                    {formErrors?.profession && <Error>{formErrors?.profession}</Error>}
                  </DetailsDropDown>
                }


                {(selectedSegmentId === `2` || selectedSegmentId === `3` || selectedSegmentId === `20`) && (
                  <>
                    <DetailsDropDown style={{ outlineColor: "rgba(0, 0, 0, 0.5)" }}>

                      <select onChange={(e) => setUserDetails((prev) => ({ ...prev, "type_of_organization": e.target.value }))}>
                        <option value="">Type of Organization</option>

                        {typeOfOraganization?.map((item) => {
                          return (
                            <>
                              <option value={item?.organisation_name} onChange={handleChange}>
                                {item?.organisation_name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                      {formErrors?.type_of_organization && <Error>{formErrors?.type_of_organization}</Error>}
                    </DetailsDropDown>
                  </>
                )}


                {(selectedSegmentId === `2` || selectedSegmentId === `3` || selectedSegmentId === `20`) && (
                  <>
                    <Input>
                      <input
                        type="text"
                        placeholder="Organization Name"
                        name="organization_name"
                        value={userDetails.organization_name}
                        onChange={(e) => { userDetailChange('organization_name', e.target.value) }}
                      />
                      {formErrors?.organization_name && <Error>{formErrors?.organization_name}</Error>}
                    </Input>

                    <Input>
                      <input
                        type="text"
                        placeholder="Enter your Designation"
                        name="designation"
                        value={userDetails.designation}
                        onChange={(e) => { userDetailChange('designation', e.target.value) }}
                      />
                      {formErrors?.designation && <Error>{formErrors?.designation}</Error>}
                    </Input>
                  </>
                )}


                <Resend mt={"1rem"}><span>Secondary users details</span></Resend>

                {list?.map((item, key) => (
                  <div key={key}>
                    <SecUserWrap>
                      <SecUserIcon> <MdDelete size={18} onClick={() => removeRow(key)} /></SecUserIcon>
                      {/* <Input>
                        <input
                          type="number"
                          placeholder="Phone number"
                          name="phonenumber2"
                          value={item?.mob}
                          onChange={(e) => handleSecondaryChange(key, e)}
                        />
                        {item.mobError && <Error>{item.mobError}</Error>}

                      </Input>

                      <Input>
                        <input
                          type="text"
                          placeholder="Enter name"
                          name="yourname2"
                          value={item?.name}
                          onChange={(e) => handleSecondaryChange(key, e)}
                        />
                        {item.nameError && <Error>{item.nameError}</Error>}
                      </Input> */}



                      <Input error={!!item.mobError}>
                        <input
                          type="number"
                          placeholder="Phone number"
                          name="mob"
                          value={item.mob}
                          onChange={(e) => handleSecondaryChange(key, e)}
                        />
                        <Error>{item.mobError}</Error>
                      </Input>
                      <Input error={!!item.nameError}>
                        <input
                          type="text"
                          placeholder="Enter name"
                          name="name"
                          value={item.name}
                          onChange={(e) => handleSecondaryChange(key, e)}
                        />
                        <Error>{item.nameError}</Error>
                      </Input>
                    </SecUserWrap>
                  </div>
                ))}
                <Resend><span onClick={() => addRow()}>Add more users details +</span></Resend>
              </InputWraper>




              <LogSec>
                <LoginText fw={"600"} fs={"0.75rem"} clr={"#00000080"}>Already have a clikekart account.?
                  <Span clr={"#5173EA"} onClick={() => navigation('/login')}> Log in </Span> </LoginText>

              </LogSec>
              <Accept>
                <input type='checkbox' onClick={() => handleclick(1)} />
                <LoginText fw={"600"} fs={"0.75rem"} clr={"#00000080"}>By creating an account  or logginig in, you agree to clikekart
                  <Span clr={"#5173EA"} onClick={() => setTermsModal(true)}> Terms and Conditions</Span> </LoginText>
              </Accept>


              {/* <AccSec gp={"1rem"}>
                <LoginText fw={"600"} fs={"0.75rem"} clr={"#00000080"}>By creating an account  or logginig in, you agree to clikekart
                  <Span clr={"#5173EA"}> condition of use </Span> and  <Span clr={"#5173EA"}>privacy policy</Span> </LoginText>
              </AccSec> */}

              <SubmitBtn disabled={check === 0} onClick={handleSubmit}><button disabled={check === 0} >Sign up</button></SubmitBtn>
            </InputSec>


          </LoginSection>
        </RightContainer>
      </Container>
    </Screen>
  )
}

export default SignUp;






const Accept = styled.div`
margin-top: 0.5rem;
      display:flex;
gap:0.5rem;
width:100%;
      align-items:center;
        `;


const DateContainer = styled.div`
  display: flex;
  align-items: center;
  height: 2.5rem;
  border: 0.1px solid ${colors.line};
  border-radius: 5px;
  width:100%;
`;

const DateInput = styled.span`
width: 100%;
input{
width:calc(100% + 12px);
height:2.4rem;
border: none;
background: transparent;
z-index: 1;
padding-left: 0.5rem;
}
.react-datepicker-wrapper{
  width: 100%;
}


textarea:focus,
input:focus {
  outline: none;
}

`;

const Icon = styled.span`
  margin-right: 0.5rem;
`;


const In = styled.div`
width:100%;
`


const SecUserIcon = styled.div`
display: flex;
justify-content: flex-end;
color:#4669E8;
`;



const SecUserWrap = styled.div`
display: flex;
flex-direction: column;
gap:0.5rem;
`;



const Resend = styled.div`
margin-top: ${(props) => props.mt};
width:100%;
span{
  color:#4669E8;
  font-size: 0.8rem;
  font-weight: 500;
}
`;



const InputWraper = styled.div`
margin-top: 1rem;
display:flex;
flex-direction:column;
gap:0.5rem;
width:100%;
`;





const Screen = styled.div`
  /* position: absolute; */
  /* top: calc(2.5rem + 50%); */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  max-width: 100%;
  overflow: auto;
  /* max-height: calc(100% - 5rem); */
`;


const Container = styled.div`
margin:1rem;
display: flex;
justify-content: center;
padding-top: 3rem;
padding-bottom: 3rem;
/* height:530px;
@media only screen and (max-width: 1023px) {
  height:370px;
} */

height: fit-content;
`;

const LeftContainer = styled.div`
border-top-left-radius: 15px;
border-bottom-left-radius: 15px;
background: #4669E8;
width:380px;
padding:1.5rem;
@media only screen and (max-width: 1023px) {
display: none;
}
`;


const RightContainer = styled.div`
border:1px solid rgba(0, 0, 0, 0.2);
border-top-right-radius: 15px;
border-bottom-right-radius: 15px;
width:380px;
padding:1.5rem;
@media only screen and (max-width: 1023px) {border-radius: 15px;}
@media only screen and (max-width: 550px) {
width:300px;
margin:1rem;
}
`;

const ImageSection = styled.div`
height:100%;
display: flex;
flex-direction: column;
`;

const ImgSecLogo = styled.div`
width:100%;
display: flex;
justify-content:center;
img{
  width:100px;
  height:35px;
  object-fit: contain;
}
`;

const ImgSecImg = styled.div`
margin-top: 1rem;
width:100%;
display: flex;
justify-content:center;
img{
  width:270px;
  height:300px;
}
`;


const ImgSecText = styled.div`
margin-top: ${(props) => props.mt};
font-size:${(props) => props.fs};
font-weight: ${(props) => props.fw};
color: #FFFFFF;
width:100%;
display: flex;
justify-content:center;
`;

const LoginSection = styled.div`
height:100%;
display: flex;
flex-direction: column;
`;

const InputSec = styled.div`
flex-grow: 1;
display: flex;
flex-direction: column;
align-items: center;
`;

const AccSec = styled.div`
margin-top: 1rem;
display: flex;
flex-direction: column;
align-items: center;
gap:${(props) => props.gp};
`;

const LogSec = styled.div`
margin-top: 1rem;
width:100%;
`;


const LoginText = styled.div`
margin-top:${(props) => props.mt};
font-size:${(props) => props.fs};
font-weight: ${(props) => props.fw};
color:${(props) => props.clr};
`;

const Span = styled.span`
font-size:${(props) => props.fs};
font-weight: ${(props) => props.fw};
color:${(props) => props.clr};
`;



const Input = styled.div`
width:100%;
input{
  box-sizing: border-box;
  width: 100%;
  height: 2.5rem;
  border: 0.1px solid ${colors.line};
  border-radius: 5px;
  padding:0 0.5rem;
  &:focus {
      border-color: #587ef5;
      outline: 0;
    }
   /* Hide spinner controls */
   -moz-appearance: textfield; /* Firefox */
    appearance: none; /* Safari and Chrome */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none; /* Safari */
    }
  }
`




export const DetailsDropDown = styled.div`
width:100%;

select{
  width: 100%;
  height: 2.5rem;
  border: 0.1px solid ${colors.line};
  border-radius: 5px;
  &:focus {
      border-color: #587ef5;
      outline: 0;
    }
    ${'' /* select { */}
        ${'' /* width: 100%; */}
        ${'' /* height: 30px;
        border: 0px solid #c9c7c7;
        border-radius: 8px; */}
        color: #878787;
        font-size: 12px;
        padding: 8px;

        appearance: none;
        background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23000000%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E) !important;
        background-repeat: no-repeat, repeat !important;
        background-position: right 0.7em top 50%, 0 0 !important;
        background-size: 0.65em auto, 100% !important;
    }
`;
























const Error = styled.div`
font-size:12px;
color:red;
`


























const SubmitBtn = styled.div`
margin-top: 0.5rem;
width:100%;
display: flex;
justify-content: center;
button{
  width: 100%;
  padding:0.8rem 0;
  /* background-color:#4669E8; */
   background-color: ${props => props.disabled ? '#D5E2FE' : '#4669E8'};
     cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  color:white;
  border: none;
  border-radius: 5px;
}
`;
