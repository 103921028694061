export const Download = [
    require('../assets/sale/images/Subtract.png'),
];



export const Clikekart = [
    require('../assets/sale/images/clikartImageicon.png'),

];
export const IDData = [
    {
        id: "CLK1736194610",
        name: "Lorem Ipsum",
    },
];
export const date = [
    {
        date: "13/13/2020",
    },
];
export const Address = [
    {
        address: "Lorem ipsum dolor site amet, consectertur adipiscing elite, sed do eiusmod tempor ",
        location: "124691-Lorem Ipsum",
    },
];
export const tabledata = [
    'Item', 'Sl no.', 'Qty', 'warrenty', 'Unit Price', 'Amount',
];
export const TableCont = [
    {

        img: require('../assets/sale/images/Lap.png'),
        qty: "1",
        unit: "239102",
        amount: "239102",
    },
    {

        img: require('../assets/sale/images/clikartImg.png'),
        qty: "1",
        unit: "2000",
        amount: "2000",
    },
    {
        img: require('../assets/sale/images/Mouse1.png'),
        qty: "2",
        unit: "1200",
        amount: "2400",
    },
];

export const Total = [
    '287193',
];
export const delivery = [
    {
        day: '5',
        weak: 'saturday',
        month: 'August',
        date: '20',
        yr: '2022',
    },

];
