import React from "react";
import Spin from "./Spin";
import ZoomInText from "./ZoomInText";
import { useNavigate } from "react-router-dom";
function Index() {
    const navigation = useNavigate();
    const [track, setTrack] = React.useState(false);
    React.useEffect(() => {
        setTimeout(() => {
            setTrack(true);
        }, 3200);
        setTimeout(() => {
            navigation("/sales/productcard");
        },5000 );
    }, []);

    return track ? <ZoomInText /> : <Spin />;
}
export default Index;
