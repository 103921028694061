import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalContainer, Modal, Overlay } from "../../modal"
import { IoClose } from "react-icons/io5";
import { Button, ButtonContainer, CloseContainer, Container, Head1, InnerConatiner } from '../ModalStyled';


const PrinterAccModal = ({ close }) => {
  const navigation = useNavigate();
  return (
    <ModalContainer>
      <Modal Modal>
        <Overlay>
          <Container>
            <CloseContainer>
              <IoClose size={22} onClick={() => close(false)} />
            </CloseContainer>
            <InnerConatiner>
              <Head1>Do you need Accessories ?</Head1>
              <ButtonContainer>
                <Button onClick={() => navigation("/sales/shoppingcart")}>No</Button>
                <Button bg={1} txt={1} onClick={() => navigation('/accessories/home')}>Yes</Button>
              </ButtonContainer>
            </InnerConatiner>
          </Container>
        </Overlay>
      </Modal>
    </ModalContainer >
  )
}

export default PrinterAccModal;

