
import React, { createContext, useEffect, useState } from 'react';
import axiosInstance from './axiosInstance';
import LoadingSpinner from '../components/Button/Loading/LoadingSpinner';

export const AuthContext = createContext();

export const AuthProvider = (props) => {

  const [authUser, setAuthUser] = useState(null);
  const [loading, setLoading] = useState(true)
  const userId = localStorage.getItem("userid");
  const token = localStorage.getItem('token');

  async function getUserInfo() {
    try {
      const response = await axiosInstance.get(`getUser/${userId}`);
      setAuthUser({ user: response?.data });
      setLoading(false)
    } catch (error) {
      if (error.response && error.response.status === 499) {
        handleLogout();
        setLoading(false)
      }
    }
  }


  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userid');
    localStorage.clear();
    window.location.href = '/login';
  };



  useEffect(() => {

    if (userId) {
      getUserInfo();
      setLoading(true)
    } else {
      setAuthUser(null);
      setLoading(false)
    }
  }, [userId, token]);

if(loading){
  return <LoadingSpinner />
}

  return (
    <AuthContext.Provider value={[authUser, setAuthUser]}>
      {props.children}
    </AuthContext.Provider>
  );
};













//old code
// AuthContext.js
// import React, { createContext, useContext, useState, useEffect } from 'react';
// import { getUser } from "../api/api";
// import axios from "axios";
// import axiosInstance from './axiosInstance';

// export const AuthContext = createContext();

// export const AuthProvider = (props) => {
//   const [authUser, setAuthUser] = useState(null);
//   const userId = localStorage.getItem("userid");
//   const token = localStorage.getItem('token');
//   console.log(token, "token of user alexxxx")
//   console.log("userId of alex", userId)

//   async function getUserInfo() {
//     try {
//       // const response = await axios.get(getUser + "/" + userId);
//       const response = await axiosInstance.get(`getUser/${userId}`);
//       setAuthUser({ user: response?.data });
//     } catch (e) {
//       console.error(e);
//     }
//   }

//   // console.log("auth user alex", authUser)

//   useEffect(() => {
//     if (userId) {
//       getUserInfo();
//     } else {
//       setAuthUser(null);
//     }
//   }, [userId]);

//   // const logout = () => {
//   //   localStorage.removeItem("userid");
//   //   setAuthUser(null);
//   // };

//   // value={{ authUser, setAuthUser, logout }}

//   return (
//     <AuthContext.Provider value={[authUser, setAuthUser]}>
//       {props.children}
//     </AuthContext.Provider>
//   );
// };
