import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";
import { useContext } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import AccessoriesCart from "../AccessoriesCart";
import AccessoriesMobileCart from "../AccessoriesMobileCart";
import axiosInstance from "../../../../consts/axiosInstance";
import ErrorComponent from "../../../../components/Button/error/ErrorComponent";
import LoadingSpinner from "../../../../components/Button/Loading/LoadingSpinner";
import { AuthContext } from "../../../../consts/AuthContext";
import PrinterType from "../../../../components/Modal/PrinterType";
import AssistModal from "../../../../components/Modals/AssistModal";
import { colors, fonts } from "../../../../consts/themes";
import { BiRupee } from "react-icons/bi";
import Colors from "../../../../consts/Colors";
import Fonts from "../../../../consts/Font";


const InstallHome = () => {

  const navigation = useNavigate();

  const [authUser, setAuthUser] = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [total, setTotal] = useState(0);








  // for mobile screen
  const [cartVisible, setCartVisible] = useState(false);

  const toggleCart = () => {
    setCartVisible(!cartVisible);
  };



  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024 && cartVisible) {
        setCartVisible(false);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [cartVisible]);




  const categorycolor = JSON.parse(localStorage.getItem("productcode"));
  const pcartpid = JSON.parse(localStorage.getItem("cartpid"));
  const parentProductid = JSON.parse(localStorage.getItem("Productid"));

  const [cartData, setCartData] = useState([]);

  //getting accessories based on parent product - temp cart
  const getCartProducts = async () => {
    try {
      setLoading(true);

      const response = await axiosInstance.get(`productaccessories/${pcartpid}/${parentProductid}`);
      setCartData(response.data.products[0]);
      // setCalcTotal(response?.data?.products)
      setTotal(response?.data?.total_amount);
    } catch (error) {

      setError(error);
    } finally {

      setLoading(false);
    }
  };



  useEffect(() => {
    getCartProducts();
  }, [])





  //remove item from cart only accessory not main product
  async function removeItemFromCart(item) {
    try {
      const formData = {
        cart_id: pcartpid,
        product_id: item.productId,
        total_amount: -(item.qnty * item.comboPrice),
      };
      const options = {
        method: "POST",
        url: 'removeProduct',
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };

      const response = await axiosInstance.request(options);
      console.log("Response:", response.data);

      getCartProducts();

    } catch (error) {
      console.error("Error:", error.message || error);
    }
  }



  // remove accesssories
  const accessoriesRemoveHandler = (item) => {
    console.log(item)
    removeItemFromCart(item);
  }



  // remove parent product
  const parentProductRemoveHandler = (item) => {
    removeItemFromCart(item);
    navigation('/')

  }



  //increase quantity
  async function increaseQuantity(item) {
    try {
      const formData = {
        id: pcartpid,
        product_category_id: item.product_category_id,
        product_id: item.productId,
        amount: item.parent_product_id ? (item.comboPrice) : (item.offer_price),
        total_amount: 1,
        qnty: ++item.qnty,
      };

      const options = {
        method: "POST",
        url: 'changeQuantity',
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };

      const response = await axiosInstance.request(options);
      console.log("Response: for increase quantity", response.data);
      getCartProducts();

    } catch (error) {
      console.error("Error:", error.message || error);
    }
  }





  // decrease quantity
  async function decreaseQuantity(item) {
    try {
      const formData = {
        id: pcartpid,
        product_category_id: item.product_category_id,
        product_id: item.productId,
        amount: item.parent_product_id ? (item.comboPrice) : (item.offer_price),
        total_amount: -1,
        qnty: --item.qnty,
      };

      const options = {
        method: "POST",
        url: 'changeQuantity',
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };

      const response = await axiosInstance.request(options);
      console.log("Response:", response.data);
      getCartProducts();
    } catch (error) {
      console.error("Error:", error.message || error);

    }

  }



  const handleAddQuantity = (item) => {
    if (item?.qnty < 10) {
      increaseQuantity(item);
    }
  };



  const handleRemoveQuantity = (item) => {
    if (item?.qnty > 1) {
      decreaseQuantity(item);
    }
  };













  // new



  const [installss, setInstallss] = useState();
  const [selected, setSelected] = React.useState(false);

  const [select, setSelect] = React.useState(false);
  const [myItemId, setMyItemId] = useState(false);
  const [myItem, setMyItem] = useState(false)
  const [hoverItemId, setHoverItemId] = useState('');
  const [isHovering, setIsHovering] = useState(false);







  //id of lap , desk etc
  const productcategoryid = JSON.parse(localStorage.getItem("productid") || "[]");
  // console.log(productcategoryid, "product category id ")








  const handleCheck = (it) => {
    console.log("alienated pdt", it);

    localStorage.setItem("install", JSON.stringify(it));

    setSelected(it?.id);  //selected install id
    setSelect(it); //selected install item

    it?.id === selected && setSelected(0);
    it?.id === selected && setSelect(0);
  };





  async function Installationss() {
    try {
      const response = await axiosInstance.get('getInstallation');
      setInstallss(response.data);
    } catch (error) {
      console.error("Error fetching installation data:", error);
    }
  }



  const handleInstall = (item) => {

    console.log("jo item", item)

    setMyItem(item);
    setMyItemId(item.id)

    item.id === myItemId && setMyItem(0);
    item.id === myItemId && setMyItemId(0)

  }




  const handleAddtoCart = () => {
    if (myItem) {
      console.log("myItem make api call");
      addinstallwithproduct();
    } else {
      console.log("dont make an api call")
    }
  }




  const handleMouseEnter = (id) => {
    setHoverItemId(id)
    setIsHovering(true);
  }


  const handleMouseLeave = () => {
    setHoverItemId('')
    setIsHovering(false);
  }




  const addinstallwithproduct = async () => {
    try {
      setLoading(true);
      setError(null);


      const response1 = await axiosInstance.get(`productaccessories/${pcartpid}/${parentProductid}`);
      const data1 = response1.data.products[0].qnty;
      // console.log(response1.data.products[0].qnty, " rep 1 data")
      // console.log(data1, 'Data from 1st API call:')

      // Use the data from the first response in the second API call
      const formData = {
        cart_id: pcartpid,
        product_category_id: productcategoryid,
        product_id: parentProductid,
        qnty: data1,
        installation_id: myItem.id,
        installation_amount: myItem.amount,
      };

      const response2 = await axiosInstance.post('addInstallation', formData);
      const data2 = response2.data;
      console.log('Data from second API call:', data2);
    } catch (error) {
      console.error('Error:', error);

      setError('An error occurred during API call.');
    } finally {

      setLoading(false);
      navigation('/sales/shoppingcart')
    }
  };

  useEffect(() => {
    Installationss();
  }, []);

  const nextButtonHandler = () => {
    if (myItem) {
      addinstallwithproduct();
    }
  }

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }



  return (
    <>
      <Pdiv>

        <Cdiv1>



          <CartIcon onClick={toggleCart}>
            <AiOutlineShoppingCart size={24} />
          </CartIcon>


          <Installationcontentleft>
            <Installationtextone>Installation Process</Installationtextone>
            <Installationtexttwo>
              Lorem Ipsum is simply dummy text of the printing and typesetting insustry. Lorem Ipsum has
              been the industry's standard dummy text ever since the 1500s, when an unknown printer took
              a gallery of type and scrambled it to make a type specimen book.It has survived not only
              five centuries, but also the leap in to electronic typesetting,remaining essentially
              unchang.
            </Installationtexttwo>

            <Installationradio>
              <Installationradioleft1>
                {installss?.map((itemssss, i) => {
                  if (!itemssss.status) {
                    return (
                      <>
                        <span onClick={() => handleInstall(itemssss)}
                          onMouseEnter={() => handleMouseEnter(itemssss.id)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <Radioleftcontent>
                            <Radioleftone
                              key={i}
                              onClick={() => handleCheck(itemssss)}
                              style={{
                                border: selected == itemssss?.id && "1px solid #4169e1",
                              }}>
                              <Heading>{itemssss?.title}</Heading>
                              {/* <Text>{itemssss?.description}</Text> */}

                              {isHovering && hoverItemId === itemssss.id && (
                                <BgDiv>
                                  <Heading1>{itemssss?.title}</Heading1>
                                  <Text1>{itemssss?.description}</Text1>
                                </BgDiv>
                              )}

                            </Radioleftone>
                            <Radiolefttwo
                              onClick={() => handleCheck(itemssss)}
                              style={{
                                backgroundColor: selected == itemssss?.id ? "#4169e1" : "",
                                color: selected == itemssss.id ? "#fff" : "#000",
                              }}
                            // key={i}
                            >
                              <Icon>
                                +<BiRupee />
                                {itemssss?.amount}
                              </Icon>
                              <p>(GST excluded)</p>
                            </Radiolefttwo>
                          </Radioleftcontent>
                        </span>
                      </>
                    );
                  }
                })}
              </Installationradioleft1>

            </Installationradio>
          </Installationcontentleft>

        </Cdiv1>


        <Cdiv2>
          <AccessoriesCart
            data={cartData}
            accRemove={accessoriesRemoveHandler}
            parentRemove={parentProductRemoveHandler}
            handleRemoveQuantity={handleRemoveQuantity}
            handleAddQuantity={handleAddQuantity}
            nextHandler={nextButtonHandler}
            total={total}
          />
        </Cdiv2>


        {cartVisible && <AccessoriesMobileCart onClose={toggleCart}
          data={cartData}
          accRemove={accessoriesRemoveHandler}
          parentRemove={parentProductRemoveHandler}
          handleRemoveQuantity={handleRemoveQuantity}
          handleAddQuantity={handleAddQuantity}
          nextHandler={nextButtonHandler}
          total={total}
        />}


      </Pdiv>
    </>

  );
};
export default InstallHome;





const CartIcon = styled.div` 
  cursor: pointer;
  display: none;
  @media only screen and (max-width: 1023px) {
    display: flex;
justify-content: flex-end;
  }
`;

const Pdiv = styled.div`
padding-top: 5rem;
/* height: calc(100vh - 5rem); */
@media only screen and (min-width: 1024px) {
display: grid; 
grid-template-columns: repeat(12, minmax(0, 1fr)); 
}`;

const Cdiv1 = styled.div`
@media only screen and (min-width: 1024px) {grid-column: span 8;}
padding:1rem;
`;

const Cdiv2 = styled.div`
  @media only screen and (max-width: 1023px) {display:none;}
  @media only screen and (min-width: 1024px) {
  grid-column: span 4 / span 4; 
  box-shadow: 0px 7px 30px 0px #00000012;
  }
`;

export const Installationmain = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  backdrop-filter: blur(0.5px);
  opacity: 5;
`;

export const Installationcontainer = styled.div`
  position: absolute;
  top: 40px;
  right: calc(50% - 44vw);
  padding: 20px;
  border-radius: 5px;
  width: 85.3%;
  height: 90%;
  background: ${Colors.white};
  border: 1px solid white;
  border: thin solid #e3e3e3;
  box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 1024px) {
    width: 86.5%;
  }
  @media (max-width: 768px) {
    right: calc(50% - 49vw);
    height: 180%;
  }
  @media (max-width: 425px) {
    height: 180%;
    width: 80%;
    right: calc(50% - 46.5vw);
  }

  @media (max-width: 375px) {
    width: 75%;
    right: calc(50% - 45vw);
    height: 185%;
  }
  @media (max-width: 320px) {
    height: 220%;
    right: calc(50% - 47vw);
  }
`;
export const Installationcontent = styled.div`
  display: flex;
  flex-direction: row;
  // background-color:red;
  /* margin-bottom: -4rem; */
  position: relative;
`;
export const Installationcontentright = styled.div`
  @media (max-width: 768px) {
    width: auto;
    margin-top: 20vw;
    display: flex;
    margin-left: 25vw;
  }

  @media (max-width: 425px) {
    margin-top: 60vw;
    margin-left: 10vw;
  }

  @media (max-width: 375px) {
    margin-left: 4vw;
    margin-top: 80vw;
  }

  @media (max-width: 320px) {
    margin-top: 140vw;
    margin-left: unset;
  }
`;

export const Show = styled.div`
  margin-top: 80px;
  display: flex;
  width: 40%;
  @media (max-width: 1150px) {
    width: 45%;
  }
  @media (max-width: 1040px) {
    width: 55%;
  }
  @media (max-width: 1024px) {
    width: 60%;
  }
  @media (max-width: 900px) {
    display: none;
  }
`;
export const Installationcontentleft = styled.div`
`;

export const Installationtextone = styled.div`
  font-weight: ${fonts.fontWeight5};
  font-size: 18px;
  @media (max-width: 1500px) {
    padding-left: 2rem;
    padding-top: 2rem;
  }

  @media (max-width: 800px) {
    padding-top: 0rem;
  }
  @media (max-width: 500px) {
    padding-left: 2rem;
    padding-top: 0rem;
  }
`;
export const ButtonDiv = styled.div`
  display: none;
  @media (max-width: 900px) {
    display: flex;
    width: 100%;
    justify-content: right;
  }
`;

export const Button = styled.div`
  background-color: ${colors.blue};
  color: white;
  text-align: center;
  font-size: 12px;
  padding: 5px 10px;
  margin: 10px;
  border-radius: 5px;
`;

export const Installationtexttwo = styled.div`
font-size: 12px;
margin-top: 1rem;
`;


export const BgDiv = styled.div`
  align-items: center;
  justify-content: center;
  color: black;
  z-index: 2000;
  position: absolute;
  background-color: white;
  border-radius: 8px;
  text-align: left;
  margin-top: 5px;
  margin-left: 5%;
  width: auto;
  font-size: 12px;
  border-color: ${colors.black};
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  padding: 10px 18px;
  pointer-events: none;
`;

export const Heading = styled.div`
  font-size: 12px;
  margin-inline: 1vw;
  font-weight: ${fonts.fontWeight5};
  @media (max-width: 2560px) {
    font-size: 12px;
  }
  @media (max-width: 1440px) {
    font-size: 12px;
  }
  @media (max-width: 1100px) {
    font-size: 14px;
  }
`;
export const Text = styled.div`
  overflow-wrap: break-word;
  font-size: 12px;
  margin-left: 1vw;
  padding-top: 5px;

  @media (max-width: 1100px) {
    font-size: 12px;
  }
`;
export const Text1 = styled.div`
  overflow-wrap: break-word;
  font-size: 12px;
  /* margin-left:1vw; */
  padding-top: 5px;
  width: 250px;
  @media (max-width: 1100px) {
    font-size: 12px;
  }
`;
export const Heading1 = styled.div`
  font-size: 12px;
  /* margin-inline: 1vw; */
  font-weight: ${fonts.fontWeight5};
  @media (max-width: 2560px) {
    font-size: 12px;
  }
  @media (max-width: 1440px) {
    font-size: 12px;
  }
  @media (max-width: 1100px) {
    font-size: 14px;
  }
`;
export const Radiobutton = styled.div``;
export const Installationradio = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`;

export const Installationradioleft = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 30px;
  display: grid;
  grid-template-columns: auto auto auto;

  h3 {
    margin-top: 3.2rem;
  }
  @media (max-width: 1440px) {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: auto auto;
    width: 60vw;
  }
  @media (max-width: 800px) {
    width: 95vw;
    display: grid;
    grid-template-columns: auto auto;
  }
  @media (max-width: 500px) {
    width: 90vw;
    flex-direction: column;
    display: grid;
    grid-template-columns: auto;
  }
`;

export const Installationradioleft1 = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 30px;
  display: grid;
  grid-template-columns: auto auto auto;

  h5 {
    display: none;
  }

  span:hover + h5 {
    display: block;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2000;
    position: absolute;
    top: 0;
    background-color: white;
    border-radius: 8px;
    text-align: left;
    margin-top: 5px;
    margin-left: 60%;
    width: 100%;
    font-size: 12px;
    border-color: ${colors.black};
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    padding: 10px 18px;
    pointer-events: none;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  h3 {
    margin-top: 3.2rem;
  }
  @media (max-width: 1440px) {
    display: grid;
    grid-template-columns: auto auto auto;
  }
  @media (max-width: 1100px) {
    display: grid;
    grid-template-columns: auto auto;
    width: 60vw;
  }
  @media (max-width: 800px) {
    width: auto;
    display: grid;
    grid-template-columns: auto auto;
  }
  @media (max-width: 500px) {
    width: 90vw;
    flex-direction: column;
    display: grid;
    grid-template-columns: auto;
  }
`;

export const Sub = styled.div`
  width: 97%;
  font-size: 12px;
  padding-block: 1rem;
  @media (max-width: 1440px) {
    width: 145%;
  }
  @media (max-width: 7680px) {
    width: 95%;
  }
`;
export const Installationdiv = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 30px;
  h3 {
    margin-top: 3.2rem;
  }
  @media (max-width: 1100px) {
    width: 95vw;
  }
  @media (max-width: 800px) {
    width: 102vw;
  }
  @media (max-width: 500px) {
    width: 102vw;
    // flex-direction:column;
  }
`;

export const Installationradioright = styled.div`
  margin-top: 2rem;

  p {
    width: 100%;
  }
  @media (max-width: 500px) {
    // margin-left: 0rem;
    margin-top: 1rem;
  }
`;
export const Div2 = styled.div`
  display: grid;
  flex-direction: row;
  grid-template-columns: auto auto;
  @media (max-width: 500px) {
    grid-template-columns: auto;
    flex-direction: column;
  }
`;

export const Divs = styled.div`
  border-bottom: 0.5px solid ${colors.line};
  margin-top: 4rem;
  width: 50%;
  margin-inline: 1rem;
  height: 0;

  // @media(max-width: 500px){
  //     display: none;
  // }
`;

export const Radioleftcontent = styled.div`
  width: 20vw;
  height: auto;
  display: flex;
  flex-direction: row;
  /* margin-top:1vw; */
  padding-right: 5px;
  cursor: pointer;

  &:hover ${Heading} {
    display: block;
  }

  @media (max-width: 1440px) {
    width: 19vw;
    /* height: 12vw; */
  }
  @media (max-width: 1100px) {
    width: 29vw;
  }
  @media (max-width: 800px) {
    width: 40vw;
    height: auto;
  }
  @media (max-width: 500px) {
    width: 85vw;
    height: auto;
    margin-bottom: 1rem;
  }
  @media (max-width: 320px) {
    width: 86vw;
  }
`;
export const Radioleftone = styled.div`
  width: 12vw;
  height: auto;
  border: 1px solid #e3e3e3;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-left:2vw; */
  padding-block: 8px;

  h5 {
    display: none;
  }

  span:hover + h5 {
    display: block;
    align-items: center;
    justify-content: center;
    color: black;
    z-index: 2000;
    position: absolute;
    top: 0;
    background-color: white;
    border-radius: 8px;
    text-align: left;
    margin-top: 5px;
    margin-left: 60%;
    width: 100%;
    font-size: 12px;
    border-color: ${colors.black};
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    padding: 10px 18px;
    pointer-events: none;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  @media (max-width: 1440px) {
    // width: 15vw;
  }
  @media (max-width: 1100px) {
    width: 18vw;
  }
  @media (max-width: 800px) {
    width: 30vw;
  }
  @media (max-width: 500px) {
    width: 65vw;
  }
`;
export const Radiolefttwo = styled.div`
  width: 6vw;
  text-align: center;
  padding-top: 3vw;
  background: #e3e3e3;
  color: ${colors.black};
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  @media (max-width: 1100px) {
    width: 8vw;
  }
  p {
    font-size: 0.6rem;
  }
  @media (max-width: 768px) {
    width: 15vw;
  }
  @media (max-width: 425px) {
    width: 20vw;
  }
`;

export const Radiorightheading = styled.div`
  font-size: 12px;
  font-weight: ${Fonts.fontWeight3};

  @media (max-width: 800px) {
    margin-left: 0rem;
  }
`;

export const Headingsub = styled.div`
  color: #b5b5b5;
  font-size: 12px;
  margin-inline: 1rem;
  margin-top: 0.2rem;

  @media (max-width: 500px) {
    margin-inline: 0rem;
    margin-top: -0.8rem;
  }
`;
export const Icon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;


