import React, { useContext, useState } from 'react';
import { FlexP, NavCard } from './UserPanelStyle';
import { Avatar, Name, ProfileCard, ProfileWrap } from './UserPanelStyle';
import avatar from '../../../assets/Images/png/avatarplaceholder.png';
import { Link, useLocation } from 'react-router-dom';
import { Edit } from '../ProfilePage/ProfilePageStyle';
import { AuthContext } from '../../../consts/AuthContext';

const UserPanel = ({ setIsOpen, userDetails }) => {
  const { authUser } = useContext(AuthContext)
  const location = useLocation().pathname;



  const clickHandler = (e) => {
    const { innerWidth: width } = window;
    width <= 1200 && setIsOpen(false);
  };


  return (
    <ProfileWrap>
      <ProfileCard>
        <FlexP>
          <Avatar src={avatar} />
          <div>
            <Name>
              Hi , {userDetails?.user?.name}
              {userDetails?.user_basic_details
                ? userDetails?.user_basic_details[0]?.name
                : ''}
            </Name>
            <Edit style={{ pointerEvents: 'unset' }} onClick={clickHandler}>
              {location !== '/profile' && (
                <Link to='/profile'>View Profile</Link>
              )}
            </Edit>
          </div>
        </FlexP>
      </ProfileCard>
      <NavCard>
        <ul>
          <li>
            <Link
              style={{ color: location === '/profile' && 'black' }}
              onClick={clickHandler}
              to='/profile'
            >
              Profile
            </Link>
          </li>
          <li>
            <Link
              style={{ color: location === '/profile/reward' && 'black' }}
              onClick={clickHandler}
              to='reward'
            >
              rewards
            </Link>
          </li>
          <li>
            <Link
              style={{ color: location === '/profile/myorders' && 'black' }}
              onClick={clickHandler}
              to='myorders'
            >
              my orders
            </Link>
          </li>
          <li>
            <Link
              style={{ color: location === '/profile/services' && 'black' }}
              onClick={clickHandler}
              to='services'
            >
              services
            </Link>
          </li>
          <li>
            <Link
              style={{ color: location === '/profile/wishlist' && 'black' }}
              onClick={clickHandler}
              to='wishlist'
            >
              wishlist
            </Link>
          </li>
          <li>
            <Link
              style={{ color: location === '/profile/viewquotation' && 'black' }}
              onClick={clickHandler}
              to='viewquotation'
            >enquiry
            </Link>
          </li>
        </ul>
      </NavCard>
    </ProfileWrap>
  );
};

export default UserPanel;
