import styled from "styled-components";
import { colors } from "../consts/themes";

export const ModalContainer = styled.div`
  /* background-color: rgb(0, 0, 0, 0.01); */
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: opacity 0.5s, visibility 0.5s;
  height: 100%;
  z-index: 99999;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  display: flex;
  backdrop-filter: blur(1px);
`;




export const Modal = styled.div`
  display: flex;
  ${'' /* background-color: ${colors.white}; */}

  height: auto;
  /* position: relative; */
  border-radius: 10px;
${'' /* 
  @media (max-width: 1024px) {
    width: auto;
    margin: 20px;
  }
  @media (max-width: 425px) {
    width: 90%;
  } */}
`;

export const Overlay = styled.div`
  display: flex;
  background-color: ${colors.white};
  border-radius: 10px;
  z-index: 1000;
  transition: 0.5s;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 25px 2px rgba(204, 204, 204, 0.45);

  textarea {
    outline: none;
    border: none;
    padding-block: 1rem;
    /* overflow: scroll; */
    height: 10vh;
    width: 90%;
    padding-inline: 1rem;
    margin-inline: 1rem;
    margin-block: 1.5rem;
    background-color: #f6f6f6;
    border-radius: 10px;
    /* line-break: strict; */

    @media (max-width: 425px) {
      /* padding-block: 3rem; */
      padding-inline: 0.2rem;
      margin-block: 1.5rem;
    }
  }
  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const Modalbar = styled.div`
padding:20px;
// width:320px;
background-color:${colors.white};
display:flex;
flex-direction:column;
z-index: 1000;
transition: 0.5s;
flex-direction: column;
align-items: center;
justify-content: center;
box-shadow: 2px 2px 25px 2px rgba(204, 204, 204, 0.45);
`

export const Closediv = styled.div`
width:100%;
align-items:center;
justify-content:flex-end;
display:flex;
// background-color:red;
`

export const CardSpace = styled.div`
display:flex;
flex-direction:row;

`

export const Card = styled.div`
display:flex;
flex-direction:column;
width:190px;
height:190px;
margin:0 8px;
`
export const ImageDiv = styled.div``

