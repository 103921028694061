import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalContainer, Modal, Overlay } from "../modal"
import styled from 'styled-components';
import { IoClose } from "react-icons/io5";
import { Button, ButtonContainer, CloseContainer, Container, Head1, InnerConatiner } from './ModalStyled';


const AccessorySectionModal = ({ close }) => {

  const navigation = useNavigate();
  const productCode = JSON.parse(localStorage.getItem("productcode"));
  const [installModal, setInstallModal] = useState(false);

  const changeFunction = () => {
    if (productCode === "001" || productCode === "004") {
      //lap and desk top
      setInstallModal(true)
    } else {
      navigation('/sales/shoppingcart')
    }
  }

  return (
    <ModalContainer>
      <Modal Modal>
        <Overlay>
          <Container>
            <CloseContainer>
              <IoClose size={22} onClick={() => close(false)} />
            </CloseContainer>

            {installModal === true ?
              <InnerConatiner>
                <Head1>Do you need Installation ?</Head1>
                <ButtonContainer>
                  <Button onClick={() => navigation("/sales/shoppingcart")}>No</Button>
                  <Button bg={1} txt={1} onClick={() => navigation("/sales/products/installationpage")}>Yes</Button>
                </ButtonContainer>
              </InnerConatiner> : (

                <InnerConatiner>
                  <Head1>Do you need Printer ?</Head1>
                  <ButtonContainer>
                    <Button onClick={changeFunction}>No</Button>
                    <Button bg={1} txt={1} onClick={() => navigation("/printer/categories")}>Yes</Button>
                  </ButtonContainer>
                </InnerConatiner>
              )}
          </Container>
        </Overlay>
      </Modal>
    </ModalContainer>
  )
}

export default AccessorySectionModal;


