import styled from "styled-components";
import { colors, fonts } from "../../../consts/themes";

export const Cards3 = styled.div`
  // width: 100%;
  padding-top: 20px;
  // background-color:red;
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  @media screen and (max-width: 1050px) {
    /* width: 80%; */
    /* width: 250%; */
    width: auto;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    margin-top: 10px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
  }
`;
export const Card2 = styled.div`
  display: flex;
  // background-color:blue;
  flex-direction: column;
  border: 0.5px solid ${colors.line};
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  width: 250px;
  // height: 80%;
  height: 300px;
  padding: 5px;
  @media screen and (max-width: 1440px) {
    // width: 50%;
    height: auto;
  }
  p {
    font-size: 12px;
  }
  @media (min-width: 1000px) and (max-width: 1024px) {
    // width:70%;
    height: auto;
  }
  @media screen and (max-width: 800px) {
    // width: 50%;
    height: auto;
  }
  @media screen and (max-width: 350px) {
    width: 235px;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
`;
export const CardImage = styled.div`
  margin: 0px;
  img {
    width: 160px;
    height: 160px;
  }
  @media screen and (max-width: 1024px) {
    img {
      width: 80px;
      height: 80px;
    }
  }
`;

export const CardName = styled.div`
  p {
    text-align: center;
    margin: 0px;
  }
`;
export const CardCash = styled.div`
  margin: 0px;
  p {
    font-size: 12px;
    font-weight: ${fonts.fontWeight4};
    margin: 0px;
  }
`;
export const Off = styled.div`
  display: flex;
  ${'' /* flex-direction: row;
  justify-content: space-between; */}
  justify-content:center;
  width: 90%;
  p {
    margin: 0px;
    font-size: 12px;
  }
`;
export const MrpCard = styled.div`
  p {
    color: ${colors.line};
    text-decoration: line-through;
  }
`;
export const Offer = styled.div`
  p {
    color: ${colors.red};
  }
`;
export const CardIcon = styled.div`
  align-items: center;
  justify-content: center;
  align-self: center;
  padding-inline: 2rem;
  @media (min-width: 1200px) and (max-width: 1440px) {
    padding-inline: 1rem;
  }

  @media screen and (max-width: 800px) {
    padding-inline: 0.5rem;
  }
  @media screen and (max-width: 500px) {
    margin-top: 6px;
  }
`;
