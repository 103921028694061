import { server_api } from '../consts/api';

const signupterms = `${server_api}selectRegistrationTerms`
const productcategories = `${server_api}getProductCategory`;
const PurchaseType = `${server_api}getPurchaseType`;
const insertInvoice = `${server_api}insertInvoice`;
const insertCart = `${server_api}insertCart`;
const themeList = `${server_api}getThemeList`;
const CustomerCategory = `${server_api}getCustomerCategory`;
const CustomerSubCategory = `${server_api}getCustomerSubCategory`;
const insertCustomerAddress = `${server_api}insertCustomerAddress`;
const customerAddress = `${server_api}getCustomerAddress`;
const productListBasedOnCategory = `${server_api}getProductListBasedOnCategory`;
const productDetailedDisplay = `${server_api}getProductDetailedDisplay`;
const accessoriesByProduct = `${server_api}getAccessoriesByProduct`;
const getTypeOfOrganisationById = `${server_api}getTypeOfOrganisationById`;
const insertCustomerFav = `${server_api}insertCustomerFav`;
const getFavListById = `${server_api}getFavListById`;
const insertCustomers = `${server_api}insertCustomer`;
const getProductBasedAccessoriesList = `${server_api}getProductBasedAccessoriesList`;
const insertCustomSale = `${server_api}insertCustomSale`;
const CustomSaleReport = `${server_api}getCustomSaleReport`;
const updateCustomSaleReport = `${server_api}updateCustomSaleReport`;
const getInstallation = `${server_api}getInstallation`;
const Productdelete = `${server_api}Productdelete`;
const getServicePack = `${server_api}getServicePack`;
const getProfession = `${server_api}getProfession`;
const updateCart = `${server_api}updateCart`;
const getCart = `${server_api}getCart`;
const getAllCart = `${server_api}getAllCart`;
const getUserCart = `${server_api}getCartByUser`;
const getRating = `${server_api}getRating`;
const getComponents = `${server_api}getComponents`;
const getProductBasedComponentsList = `${server_api}getProductBasedComponentsList`;
const getProducts = `${server_api}getProducts`;
const getProductDetailsForService = `${server_api}getProductDetailsForService`;
const getServiceType = `${server_api}getServiceType`;
const insertServiceReg = `${server_api}insertServiceReg`;
const getCustomerAddressById = `${server_api}getCustomerAddressById`;
const updateServiceRegAddress = `${server_api}updateServiceRegAddress`;
const getProductOfPrinter = `${server_api}getProductOfPrinter`;
const getPrinterTypes = `${server_api}getPrinterTypes`;
const getPrintersByProduct = `${server_api}getPrintersByProduct`;
const removeProduct = `${server_api}removeProduct`;
const getProcessorBrands = `${server_api}processorBrands`;
const getDesktopCusomization = `${server_api}relatedProductsList`;
const getBuildPc = `${server_api}getbuildpc`


// auction
const getBidProduct = `${server_api}customerBidProduct`
const checkPayment = `${server_api}checkPayment`
const insertCustomerBid = `${server_api}insertCustomerBid`
const getCurrentBid = `${server_api}getplacedbid`
const placeBid = `${server_api}placebid`
const customerBidCart = `${server_api}customerBidCart`

const getUser1 = `${server_api}getUser1`;
const insertPurchaseFeedback = `${server_api}insertPurchaseFeedback`;
const getUser = `${server_api}getUser`;
const userLogin = `${server_api}userLogin`;
const userLogout = `${server_api}userLogout`;
const getProductList = `${server_api}getProductList`;
const getBrands = `${server_api}getBrands`;
const getCustomSaleReport = `${server_api}getCustomSaleReport`;
const updateStatusCustomSaleReport = `${server_api}updateStatusCustomSaleReport`;
const getCustomSaleReportById = `${server_api}getCustomSaleReportById`;
const getAllDropDownValues = `${server_api}getAllDropDownValues`;
const getServiceReg = `${server_api}getServiceReg`;
const getAvailableServicesByProductId = `${server_api}getAvailableServicesByProductId`;
const updateAdvancePayment = `${server_api}updateAdvancePayment`;
const insertfeedback = `${server_api}insertfeedback`;
const getDistrict = `${server_api}getDistrict`;
const getProductCategory = `${server_api}getProductCategory`;
const listQuotid = `${server_api}listQuotid`;
const getfeedback = `${server_api}getfeedback`;
const updateCustomer_approval = `${server_api}updateCustomer_approval`;
const getCctv = `${server_api}getCctv`;
const getSpeaker = `${server_api}getSpeaker`;
const getNetworkingProducts = `${server_api}getNetworkingProducts`;
const getAccessoriesTypes = `${server_api}getAccessoriesTypes`;
const getProductBasedcctv = `${server_api}getProductBasedcctv`;
const getProductBasedSpeaker = `${server_api}getProductBasedSpeaker`;
const getProductBasedNetworking = `${server_api}getProductBasedNetworking`;
const insertReward = `${server_api}insertReward`;
const insertRedeemedReward = `${server_api}insertReward`;
const getUserDetailsForProfile = `${server_api}getUserDetailsForProfile`;
const getRewardList = `${server_api}getRewardList`;
const getOrderDetails = `${server_api}getOrderDetails`;
const professionName = `${server_api}getProfession`;
const updateCustomer = `${server_api}updateCustomer`;
const updateCustomerAddress = `${server_api}updateCustomerAddress`;
const deleteCustomerAddress = `${server_api}deleteCustomerAddress`;
const insertReview = `${server_api}insertReview`;
const insertRedeemedRewards = `${server_api}insertRedeemedRewards`;


const changeQuantity = `${server_api}changeQuantity`;
const getDetailsProductAccessories = `${server_api}productaccessories`;
const addInstallation = `${server_api}addInstallation`;
const buildPCInsertion = `${server_api}buildPCInsertionWeb`;
const getCustomPcCart = `${server_api}recentCustomPCOfUser`;
const deleteCustomPcComponent = `${server_api}deleteCustomPCComponent`;
const CustomPcSubmit = `${server_api}submit`;
const getBrandById = `${server_api}getBrandById`;
const customPCCart = `${server_api}customPCCart`
const getAvailableServices = `${server_api}getAvailableServices`
const getCityList = `${server_api}getCity`
const PurchaseOrderpdfUpload = `${server_api}PurchaseOrderpdfUpload`
// Exchange

const listComplaint = `${server_api}getAllComplaint`;
const listDisplay = `${server_api}getAllDisplay`;
const getGraphics = `${server_api}getAllGraphics`
const getProcessors = `${server_api}getAllProcessor`
const getRams = `${server_api}getAllRam`
const getReason = `${server_api}getReasonOfEcxhange`
const getStorage = `${server_api}getAllStorage`
const getBrandSize = `${server_api}getMonitorBrand`
const insertExchangeform = `${server_api}insertexchangeform`
const list_estimatedprice = `${server_api}list_estimatedprice`
const acceptestimate_price = `${server_api}acceptestimate_price`
const rejectestimate_price = `${server_api}rejectestimate_price`
const getAllreason = `${server_api}getAllreason`
const postponedestimate_price = `${server_api}postponedestimate_price`
const acceptfinal_price = `${server_api}acceptfinal_price`
const exchangeclikepoints = `${server_api}exchangeclikepoints`



// Quiz

const list_quizquestions = `${server_api}list_quizquestions`
const list_questions = `${server_api}list_questions`
const quiz_participants = `${server_api}quiz_participants`
const list_detailsquiz = `${server_api}list_detailsquiz`

const display_winner = `${server_api}display_winner`
const quizsubmissionweb = `${server_api}quizsubmissionweb`
const listQuizids = `${server_api}list_quizids`
const checkQuiz = `${server_api}checkQuiz`
const quizStatus = `${server_api}quizStatus`
const quizParticipantsCheck = `${server_api}quizparticipantscheck`
const selectQuizTerms = `${server_api}selectQuizTerms`


// guide
const productGuid = `${server_api}productGuid`

export {
  signupterms,
  insertRedeemedRewards,
  insertReview,
  deleteCustomerAddress,
  updateCustomerAddress,
  updateCustomer,
  professionName,
  getOrderDetails,
  getUserDetailsForProfile,
  insertRedeemedReward,
  insertReward,
  productcategories,
  insertCustomerAddress,
  PurchaseType,
  insertInvoice,
  getProductDetailsForService,
  insertCart,
  insertServiceReg,
  themeList,
  CustomerCategory,
  CustomerSubCategory,
  customerAddress,
  productListBasedOnCategory,
  productDetailedDisplay,
  accessoriesByProduct,
  getTypeOfOrganisationById,
  insertCustomerFav,
  getFavListById,
  insertCustomers,
  getProductBasedAccessoriesList,
  insertCustomSale,
  CustomSaleReport,
  updateCustomSaleReport,
  getInstallation,
  Productdelete,
  getServicePack,
  getProfession,
  updateCart,
  getCart,
  getAllCart,
  getRating,
  getComponents,
  getProductBasedComponentsList,
  getProducts,
  removeProduct,
  getServiceType,
  getCustomerAddressById,
  updateServiceRegAddress,
  getProductOfPrinter,
  getUser1,
  insertPurchaseFeedback,
  getUser,
  userLogin,
  userLogout,
  getProductList,
  getBrands,
  getCustomSaleReport,
  updateStatusCustomSaleReport,
  getCustomSaleReportById,
  getAllDropDownValues,
  getServiceReg,
  getAvailableServicesByProductId,
  updateAdvancePayment,
  insertfeedback,
  getDistrict,
  getProductCategory,
  listQuotid,
  getfeedback,
  updateCustomer_approval,
  getCctv,
  getSpeaker,
  getNetworkingProducts,
  getAccessoriesTypes,
  getProductBasedcctv,
  getProductBasedSpeaker,
  getProductBasedNetworking,
  getRewardList,
  getUserCart,

  changeQuantity,
  getDetailsProductAccessories,
  addInstallation,
  buildPCInsertion,
  getCustomPcCart,
  deleteCustomPcComponent,
  CustomPcSubmit,
  getBrandById,
  customPCCart,
  getAvailableServices,
  getCityList,
  PurchaseOrderpdfUpload,
  getPrinterTypes,
  getPrintersByProduct,
  getProcessorBrands,
  getDesktopCusomization,
  getBuildPc,



  // Auction
  getBidProduct,
  checkPayment,
  insertCustomerBid,
  getCurrentBid,
  placeBid,
  customerBidCart,

  // Exchange

  listComplaint,
  listDisplay,
  getGraphics,
  getProcessors,
  getRams,
  getReason,
  getStorage,
  getBrandSize,
  insertExchangeform,
  list_estimatedprice,
  acceptestimate_price,
  rejectestimate_price,
  getAllreason,
  postponedestimate_price,
  acceptfinal_price,
  exchangeclikepoints,



  // Quiz
  list_quizquestions,
  list_questions,
  quiz_participants,
  list_detailsquiz,
  display_winner,
  quizsubmissionweb,
  listQuizids,
  checkQuiz,
  quizStatus,
  quizParticipantsCheck,
  selectQuizTerms,


  productGuid,

};
