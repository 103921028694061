import { useNavigate } from 'react-router-dom'
import { ModalContainer, Modal, Overlay } from "../modal"
import styled from 'styled-components';
import { IoClose } from "react-icons/io5";
import { CloseContainer } from './ModalStyled';
import axiosInstance from '../../consts/axiosInstance';
import { useEffect, useState } from 'react';
import LoadingSpinner from '../Button/Loading/LoadingSpinner';
import ErrorComponent from '../Button/error/ErrorComponent';
import { Container, Head1, Img, ImgContainer, InnerConatiner, Text } from './TypeModalStyle';
import brandeddesktop from '../../assets/Images/png/Printer.png'



const arr = [
  {
    id: 0,
    title: 'Light'
  },
  {
    id: 1,
    title: 'Heavy'
  }
]



const PrinterTypeModal = ({ close, navi }) => {
  const navigation = useNavigate();



  const navfunction = () => {
    navigation(navi);
  }


  return (
    <ModalContainer>
      <Modal Modal>
        <Overlay>
          <Container>
            <CloseContainer>
              <IoClose size={22} onClick={() => close(false)} />
            </CloseContainer>
            <InnerConatiner>
              <Head1>Choose Printer Type</Head1>

              <ItemContainer>
                {arr?.map((item) => (
                  <ImgContainer key={item.id}
                    onClick={() => [navfunction(), localStorage.setItem('PrinterfilterId', JSON.stringify(item?.id))]}>
                    <Img><img src={brandeddesktop} alt='printer' /></Img>
                    <Text>{item?.title}</Text>
                  </ImgContainer>
                ))}

              </ItemContainer>
            </InnerConatiner>
          </Container>
        </Overlay>
      </Modal>
    </ModalContainer>
  )
}

export default PrinterTypeModal;

const ItemContainer = styled.div`
display: flex;
width: 100%;
justify-content: center;
align-items: center;
gap:1.5rem;
flex-wrap: wrap;

height: fit-content;
max-height: 240px;
overflow:scroll;
`