import React from 'react';
import styled from 'styled-components';


const ErrorContainer = styled.div`
height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;




const ErrorComponent = ({ error }) => {
  return (


    <ErrorContainer>
      <h3>An Error Occured.....!</h3>
      {error ? <p>{error}</p> : ''}
    </ErrorContainer>

  );
};

export default ErrorComponent;
