import React, { useContext, useEffect, useState } from 'react'
import { BsUpload } from 'react-icons/bs'
import Switch from 'react-switch'
import { ComponentDiv, ComponentHeading, Dropdown, DropdownDiv, ExchangeContainer, ExchangeContents, Exchangediv, ExHeading, ItemName, ItemNameDiv, ItemsDiv, RadioDiv, SingleItem, SpecificationDiv, SubmitButtonDiv, SwitchDiv, SwitchDiv1, WorkingCondition } from '../LaptopExchange/LaptopExchangeStyle'
import SubmitModal from '../Modals/SubmitModal'
import OnOffSwitch from '../Switch/Switch'
import axios from 'axios';
import { listComplaint, getGraphics, getRams, getStorage, getProcessors, getBrandSize, getReason, insertExchangeform, list_estimatedprice, getCityList, getDistrict } from '../../../api/api';
import Multiselect from 'multiselect-react-dropdown';
import { AuthContext } from '../../../consts/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom'
import { RiExchangeFill } from 'react-icons/ri'
import { InputField, TextArea } from '../MobileExchange/MobileExchangeStyle'
import axiosInstance from '../../../consts/axiosInstance'

const DesktopExchange = () => {

    const { state } = useLocation();
    const navigate = useNavigate();

    const [isOnProcessor, setIsOnProcessor] = useState(false);
    const [complaints, setComplaints] = useState([])
    const [items, setItems] = useState([])
    const [graphics, setGraphics] = useState([])
    const [ram, setRam] = useState([])
    const [storage, setStorage] = useState([])
    const [processor, setProcessor] = useState([])
    const [brand, setBrand] = useState([])
    const [reason, setReason] = useState([])
    const [authUser, setAuthUser] = useContext(AuthContext);
    const [isMonitorSize, setMonitorSize] = useState(false);
    const [isKeyMouse, setKeyMouse] = useState(false);
    const [isWifi, setIsWifi] = useState(false);
    const [isSpeaker, setIsSpeaker] = useState(false);
    const [isWebcam, setIsWebcam] = useState(false);

    //district lit
    const [district, setDistrict] = useState([]);
    const [selectedDistrictId, setSelectedDistrictId] = useState('')
    //city list based on district
    const [cityList, setCityList] = useState([]);
    const currentYear = new Date().getFullYear();
    const startYear = currentYear - 8;
    const endYear = currentYear;
    const years = [];

    for (let i = startYear; i <= endYear; i++) {
        years.push(i);
    }


    const [values, setValues] = useState({
        product_cat_id: state?.id,
        user_id: authUser?.user?.id,
        processor: '',
        processor_status: 0,
        ram: '',
        storage: '',
        graphics: '',
        graphics_size: '',
        monitor_brand: '',
        monitor_size: '',
        keyboard_working: 0,
        keyboard_type: '',
        wifi_working: 0,
        wifi: '',
        speaker_working: 0,
        speaker: '',
        webcam: '',
        webcam_working: 0,
        address: '',
        pincode: '',
        district_id: '',
        city_id: '',

    })
    console.log('values: ', values);


    const handleChange = (e) => {

        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const options =

        complaints.map(item => {
            return (
                {
                    name: item?.complaint,
                    id: item?.id

                }
            )
        })

    const handleSelect = (selectedList) => {
        setValues({
            ...values,
            complaint_id: JSON.stringify(selectedList)
        })
    };

    const handleRemove = (selectedList) => {
        setValues({
            ...values,
            complaint_id: JSON.stringify(selectedList)
        })
    };


    const listComplaints = async () => {
        try {
            const response = await axiosInstance.get(`getAllComplaint?product_cat_id=${state?.id}`)
            setComplaints(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const listGraphics = async () => {
        try {
            const response = await axiosInstance.get(`getAllGraphics?product_cat_id=${state?.id}`)
            setGraphics(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const listRam = async () => {
        try {
            const response = await axiosInstance.get(`getAllRam?product_cat_id=${state?.id}`)
            setRam(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const listStorage = async () => {
        try {
            const response = await axiosInstance.get(`getAllStorage?product_cat_id=${state?.id}`)
            setStorage(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };




    const listReason = async () => {
        try {
            const response = await axiosInstance.get(`getReasonOfEcxhange?product_cat_id=${state?.id}`)
            setReason(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    const listProcessor = async () => {
        try {
            const response = await axiosInstance.get(`getAllProcessor?product_cat_id=${state?.id}`)
            setProcessor(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };




    const listBrand = async () => {
        try {
            const response = await axiosInstance.get(`getMonitorBrand?product_cat_id=${state?.id}`)
            setBrand(response.data)
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    const handleSubmit = () => {
        axiosInstance.post('insertexchangeform', values).then(resp => {
            console.log(resp, "insertExchange")
            if (resp?.data.code == 200) {
                setIsOpen(true)
            } else {
                alert(resp.data.message)
            }
        })
    }




    useEffect(() => {
        listComplaints()
        listGraphics()
        listRam()
        listStorage()
        listProcessor()
        listBrand()
        listReason()
    }, [])




    const getDistrictdata = async () => {
        try {
            const response = await axiosInstance.get('getDistrict');
            setDistrict(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        getDistrictdata();
    }, [])




    useEffect(() => {
        const getAvailableCity = async () => {
            try {
                if (selectedDistrictId) {
                    const response = await axiosInstance.get(`getCity/${selectedDistrictId}`);
                    setCityList(response?.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        getAvailableCity();
    }, [selectedDistrictId]);





    const handleDistrictChange = (e) => {
        setValues({ ...values, district_id: e.target.value, city_id: '' });
        setSelectedDistrictId(e.target.value);
    }





    //graphics onchnages
    const handleGraphicsChange = (value) => {
        setValues((prevValues) => ({
            ...prevValues,
            graphics: value,
            graphics_size: value === 0 ? 0 : prevValues.graphics_size, // Reset graphicsOption when switching to inbuilt
        }));

        if (value === 1) {
            setValues((prevValues) => ({
                ...prevValues,
                graphics_size: '',
            }))
        };
    };



    //wifi onchnages
    const handleWifiChange = (value) => {
        setValues((prevValues) => ({
            ...prevValues,
            wifi: value,
            wifi_working: value === 0 ? 0 : prevValues.wifi_working,
        }));
        if (value === 0) {
            setIsWifi(false);
        }
    };



    //speaker onchnages
    const handleSpeakerChange = (value) => {
        setValues((prevValues) => ({
            ...prevValues,
            speaker: value,
            speaker_working: value === 0 ? 0 : prevValues.speaker_working,
        }));
        if (value === 0) {
            setIsSpeaker(false);
        }
    };




    //webcam onchnages
    const handleWebcamChange = (value) => {
        setValues((prevValues) => ({
            ...prevValues,
            webcam: value,
            webcam_working: value === 0 ? 0 : prevValues.webcam_working,
        }));
        if (value === 0) {
            setIsWebcam(false);
        }
    };


    const isSubmitButton = Object.values(values).every((value) => value !== '')


    const [isOpen, setIsOpen] = useState(false)

    return (
        <ExchangeContainer>
            {isOpen && <SubmitModal setIsOpen={setIsOpen} />}
            <Exchangediv>
                <ExHeading>
                    <div>
                        <p style={{ marginBottom: "0" }}>DESKTOPS</p>
                        <label>*only can exchange within the 7 years limit</label>
                    </div>
                    {/* { exchangeData.length > 0 && <SubmitButtonDiv style={{display: 'flex' }}>
                    <button style={{width: '200px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' 
                }} onClick={() => navigate('/exchange/confirmation')}>Exchange Cart <RiExchangeFill size={25}/></button>
                </SubmitButtonDiv>} */}

                </ExHeading>
                <ExchangeContents>
                    <ComponentHeading>
                        <ComponentDiv>
                            <h5>Components</h5>
                        </ComponentDiv>
                        <SpecificationDiv>
                            <h5>Specifications</h5>
                        </SpecificationDiv>
                        <WorkingCondition>
                            <h5>Working condition (Yes/No)</h5>
                        </WorkingCondition>
                    </ComponentHeading>
                    <ItemsDiv>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>PROCESSOR</ItemName></ItemNameDiv>
                            <DropdownDiv>
                                <Dropdown name="processor" id="processor" onChange={(e) => setValues({
                                    ...values,
                                    processor: e.target.value
                                })}>
                                    <option value=''>select processor</option>

                                    {processor.map((item) => {
                                        return (
                                            <option value={item?.processor_type}>{item?.processor_type}</option>
                                        )
                                    })}
                                </Dropdown>
                            </DropdownDiv>
                            <SwitchDiv>
                                <Switch
                                    onChange={() => {
                                        setIsOnProcessor(prev => {
                                            const newProgress = !prev;
                                            setValues({ ...values, processor_status: newProgress ? 1 : 0 });
                                            return newProgress;
                                        });
                                    }}
                                    checked={isOnProcessor}
                                    height={25}
                                    width={45}
                                    handleDiameter={20}
                                    offColor="#ff0000"
                                    onColor="#4669E8"
                                    offHandleColor="#ffffff"
                                    onHandleColor="#ffffff"
                                />
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>RAM</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                <Dropdown name="ram" id="ram" onChange={(e) => setValues({
                                    ...values,
                                    ram: e.target.value
                                })}>
                                    <option value=''>select</option>
                                    {ram.map((item) => {
                                        return (
                                            <option value={item?.ram_type}>{item?.ram_type}</option>
                                        )
                                    })}
                                </Dropdown>
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>

                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>STORAGE</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                <Dropdown name="storage" id="storage" onChange={(e) => setValues({
                                    ...values,
                                    storage: e.target.value
                                })}>
                                    <option value=''>select storage</option>
                                    {storage.map((item) => {
                                        return (
                                            <option value={item?.storage}>{item?.storage}</option>
                                        )
                                    })}
                                </Dropdown>
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>

                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>GRAPHICS</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv>
                                    <input type="radio" id="inbuilt" name="graphics" value={0}
                                        onChange={() => handleGraphicsChange(0)} />
                                    <label for="html">Inbuilt</label>
                                    <input type="radio" id="dedicated" name="graphics" value={1}
                                        onChange={() => handleGraphicsChange(1)} />
                                    <label for="css">Dedicated</label></RadioDiv>


                                {values.graphics === 1 &&
                                    <div style={{ paddingTop: "10px" }}>
                                        <select style={{
                                            width: "100px", height: "40px", borderRadius: "10px",
                                            borderColor: "gray", color: "gray"
                                        }}
                                            name="graphics_size" id="graphics" onChange={(e) => setValues({
                                                ...values,
                                                graphics_size: e.target.value
                                            })}>
                                            <option value=''>select</option>
                                            {graphics.map((item) => {
                                                return (
                                                    <option value={item?.size}>{item?.size}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                }
                            </SwitchDiv1>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>


                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>Monitor Brand</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                <Dropdown name="monitor_brand" id="monitor_brand" onChange={(e) => setValues({
                                    ...values,
                                    monitor_brand: e.target.value
                                })}>
                                    <option value=''>select</option>
                                    {brand.map((item) => {
                                        return (
                                            <option value={item?.monitor_brand}>{item?.monitor_brand}</option>
                                        )
                                    })}
                                </Dropdown>
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>


                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>MONITOR SIZE</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                <Dropdown name="monitor-size" id="monitor-size" onChange={(e) => setValues({
                                    ...values,
                                    monitor_size: e.target.value
                                })}>
                                    <option value=''>select</option>
                                    {brand.map((item) => {
                                        return (
                                            <option value={item?.monitor_size}>{item?.monitor_size}</option>
                                        )
                                    })}
                                </Dropdown>
                            </DropdownDiv>
                            <SwitchDiv>
                                {/* <Switch
                                    onChange={() => { setMonitorSize(!isMonitorSize); setValues({ ...values, monitor_size: isMonitorSize ? '1' : '0' }) }}
                                    checked={isMonitorSize}
                                    height={25}
                                    width={45}
                                    handleDiameter={20}
                                    offColor="#ff0000"
                                    onColor="#4669E8"
                                    offHandleColor="#ffffff"
                                    onHandleColor="#ffffff"
                                /> */}
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>KEYBOARD WITH MOUSE</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv>
                                    <input type="radio" id="ordinary" name="keyboard_type" value={0} onChange={(e) => handleChange(e)} />
                                    <label for="html">Ordinary</label>
                                    <input type="radio" id="backlight" name="keyboard_type" value={1} onChange={(e) => handleChange(e)} />
                                    <label for="css">Backlight</label></RadioDiv>
                            </SwitchDiv1>
                            <SwitchDiv>
                                <Switch
                                    onChange={() => {
                                        setKeyMouse(prev => {
                                            const newProgress = !prev;
                                            setValues({ ...values, keyboard_working: newProgress ? 1 : 0 });
                                            return newProgress;
                                        });
                                    }}
                                    checked={isKeyMouse}
                                    height={25}
                                    width={45}
                                    handleDiameter={20}
                                    offColor="#ff0000"
                                    onColor="#4669E8"
                                    offHandleColor="#ffffff"
                                    onHandleColor="#ffffff"
                                />
                            </SwitchDiv>
                        </SingleItem>


                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>WIFI</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv id="wifi" >
                                    <input type="radio" id="yes" name="wifi" value={1} onChange={() => handleWifiChange(1)} />
                                    <label for="Yes">Yes</label>
                                    <input type="radio" id="no" name="wifi" value={0} onChange={() => handleWifiChange(0)} />
                                    <label for="No">No</label></RadioDiv>

                            </SwitchDiv1>
                            <SwitchDiv>
                                {values.wifi == 1 &&
                                    <Switch
                                        onChange={() => {
                                            setIsWifi(prev => {
                                                const newProgress = !prev;
                                                setValues({ ...values, wifi_working: newProgress ? 1 : 0 });
                                                return newProgress;
                                            });
                                        }}
                                        checked={isWifi}
                                        height={25}
                                        width={45}
                                        handleDiameter={20}
                                        offColor="#ff0000"
                                        onColor="#4669E8"
                                        offHandleColor="#ffffff"
                                        onHandleColor="#ffffff"
                                    />}
                            </SwitchDiv>
                        </SingleItem>




                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>SPEAKERS</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv id="speakers" >
                                    <input type="radio" id="yes" name="speaker" value={1} onChange={() => handleSpeakerChange(1)} />
                                    <label for="Yes">Yes</label>
                                    <input type="radio" id="no" name="speaker" value={0} onChange={() => handleSpeakerChange(0)} />
                                    <label for="No">No</label></RadioDiv>
                            </SwitchDiv1>

                            <SwitchDiv>
                                {values.speaker == 1 &&
                                    <Switch
                                        onChange={() => {
                                            setIsSpeaker(prev => {
                                                const newProgress = !prev;
                                                setValues({ ...values, speaker_working: newProgress ? 1 : 0 });
                                                return newProgress;
                                            });
                                        }}
                                        checked={isSpeaker}
                                        height={25}
                                        width={45}
                                        handleDiameter={20}
                                        offColor="#ff0000"
                                        onColor="#4669E8"
                                        offHandleColor="#ffffff"
                                        onHandleColor="#ffffff"
                                    />}
                            </SwitchDiv>
                        </SingleItem>


                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>WEBCAM</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv id="webcam" >
                                    <input type="radio" id="yes" name="webcam" value={1} onChange={() => handleWebcamChange(1)} />
                                    <label for="Yes">Yes</label>
                                    <input type="radio" id="no" name="webcam" value={0} onChange={() => handleWebcamChange(0)} />
                                    <label for="No">No</label></RadioDiv>
                            </SwitchDiv1>
                            <SwitchDiv>
                                {values.webcam == 1 &&
                                    <Switch
                                        onChange={() => {
                                            setIsWebcam(prev => {
                                                const newProgress = !prev;
                                                setValues({ ...values, webcam_working: newProgress ? 1 : 0 });
                                                return newProgress;
                                            });
                                        }}
                                        checked={isWebcam}
                                        height={25}
                                        width={45}
                                        handleDiameter={20}
                                        offColor="#ff0000"
                                        onColor="#4669E8"
                                        offHandleColor="#ffffff"
                                        onHandleColor="#ffffff"
                                    />}
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>PHYSICAL CONDITION</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv id="condition">
                                    <input type="radio" id="good" name="physical_condition" value={0} onChange={(e) => handleChange(e)} />
                                    <label for="good">Good</label>
                                    <input type="radio" id="fair" name="physical_condition" value={1} onChange={(e) => handleChange(e)} />
                                    <label for="fair">Fair</label>
                                    <input type="radio" id="excellent" name="physical_condition" value={2} onChange={(e) => handleChange(e)} />
                                    <label for="excellent">Excellent</label></RadioDiv>
                            </SwitchDiv1>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>WORKING CONDITION</ItemName>
                            </ItemNameDiv>
                            <SwitchDiv1>
                                <RadioDiv id="condition" >
                                    <input type="radio" id="good" name="working_condition" value={0} onChange={(e) => handleChange(e)} />
                                    <label for="good">Good</label>
                                    <input type="radio" id="fair" name="working_condition" value={1} onChange={(e) => handleChange(e)} />
                                    <label for="fair">Fair</label>
                                    <input type="radio" id="excellent" name="working_condition" value={2} onChange={(e) => handleChange(e)} />
                                    <label for="excellent">Excellent</label></RadioDiv>
                            </SwitchDiv1>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>PURCHASE YEAR</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                {/* <Dropdown>
                                <option>2022</option>
                                <option>2022</option>
                                <option>2022</option>
                                <option>2022</option>
                                <option>2022</option>
                            </Dropdown> */}
                                <Dropdown onChange={(e) => setValues({ ...values, purchase_year: e.target.value })}>
                                    <option value='' >Select purchase year</option>
                                    {years.map((year) => {
                                        return (
                                            <option value={year}>{year}</option>
                                        )
                                    })} </Dropdown>
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>COMPLAINT</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                {/* <Dropdown>
                                <option>select</option>
                                <option>Not working</option>
                                <option>Not working</option>
                                <option>Not working</option>
                                <option>Not working</option>
                               
                            </Dropdown> */}
                                <Multiselect
                                    options={options}
                                    selectedValues={items}
                                    onSelect={handleSelect}
                                    onRemove={handleRemove}
                                    displayValue="name"
                                />
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>REASON OF EXCHANGE</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                <Dropdown name="reason" id="reason" onChange={(e) => setValues({
                                    ...values,
                                    reason_for_exchange_id: e.target.value
                                })}>
                                    <option>select</option>
                                    {reason.map((item) => {
                                        return (
                                            <option value={item?.id}>{item?.reason}</option>
                                        )
                                    })}
                                </Dropdown>
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>

                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>CHOOSE DISTRICT</ItemName>
                            </ItemNameDiv>
                            <DropdownDiv>
                                <Dropdown name="district" id="district" onChange={handleDistrictChange}>
                                    <option value="">select</option>
                                    {district?.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.district_name}</option>
                                    ))}
                                </Dropdown>
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>


                        {selectedDistrictId &&
                            <SingleItem>
                                <ItemNameDiv>
                                    <ItemName>CHOOSE CITY</ItemName>
                                </ItemNameDiv>
                                <DropdownDiv>
                                    <Dropdown name="city" id="city" onChange={(e) => setValues({
                                        ...values,
                                        city_id: e.target.value
                                    })} >
                                        <option value="">select</option>
                                        {cityList?.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.city_name}</option>
                                        ))}
                                    </Dropdown>
                                </DropdownDiv>
                                <SwitchDiv>
                                </SwitchDiv>
                            </SingleItem>
                        }

                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>ADDRESS</ItemName></ItemNameDiv>
                            <DropdownDiv>
                                <TextArea name='address' type='text' rows={'6'} onChange={(e) => handleChange(e)} />
                            </DropdownDiv>
                            <SwitchDiv>

                            </SwitchDiv>
                        </SingleItem>


                        <SingleItem>
                            <ItemNameDiv>
                                <ItemName>PIN CODE</ItemName></ItemNameDiv>
                            <DropdownDiv>
                                <InputField
                                    name='pincode'
                                    type='text'
                                    maxLength='6'
                                    onChange={(e) => {
                                        // Only allow numeric input
                                        if (/^\d*$/.test(e.target.value)) {
                                            handleChange(e);
                                        }
                                    }}
                                />
                            </DropdownDiv>
                            <SwitchDiv>
                            </SwitchDiv>
                        </SingleItem>
                    </ItemsDiv>

                </ExchangeContents>
                {isSubmitButton &&
                    <SubmitButtonDiv>
                        <button onClick={() => handleSubmit()}>Submit</button>
                    </SubmitButtonDiv>
                }
            </Exchangediv>
        </ExchangeContainer>
    )
}

export default DesktopExchange