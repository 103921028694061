import styled from "styled-components";
import { colors, fonts } from "../../../consts/themes";

export const MainDiv = styled.div`
  display: flex;
  padding-top: 12rem;
  gap: 3rem;
  margin: auto;
  max-width: 1500px;
  padding-bottom: 4rem;
  @media screen and (max-width: 1450px) {
    padding-left: 15px;
    padding-right: 15px;
  }
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    gap: 2rem;
  }
  @media screen and (max-width: 800px) {
    padding-top: 9rem;
  }
`;
export const SliderDiv = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  @media screen and (max-width: 1440px) {
    /* width: 50%; */
  }
`;


export const Card = styled.div`
  ${'' /* border: 1px solid red; */}

  img {
    ${'' /* border: 1px solid green; */}
    width: 400px;
    height: 400px;
    object-fit: contain;
  }
`;


export const Pro = styled.div`
width:115px;
`

export const Sub = styled.div`
  display: flex;
gap:0.625rem;
justify-content:center;
  flex-direction: row;
  ${'' /* width: 596px; */}
  margin-top: 2rem;
  overflow: scroll;
  @media screen and (max-width: 1000px) {
    margin: auto;
    margin-top: 2rem;
  }
  @media screen and (max-width: 660px) {
    width: 480px;
  }
  @media screen and (max-width: 540px) {
    width: 386px;
  }
  @media screen and (max-width: 440px) {
    width: 280px;
  }
  @media screen and (max-width: 440px) {
    width: 208px;
  }
  img {
    width: 100px !important;
    height: 100px !important;
    border: 0.5px solid ${colors.line};
    border-radius: 8px;
    margin-inline: 8px;
    @media screen and (max-width: 660px) {
      width: 80px;
      height: 80px;
    }
    @media screen and (max-width: 540px) {
      width: 60px;
      height: 60px;
    }
    @media screen and (max-width: 440px) {
      width: 50px;
      height: 50px;
    }
  }
`;
export const Divss = styled.div`
  display: flex;
`;
export const Features = styled.div`
display:flex;
flex-direction:row;
align-items: flex-start;
justify-content : flex-start;
// background-color:yellow;
// padding:0 5px;


p{
  font-size:13px;
  color:${colors.black};
  font-weight:${fonts.fontWeight3};
  margin:0 0px;
}
span{
  font-size:13px;
  color:${colors.black};
  font-weight:${fonts.fontWeight4};
  margin:0 6px;
}
`
export const Spec = styled.div`
width:400px;
// background-color:red;
`

export const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ContMain = styled.div`
  @media screen and (max-width: 1500px) {
    padding-top: 0px;
  }
  @media screen and (min-width: 1000px) and (max-width: 1050px) {
    align-items: center;
    width: 92%;
  }

  @media screen and (min-width: 600px) and (max-width: 768px) {
    padding-left: 10px;
    align-items: center;
    width: 92%;
  }
`;
export const Heading = styled.div`
  p {
    font-size: 18px;
    margin: 0;
    font-weight: ${fonts.fontWeight5};
  }
`;
export const Rate = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const Icon = styled.div`
  color: ${colors.yellow};
  display: flex;
  flex-direction: row;
`;
export const Rateings = styled.div`
  margin-left: 2px;
  p {
    margin-left: 10px;
    font-size: 12px;
    cursor: pointer;
    color: ${colors.blue};
  }
`;
export const KeyF = styled.div`
  display:flex;
  flex-direction:column;
  p {
    margin: 0px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;
export const Table = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 3px 0;
  width: 90%;
  p {
    padding-left: 10px;
    margin: 2px;
    font-size: 12px;
  }
  @media screen and (max-width: 800px) {
    align-items: baseline;
    p {
      text-align: left;
      align-items: flex-start;
      font-size: 12px;
    }
  }
  @media screen and (max-width: 500px) {
    p {
      width: 90%;
      padding-left: 5px;
    }
  }
  @media screen and (max-width: 320px) {
    p {
      width: 100%;
    }
  }
`;
export const Key = styled.div`
  background-color: ${colors.black};
  width: 5px;
  height: 5px;
  border-radius: 10px;
`;
export const Box = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 800px) {
    /* width: 100%; */
    align-items: center;
    justify-content: center;
    display: flex;
    width: 35%;
  }
  @media screen and (max-width: 500px) {
    width: 45%;
  }
`;
export const YrWar = styled.div`
  border: none;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  margin-right: 12px;
  width: 70px;
  background-color: ${colors["background-blue"]};
  height: 70px;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  margin-top: 5px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  // @media screen and (max-width: 500px){
  // width: 90%;
  // }
  // @media screen and (max-width: 400px){
  // width: 100%;
  // }
`;
export const Year = styled.div`
  font-size: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: ${fonts.fontWeight3};
  padding-left: 5px;
  p {
    margin: 0;
    font-weight: 500;
  }
  img {
    width: 19px;
    height: 19px;
    margin-bottom: 3px;
  }
`;
export const Details = styled.div`
  color: ${colors.blue};
  padding-right: 5px;
`;
export const FooterDiv = styled.div``;
export const Cards1 = styled.div``;

export const NumerAdd = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 0 0;
  margin-top:1.563rem;

  // justify-content: space-evenly;
  button {
    background-color: ${colors.white};
    border: 0.5px solid ${colors.ash};
    width: 35px;
    height: 35px;
  }
  input {
    background-color: ${colors.white};
    border: 0.5px solid ${colors.ash};
 
    height: 31px;
    width: 5.125rem;
    margin: 0 10px;
  
    text-align: center;
  }
  @media (min-width: 1200px) and (max-width: 1440px) {
    width: 40%;
  }
  @media (min-width: 900px) and (max-width: 1100px) {
    width: 30%;
  }
  @media (min-width: 700px) and (max-width: 800px) {
    width: 25%;
  }
  @media (min-width: 2000px) and (max-width: 3000px) {
    width: 22%;
  }
`;

export const Cash = styled.div`
  margin-top: 1rem;
`;
export const Mrp = styled.div`
  width: fit-content;
  p {
    width: 9rem;
  }
  display: flex;
  p {
    color: ${colors.ash};
    font-size: 13px;
    margin: 0;
    font-weight: ${fonts.fontWeight5};
  }
`;

export const Mrps = styled.div`
  > p {
    width: 8rem;
  }
  display: flex;
  line-height: 1.7;
  gap: 1rem;
  p {
    color: ${colors.ash};
    font-size: 13px;
    margin: 0;
    font-weight: ${fonts.fontWeight5};
  }
`;

export const Rupa = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  color: ${colors.ash};
  p {
    /* text-decoration: line-through; */
    color: ${colors.ash};
  }
  @media (min-width: 600px) and (max-width: 800px) {
    // padding-left:200px;
    p {
      width: 200px;
    }
    // margin:0;
  }
  @media (min-width: 400px) and (max-width: 500px) {
    p {
      width: 200px;
    }
  }
  @media (min-width: 360px) and (max-width: 380px) {
    p {
      width: 100px;
    }
  }
  @media (min-width: 300px) and (max-width: 350px) {
    p {
      width: 100px;
    }
  }
`;
export const Rupees = styled.div`
  p {
    color: ${colors.ash};
    font-size: 13px;
    font-weight: ${fonts.fontWeight5};
    margin: 0;
  }
  span {
    color: ${colors.blue};
    font-size: 13px;
    font-weight: ${fonts.fontWeight5};
    margin: 0 0 0 5px;
    cursor: pointer;
  }
`;

export const Rupees1 = styled.div`
  display: flex;
  flex-direction: row;
  p {
    color: ${colors.ash};
    font-size: 13px;
    font-weight: ${fonts.fontWeight5};
    margin: 0;
  }
  span {
    color: ${colors.blue};
    font-size: 13px;
    font-weight: ${fonts.fontWeight5};
    margin: 0 0 0 5px;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: ${colors.blue};
  }
  @media (min-width: 400px) and (max-width: 430px) {
    margin: 0;
    p {
      padding-left: 30px;
      width: 50px;
    }
    span {
      margin: 0;
      width: 100px;
    }
  }
`;

export const Rupees2 = styled.div`
  display: flex;
  flex-direction: row;
  p {
    color: ${colors.ash};
    font-size: 13px;
    font-weight: ${fonts.fontWeight5};
    margin: 0;
  }
  span {
    color: ${colors.blue};
    cursor: pointer;
    font-size: 13px;
    font-weight: ${fonts.fontWeight5};
    margin: 0 0 0 5px;
    text-decoration: underline;
    text-decoration-color: ${colors.blue};
  }
  @media (min-width: 600px) and (max-width: 800px) {
    p {
      // padding-left:200px;
    }
  }
`;
export const Price = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;


export const Cash1 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
font-weight: 600;
font-size:1.5rem;
 padding-bottom:0.938rem;
  span {
    cursor: pointer; 
  }
  p{
     margin:0;
  }
`;





export const Point = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  p {
    font-size: 12px;
    color: ${colors.black};
    margin: 0;
    font-weight: 500;
  }
  img {
    width: 18px;
    height: 18px;
    // margin:0 2px;
    margin-bottom: 3px;
  }
`;
export const Button = styled.div`
  button {
    max-width: 12.75rem;
    padding: 12px 57px;
    background-color: ${colors.blue};
    color: ${colors.white};
    border: none;
    margin-top: 1.313rem;
  }
`;
export const Help = styled.div`
  height: 100px;
  width: 100%;
  background-color: "blue";
`;















//new style 

export const FDiv = styled.div`
 margin: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      height: 100vh;

`

export const SubDiv1 = styled.div`
display:flex;
justify-content:center;
align-items:center;
border-right: 1px solid #486BE880;
`


export const SubDiv2 = styled.div`
display:flex;
margin-top:9.5rem;
margin-left:4.813rem;
`


export const WarDiv = styled.div`
margin-top:1.563rem;
width:22.625rem;
padding:0.75rem 0.813rem;
display:flex;
justify-content:space-between;
align-items:center;
border:1px solid #00000033;
`;


export const MRP = styled.div`
display:flex;
flex-direction:row;
gap:1rem;
 color: ${colors.ash};
p{
  margin:0;
  font-weight:400;
  font-size:1rem;
}
`;



export const QtyBox = styled.div`
border: 0.5px solid ${colors.ash};
height: 2.188rem;
width:5.125rem;
margin: 0 10px;
text-align: center;
display:flex;
justify-content:center;
align-items:center;
`;



