import { React, useState, useEffect } from "react";

import {
  Boxs,
  CardData,
  CardData2,
  contents,
  datas,
  Keyf,
} from "../../../MappingDatas/AccessoriesDetailsData";
import {
  MainDiv,
  SlideerDiv,
  Card,
  ContentDiv,
  ContMain,
  Heading,
  Rate,
  Icon,
  Rateings,
  KeyF,
  Table,
  Box,
  Year,
  Details,
  FooterDiv,
  Cards1,
  NumerAdd,
  IconDiv,
  AddDiv,
  Prices,
  Div1,
  Mrp,
  Rupa,
  Point,
  Cash,
  Div2,
  Cash1,
  Tax,
  Cash2,
  Button,
  Cards2,
  Card1,
  CardIcon,
  Card2,
  Key,
  Discription,
  Cards,
  Remove,
  CardImage,
  CardName,
  CardCash,
  Off,
  MrpCard,
  Offer,
  YrWar,
  Cards3,
} from "./CardsAdd.Styled";
import { IoStarOutline, IoRemove, IoAdd } from "react-icons/io5";
import { BiPlusMedical } from "react-icons/bi";
import Carousel from "react-carousel-minimal/dist/components/Carousel";
import { useNavigate } from "react-router-dom";
import { getAllCart, getCart, productDetailedDisplay } from "../../../api/api";
import { AuthContext } from "../../../consts/AuthContext";
import { useContext } from "react";
import axios, { Axios } from "axios";
import LoadingSpinner from "../../../components/Button/Loading/LoadingSpinner";
import ErrorComponent from "../../../components/Button/error/ErrorComponent";
import axiosInstance from "../../../consts/axiosInstance";



const CardsAdd = ({ det, count }) => {




  //product id of the selected product
  const productdetailid = JSON.parse(localStorage.getItem("Productid"));

  // id of accessory selected product
  const accessoriesid = JSON.parse(localStorage.getItem("accids"));


  const Acclistss = JSON.parse(localStorage.getItem("acclistss"));
  const Cartpid = JSON.parse(localStorage.getItem("cartpid"));



  const [authUser, setAuthUser] = useContext(AuthContext);
  const navigation = useNavigate();
  const [selectedImg, setSelectedImg] = useState(
    Acclistss?.productImages && JSON.parse(Acclistss?.productImages)[0]
  );




  const [accessories, setAccessories] = useState([]);
  const [myProducts, setMyProducts] = useState([]);
  // main product
  const [selectedProduct, setSelectedProduct] = useState('')
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);




  // accessory product details
  const accessoriesDetails = async () => {
    try {
      setLoading(true);
      //accessoriesid  is the product id of the accessory that we selecetd
      const response = await axiosInstance.get(`getProductDetailedDisplay?id=${accessoriesid}`);
      console.log(response.data, " response of product details")
      setAccessories(response.data);

      response?.data?.images && setSelectedImg(JSON?.parse(response?.data?.images)[0]);
      localStorage.setItem("response", JSON.stringify(response.data));


    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };



  // getting detail of the parent product
  async function getallcart() {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`getAllCart/${authUser?.user?.id}`);
      setMyProducts(response?.data[0].productArray)
      setSelectedProduct(response?.data[0].productArray.find((product) => product.productId === productdetailid))
    } catch (error) {
      console.error("Error fetching cart:", error);
    } finally {
      setLoading(false);
    }
  }




  useEffect(() => {
    accessoriesDetails();
    getallcart();
  }, []);






  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }


  return (

    <Cards3>
      <Card2>
        <CardImage>
          <img
            src={selectedProduct?.productImages && JSON.parse(selectedProduct.productImages)[0]}
          />
        </CardImage>
        <CardName>
          <p>{selectedProduct?.title}</p>
        </CardName>
        <CardCash>
          <p> ₹{selectedProduct?.offer_price}</p>
        </CardCash>
        <Off>


          <MrpCard>
            <p>M.R.P ₹{selectedProduct?.price}</p>
          </MrpCard>
        </Off>
      </Card2>


      <CardIcon>
        <BiPlusMedical style={{ color: "#808080" }} />
      </CardIcon>

      <Card2>
        <CardImage>
          <img src={accessories?.images && JSON.parse(accessories?.images)[0]} />
        </CardImage>
        <CardName>
          <p>{accessories?.title}</p>
        </CardName>
        <CardCash>
          {count === 1 ? (
            <p>₹{accessories?.comboPrice}</p>
          ) : (
            <p>₹{accessories?.offer_price * count}</p>
          )}
        </CardCash>

        <Off>
          <MrpCard>
            <p>M.R.P ₹{accessories?.price}</p>
          </MrpCard>
          <Offer>
            {/* <p>
              {Math.round(
                (JSON.stringify(accessories?.price - accessories?.offer_price) /
                  accessories?.price) *
                100
              )}{" "}
              % OFF
            </p> */}
          </Offer>
        </Off>
      </Card2>
    </Cards3>
  );
};

export default CardsAdd;
