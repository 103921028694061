import styled from "styled-components";
import { colors } from "../../../consts/themes";

export const Div = styled.div `
margin-top:8%;`

export const Buttondiv = styled.div `
margin:20px;
button{
    padding:10px 20px;
    border:none;
    border-radius:8px;
    background-color:${colors.blue};
    color:${colors.white};
    cursor:pointer;
}`