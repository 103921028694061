import styled from "styled-components";
import { colors } from "../../../consts/themes";

export const Table = styled.table`
    border:1px solid ${colors.black};
    width:100%;
    border-spacing:0;
    td , th{
        border:1px solid ${colors.black};
        display:flex;
        justify-content:center;
        align-items:center;
        height:2rem;
        text-transform:capitalize;
    }
    td{
        font-size:12px;
    }
    th{
        font-size:14px;
    }
`

export const Main = styled.div`
    
    *{
        box-sizing:border-box;
    }
    svg{
        position:absolute;
        top:2Rem;
        right:2rem;
        cursor:pointer;
    }
`

export const  Letter = styled.div`
    ${'' /* border:1px solid ${colors.black}; */}
    padding:.8rem .3rem;
    height: 40rem;
    width: 40rem;
    
`
export const SubHead = styled.h4`
    text-align:center;
    font-weight:400;
    font-size:14px;
    margin:0;
`
export const FlexBetween = styled.div`
    display:flex;
    justify-content:space-between;
    width:100%;
`
export const Items = styled.div`
padding:1rem 0;
` 

export const Narration = styled.p`
    margin-block:1rem;
`
export const Num = styled.td`
    width:2rem;
` 
export const Row = styled.tr`
    display:flex;
`
export const Item = styled.td`
    width:15rem;
`
export const Warranty = styled.td`
    width:6rem;
`
export const Price = styled.td`
    width:5.2rem;
    `
export const Quantity = styled.td`
        width:5rem;
`
export const Total = styled.td`
        width:6rem;
`

export const Sgst = styled.td`
    width: 28.2rem;
    justify-content:flex-start !important;
    padding-left:.5rem;
`
export const TotalSpan = styled.td`
    width:33.2rem;
    justify-content:flex-start !important;
    padding-left:.5rem;
`
export const TotalFull = styled.td`
    width:100%;
    justify-content:flex-start !important;
    padding-left:.5rem;
`

export const Terms = styled.div`
    margin-block:3rem;   
    font-size:14px;
`

export const Prepared = styled.div`
    margin-bottom: 1rem; 
    font-size:14px;   
`
export const FlexCol = styled.div`
display:flex;
justify-content:space-between;
flex-direction:column;
height: 100%;
`