import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CustomerSubCategory } from "../../api/api";
import axios from "axios";
import ErrorComponent from "../../components/Button/error/ErrorComponent";
import LoadingSpinner from "../../components/Button/Loading/LoadingSpinner";
import styled from "styled-components";
import { fonts } from "../../consts/themes";
import axiosInstance from "../../consts/axiosInstance";


function Subcategorytwo() {

  const navigation = useNavigate();
  // const [isOpen, setOpen] = useState(false);
  const [showproduct, setShowproduct] = useState();
  const [subcat, setSubcat] = useState([]);
  const [subcat1, setSubcat1] = useState([]);
  const [filteredArray, setFilteredArray] = useState([]);
  const [open, setOpen] = useState(false);
  const [tableOpen, setTableOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //id of 1st sub catagory
  const categoryid = JSON.parse(localStorage.getItem("id") || "[]");
  //color id of 1st sub catagory
  const cusssid = localStorage.getItem("cussid") || "[]";
  const productscode = JSON.parse(localStorage.getItem("productcode") || "[]");


  //2nd subcatageories
  const Subcategoryies = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`getCustomerSubCategory?category_id=${categoryid}`);
      // console.log(response.data, " response of 2nd SubCategory")
      setSubcat(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    Subcategoryies();
  }, []);


  const handleClick = (item) => {
    setOpen(true);
    const filtered1 = subcat1?.filter((itm) => JSON.parse(item?.color).includes(itm?.id));
    setFilteredArray(filtered1);
  };

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }

  return (
    <Screen>
      <ScreenCon>
        {subcat?.map((item, j) => {
          return (
            <CardImg key={item.id}>
              <ImgCard
                onClick={() => [
                  navigation("/sales/productcard/users/products"),
                  localStorage.setItem("subcateid", JSON.stringify(item?.id)),
                  localStorage.setItem("cscatid", (item?.id)),
                ]
                }
                style={{ background: `linear-gradient(45deg, {item?.color}, {item?.color} 100%)` }}
                key={j}>
                <img src={item?.sub_category_image} width="100%" height="100%"></img>
              </ImgCard>
              <P>{item?.sub_category_name}</P>
            </CardImg>
          );
        })}
      </ScreenCon>
    </Screen>
  );
}
export default Subcategorytwo;



const P = styled.p`
font-weight: 500;
font-size:12px;
@media only screen and (min-width: 250px) and (max-width: 550px) {font-size:10px;}
`;

const Screen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:0;
  padding-top:5rem;
  height: calc(100vh - 5rem);
`;

const ScreenCon = styled.div`
display: grid;
grid-template-columns: repeat(12, minmax(0, 1fr));
overflow-y: scroll;
margin-top:1rem;
@media only screen and (min-width: 250px) and (max-width: 350px) {gap: 0.5rem;}
@media only screen and (min-width: 351px) and (max-width: 768px) {gap: 1rem;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {gap: 1.5rem;}
@media only screen and (min-width: 1024px) {gap: 1.5rem;}
`;

const CardImg = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
@media only screen and (min-width: 250px) and (max-width: 768px) {grid-column: span 6 / span 6;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {grid-column: span 4 / span 4;}
@media only screen and (min-width: 1024px) {grid-column: span 3 / span 3;}
`;

const ImgCard = styled.div`
background-color: #ccc; 
width: 190px;
height: 190px;
background-color:#ccc;
border: .5px solid #ccc;
img{
    width: 190px;
    height: 190px;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    transition :all 0.3s ;
}
p{
    color: white;
    font-size: 16px;
    margin-top: 0px;
    padding-inline:10px;
    text-align:center;
    font-weight: ${fonts.fontWeight5};
    letter-spacing: 0.04rem;
}

@media screen and (max-width: 500px){
    justify-content: center;
    align-items: center;
    justify-content: space-around; 
    width: 140px;
    height: 140px;
    img{
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
}
p{
    color: white;
    font-size: 1rem;
    font-weight: 450;
    margin-top: 0px;
}
}
@media screen and (max-width: 320px){
justify-content: center;
align-items: center;
justify-content: space-around; 
width: 130px;
height: 130px;
img{
justify-content: center;
align-items: center;
width: 130px;
height: 130px;
}
p{
color: white;
font-size: 1rem;
font-weight: 450;
margin-top: 0px;
}
}
`

