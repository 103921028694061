import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { fonts } from "../../../../consts/themes";
import LoadingSpinner from '../../../../components/Button/Loading/LoadingSpinner';
import ErrorComponent from '../../../../components/Button/error/ErrorComponent';
import axiosInstance from "../../../../consts/axiosInstance";



const CctvChannel = () => {


  const navigation = useNavigate();

  const [cctvChannel, setCctvChannel] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const cctvchannel = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('displayCCTVchannelCategories');
      // console.log(response.data, " response of cctv ")
      setCctvChannel(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    cctvchannel();
  }, []);




  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }



  return (
    <Screen>

      <ScreenCon>
        <>
          {cctvChannel?.map((item) => {
            return (
              <CardImg key={item.id}>
                <ImgCard
                  style={{ background: `linear-gradient(45deg,{item.color.start} 0%, {item.color.end} 100%)`, }}
                  onClick={() => [
                    localStorage.setItem("cctvChannelId", JSON.stringify(item?.id)),
                    navigation('/cctcustomproduct')
                  ]}
                >
                  <img src={item?.image}></img>
                </ImgCard>
                <p style={{ color: "black" }}>{item?.channel_name}</p>
              </CardImg>
            );
          })}
        </>
      </ScreenCon>
    </Screen>
  )
}

export default CctvChannel



const Screen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:0;
  padding-top:5rem;
  height: calc(100vh - 5rem);
`;

const ScreenCon = styled.div`
display: grid;
grid-template-columns: repeat(12, minmax(0, 1fr));
overflow-y: scroll;
margin-top:1rem;
@media only screen and (min-width: 250px) and (max-width: 350px) {gap: 0.5rem;}
@media only screen and (min-width: 351px) and (max-width: 768px) {gap: 1rem;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {gap: 1.5rem;}
@media only screen and (min-width: 1024px) {gap: 1.5rem;}
`;

const CardImg = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
@media only screen and (min-width: 250px) and (max-width: 354px) {grid-column: span 12 / span 12;}
@media only screen and (min-width: 355px) and (max-width: 768px) {grid-column: span 6 / span 6;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {grid-column: span 4 / span 4;}
@media only screen and (min-width: 1024px) {grid-column: span 3 / span 3;}
`;


const ImgCard = styled.div`
background-color: #ccc; 
width: 190px;
height: 190px;
background-color:#ccc;
border: .5px solid #ccc;

img{
  object-fit: contain;
    width: 190px;
    height: 190px;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    transition :all 0.3s ;
}
p{
    color: white;
    font-size: 16px;
    margin-top: 0px;
    padding-inline:10px;
    text-align:center;
    font-weight: ${fonts.fontWeight5};
    letter-spacing: 0.04rem;
}

@media screen and (max-width: 500px){
    justify-content: center;
    align-items: center;
    justify-content: space-around; 
    width: 140px;
    height: 140px;
    img{
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
}
p{
    color: white;
    font-size: 1rem;
    font-weight: 450;
    margin-top: 0px;
}
}
@media screen and (max-width: 320px){
justify-content: center;
align-items: center;
justify-content: space-around; 
width: 130px;
height: 130px;
img{
justify-content: center;
align-items: center;
width: 130px;
height: 130px;
}
p{
color: white;
font-size: 1rem;
font-weight: 450;
margin-top: 0px;
}
}
`



