import React, { useState } from "react";
import { Modals, Images, Imageone, Imagetwo, Imagetext, Downbar, DIv, Downbars } from "./Desktoptype.styled";
import brandeddesktop from '../../assets/Images/png/Printer.png'
import customizeddesktop from '../../assets/Images/png/Rectangle2.png'
import { Overlay, ModalContainer, Modal } from "../modal";
import { useNavigate } from "react-router-dom";
import { GrFormClose } from 'react-icons/gr';





const arr = [
    {
        id: 0,
        title: 'Light'
    },
    {
        id: 1,
        title: 'Heavy'
    }
]





function PrinterType({ data, navi }) {

    const navigation = useNavigate();

    // const [datas, setDatas] = useState(false);

    // const printer = () => {
    //     navigation('/sales/productcard/users/products')
    // }


    const navfunction = () => {
        // setDatas(true);
        // combo ? navigation("/printer") : navigation('/sales/productcard/users/products')
        navigation(navi);
    }

    const closefunction = () => {
        data(false);
    }


    return (
        // <ModalContainer>
        <>
            {/* {datas === true ?

                printer()
                :
                ("")
            } */}
            <ModalContainer>
                <Modal>
                    <Overlay style={{ borderRadius: '0px' }}>
                        <Modals>
                            <DIv>
                                <GrFormClose size={20} onClick={() => closefunction()} />
                            </DIv>
                            <p>Choose Printer Type</p>
                            <Images>
                                {
                                    arr.map((item) => {
                                        return (
                                            <Downbar>
                                                <Imageone onClick={() => [navfunction(),
                                                localStorage.setItem('PrinterfilterId', JSON.stringify(item?.id))
                                                ]}>
                                                    <img src={brandeddesktop}></img>
                                                </Imageone>
                                                <Imagetext>{item?.title}</Imagetext>
                                            </Downbar>
                                        )
                                    })
                                }
                            </Images>
                        </Modals>
                    </Overlay>
                </Modal>
            </ModalContainer>
        </>
        // </ModalContainer>
    )
}
export default PrinterType;