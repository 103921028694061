
import React from 'react';
import styled from 'styled-components';
import aboutus1 from '../../../../assets/Images/png/aboutus1.jpg'
import aboutus2 from "../../../../assets/Images/png/aboutus2.jpg"

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height:100vh;
  background-color: #f8f9fa;

  @media only screen and (max-width:1024px){
    flex-direction:column;
    padding-top:130px;
    height:100vh;
  }
  @media only screen and (min-width:426px) and (max-width:1024px){
    flex-direction:column;
    padding-top:130px;
    height:130vh;
  }

  @media only screen  and (max-width:425px){
    flex-direction:column;
    height:180vh;

  }
  @media only screen and (max-width:425px){
    align-items:flex-start;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position:relative;

  @media only screen and (max-width:425px){
    padding-left: 40px;
  }
`;

const ImageOne = styled.img`
  border-radius: 10px;
  width: 350px;
  height: auto;
  object-fit: cover;
  @media only screen and (max-width:375px){
    width:180px;

  };
  @media only screen and (min-width:376px) and (max-width:425px){
    width:250px;
  }
`;
const ImageTwo = styled.img`
  border-radius: 10px;
  width: 350px;
  height: auto;
  object-fit: cover;
  position: absolute;
  left: 171px;
  top: 93px;
  @media only screen and (max-width:375px){
    width:180px;
    left: 105px;
    top: 85px;
  };
  @media only screen and (min-width:376px) and (max-width:425px){
    width:250px;
    left: 140px;
  }
`;

const TextContainer = styled.div`
  max-width: 600px;
  padding-left: 50px;
  @media only screen and (max-width:1024px){
    max-width: 100%;
    margin-top:100px;
    padding-right:50px
  }
  @media only screen and (max-width:425px){
    max-width: 100%;
    margin-top:100px;
    padding-right:20px;
    padding-left:20px;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  font-weight: bold;
  color: #1a2b4c;
`;

const Description = styled.p`
  font-size: 1rem;
  color: #5a5a5a;
  line-height: 1.8;
  margin-top: 20px;
`;

const AboutUs = () => {
  return (
    <Container>
      <ImageContainer>
        <ImageOne src={aboutus1} alt="Team working" />
        <ImageTwo src={aboutus2}  alt="Happy customer" />
      </ImageContainer>
      <TextContainer>
        <Title>Your Innovative E-Commerce Platform.</Title>
        <Description>
          Welcome to our cutting-edge e-commerce platform, we specialize in providing the latest and greatest in IT products. Our commitment to quality is matched only by our dedication to delivering exceptional customer support and a seamless shopping experience. We offer a wide range of products, including computers, laptops, smartphones, and accessories, and our user-friendly interface makes us the go-to destination for all of your IT requirements, in terms of products and services.

          We offer an extensive selection of genuine IT products and accessories, providing customers with unparalleled choice and convenience. We cater to a wide range of budgets and needs, and our products are sourced from reputable manufacturers to ensure the highest quality. Whether you're in the market for a high-end computer or a budget-friendly smartphone, we have the right product for you. Join us today and experience the best in IT services.
        </Description>
      </TextContainer>

    </Container>
  );
};

export default AboutUs;
