
import React, { useEffect } from 'react';
import {
  MainContainer,
  Left,
  Right,
  Inputbar,
  Inputlongbar,
  Selecting,
  Select,
  Rupees,
  Div,
  Rightline,
  Terms,
  Filter,
  Lists,
  Dot,
  Dotbar,
  Accept,
  DetailsDropDown,
  Dist,
} from '../services/modalbutton/ServiceCheckingStyled';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import axiosInstance from '../../consts/axiosInstance';
import Help from '../../components/Help/help';
import { AuthContext } from '../../consts/AuthContext';
import { colors, fonts } from '../../consts/themes';

function ReServiceReg() {
  const navigation = useNavigate();
  const { state } = useLocation();
  const {
    cartid,
    productsid,
    brand,
    slno,
    year,
    warranty,
    category,
    category_name,
    warrantyExp,
    serviceTypes,
  } = state || {};

  //service needed product details
  const [data, setData] = useState({
    serviceCategoryId: '',
    city: '',
    district: '',
    complaint: '',
  });

  const [productcate, setProductcate] = useState([]);
  const [serviceList, setServiceList] = useState('')
  const [district, setDistrict] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedDistrictId, setSelectedDistrictId] = useState('')
  const [cityList, setCityList] = useState([]);
  const [check, setCheck] = useState(0);
  const [list, setList] = useState([]);
  const [authUser, setAuthUser] = useContext(AuthContext);
  const [selectedCategory, setSelectedCategory] = useState(null);

  //service data
  let servData = {};

  const ProductlistCategory = async () => {
    try {
      const response = await axiosInstance.get('getProductCategory');
      setProductcate(response.data);

      if (category) {
        const foundCategory = response.data.find(item => item.id === parseInt(category));
        if (foundCategory) {
          setSelectedCategory(foundCategory);
        } else {
          console.error(`Category with ID ${category} not found`);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };


  //getting available services list
  const getAvailableServicesList = async () => {
    try {
      const response = await axiosInstance.get('getAvailableServices');
      setServiceList(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  //getting district list 
  const getDistrictdata = async () => {
    try {
      const response = await axiosInstance.get('getDistrict');
      setDistrict(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const compState = () => {
    servData = {
      product_id: productsid,
      cart_id: cartid,
      product_category_id: category,
      serviceTypes: serviceTypes,
      brand: brand,
      slno: slno,
      yearOfPurchase: year,
      warranty: warranty,
      warrantyExpiryDate: warrantyExp,
      user_id: authUser?.user?.id,

      serviceCategoryId: data?.serviceCategoryId,
      district: data?.district,
      city: data?.city,
      complaint: data?.complaint,

      servicePackDate: "",
      servicePackExpDate: "",
      customer_visibility: "",
      service_validity: "",
      advance_payment: "",
      total_amount: "",
      payment_status: "",
    };
  };

  function handleclick(value) {
    setCheck(value);
    check === value && setCheck(0);
  }

  const handleDistrictChange = (e) => {
    const selectedIndex = e.target.selectedIndex;
    // console.log(" selecetd index", selectedIndex);
    setSelectedDistrictId(e.target.value);
    setSelectedDistrict(selectedIndex <= 0 ? '' : district[selectedIndex - 1].district_name);
    setData((prev) => ({ ...prev, district: e.target.value }));
  }


  //getting city list based on district 
  useEffect(() => {
    const getAvailableCity = async () => {
      try {
        if (selectedDistrictId) {
          const response = await axiosInstance.get(`getCity/${selectedDistrictId}`);
          setCityList(response?.data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    getAvailableCity();
  }, [selectedDistrictId]);


  const ReButton = Object.values(data).every((value) => value !== '') && check === 1;

  useEffect(() => {
    getAvailableServicesList();
    getDistrictdata();
  }, []);

  useEffect(() => {
    ProductlistCategory();
  }, [category]);

  return (
    <MainContainer>
      <Left>

        {/* <img src={activeImage} alt='Product Image' /> */}
        <img src={selectedCategory?.product_category_image} alt='Product Image' />
        {/* {cartproduct &&
          product?.map((item) => {
            return (item?.id === cartproduct?.product_id && <p>{item?.description} </p>);
          })} */}
      </Left>
      <Right>
        {/* category name */}
        <Filter>
          <select> <option value={category}>{category_name}</option></select>
        </Filter>
        {/* brand name */}
        {<Inputbar placeholder='Brand' value={brand}></Inputbar>}
        {/* serial number */}
        <Inputbar placeholder='Serial number' value={slno}></Inputbar>
        {/* year of purcahse */}
        <Inputbar placeholder='Year of purchase' value={year}></Inputbar>
        {/* Warranty expiry date */}
        <Inputbar placeholder='Warranty expiry date' value={warrantyExp}></Inputbar>


        {/* serivices list */}
        <Filter>
          {serviceList &&
            <select onChange={(e) => { setData((prev) => ({ ...prev, serviceCategoryId: e.target.value })); }}>
              <option value=''>Service Price List</option>
              {serviceList.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.service_name} ₹{item.price}
                </option>
              ))}
            </select>
          }
        </Filter>

        {/* district selection */}
        <Filter>
          {district.length > 0 &&
            <select value={selectedDistrict.districtId} onChange={handleDistrictChange}>
              <option value=''>Choose District</option>
              {district.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.district_name}</option>
              ))}
            </select>
          }
        </Filter>

        {/* city selection */}
        {selectedDistrict && selectedDistrictId && (
          <Filter>
            {cityList &&
              <select onChange={(e) => { setData((prev) => ({ ...prev, city: e.target.value })); }}>
                <option value=''>Choose City</option>
                {cityList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.city_name}
                  </option>
                ))}
              </select>
            }
          </Filter>
        )}

        {/* complaint description */}
        <Inputlongbar
          placeholder='Describe the problem'
          value={data?.complaint}
          onChange={(e) =>
            setData((prev) => ({ ...prev, complaint: e.target.value }))
          }
        >
        </Inputlongbar>

        <Div>
          {/* <label onClick={() => inserted()}>Need Other Services?</label> */}
          <label>Need Other Services?</label>
          <Selecting>
            <Select>
              <Rightline>
                <p>Checking charge</p>
              </Rightline>

              <Rupees>
                <p>₹400</p>
              </Rupees>
            </Select>
          </Selecting>
        </Div>
        <Terms>
          <span>TERMS & CONDITIONS</span>
          <Lists>
            <Dotbar>
              <Dot></Dot>
            </Dotbar>
            <p>Checking charge must be paid in advance.</p>
          </Lists>
          <Lists>
            <Dotbar>
              <Dot></Dot>
            </Dotbar>
            <p>The service engineer should check the product with 48hrs.</p>
          </Lists>
          <Lists>
            <Dotbar>
              <Dot></Dot>
            </Dotbar>
            <p>
              And after checking the diagnosing the complaint and he will give
              the solutions.
            </p>
          </Lists>
          <Lists>
            <Dotbar>
              <Dot></Dot>
            </Dotbar>
            <p>
              Checking charge will deducting further related service charges.
            </p>
          </Lists>
          <Lists>
            <Dotbar>
              <Dot></Dot>
            </Dotbar>
            <p>
              Checking service charge will not be returned even if the complaint
              is not resolved.
            </p>
          </Lists>
          <Accept>
            <input type='checkbox' onClick={() => handleclick(1)} />
            <p>Accept all terms and conditions</p>
          </Accept>
        </Terms>

        <Buttonbar disabled={!ReButton}
          onClick={() =>
            check && [
              compState(),
              console.log("my data", servData),
              navigation('/service/registration/address', { state: { servData: servData, }, }),
            ]} >Register your complaint</Buttonbar>
      </Right>
      <Help />
    </MainContainer>
  );
}
export default ReServiceReg;


const Buttonbar = styled.button`
background-color: ${props => props.disabled ? '#D5E2FE' : '#4669E8'};
     cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
width:250px;
   margin:10px 2px;
   outline:none;
   border:none;
   border-radius:8px;
  
   padding:12px 0;
   font-size:14px;
   font-weight:${fonts.fontWeight5};
   color:${colors.white};
   @media(min-width:2000px) {
      font-size:16px;
      margin:12px 2px;
      padding:10px 0;
   }
   @media (max-width:500px){
      margin:10px 50px;
   }
   @media (max-width:380px){
      margin:10px 28px;
   }
   `