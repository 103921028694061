import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import logo from "../../../assets/Images/png/logoHeaderNew.png";
import image1 from "../../../assets/Images/png/Image1.png";
import { colors } from '../../../consts/themes';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { userLogin } from '../../../api/api';
import { AuthContext } from '../../../consts/AuthContext';



const Login = () => {

    // const navigation = useNavigate();

    // const [width, setWidth] = useState(window.innerWidth);
    // const [authUser, setAuthUser] = useContext(AuthContext);
    // const [mobileNumber, setMobileNumber] = useState('');
    // const [error, setError] = useState('');



    // // const handleMobileNumberChange = (event) => {
    // //     const { value } = event.target;
    // //     // Regular expression for a 10-digit mobile number
    // //     const mobileNumberRegex = /^[0-9]{10}$/;
    // //     setMobileNumber(value);
    // //     if (!mobileNumberRegex.test(value)) {
    // //         setError('Please enter a valid 10-digit mobile number');
    // //     } else {
    // //         setError('');
    // //     }
    // // };



    // async function Loginfunc() {

    //     const form = new FormData();
    //     form.append("mob", mobileNumber);
    //     form.append("device_type", width <= 768 ? 1 : 0);

    //     const options = {
    //         method: 'POST',
    //         url: userLogin,
    //         headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' },
    //         data: form
    //     };

    //     try {
    //         const response = await axios.request(options);

    //         if (response.data.code === 200) {

    //             localStorage.setItem('token', response.data.token);
    //             localStorage.setItem("userid", response?.data?.user?.userid);
    //             setAuthUser({ user: response?.data?.user });
    //             // navigation('/signup/verification');
    //             navigation('/otp/verification')
    //         } else if (response.data.code === 401) {
    //             alert(`${response.data.message}`);
    //         }

    //     } catch (error) {
    //         console.error(error);
    //     }
    // }


    // const handleWindowSizeChange = () => {
    //     setWidth(window.innerWidth);
    // };


    // useEffect(() => {
    //     window.addEventListener('resize', handleWindowSizeChange);
    //     return () => {
    //         window.removeEventListener('resize', handleWindowSizeChange);
    //     };
    // }, []);


    // // new
    // const handleMobileNumberChange = (e) => {
    //     const value = e.target.value;
    //     if (/^\d*$/.test(value) && value.length <= 10) {
    //         setMobileNumber(value);
    //     }
    // };

    // const validatePrimaryMobileNumber = () => {
    //     let error = '';
    //     if (mobileNumber.length > 0 && mobileNumber.length < 10) {
    //         error = 'enter a valid 10-digit mobile number';
    //     }
    //     setError(error);
    // };

    // useEffect(() => {
    //     validatePrimaryMobileNumber();
    // }, [mobileNumber]);

    const navigation = useNavigate();

    const [width, setWidth] = useState(window.innerWidth);
    const [authUser, setAuthUser] = useContext(AuthContext);
    const [mobileNumber, setMobileNumber] = useState('');
    const [error, setError] = useState('');



    // const handleMobileNumberChange = (event) => {
    //     const { value } = event.target;
    //     // Regular expression for a 10-digit mobile number
    //     const mobileNumberRegex = /^[0-9]{10}$/;
    //     setMobileNumber(value);
    //     if (!mobileNumberRegex.test(value)) {
    //         setError('Please enter a valid 10-digit mobile number');
    //     } else {
    //         setError('');
    //     }
    // };



    async function Loginfunc() {

        //     const form = new FormData();
        //     form.append("mob", mobileNumber);
        //     form.append("device_type", width <= 768 ? 1 : 0);

        //     const options = {
        //         method: 'POST',
        //         url: userLogin,
        //         headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' },
        //         data: form
        //     };

        //     try {
        //         const response = await axios.request(options);

        //         if (response.data.code === 200) {

        //             localStorage.setItem('token', response.data.token);
        //             localStorage.setItem("userid", response?.data?.user?.userid);
        //             setAuthUser({ user: response?.data?.user });
        //             // navigation('/signup/verification');
        //             navigation('/otp/verification')
        //         } else if (response.data.code === 401) {
        //             alert(`${response.data.message}`);
        //         }

        //     } catch (error) {
        //         console.error(error);
        //     }
        // }

        const form = new FormData();
        form.append("mob", mobileNumber);
        const options = {
            method: 'POST',
            url: userLogin,
            headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' },
            data: form
        };

        try {
            const response = await axios.request(options);

            if (response.data.code === 200) {
                // Store the token and user id in localStorage
                // localStorage.setItem('token', response.data.token);
                // localStorage.setItem("userid", response?.data?.user?.userid);

                // // Store user data and mobile number in AuthContext
                // setAuthUser({ user: response?.data?.user, mobileNumber: mobileNumber });

                // console.log({ user: response?.data?.user, mobileNumber });

                // Navigate to OTP verification page
                navigation('/otp/verification', { state: { mobileNumber: mobileNumber } });
            } else if (response.data.code === 401) {
                alert(`${response.data.message}`);
            }

        } catch (error) {
            console.error(error);
        }
    }

    // const handleWindowSizeChange = () => {
    //     setWidth(window.innerWidth);
    // };


    // useEffect(() => {
    //     window.addEventListener('resize', handleWindowSizeChange);
    //     return () => {
    //         window.removeEventListener('resize', handleWindowSizeChange);
    //     };
    // }, []);


    // new
    const handleMobileNumberChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value) && value.length <= 10) {
            setMobileNumber(value);
        }
    };

    const validatePrimaryMobileNumber = () => {
        let error = '';
        if (mobileNumber.length > 0 && mobileNumber.length < 10) {
            error = 'enter a valid 10-digit mobile number';
        }
        setError(error);
    };

    useEffect(() => {
        validatePrimaryMobileNumber();
    }, [mobileNumber]);



    return (
        <Screen className='h-screen flex items-center justify-center'>
            <Container>
                <LeftContainer>
                    <ImageSection>
                        <InputSec>
                            <ImgSecLogo>
                                <img src={logo} alt="logo" />
                            </ImgSecLogo>
                            <ImgSecImg >
                                <img src={image1} alt='login-cover' />
                            </ImgSecImg>
                        </InputSec>
                        <AccSec gp={"0.2rem"}>
                            <ImgSecText fs={"1rem"} fw={"500"}>Login to Explore the clikekart World</ImgSecText>
                            <ImgSecText fs={"0.8em"} fw={"300"}>rem ipsum dolor sit amet, consectetur adipiscing elit,</ImgSecText>
                        </AccSec>
                    </ImageSection>
                </LeftContainer>

                <RightContainer>
                    <LoginSection>
                        <InputSec>
                            <LoginText fw={"600"} fs={"1rem"}>LogIn</LoginText>
                            <LoginText mt={"0.5rem"} fw={"600"} fs={"0.8rem"} clr={"#00000080"}>Enter your mobile number to login.</LoginText>
                            <Input>
                                <input
                                    type="number"
                                    placeholder='Mob Number'
                                    id="mobileNumber"
                                    name="mobileNumber"
                                    value={mobileNumber}
                                    onChange={handleMobileNumberChange}
                                />
                                {error && <Error>{error}</Error>}
                            </Input>
                            <SubmitBtn><button onClick={Loginfunc}>Log in</button></SubmitBtn>
                        </InputSec>
                        <AccSec gp={"1rem"}>
                            <LoginText fw={"500"} fs={"0.8rem"} clr={"#00000080"}>New to Clikekart ?
                                <Span clr={"#5173EA"} onClick={() => navigation('/signup')}> Create an account</Span> </LoginText>
                            <LoginText fw={"600"} fs={"0.75rem"} clr={"#00000080"}>By creating an account  or logginig in, you agree to clikekart
                                <Span clr={"#5173EA"}> condition of use </Span> and  <Span clr={"#5173EA"}>privacy policy</Span> </LoginText>
                        </AccSec>
                    </LoginSection>
                </RightContainer>
            </Container>
        </Screen >
    )
}

export default Login;

// import React, { useContext, useEffect, useState } from 'react'
// import styled from 'styled-components';
// import logo from "../../../assets/Images/png/logoHeaderNew.png";
// import image1 from "../../../assets/Images/png/Image1.png";
// import { colors } from '../../../consts/themes';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import { userLogin } from '../../../api/api';
// import { AuthContext } from '../../../consts/AuthContext';



// const Login = () => {


//     const navigation = useNavigate();

//     const [width, setWidth] = useState(window.innerWidth);
//     const [authUser, setAuthUser] = useContext(AuthContext);
//     const [mobileNumber, setMobileNumber] = useState('');
//     const [error, setError] = useState('');



//     // const handleMobileNumberChange = (event) => {
//     //     const { value } = event.target;
//     //     // Regular expression for a 10-digit mobile number
//     //     const mobileNumberRegex = /^[0-9]{10}$/;
//     //     setMobileNumber(value);
//     //     if (!mobileNumberRegex.test(value)) {
//     //         setError('Please enter a valid 10-digit mobile number');
//     //     } else {
//     //         setError('');
//     //     }
//     // };



//     async function Loginfunc() {

//         // const form = new FormData();
//         // form.append("mob", mobileNumber);
//         // form.append("device_type", width <= 768 ? 1 : 0);

//         // const options = {
//         //     method: 'POST',
//         //     url: userLogin,
//         //     headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' },
//         //     data: form
//         // };

//         // try {
//         //     const response = await axios.request(options);

//         //     if (response.data.code === 200) {

//         //         localStorage.setItem('token', response.data.token);
//         //         localStorage.setItem("userid", response?.data?.user?.userid);
//         //         setAuthUser({ user: response?.data?.user });
//         //         // navigation('/signup/verification');
//         //         navigation('/otp/verification')
//         //     } else if (response.data.code === 401) {
//         //         alert(`${response.data.message}`);
//         //     }

//         // } catch (error) {
//         //     console.error(error);
//         // }

//         const form = new FormData();
//         form.append("mob", mobileNumber);
//         form.append("device_type", width <= 768 ? 1 : 0);

//         const options = {
//             method: 'POST',
//             url: userLogin,
//             headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' },
//             data: form
//         };

//         try {
//             const response = await axios.request(options);

//             if (response.data.code === 200) {

//                 localStorage.setItem('token', response.data.token);
//                 localStorage.setItem("userid", response?.data?.user?.userid);
//                 setAuthUser({ user: response?.data?.user });
//                 // navigation('/signup/verification');
//                 navigation('/otp/verification')

//             } else if (response.data.code === 401) {
//                 alert(`${response.data.message}`);
//             }

//         } catch (error) {
//             console.error(error);
//         }
//     }




//     const handleWindowSizeChange = () => {
//         setWidth(window.innerWidth);
//     };


//     useEffect(() => {
//         window.addEventListener('resize', handleWindowSizeChange);
//         return () => {
//             window.removeEventListener('resize', handleWindowSizeChange);
//         };
//     }, []);


//     // new
//     const handleMobileNumberChange = (e) => {
//         const value = e.target.value;
//         if (/^\d*$/.test(value) && value.length <= 10) {
//             setMobileNumber(value);
//         }
//     };

//     const validatePrimaryMobileNumber = () => {
//         let error = '';
//         if (mobileNumber.length > 0 && mobileNumber.length < 10) {
//             error = 'enter a valid 10-digit mobile number';
//         }
//         setError(error);
//     };

//     useEffect(() => {
//         validatePrimaryMobileNumber();
//     }, [mobileNumber]);



//     return (
//         <Screen className='h-[85vh] flex items-center justify-center'>
//             <Container>
//                 <LeftContainer>
//                     <ImageSection>
//                         <InputSec>
//                             <ImgSecLogo>
//                                 <img src={logo} alt="logo" />
//                             </ImgSecLogo>
//                             <ImgSecImg >
//                                 <img src={image1} alt='login-cover' />
//                             </ImgSecImg>
//                         </InputSec>
//                         <AccSec gp={"0.2rem"}>
//                             <ImgSecText fs={"1rem"} fw={"500"}>Login to Explore the clikekart World</ImgSecText>
//                             <ImgSecText fs={"0.8em"} fw={"300"}>rem ipsum dolor sit amet, consectetur adipiscing elit,</ImgSecText>
//                         </AccSec>
//                     </ImageSection>
//                 </LeftContainer>

//                 <RightContainer>
//                     <LoginSection>
//                         <InputSec>
//                             <LoginText fw={"600"} fs={"1rem"}>LogIn</LoginText>
//                             <LoginText mt={"0.5rem"} fw={"600"} fs={"0.8rem"} clr={"#00000080"}>Enter your mobile number to login.</LoginText>
//                             <Input>
//                                 <input
//                                     type="number"
//                                     placeholder='Mob Number'
//                                     id="mobileNumber"
//                                     name="mobileNumber"
//                                     value={mobileNumber}
//                                     onChange={handleMobileNumberChange}
//                                 />
//                                 {error && <Error>{error}</Error>}
//                             </Input>
//                             <SubmitBtn><button onClick={Loginfunc}>Log in</button></SubmitBtn>
//                         </InputSec>
//                         <AccSec gp={"1rem"}>
//                             <LoginText fw={"500"} fs={"0.8rem"} clr={"#00000080"}>New to Clikekart ?
//                                 <Span clr={"#5173EA"} onClick={() => navigation('/signup')}> Create an account</Span> </LoginText>
//                             <LoginText fw={"600"} fs={"0.75rem"} clr={"#00000080"}>By creating an account  or logginig in, you agree to clikekart
//                                 <Span clr={"#5173EA"}> condition of use </Span> and  <Span clr={"#5173EA"}>privacy policy</Span> </LoginText>
//                         </AccSec>
//                     </LoginSection>
//                 </RightContainer>
//             </Container>
//         </Screen>
//     )
// }

// export default Login;



const Screen = styled.div`
  /* position: absolute; */
  /* top: calc(2.5rem + 50%);
  left: 50%;
  transform: translate(-50%, -50%); */
  max-width: 100%;
  overflow: auto;
  /* max-height: calc(100% - 5rem); */
`;


const Container = styled.div`
display: flex;
height:530px;
@media only screen and (max-width: 1023px) {
  height:370px;
}
`;

const LeftContainer = styled.div`
border-top-left-radius: 15px;
border-bottom-left-radius: 15px;
background: #4669E8;
width:380px;
padding:1.5rem;
@media only screen and (max-width: 1023px) {
display: none;
}
`;


const RightContainer = styled.div`
border:1px solid rgba(0, 0, 0, 0.2);
border-top-right-radius: 15px;
border-bottom-right-radius: 15px;
width:380px;
padding:1.5rem;
@media only screen and (max-width: 1023px) {border-radius: 15px;}
@media only screen and (max-width: 550px) {
width:300px;
margin:1rem;
}
`;

const ImageSection = styled.div`
height:100%;
display: flex;
flex-direction: column;
`;

const ImgSecLogo = styled.div`
width:100%;
display: flex;
justify-content:center;
img{
  width:100px;
  height:35px;
  object-fit: contain;
}
`;

const ImgSecImg = styled.div`
margin-top: 1rem;
width:100%;
display: flex;
justify-content:center;
img{
  width:270px;
  height:300px;
}
`;


const ImgSecText = styled.div`
margin-top: ${(props) => props.mt};
font-size:${(props) => props.fs};
font-weight: ${(props) => props.fw};
color: #FFFFFF;
width:100%;
display: flex;
justify-content:center;
`;

const LoginSection = styled.div`
height:100%;
display: flex;
flex-direction: column;
`;

const InputSec = styled.div`
flex-grow: 1;
display: flex;
flex-direction: column;
align-items: center;
`;

const AccSec = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap:${(props) => props.gp};
`;

const LoginText = styled.div`
margin-top:${(props) => props.mt};
font-size:${(props) => props.fs};
font-weight: ${(props) => props.fw};
color:${(props) => props.clr};
`;

const Span = styled.span`
font-size:${(props) => props.fs};
font-weight: ${(props) => props.fw};
color:${(props) => props.clr};
`;

const Input = styled.div`
margin-top: 1.5rem;
width:100%;
input{
  box-sizing: border-box;
  width: 100%;
  height: 2.5rem;
  border: 0.1px solid ${colors.line};
  border-radius: 5px;
  padding:0 0.5rem;
  &:focus {
      border-color: #587ef5;
      outline: 0;
    }
   /* Hide spinner controls */
   -moz-appearance: textfield; /* Firefox */
    appearance: none; /* Safari and Chrome */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none; /* Safari */
    }
  }
`

const Error = styled.div`
font-size:12px;
color:red;
`
const SubmitBtn = styled.div`
margin-top: 0.5rem;
width:100%;
display: flex;
justify-content: center;
button{
  width: 100%;
  padding:0.8rem 0;
  background-color:#4669E8;
  color:white;
  border: none;
  border-radius: 5px;
}
`;
