import React from 'react'
import styled from 'styled-components';
import LoadingChart from './table/LoadingChart';
import PowerConsumptionChart from './table/PowerConsumptionChart';

const UpsChart = () => {
  return (
    <Screen>
<LoadingChart/>
<PowerConsumptionChart/>
    </Screen>
  )
}

export default UpsChart;




const Screen = styled.div`
  margin-top: 5rem;
  height: calc(100vh - 5rem);
  max-height: calc(100vh - 5rem);
  overflow-y: auto;
`;