// import React, { useContext, useEffect, useRef, useState } from 'react'
// import styled from 'styled-components';
// import logo from "../../../assets/Images/png/logoHeaderNew.png";
// import image1 from "../../../assets/Images/png/imgOtp.png";
// import { colors } from '../../../consts/themes';
// import { useLocation, useNavigate } from 'react-router-dom';
// import Swal from 'sweetalert2';
// import { AuthContext } from '../../../consts/AuthContext';
// import axios from 'axios';
// import { userLogin } from '../../../api/api';




// const OtpVerification = () => {
//   // const navigation = useNavigate();
//   // const [otp, setOtp] = useState(['', '', '', '', '']);
//   // const inputRefs = useRef([]);

//   // const handleChange = (e, index) => {
//   //   const value = e.target.value;
//   //   if (/^[0-9]$/.test(value)) {
//   //     const newOtp = [...otp];
//   //     newOtp[index] = value;
//   //     setOtp(newOtp);

//   //     if (index < 4) {
//   //       inputRefs.current[index + 1].focus();
//   //     }
//   //   } else if (value === '') {
//   //     const newOtp = [...otp];
//   //     newOtp[index] = '';
//   //     setOtp(newOtp);
//   //   }
//   // };

//   // const handleKeyDown = (e, index) => {
//   //   if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
//   //     inputRefs.current[index - 1].focus();
//   //   }
//   // };

//   const location = useLocation();
//   const mobileNumber = location.state?.mobileNumber;
//   const [authUser, setAuthUser] = useContext(AuthContext);
//   const navigation = useNavigate();
//   const [otp, setOtp] = useState(['', '', '', '', '', '']);
//   const inputRefs = useRef([]);
//   const [deviceType, setDeviceType] = useState('');

//   useEffect(() => {
//     const width = window.innerWidth;
//     if (width <= 768) {
//       setDeviceType(1);
//     } else {
//       setDeviceType(0);
//     }
//   }, []);

//   const maskMobileNumber = (number) => {
//     if (number && number.length === 10) {
//       return `${number.slice(0, 2)}********${number.slice(-2)}`;
//     }
//     return number; // Return the original number if it's not 10 digits long
//   };

//   const handleChange = (e, index) => {
//     const value = e.target.value;
//     if (/^[0-9]$/.test(value)) {
//       const newOtp = [...otp];
//       newOtp[index] = value;
//       setOtp(newOtp);

//       if (index < 5) {
//         inputRefs.current[index + 1].focus();
//       }
//     } else if (value === '') {
//       const newOtp = [...otp];
//       newOtp[index] = '';
//       setOtp(newOtp);
//     }
//   };

//   const handleKeyDown = (e, index) => {
//     if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
//       inputRefs.current[index - 1].focus();
//     }
//   };

//   const handleSubmit = async () => {
//     const phoneNumber = mobileNumber; // Replace with actual phone number if available

//     try {
//       const formData = new FormData();
//       formData.append('phoneNumber', phoneNumber);
//       formData.append('otp', otp.join(''));  // Combine the OTP array into a string
//       formData.append('device_type', deviceType);  // Add device type

//       const response = await axios.post('https://dev.clikekart.com/api/verifyOTP', formData, {
//       // const response = await axios.post('http://192.168.1.39:8001/api/verifyOTP', formData, {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//         },
//       });

//       if (response.data.code === 200) {
//         Swal.fire({
//           position: 'center',
//           icon: 'success',
//           color: '#4169e1',
//           text: 'Login Success',
//           showConfirmButton: false,
//           timer: 1500,
//         });
//         localStorage.setItem('token', response.data.token);
//         localStorage.setItem("userid", response?.data?.user?.userid);
//         // Store user data and mobile number in AuthContext
//         setAuthUser({ user: response?.data?.user });

//         console.log({ user: response?.data?.user, mobileNumber });

//         navigation('/');
//       } else {
//         Swal.fire({
//           position: 'center',
//           icon: 'error',
//           text: `Error: ${response.data.message}`,
//           showConfirmButton: true,
//         });
//       }
//     } catch (error) {
//       console.error('Error verifying OTP:', error);
//       Swal.fire({
//         position: 'center',
//         icon: 'error',
//         text: 'An error occurred while verifying OTP. Please try again.',
//         showConfirmButton: true,
//       });
//     }
//   };
//   const handleResendOtp = async () => {
//     try {
//       const form = new FormData();
//       form.append("mob", mobileNumber);
//       const options = {
//         method: 'POST',
//         url: userLogin,
//         headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' },
//         data: form
//       };

//       const response = await axios.request(options);

//       if (response.data.code === 200) {
//         Swal.fire({
//           position: 'center',
//           icon: 'success',
//           text: 'OTP resent successfully!',
//           showConfirmButton: true,
//         });
//       } else {
//         Swal.fire({
//           position: 'center',
//           icon: 'error',
//           text: `Error: ${response.data.message}`,
//           showConfirmButton: true,
//         });
//       }
//     } catch (error) {
//       console.error('Error resending OTP:', error);
//       Swal.fire({
//         position: 'center',
//         icon: 'error',
//         text: 'An error occurred while resending OTP. Please try again.',
//         showConfirmButton: true,
//       });
//     }
//   };


//   return (
//     <Screen>
//       <Container>
//         <LeftContainer>
//           <ImageSection>
//             <InputSec>
//               <ImgSecLogo>
//                 <img src={logo} alt="logo" />
//               </ImgSecLogo>
//               <ImgSecImg >
//                 <img src={image1} alt='login-cover' />
//               </ImgSecImg>
//             </InputSec>
//             <AccSec gp={"0.2rem"}>
//               <ImgSecText fs={"1rem"} fw={"500"}>Verify your mobile number to continue</ImgSecText>
//               <ImgSecText fs={"0.8em"} fw={"300"}>rem ipsum dolor sit amet, consectetur adipiscing elit,</ImgSecText>
//             </AccSec>
//           </ImageSection>
//         </LeftContainer>


//         <RightContainer>
//           <LoginSection>
//             <InputSec>
//               <LoginText fw={"600"} fs={"1rem"}>Mobile Verification</LoginText>
//               <LoginText mt={"4rem"} fw={"400"} fs={"0.8rem"}>Please enter OTP to verify your account</LoginText>
//               <LoginText mt={"0.6rem"} fw={"400"} fs={"0.8rem"} clr={"#00000080"}>Enter OTP send to +91 {maskMobileNumber(mobileNumber)}</LoginText>

//               <Input>
//                 {otp.map((digit, index) => (
//                   <input
//                     key={index}
//                     type="text"
//                     value={digit}
//                     onChange={(e) => handleChange(e, index)}
//                     onKeyDown={(e) => handleKeyDown(e, index)}
//                     maxLength={1}
//                     ref={(el) => (inputRefs.current[index] = el)}
//                   />
//                 ))}
//               </Input>
//               <Resend onClick={handleResendOtp}><span>Resend OTP</span></Resend>
//               <SubmitBtn><button onClick={() => {
//                 Swal.fire({
//                   position: 'center',
//                   icon: 'success',
//                   color: '#4169e1',
//                   text: 'Login Success',
//                   showConfirmButton: false,
//                   timer: 1500
//                 })
//                 handleSubmit()
//               }}
//               >Continue</button></SubmitBtn>
//             </InputSec>
//           </LoginSection>
//         </RightContainer>
//       </Container>
//     </Screen >
//   )
// }

// export default OtpVerification

import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import logo from "../../../assets/Images/png/logoHeaderNew.png";
import image1 from "../../../assets/Images/png/imgOtp.png";
import { colors } from '../../../consts/themes';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { AuthContext } from '../../../consts/AuthContext';
import axios from 'axios';
import { userLogin } from '../../../api/api';
import { server_api } from '../../../consts/api';




const OtpVerification = () => {
  // const navigation = useNavigate();
  // const [otp, setOtp] = useState(['', '', '', '', '']);
  // const inputRefs = useRef([]);

  // const handleChange = (e, index) => {
  //   const value = e.target.value;
  //   if (/^[0-9]$/.test(value)) {
  //     const newOtp = [...otp];
  //     newOtp[index] = value;
  //     setOtp(newOtp);

  //     if (index < 4) {
  //       inputRefs.current[index + 1].focus();
  //     }
  //   } else if (value === '') {
  //     const newOtp = [...otp];
  //     newOtp[index] = '';
  //     setOtp(newOtp);
  //   }
  // };

  // const handleKeyDown = (e, index) => {
  //   if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
  //     inputRefs.current[index - 1].focus();
  //   }
  // };

  const location = useLocation();
  const mobileNumber = location.state?.mobileNumber;
  const [authUser, setAuthUser] = useContext(AuthContext);
  const navigation = useNavigate();
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef([]);
  const [deviceType, setDeviceType] = useState('');

  useEffect(() => {
    const width = window.innerWidth;
    if (width <= 768) {
      setDeviceType(1);
    } else {
      setDeviceType(0);
    }
  }, []);

  const maskMobileNumber = (number) => {
    if (number && number.length === 10) {
      return `${number.slice(0, 2)}********${number.slice(-2)}`;
    }
    return number; // Return the original number if it's not 10 digits long
  };

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    } else if (value === '') {
      const newOtp = [...otp];
      newOtp[index] = '';
      setOtp(newOtp);
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = async () => {
    const phoneNumber = mobileNumber; // Replace with actual phone number if available

    try {
      const formData = new FormData();
      formData.append('phoneNumber', phoneNumber);
      formData.append('otp', otp.join(''));  // Combine the OTP array into a string
      formData.append('device_type', deviceType);  // Add device type

      // const response = await axios.post('https://dev.clikekart.com/api/verifyOTP', formData, {
      const response = await axios.post(`${server_api}verifyOTP`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.code === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          color: '#4169e1',
          text: 'Login Success',
          showConfirmButton: false,
          timer: 1500,
        });
        localStorage.setItem('token', response.data.token);
        localStorage.setItem("userid", response?.data?.user?.userid);
        // Store user data and mobile number in AuthContext
        setAuthUser({ user: response?.data?.user });
        console.log({ user: response?.data?.user, mobileNumber });
        navigation('/');
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: `Error: ${response.data.message}`,
          showConfirmButton: true,
        });
        navigation('/otp/verification');
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      Swal.fire({
        position: 'center',
        icon: 'error',
        text: 'An error occurred while verifying OTP. Please try again.',
        showConfirmButton: true,
      });
      navigation('/otp/verification');
    }
  };

  const handleResendOtp = async () => {
    try {
      const form = new FormData();
      form.append("mob", mobileNumber);
      const options = {
        method: 'POST',
        url: userLogin,
        headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json' },
        data: form
      };

      const response = await axios.request(options);

      if (response.data.code === 200) {
        Swal.fire({
          position: 'center',
          icon: 'success',
          text: 'OTP resent successfully!',
          showConfirmButton: true,
        });
      } else {
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: `Error: ${response.data.message}`,
          showConfirmButton: true,
        });
      }
    } catch (error) {
      console.error('Error resending OTP:', error);
      Swal.fire({
        position: 'center',
        icon: 'error',
        text: 'An error occurred while resending OTP. Please try again.',
        showConfirmButton: true,
      });
    }
  };


  return (
    <Screen className='h-screen flex items-center justify-center'>
      <Container>
        <LeftContainer>
          <ImageSection>
            <InputSec>
              <ImgSecLogo>
                <img src={logo} alt="logo" />
              </ImgSecLogo>
              <ImgSecImg >
                <img src={image1} alt='login-cover' />
              </ImgSecImg>
            </InputSec>
            <AccSec gp={"0.2rem"}>
              <ImgSecText fs={"1rem"} fw={"500"}>Verify your mobile number to continue</ImgSecText>
              <ImgSecText fs={"0.8em"} fw={"300"}>rem ipsum dolor sit amet, consectetur adipiscing elit,</ImgSecText>
            </AccSec>
          </ImageSection>
        </LeftContainer>


        <RightContainer>
          <LoginSection>
            <InputSec>
              <LoginText fw={"600"} fs={"1rem"}>Mobile Verification</LoginText>
              <LoginText mt={"4rem"} fw={"400"} fs={"0.8rem"}>Please enter OTP to verify your account</LoginText>
              <LoginText mt={"0.6rem"} fw={"400"} fs={"0.8rem"} clr={"#00000080"}>Enter OTP send to +91 {maskMobileNumber(mobileNumber)}</LoginText>

              <Input>
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    type="text"
                    value={digit}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    maxLength={1}
                    ref={(el) => (inputRefs.current[index] = el)}
                  />
                ))}
              </Input>

              {/* <Resend><span>Resend OTP</span></Resend> */}
              <Resend onClick={handleResendOtp}><span>Resend OTP</span></Resend>
              <SubmitBtn><button onClick={() => {
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  color: '#4169e1',
                  text: 'Login Success',
                  showConfirmButton: false,
                  timer: 1500
                })
                navigation('/')
                handleSubmit()
              }}
              >Continue</button></SubmitBtn>
            </InputSec>
          </LoginSection>
        </RightContainer>
      </Container>
    </Screen >
  )
}

export default OtpVerification






const Screen = styled.div`
  /* position: absolute;
  top: calc(2.5rem + 50%);
  left: 50%;
  transform: translate(-50%, -50%); */
  max-width: 100%;
  overflow: auto;
  /* max-height: calc(100% - 5rem); */
`;


const Container = styled.div`
display: flex;
height:530px;
@media only screen and (max-width: 1023px) {
  height:370px;
}
`;

const LeftContainer = styled.div`
border-top-left-radius: 15px;
border-bottom-left-radius: 15px;
background: #4669E8;
width:380px;
padding:1.5rem;
@media only screen and (max-width: 1023px) {
display: none;
}
`;


const RightContainer = styled.div`
border:1px solid rgba(0, 0, 0, 0.2);
border-top-right-radius: 15px;
border-bottom-right-radius: 15px;
width:380px;
padding:1.5rem;
@media only screen and (max-width: 1023px) {border-radius: 15px;}
@media only screen and (max-width: 550px) {
width:300px;
margin:1rem;
}
`;

const ImageSection = styled.div`
height:100%;
display: flex;
flex-direction: column;
`;

const ImgSecLogo = styled.div`
width:100%;
display: flex;
justify-content:center;
img{
  width:100px;
  height:35px;
  object-fit: contain;
}
`;

const ImgSecImg = styled.div`
margin-top: 1rem;
width:100%;
display: flex;
justify-content:center;
img{
  width:400px;
  height:280px;
}
`;


const ImgSecText = styled.div`
margin-top: ${(props) => props.mt};
font-size:${(props) => props.fs};
font-weight: ${(props) => props.fw};
color: #FFFFFF;
width:100%;
display: flex;
justify-content:center;
`;

const LoginSection = styled.div`
height:100%;
display: flex;
flex-direction: column;
`;

const InputSec = styled.div`
flex-grow: 1;
display: flex;
flex-direction: column;
align-items: center;
margin:0 1rem;

@media only screen and (max-width: 550px) {
margin:0;
}

`;

const AccSec = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap:${(props) => props.gp};
`;

const LoginText = styled.div`

font-size:${(props) => props.fs};
font-weight: ${(props) => props.fw};
color:${(props) => props.clr};
@media only screen and (min-width: 500px) {
margin-top:${(props) => props.mt};
}
margin-top:1rem;

`;






const Input = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 1.5rem;
width:100%;
input{
  text-align:center;
  box-sizing: border-box;
  width:3rem;
  height:3rem;
  border: 0.1px solid ${colors.line};
  border-radius: 5px;
  padding:0 0.5rem;
  &:focus {
      border-color: #587ef5;
      outline: 0;
    }
   /* Hide spinner controls */
   -moz-appearance: textfield; /* Firefox */
    appearance: none; /* Safari and Chrome */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none; /* Safari */
    }

@media only screen and (max-width: 550px) {
  width:2.5rem;
  height:2.5rem;
}
  }
`

const SubmitBtn = styled.div`
margin-top: 0.5rem;
width:100%;
display: flex;
justify-content: center;
button{
  width: 100%;
  padding:0.8rem 0;
  background-color:#4669E8;
  color:white;
  border: none;
  border-radius: 5px;
}
`;




const Resend = styled.div`
margin-top:1rem;
display: flex;
justify-content: flex-end;
width:100%;
span{
  color:#4669E8;
  font-size: 0.8rem;
  font-weight: 500;
text-decoration: underline;
}
`;

