import React from "react";
import { Assistancemain, Assistancecontentcontainer, DIv, Assistancecontent, Assistancebutton, Buttonone, Buttontwo } from './Assistance.styled'
import Vector from '../../assets/sale/assistance/Vector.png'
import { Modal, ModalContainer, Overlay } from "../modal";
import { useNavigate } from "react-router-dom";
import { GrFormClose } from 'react-icons/gr';

function Assistance({ close }) {

    const navigation = useNavigate();


    const closefunction = () => {
        close(false);
    }


    return (

        <ModalContainer>
            <Modal>
                <Overlay>

                    <Assistancecontent>
                        <DIv>
                            <GrFormClose size={20} onClick={() => close(false)} />
                        </DIv>

                        <p>Do you need our help to choose the right product ?</p>
                        <Assistancebutton>
                            <Buttonone id="btn" onClick={() => [navigation('/sales/help'), close(false)]}>
                                Yes
                            </Buttonone>
                            <Buttontwo onClick={() => close(false)}>
                                No
                            </Buttontwo>
                        </Assistancebutton>
                    </Assistancecontent>
                </Overlay>
            </Modal>
        </ModalContainer>
        //     </Assistancecontentcontainer>
        // </Assistancemain>
    )
}
export default Assistance;