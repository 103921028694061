import styled from "styled-components";
import Colors from "../../consts/Colors";
import { colors } from "../../consts/themes";
export const Loginmain = styled.div`

position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100vh;
backdrop-filter: blur(0.5px);
opacity: 5;

`

export const Loginmaincontainer = styled.div`
position: absolute;
top: 150px;
right: calc(50% - 160px);
padding: 20px;
border-radius:5px;
width:23%;
height:55%;
background: ${Colors.white};
border: 1px solid white;
border: thin solid #e3e3e3 ;
box-shadow: 0 6px 6px rgba(0, 0, 0, 0.1);

@media(max-width:2560px)
{
    right: calc(50% - 250px);
    
}

@media(max-width:1440px)
{
    right: calc(50% - 160px);
}

@media(max-width:1024px)
{
    top: 100px;
}
@media(max-width:768px)
{
    width:33%;
}

@media(max-width:425px)
{
    width:auto;
    right: calc(50% - 100px);
}
@media(max-width:375px)
{
    right: calc(50% - 120px);
}

`
export const Logincontent = styled.div`

display:flex;
flex-direction:column;
row-gap:30px;
padding-inline: 5rem;
padding-top: 5rem;
padding-bottom: 10rem;
@media(max-width:2560px)
{
    row-gap:60px;
}
@media(max-width:1440px)
{
    row-gap:30px;
}
@media(max-width:425px)
{
padding-inline: 2rem;
}
@media (max-width: 375px)
{
    padding-inline: 0rem;
}
@media (max-width: 320px)
{
align-items: center;
}
img
{
    width: 12vw;
    align-self: center;
    height:auto;

    @media(max-width:768px)
    {
        width:25vw;
    }
    @media(max-width:425px)
    {
        width:40vw;
    }
    @media(max-width:375px)
    {
    width:45vw;
    }
}

`
export const Logincontentsub = styled.div`
/* align-items: center; */
display:flex;
flex-direction:column;
/* margin-left:2vw; */
row-gap:15px;

@media(max-width:2560px)
{
    row-gap:45px;
}
@media(max-width:1440px)
{
    row-gap:15px;
}


`
export const Label = styled.div`
font-size:1rem;
color: ${colors.light_grey};
text-align: left;
margin-bottom: 1rem;
@media (max-width: 320px)
{
    font-size: 0.875rem;
}
`
export const Input = styled.div`

// height:5vw;
input
{
width:16.5vw;
padding-left: 5px;
font-size: 0.875rem;
height:1.7vw;
border-radius:5px;
border: 1px solid  #e3e3e3;
@media(max-width:1440px)
{
    width:20vw;
    height:2.5vw;
    padding-left: 10px;
}

@media(max-width:1024px)
{
    width:28vw;
    height:4vw;
    padding-left: 10px;
}

@media(max-width:768px)
{
  width:35.8vw;
  height:5vw;
}

@media(max-width:425px)
{
  width:65vw;
  height:8vw;
}

@media(max-width:375px)
{
    width:75vw;
    height:10vw;
}
}

`
export const Logintextone = styled.div`

width:17vw;
height:auto;
border-radius:8px;
font-size:1rem;
display:flex;
padding-block:4px;
justify-content:center;
color: ${Colors.white};
background: ${Colors.royalblue};

@media(max-width:1440px)
{
    width: 21vw;
    height: auto;
    padding-block: 10px;
    border-radius: 8px;
    font-size: 0.875rem;
}

@media(max-width:1024px)
{
    width:29vw;
    /* height:4vw; */
    padding-left: 5px;
}

@media(max-width:768px)
{
  width: 37vw;
  /* height: 2vw; */
}

@media(max-width:425px)
{
    width:66.5vw;
  /* height:8vw; */
}

@media(max-width:375px)
{
    width:77vw;
}
`
export const Logintexttwo = styled.div`
font-size:1rem;
border-radius:10px;
border: 1px solid #e3e3e3;
width:17vw;
height:auto;
display:flex;
padding-block:4px;
justify-content:center;

@media(max-width:1440px)
{
    width: 21vw;
    height: auto;
    padding-block: 10px;
    border-radius: 8px;
    font-size: 0.875rem;
}
@media(max-width:1024px)
{
    width:29vw;
    /* height:4vw; */
    padding-left: 5px;
}
@media(max-width:768px)
{
  width: 37vw;
  /* height: 2vw; */
}

@media(max-width:425px)
{
    width:66.5vw;
}
@media(max-width:375px)
{
    width:77vw;
}
`
