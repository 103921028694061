
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import { checkQuiz, listQuizids, quizParticipantsCheck, quizStatus } from '../../api/api';
import { AuthContext } from '../../consts/AuthContext';
import axios from 'axios';
import LoadingSpinner from '../../components/Button/Loading/LoadingSpinner';
import ErrorComponent from '../../components/Button/error/ErrorComponent';
import QuizTandCModal from './QuizTandCModal';
import axiosInstance from '../../consts/axiosInstance';

const QuizMain = () => {

  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [authUser, setAuthUser] = useContext(AuthContext);
  const [checkQuizData, setCheckQuizData] = useState([]);
  const [checkQuizStatus, setCheckQuizStatus] = useState(0);

  const [isParticipated, setIsParticipated] = useState();
  const [quiz, setQuiz] = useState([]);
  const [join, setJoin] = useState(false);

  const startDate = new Date(quiz?.starting_date);
  const endDate = new Date(quiz?.ending_date);
  const sameDay = startDate.toDateString() === endDate.toDateString();


  const getQuizDetails = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('list_quizids');
      setQuiz(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  const getParticipation = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('quizparticipantscheck', {
        params: {
          quiz_id: quiz[0]?.id,
          user_id: authUser?.user?.id,
        }
      });
      setIsParticipated(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getQuizDetails();
  }, [])


  useEffect(() => {
    getParticipation();
  }, [quiz])




  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }

  return (
    <Main>

      {join && <QuizTandCModal endTime={quiz[0]?.ending_time1} />}

      <SubOne>
        <SubContainerOne>
          <HeadContainer>
            <HeadOne>FIND ANSWERS</HeadOne>
            <HeadTwo>TEST YOUR <span>KNOWLEDGE</span></HeadTwo>
            <HeadThree>Attend our Quiz</HeadThree>
            <HeadFour>Get Clickepoints</HeadFour>

            {quiz.length === 0 ? <HeadThree>Coming Soon</HeadThree> :

              <HeadThree>
                <span>{quiz[0]?.starting_day}</span>
                {!sameDay && <span>-{quiz[0]?.ending_day}</span>}
                <span> ({quiz[0]?.starting_time}-{quiz[0]?.ending_time})</span>
              </HeadThree>
            }

          </HeadContainer>
        </SubContainerOne>

        <SubContainerTwo>
          <img src="/image/ques-img.png" />
        </SubContainerTwo>
      </SubOne>
      {
        quiz.length !== 0 && <>
          <SubTwo classname="flex justify-center items-center">

            <ButtonWrap className="items-center">
              {quiz?.length !== 0 && quiz[0]?.status === 1 && isParticipated?.status === 0 &&
                <JoinButton onClick={() => setJoin(true)}>Join Quiz</JoinButton>
              }
            </ButtonWrap>

          </SubTwo>
        </>
      }
    </Main>
  )
}




export default QuizMain


const Main = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  height: calc(100vh - 5rem); 
`

const SubTwo = styled.div`
background-color:#2B4FD0;
height: 30%;
`

const SubOne = styled.div`
position: relative;
height: 70%;
display: flex;
justify-content: center;
background-color:#4669E8;

@media only screen and (min-width: 250px) and (max-width: 1023px) {
  align-items: center;
}
@media only screen and (min-width: 1024px) {
  align-items: center;
}
`



const SubContainerOne = styled.div`
 z-index:999;
`


const SubContainerTwo = styled.div`
${'' /* grid-column: span 3 / span 3;  */}
background-color: #4669E8;
height:100%;
  position:absolute;
  z-index:998;
  top:0;
  right:0;

maxWidth:400px;

@media only screen and (max-width: 1023px) {
display:none;
}


${'' /* @media only screen and (min-width: 1024px) {
width:400px;
} */}

@media only screen and (min-width: 1024px) and (max-width: 1499px) {
  width:400px;
}


@media only screen and (min-width: 1024px) and (max-width: 1499px) {
  width:450px;
}



${'' /* @media only screen and (min-width: 1024px) and (max-width: 1500px) {
width:350px;
} */}


img{
  width:100%;
  height:100%;
  }
`

const HeadContainer = styled.div`
display:flex;
flex-direction:column;
justify-content: center;
gap: 1rem;
align-items: center;
color:white;
`


const HeadOne = styled.div`
font-weight:400;
@media only screen and (min-width: 250px) and (max-width: 499px) {
font-size:1.2rem;
}
@media only screen and (min-width: 500px) and (max-width: 767px) {
font-size:1.6rem;
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
font-size:1.7rem;
}
@media only screen and (min-width: 1024px) and (max-width: 1199px) {
font-size:1.3rem;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
font-size:1.6rem;
}
@media only screen and (min-width: 1500px) and (max-width: 1699px) {
font-size:2.3rem;
}
@media only screen and (min-width: 1700px) {
line-height:4rem;
font-size:2.8rem;
}
`

const HeadTwo = styled.div`
padding-top:0.5rem;
font-weight:400;

span{
  color:#2B4FD0;
}


@media only screen and (min-width: 250px) and (max-width: 499px) {
font-size:1.3rem;
}

@media only screen and (min-width: 500px) and (max-width: 767px) {
font-size:2.5rem;
}


@media only screen and (min-width: 768px) and (max-width: 1023px) {
font-size:3rem;
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
font-size:1.5rem;
line-height:1rem;
}

@media only screen and (min-width: 1200px) and (max-width: 1499px) {
font-size:2.5rem;
}

@media only screen and (min-width: 1500px) and (max-width: 1699px) {
font-size:4rem;
}

@media only screen and (min-width: 1700px) {
font-size:5rem;
line-height:4rem;
}
`


const HeadThree = styled.div`
padding-top:0.5rem;
line-height:2rem;
font-weight:200;
font-size:1.5rem;



@media only screen and (min-width: 250px) and (max-width: 499px) {
font-size:1rem;
}

@media only screen and (min-width: 500px) and (max-width: 1023px) {
font-size:1.2rem;
}

@media only screen and (min-width: 1024px) and (max-width: 1200px) {
font-size:1rem;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
font-size:1.3rem;
}
`

const HeadFour = styled.div`
padding-top:0.5rem;
line-height:2rem;
font-weight:400;
font-size:1.8rem;


@media only screen and (min-width: 250px) and (max-width: 499px) {
font-size:1.1rem;
}

@media only screen and (min-width: 500px) and (max-width: 767px) {
font-size:1.6rem;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
font-size:1.7rem;
}

@media only screen and (min-width: 1024px) and (max-width: 1199px) {
font-size:1.3rem;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {

}
@media only screen and (min-width: 1500px) and (max-width: 1700px) {

}



@media only screen and (min-width: 1024px) and (max-width: 1200px) {
font-size:1.3rem;
line-height:1rem;
}
`



const BaseButton = styled.button`
font-family: Poppins;
text-align: center;
color:white;
${'' /* background-color: #4669E8; */}
border:none;
border-radius: 5px;

@media only screen and (min-width: 250px) and (max-width: 399px) {
  font-size: 0.8rem;
  padding:1rem;
}

@media only screen and (min-width: 250px) and (max-width: 767px) {padding:0.8rem 2rem;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0.8rem 4rem;}
@media only screen and (min-width: 1024px)  {padding:1rem 6rem;}
`





const JoinButton = styled(BaseButton)`
  ${'' /* background-color: ${(props) => (props.enableBuy ? '#4669E8' : '#BCBCBC')}; */}
  background-color: white;
  color:#4669E8;
  font-size: 1.5rem;
  font-weight: 400;
  minmax:5rem;
  height: 5rem;
  @media only screen and (min-width: 250px) and (max-width:1023px) {
  font-size:1rem;
}

`;




const ButtonWrap = styled.div`
display:flex;
height: 100%;
justify-content: center;
margin-top:1rem;
`