import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../consts/AuthContext'
// import { CartDivBtn, ClikePointsDiv, DetailsDivI, ItemImage, MonDiv, ProductItem, 
//   TaxesWish, WishListContainer, WishListDiv, WishlistItem } from './WishListStyle'
import { Button } from '../../sale/product/LaptopAccesoriesStyle'
import { Box, Image, Title } from '../Orders/MyOrdersStyle'
// import { Box, Image, Product, Title } from '../Orders/MyOrdersStyle'
import { RiDeleteBinLine } from "react-icons/ri"
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import clikeLogo from '../../../assets/Images/png/clicke.png'
import ErrorComponent from '../../../components/Button/error/ErrorComponent'
import LoadingSpinner from '../../../components/Button/Loading/LoadingSpinner'
import axiosInstance from '../../../consts/axiosInstance'
import { colors, fonts } from '../../../consts/themes'

const WishList = () => {

  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [authUser, setAuthUser] = useContext(AuthContext)
  const [data, setData] = useState(null);





  const getWishList = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`getFavListById/${authUser?.user?.id}`);
      // console.log(response.data, " response of fav list")
      setData(response?.data)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  const favHandler = async (item) => {
    try {
      const formData = {
        user_id: authUser?.user?.id,
        product_id: item?.productId,
        product_category_id: item?.product_category_id
        ,
      };

      const options = {
        method: "POST",
        url: 'insertCustomerFav',
        headers: {
          "Content-Type": "application/json",
        },
        data: formData,
      };

      await axiosInstance.request(options).then((response) => {
        console.log("🚀 ~ update cart response", response);
      })

    } catch (error) {
      setError(error);
      console.log("🚀 ~ update cart ", error);

    } finally {
      getWishList();
    }

  }

  useEffect(() => {
    getWishList()
  }, [authUser?.user?.id])


  if (loading) {
    return <LoadingSpinner />
  }
  if (error) {
    return <ErrorComponent error={error.message} />
  }


  return (
    <WishListDiv>
      <Title>Wishlist</Title>
      <WishListContainer>
        {Array.isArray(data) ? data.map((item, i) => (
          <WishlistItem key={i}>

            <ProductItem>
              <ItemImage>
                <Box>
                  <Image src={JSON.parse(item?.productImages)[0]} />
                </Box>
              </ItemImage>
              <DetailsDivI>
                <p style={{ marginTop: "5px" }}>{`${item?.brand_name} ${item?.model}`}</p>
                <p style={{ color: "green", margin: '0px' }}>{item?.stock_status}</p>
                <TaxesWish>
                  <p>M.R.P ₹ {item.price}</p>
                </TaxesWish>
                <MonDiv>
                  <p style={{ color: 'black', margin: '0px' }}>{`₹${item?.offer_price}`}</p>
                  <span>(incl. of all taxes)</span>
                </MonDiv>
                <ClikePointsDiv>
                  <img src={clikeLogo} alt="Clike Logo" />
                  <span>{item.reward_points} Clike Points</span>
                </ClikePointsDiv>
              </DetailsDivI>
            </ProductItem>


            <CartDivBtn>
              <RiDeleteBinLine onClick={() => favHandler(item)} size={25} />
              <Button onClick={() => {
                localStorage.setItem("Productid", JSON.stringify(item?.productId));
                navigation("/sales/products/productdetails");
              }}>
                View product
              </Button>
            </CartDivBtn>


          </WishlistItem>
        )) : (
          <h5>No Products Added</h5>
        )}

      </WishListContainer>
    </WishListDiv>
  )
}

export default WishList;




export const WishListDiv = styled.div`
    padding-top: 1rem;
    padding-bottom: 1rem;
`
export const WishListContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  `
export const WishlistItem = styled.div`
    width: 100%;
    border-radius: 15px;
    border: 1px solid gray;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 520px){
        flex-wrap: wrap;
    }
`
export const ItemImage = styled.div`
    width:10.5rem;
    margin: 10px;
    display: flex;
    align-items: center;
    margin-left: 8px;
    @media (max-width:1200px){
        width:20%
    }
    @media (max-width:1140px){
        width:25%
    }
    @media (max-width:700px){
        order:0;
        width:50%;
    }

`
export const DetailsDivI = styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
`
export const CartDivBtn = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
`
export const TaxesWish = styled.div`
    display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        /* margin:0 8px; */
        p{
            font-size:12px;
            color:${colors.line};
            font-weight:${fonts.fontWeight3};
            margin:0;
            text-decoration: line-through;
        }
        span{
            font-size:12px;
            color:${colors.line};
            font-weight:${fonts.fontWeight3};
            margin:0;
        }
        @media (max-width:425px) {
            align-items:flex-start;
            justify-content:flex-start;
            margin-left:-10px;
        }
        @media (max-width:375px) {
            /* margin-left:-110px; */
        }
        @media(max-width:325px){
            margin-left:0px; 
        }
`
export const MonDiv = styled.div`
    display: flex;
    align-items: center;
    span{
        font-size: 12px;
        color: ${colors.line};
        margin-left: 5px;
    }
    @media screen and (max-width: 350px ) {
        flex-direction: column;
    }
`
export const ClikePointsDiv = styled.div`
display: flex;
align-items: center;
    
    img{
        width: 20px;
        height: 20px;
    }
    span{
        margin: 10px 0px;
    }
`
export const ProductItem = styled.div` 
    display: flex;
    align-items: center;
    @media screen and (max-width: 1200px){
        gap: 4rem;
    }
    @media screen and (max-width: 700px){
        gap: 1rem;
    }
    @media screen and (max-width: 350px){
        gap: 5px;
    }
`