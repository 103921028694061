
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalContainer, Modal, Overlay } from "../../modal"
import styled from 'styled-components';
import { IoClose } from "react-icons/io5";
import { Button, ButtonContainer, CloseContainer, Container, Head1, InnerConatiner } from '../ModalStyled';




const ExistingSubUserModal = ({ close, path }) => {
  return (
    <ModalContainer>
      <Modal Modal>
        <Overlay>
          <Container>
            <CloseContainer>
              <IoClose size={22} onClick={() => close(false)} />
            </CloseContainer>
            <InnerConatiner>
              <Head1>Secondary user with this mobile number is already exist</Head1>
              <ButtonContainer>
                {/* <Button onClick={() => close(false)}>Cancel</Button> */}
                <Button bg={1} txt={1} onClick={() => close(false)}>ok</Button>
              </ButtonContainer>
            </InnerConatiner>
          </Container>
        </Overlay>
      </Modal>
    </ModalContainer>
  )
}

export default ExistingSubUserModal;
;
