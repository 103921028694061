import React, { useEffect, useState } from "react";
import { ImgCardData, colors } from "../../../MappingDatas/ImgCardData";
// import { ScreenDiv, ScreenCont, ImgCard, CardImag, Head } from "./productcardsStyle";
import Purchasetype from "../../../components/Modal/Purchasetype";
import { useNavigate } from "react-router-dom";
import { productcategories } from "../../../api/api";
import { server_api } from "../../../consts/api";
import Desktoptype from "../../../components/Modal/Desktoptype";
import deskimg from "../../../assets/Images/png/laptop1.png";
import axios from "axios";
import { colors as color, NoDisplay } from '../../../consts/themes'
import ErrorComponent from "../../../components/Button/error/ErrorComponent";
import LoadingSpinner from "../../../components/Button/Loading/LoadingSpinner";
import styled from "styled-components";
import axiosInstance from "../../../consts/axiosInstance";
import DesktopTypeModal from "../../../components/Modals/DesktopTypeModal";



function Screen() {

  const navigation = useNavigate();
  const typeofsale = JSON.parse(localStorage.getItem("codeid"));

  const [isOpen, setOpen] = useState(false);
  const [category, setCategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [desktopType, setDeskTopType] = useState(false);



  const Desktopmodal = () => {
    setDeskTopType(true);
  };

  //product categories eg:- lap , mob  etc
  const getProductCategories = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('getProductCategory');
      setCategory(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    localStorage.removeItem('id');
    localStorage.removeItem('Typeid');
    localStorage.removeItem('subcateid');
    getProductCategories();
  }, [])



  const changeFunction = (item) => {

    // common retain segment
    if (typeofsale === 1) {

      if (item.color === '004') {
        Desktopmodal();
      } else if (["001", "005", "008", "010", "011", "012"].includes(item.color)) {
        navigation('/sales/productcard/users');
      } else if (item.color === '006') {
        navigation('/cctvcategory');
      } else {
        navigation('/sales/productcard/users/products');
      }

      //corporate segment
    } else if (typeofsale === 2 || typeofsale === 3) {
      if (item.color === '001') {
        navigation('/category');
      } else {
        navigation('/purchase/form');
      }
    } else {
      navigation('/purchase/form');
    }


    localStorage.removeItem("isNotFirst");
    localStorage.removeItem("cartpid");
    localStorage.removeItem("cartpid");
    localStorage.setItem("productid", JSON.stringify(item?.id))
    localStorage.setItem("productcat", JSON.stringify(item?.id))
    localStorage.setItem("productcode", JSON.stringify(item?.color))
    localStorage.setItem("productname", JSON.stringify(item?.product_category_name))
  }

  if (loading) {
    return <LoadingSpinner />
  }
  if (error) {
    return <ErrorComponent error={error.message} />
  }

  return (

    <ScreenDiv>

      {desktopType && <DesktopTypeModal close={setDeskTopType} />}
      {/* {desktop && <Desktoptype data={setDesktop} />} */}
      {category?.length === 0 ? <Head>No Products to display</Head> : <Head>Choose Your Required Products</Head>}
      <ScreenCont>
        {category?.map((val, i) => {
          return (
            <>
              {val?.color === '013' ? '' :
                <CardImag onClick={() => changeFunction(val)} >
                  <CardContainer>
                    <Img
                      src={val?.product_category_image}
                      alt={val?.product_category_name}>
                    </Img>
                    <P>{val?.product_category_name}</P>
                  </CardContainer>
                </CardImag>
              }
            </>
          );
        })}
      </ScreenCont>
    </ScreenDiv>
  );
}
export default Screen;


const P = styled.p`
font-weight: 500;
font-size:12px;
@media only screen and (min-width: 250px) and (max-width: 550px) {font-size:10px;}
`;


const ScreenDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin:0;
  padding: 0;
  height: 100vh;
`;

export const Head = styled.div`
  padding:0.6rem 0;
  font-weight: 500;
  @media only screen and (max-width: 1023px) {font-size: 1rem;}
  @media only screen and (min-width: 1024px) {font-size: 1.5rem;}
`;



const ScreenCont = styled.div`
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  overflow-y: scroll;
  @media only screen and (min-width: 250px) and (max-width: 768px) {padding: 1rem 0;gap: 1rem;}
  @media only screen and (min-width: 768px) and (max-width: 1023px) {padding: 1.5rem 0;gap: 1.5rem;}
  @media only screen and (min-width: 1024px) {padding: 2rem 0;gap: 2rem;}
`;



const CardImag = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
border: 1px solid rgba(0, 0, 0, 0.1);
${'' /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px; */}
${'' /* border-radius: 5px; */}
${'' /* @media only screen and (min-width: 250px) and (max-width: 354px) {grid-column: span 12 / span 12;} */}
@media only screen and (min-width: 250px) and (max-width: 768px) {grid-column: span 6 / span 6;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {grid-column: span 4 / span 4;}
@media only screen and (min-width: 1024px) {grid-column: span 3 / span 3;}
`;


const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size:0.9rem;
  p {
    @media only screen and (min-width: 250px) and (max-width: 379px) {
      font-size: 0.7rem;
    }
  }
`;


const Img = styled.img`
object-fit:contain;
@media only screen and (min-width: 300px) and (max-width: 329px){width:8rem;height:7rem;}
@media only screen and (min-width: 330px) and (max-width: 379px){width:9rem;height:9rem;}
@media only screen and (min-width: 380px) and (max-width: 500px) {width: 10rem;height:10rem}
@media only screen and (min-width: 501px) { width: 11.875rem;height: 11.875rem}
`;

