//new
import axios from 'axios';

// Function to handle logout
const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userid');
  localStorage.clear();
  window.location.href = '/login';
};


const axiosInstance = axios.create({
  // baseURL: 'http://192.168.1.39:8001/api/user/',
  baseURL: 'https://dev.clikekart.com/api/user/',
  headers: {
    'Accept': 'application/json',
  },
});

// Add a request interceptor to add the Authorization header if a token exists
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle specific error responses
axiosInstance.interceptors.response.use(
  (response) => {
    // If the response is successful, just return it
    return response;
  },
  (error) => {
    // Check if the error response status is 499 and message is "Unauthorized"
    if (error.response && error.response.data.message === 'Unauthorized') {
      logout();
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;






// old
// import axios from 'axios';

// const axiosInstance = axios.create({
//   baseURL: 'http://192.168.1.4:8000/api/user/',
//   headers: {
//     'Accept': 'application/json',
//   },
// });

// // Add a request interceptor to add the Authorization header if a token exists
// axiosInstance.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem('token');
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },

//   (error) => {
//     return Promise.reject(error);
//   }

// );

// export default axiosInstance;
