import React from 'react';

export const Colors = {
  student: 'linear-gradient(45deg, #14567B 0%, #7AC9C2 100%)',
  proffessional: 'linear-gradient(45deg, #F36C9E 0%, #F090F5 100%)',
  business: ' linear-gradient(45deg, #FE5D53 0%, #F0971A 100%)',
  firmsmall: 'linear-gradient(45deg, #C99280 0%, #E45CB3 100%)',
  firmmedium: 'linear-gradient(45deg, #FF154B 0%, #FFA292 100%)',
  firmlarge: 'linear-gradient(45deg, #147B53 0%, #7AC9C2 100%)',
  corporates: 'linear-gradient(45deg, #C99280 0%, #E45CB3 100%)',
  others: 'linear-gradient(45deg, #677BE5 0%, #7450AA 100%)',
  white: '#ffffff',
  black: '#000000',
  royalblue: '#4169e1',
};

export const help = {};
export default Colors;
