import React, { useRef } from 'react'
import { Container, ModalPs, Image, MainDiv, Box, Close, Submit, Star, Textbars, Ptag, LeftSide, RightSide, SubBox, Number, Img, Checking, MainDiv1, Check, RowDiv, ColumnDiv } from './BuildPcModalStyle';
import { IoCheckmarkCircle } from 'react-icons/io5'
import { Modal, ModalContainer, Overlay } from '../../../components/modal';
import logo from '../../../assets/Images/png/Vector (9).png';
import imgs from '../../../assets/Images/png/Group 17.png'
import { Left } from '../modalbutton/ServiceCheckingStyled';
import { BsCheck2 } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import tick from '../../../assets/Images/png/Group 348.png'
import { AiOutlineStar } from 'react-icons/ai';
import { FaStar } from 'react-icons/fa';
import { useState } from 'react';
import axios from "axios";
import { insertfeedback } from "../../../api/api";
import { GrFormClose } from 'react-icons/gr';
import axiosInstance from '../../../consts/axiosInstance';

// const array=[
//     {id:'Brand', tag:'Lorem ipsum'},
//     {id:'Serial number', tag:'Lorem ipsum'},
//     {id:'Year of purchase', tag:'Lorem ipsum'},
//     {id:'Waranty expiry date', tag:'Lorem ipsum'},
//     {id:'Service pack date', tag:'Lorem ipsum'},
//     {id:'Sesrvice pack expiry date', tag:'Lorem ipsum'},

// ]


const FeedbackModal = ({ setFeedbackmodal, ticketNo, onClose }) => {

  const update = () => {
    setFeedbackmodal(false);
  }




  const [data, setData] = useState([]);

  const [currentValue, setCurrentValue] = useState(0);
  const [hoverValue, setHoverValue] = useState(undefined);
  const stars = Array(5).fill(0)

  const [currentValues, setCurrentValues] = useState(0);
  const [hoverValues, setHoverValues] = useState(undefined);

  const starss = Array(5).fill(0)


  const handleClick = value => {
    setCurrentValue(value)
    // console.log("current value 2: " , value)
  }

  const handleMouseOver = newHoverValue => {
    setHoverValue(newHoverValue)
  };

  const handleMouseLeave = () => {
    setHoverValue(undefined)
  }



  const handleClicks = value => {
    // console.log("current value 1: " , value)
    setCurrentValues(value)
  }



  const handleMouseOvers = newHoverValue => {
    setHoverValues(newHoverValue)
  };

  const handleMouseLeaves = () => {
    setHoverValues(undefined)
  }



  // const HandleSubmitCart = async (item) => {
  //   try {
  //     setLoading(true);
  //     const formData = {
  //       customDesktopId: customDesktopId,
  //     };

  //     const options = {
  //       method: 'POST',
  //       url: CustomPcSubmit,
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       data: formData,
  //     };

  //     const response = await axios.request(options);
  //     console.log('Response for pc submit', response.data);

  //   } catch (error) {
  //     console.error('Error in pc insertion:', error);
  //     setError('An error occurred while processing your request.');
  //   } finally {
  //     setLoading(false);
  //     // getPcCart();
  //     setSubmitModal(true);
  //   }






  async function InsertFeedback() {


    const formData = {
      ticket_no: ticketNo,
      ck_service_rating: currentValues,
      ck_service_engineer_rating: currentValue,
      suggestion: data?.suggestion?.target?.value,
    };


    const options = {
      method: "POST",
      url: 'insertfeedback',
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
      data: formData,
    };
    await axiosInstance.request(options).then(function (response) {
      console.log("response=>", response);
      // setFeedbackmodal(false);
      onClose();
    })
      // .then(() => {
      //   setIsSubCategory(isSubCategory?.total_amount - removeamntns);
      // })
      .catch(function (error) {
        console.error(error);
      });
  }


  // old code
  // async function InsertFeedback() {

  //   const form = new FormData();
  //   form.append("ticket_no", 1);
  //   form.append("ck_service_rating", currentValues);
  //   form.append("ck_service_engineer_rating", currentValue);
  //   form.append("suggestion", data?.suggestion?.target?.value);

  //   const options = {
  //     method: "POST",
  //     url: insertfeedback,
  //     headers: {
  //       "Content-Type": "multipart/form-data",
  //       Accept: "application/json",
  //     },
  //     data: form,
  //   };
  //   await axios
  //     .request(options)
  //     .then(function (response) {
  //       console.log("response=>", response);
  //       setFeedbackmodal(false);
  //     })
  //     // .then(() => {
  //     //   setIsSubCategory(isSubCategory?.total_amount - removeamntns);
  //     // })
  //     .catch(function (error) {
  //       console.error(error);
  //     });
  // }

  return (
    <>
      {/* {showModal ? ( */}
      {/* <Container > */}
      <ModalContainer>
        <Modal>
          <Overlay>


            <ModalPs>
              <Close>
                <GrFormClose size={20} onClick={onClose} />

              </Close>
              <img src={tick} />
              <p>Send Your Feedback</p>
              <MainDiv1>
                <RowDiv>
                  <p>Clikekart Service</p>
                  <RowDiv>

                    {starss.map((_, index) => {
                      {/* console.log("currentValues", (currentValues)) */ }
                      return (
                        <FaStar
                          key={index}
                          size={20}
                          onClick={() => handleClicks(index + 1)}
                          onMouseOver={() => handleMouseOvers(index + 1)}
                          onMouseLeave={handleMouseLeaves}
                          color={(hoverValues || currentValues) > index ? '#ffdf00' : '#dfdfdf'}
                          style={{
                            marginRight: 10,
                            cursor: "pointer"
                          }}
                        />
                      )
                    })}
                  </RowDiv>

                </RowDiv>
                <RowDiv>
                  <p>Clikekart Service Engineer</p>
                  <RowDiv>

                    {stars.map((_, index) => {
                      {/* console.log("currentValue", (currentValue)) */ }
                      return (
                        <FaStar
                          key={index}
                          size={20}
                          onClick={() => handleClick(index + 1)}
                          onMouseOver={() => handleMouseOver(index + 1)}
                          onMouseLeave={handleMouseLeave}
                          color={(hoverValue || currentValue) > index ? '#ffdf00' : '#dfdfdf'}
                          style={{
                            marginRight: 10,
                            cursor: "pointer"
                          }}
                        />
                      )
                    })}
                  </RowDiv>
                </RowDiv>
                <ColumnDiv>
                  <p>Please Enter Your Suggestions</p>
                  <textarea onChange={(e) => setData((prev) => ({ ...prev, "suggestion": e }))} >

                  </textarea>

                </ColumnDiv>
                <Submit>
                  <button onClick={() => InsertFeedback()}>Submit</button>
                </Submit>



              </MainDiv1>

            </ModalPs>

          </Overlay>
        </Modal>
      </ModalContainer>

    </>
  )
}

export default FeedbackModal;