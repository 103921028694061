import { React, useState, useEffect } from "react";
import Assistance from '../../../components/Modal/Assistance';
import { CardImag, ImgCard, ScreenCont, ScreenDiv } from "../../../screens/sale/Styled/Subcategory.styled";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomerCategory, productcategories } from "../../../api/api";
import PrinterType from "../../../components/Modal/PrinterType";


const clickart = [

    {
        id: 1,
        name: "Student",


    },
    {
        id: 2,
        name: "Proffessional",

    },
    {
        id: 3,
        name: "Business"
    },
    {
        id: 4,
        name: "Firm-Small"
    },
    {
        id: 5,
        name: "Firm-Medium"
    },
    {
        id: 6,
        name: "Firm-Large"
    },
    {
        id: 7,
        name: "Corporates"
    },
    {
        id: 8,
        name: "others"
    }



]

const colors = ['linear-gradient(45deg, #14567B 0%, #7AC9C2 100%)', 'linear-gradient(45deg, #F36C9E 0%, #F090F5 100%)', ' linear-gradient(45deg, #FE5D53 0%, #F0971A 100%)', 'linear-gradient(45deg, #C99280 0%, #E45CB3 100%)',
    'linear-gradient(45deg, #FF154B 0%, #FFA292 100%)', 'linear-gradient(45deg, #147B53 0%, #7AC9C2 100%)',
    ' linear-gradient(45deg, #C99280 0%, #E45CB3 100%)', 'linear-gradient(45deg, #677BE5 0%, #7450AA 100%)', 'linear-gradient(45deg, #14567B 0%, #7AC9C2 100%)', 'linear-gradient(45deg, #F36C9E 0%, #F090F5 100%)', ' linear-gradient(45deg, #FE5D53 0%, #F0971A 100%)', 'linear-gradient(45deg, #C99280 0%, #E45CB3 100%)',
    'linear-gradient(45deg, #FF154B 0%, #FFA292 100%)', 'linear-gradient(45deg, #147B53 0%, #7AC9C2 100%)',
    ' linear-gradient(45deg, #C99280 0%, #E45CB3 100%)', 'linear-gradient(45deg, #677BE5 0%, #7450AA 100%)', 'linear-gradient(45deg, #14567B 0%, #7AC9C2 100%)', 'linear-gradient(45deg, #F36C9E 0%, #F090F5 100%)', ' linear-gradient(45deg, #FE5D53 0%, #F0971A 100%)', 'linear-gradient(45deg, #C99280 0%, #E45CB3 100%)',
    'linear-gradient(45deg, #FF154B 0%, #FFA292 100%)', 'linear-gradient(45deg, #147B53 0%, #7AC9C2 100%)',

];
function PowerSub() {

    const { state } = useLocation();
    const { ids } = state;

    const navigation = useNavigate();
    let location = useLocation()

    console.log("state=====>", ids);

    const [isOpen, setOpen] = useState(location.state === false ? false : true);
    const [subCategory, setIsSubCategory] = useState([]);
    const [categorys, setCategorys] = useState();
    const [check, setCheck] = useState();
    const [checks, setChecks] = useState();
    const [printertype, setPrntertype] = useState(false);
    const productsid = JSON.parse(localStorage.getItem('productid') || '[]');
    const purchasetype = JSON.parse(localStorage.getItem('codeid') || '[]');
    const productscode = JSON.parse(localStorage.getItem('productcode') || '[]');


    useEffect(() => {
   
        const checkcategory = async () => {

            const reqData = await fetch(`${productcategories}`)
            const resData = await reqData.json()
            resData?.map(item => item?.color === ids && 
                
                fetch(`${CustomerCategory}/${item?.id}/${purchasetype}`)
            .then(response => response.json())
            .then(response => {
                setIsSubCategory(response);
            })
            )
          }
          checkcategory()

    }, []);

    const changefunction = () => {
        {
            productscode === '005' ?
                setPrntertype(true)
                :
                navigation('/sales/productcard/users/products')
        }
    }

    return (
        <>
            <ScreenDiv>
                {
                    printertype === true ?
                        <PrinterType data={setPrntertype} />
                        :
                        ("")
                }
                {isOpen === true ? <Assistance close={setOpen} />
                    : ("")}

                <ScreenCont>

                    {

                        subCategory?.map((item, i) => {
                            return (
                                <>
                                    <CardImag >
                                        {/* {console.log("Items=", item)} */}
                                        <ImgCard
                                            onClick={
                                                productscode === '005' || productscode === '008' ?

                                                    () => [changefunction(),

                                                    // () =>[navigation('/sales/productcard/users/products'),

                                                    localStorage.setItem('id', JSON.stringify(item?.id))]
                                                    :
                                                    () => [navigation('/sales/productcard/users/subusers'),
                                                    localStorage.setItem('id', JSON.stringify(item?.id)),
                                                    localStorage.setItem('cusid', JSON.stringify(item?.category_name))]

                                            }

                                            style={{ background: `linear-gradient(45deg,{item.color.start} 0%, {item.color.end} 100%)` }} key={i} >

                                            {/* <p style={{color:"black"}}>{item.category_name}</p> */}
                                            {console.log(item)}
                                            <img src={item?.category_image}></img>

                                        </ImgCard>
                                        <p style={{ color: "black" }}>{item?.category_name}</p>
                                    </CardImag>

                                </>
                            )
                        })
                    }
                </ScreenCont>


            </ScreenDiv >



        </>
    )
}
export default PowerSub;

