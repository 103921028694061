import { useEffect, useState } from 'react'
import axiosInstance from '../../../consts/axiosInstance';
import styled from 'styled-components';
import { colors, fonts } from '../../../consts/themes';
import { useNavigate } from 'react-router-dom';
import { FaFileUpload } from "react-icons/fa";
import BulkEnquiryModal from '../../../components/Modals/DetailsModals/BulkEnquiryModal';
import BulkConfirmModal from '../../../components/Modals/BulkConfirmModal';
import Swal from 'sweetalert2';


const Quotation = () => {
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState([]);
  const [openEnquiry, setOpenEnquiry] = useState(false);
  const [commnets, setCommnets] = useState();
  const [reportId, setReportId] = useState(null);
  const [openBulkSubmitModal, setOpenBulkSubmitModal] = useState(false);
  const [selectedQuotationId, setSelectedQuotationId] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  // sales report list 
  const getCustomSales = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`getCustomSaleReport`);
      console.log(response.data, " response of custom sale report...")
      // setPurchase(response.data)
      setForm(response.data)
      // const quotationId = response.data[0].quotation_details[0].id;
      // console.log("quotationId", quotationId)

    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };



  function getTime(iitem) {
    var formatted = new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    }).format(Date.parse(iitem))

    return formatted
  }

  const verifyHandler = (id) => {
    setReportId(id);
    setOpenBulkSubmitModal(true);
  }


  const handleFileUpload = (e) => {
    const fileUserSelected = e.target.files[0];
    setSelectedFile(fileUserSelected);
  }





  const handleUpload = () => {
    const formData = new FormData();
    formData.append('purchase_order_pdf', selectedFile ? selectedFile : '');
    formData.append('id', selectedQuotationId ? selectedQuotationId : '');
    axiosInstance.post('PurchaseOrderpdfUpload', formData)
      .then(response => {
        console.log("response of file upload", response.data.code);
        if (response.data.code === 200) {
          Swal.fire({
            position: 'center',
            icon: 'success',
            color: '#4169e1',
            text: 'Purchase order uploaded successfully',
            showConfirmButton: false,
            timer: 1500
          });
        }
      })
      .catch(error => {
        const errorMessage = error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : 'An error occurred while uploading the file.';
        Swal.fire({
          position: 'center',
          icon: 'error',
          color: '#4169e1',
          text: errorMessage,
        });
      });
  };

  useEffect(() => {
    if (selectedFile !== null) {
      handleUpload();
    }
  }, [selectedFile])


  useEffect(() => {
    getCustomSales();
  }, []);

  return (
    <ServicesWrapper>
      <Span>Quotation</Span>
      {openEnquiry && <BulkEnquiryModal close={setOpenEnquiry} data={commnets} />}
      {openBulkSubmitModal && <BulkConfirmModal id={reportId} close={setOpenBulkSubmitModal} refresh={getCustomSales} />}
      <TableCon>
        {form?.length != 0 && (
          <TableWrapper>
            <TableContainer>
              <Table>
                <Thead>
                  <tr>
                    <Cell rowSpan={2}>Report Id</Cell>
                    <Cell rowSpan={2}>Category</Cell>
                    <Cell colSpan={4}>Enquiry Submitted</Cell>
                    <Cell colSpan={8}>Quotation</Cell>
                  </tr>
                  <tr>
                    <Cell >Specifications</Cell>
                    <Cell >Brand</Cell>
                    <Cell >Quantity</Cell>
                    <Cell >Enquiry</Cell>
                    <Cell >Sl.no</Cell>
                    <Cell >Quotation</Cell>
                    <Cell >Date</Cell>
                    <Cell >Verify</Cell>
                    <Cell >Status</Cell>
                    <Cell >Comment</Cell>
                    <Cell >Purchase Order</Cell>
                  </tr>
                </Thead>

                <Tbody>
                  {form && form.map((item) => (
                    <tr>
                      <Td>{item?.request_id}</Td>
                      <Td>{item?.category_name}</Td>
                      <Td>{item?.enquiry_details[0]?.specification}</Td>
                      <Td>{item?.enquiry_details[0]?.brand}</Td>
                      <Td>{item?.enquiry_details[0]?.qnty}</Td>
                      {/* <Td>{item?.enquiry}</Td> */}

                      <Td><StyledButton onClick={() => [setOpenEnquiry(true), setCommnets(item?.enquiry)]}>
                        view</StyledButton></Td>

                      {item?.quotation_details ? (
                        <>
                          <Td>{item.quotation_details[0]?.id}</Td>
                          <Td>
                            <StyledButton onClick={() => navigation(`/download-pdf-report/${item.quotation_details[0]?.id}`)}>
                              view</StyledButton>
                          </Td>
                          <Td>{getTime(item.quotation_details[0]?.date)}</Td>
                          <Td><StyledButton onClick={() => verifyHandler(item?.quotation_details[0]?.id)}>
                            verify</StyledButton>
                          </Td>
                        </>
                      ) : <>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                        <Td></Td>
                      </>}
                      {item?.quotation_details ? item?.quotation_details.map((qd) => (
                        <>
                          <Td>{qd.status == 1 ? 'Accept' : qd.status == 0 ? 'Reject' : 'Pending'}</Td>
                          <Td>{qd?.comment}</Td>
                        </>
                      )) :
                        <>
                          <Td></Td>
                          <Td></Td>
                        </>
                      }

                      {item?.quotation_details ? (
                        <>
                          <Td>
                            <FileInputLabel>
                              <FaFileUpload size={20} />
                              <HiddenFileInput type="file"
                                accept=".pdf"
                                onChange={handleFileUpload}
                                onClick={() => setSelectedQuotationId(item?.quotation_details[0]?.id)} />
                            </FileInputLabel>
                          </Td>
                        </>
                      ) : <> <Td></Td> </>}
                    </tr>
                  ))}
                </Tbody>

              </Table>
            </TableContainer>
          </TableWrapper>
        )}

        <SubmitBtn pb="1.5rem"><button onClick={() => navigation('/sales/productcard')}>Choose Another Product</button></SubmitBtn>

      </TableCon>

    </ServicesWrapper>
  )
}

export default Quotation;


const Screen = styled.div`
  margin-top: 5rem;
  height: calc(100vh - 5rem);
  max-height: calc(100vh - 5rem);
  overflow-y: auto;
  margin-left:0.8rem;
  margin-right: 0.8rem;
`;

const ServicesWrapper = styled.div`
    width:100%;
    margin-top:3rem;
`
const Span = styled.span`
    font-size:18px;
    color:${colors.black};
    font-weight:${fonts.fontWeight5};
    margin-bottom:2rem;
    display:block;
    @media (max-width:1200px){
    margin-top:2rem;
    }
`
const TableCon = styled.div`
overflow: auto; 
margin:1rem;
`;





const FileInputLabel = styled.label`
display: inline-block;
background-color: #e1ecf4;
border: 1px solid #7aa7c7;
color: black;
border-radius: 5px;
cursor: pointer;
padding: 5px;
width:fit-content;
`;

const HiddenFileInput = styled.input`
display: none;
`;



const TopContainer = styled.div`
margin: 0.8rem 0;
display:flex;
gap:1rem;
width:100%;
max-width:62rem;
@media only screen and (min-width: 1024px) {width: 62rem;align-items:flex-end;}
@media only screen and (min-width: 250px) and (max-width:1023px) {flex-direction: column;}
`;

const SpecContainer = styled.div`
display:flex;
flex-direction: column;
gap:0.3rem;
`;

const InputContainer = styled.div`
display:flex;
height:45px;
`;

const BrandContainer = styled.div`
display:flex;
flex-direction: column;
gap:0.3rem;
width:fit-content;
`;

const Brand = styled.div`
width: 150px;
height:42px;
border: 0.1px solid ${colors.line};
border-radius: 6px;
background-color: ${colors.lightAsh};
font-size: 12px;
display:flex;
justify-content:center;
align-items:center;
`;

const QtyContainer = styled.div`
display:flex;
flex-direction: column;
gap:0.3rem;
width:fit-content;
input{
width:100%;
max-width: 150px;
height:42px;
border: 0.1px solid ${colors.line};
border-radius: 5px;
padding:0 0.5rem;

&:focus {
    border-color: #587ef5;
    outline: 0;
  }

  /* Hide spinner controls */
   -moz-appearance: textfield; /* Firefox */
    appearance: none; /* Safari and Chrome */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none; /* Safari */
    }

}
`;


const CmntContainer = styled.div`
display:flex;
flex-direction: column;
gap:0.3rem;
width:fit-content;
input{
height:42px;
border: 0.1px solid ${colors.line};
border-radius: 5px;
width: 240px;
padding:0 0.5rem;
&:focus {
    border-color: #587ef5;
    outline: 0;
  }
}
`;

const SubmitBtn = styled.div`
padding-bottom: ${props => (props.pb ? props.pb : '0')};
button{
  padding:0.8rem;
  height:42px;
  background-color:#4669E8;
  color:white;
  border: none;
  border-radius: 5px;
     background-color: ${props => props.disabled ? '#D5E2FE' : '#4669E8'};
     cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
}
`;

const Show = styled.div`
  display: none;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  max-width: 100%;
`;


const TableContainer = styled.div`
width: fit-content;
margin:1rem;
`;

const Table = styled.table`
border-collapse: collapse;
max-width:1410px;
width: 100%;
tbody tr:nth-child(even) {background:#F8FBFB;}
tbody tr:nth-child(odd) {background:#e9eef4;}
box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
`;

const Thead = styled.thead`
background-color: #1d5fd1;
`;

const Cell = styled.th`
border:0.1px solid #cccccc;
box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
width:100px;
padding:0.8rem 0.5rem;
font-size:0.8rem;
font-weight: 500;
color: white;
`;

const Tbody = styled.tbody`
/* background-color: #f7f7f7; */
`;

const Td = styled.td`
text-align: center;
vertical-align: middle; 
padding:1rem 0.5rem;
border:0.1px solid #cccccc;
font-size: 0.8rem;
font-weight: 400;
`;

const StyledButton = styled.button`
  background-color: #e1ecf4;
  border-radius: 3px;
  border: 1px solid #7aa7c7;
  box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #39739d;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", "Liberation Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding:0.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
`;



