import React from 'react'
import { ModalContainer, Modal, Overlay } from "../../components/modal"
import { Button, ButtonContainer, CloseContainer, Container, Head1, InnerConatiner } from './ModalStyled';
import { IoClose } from "react-icons/io5";

const DefaultAddressChangeModal = ({ close, getUserDetails, modalData, setModalData }) => {
  return (
    <ModalContainer>
      <Modal Modal>
        <Overlay>
          <Container>
            <CloseContainer>
              <IoClose size={22} onClick={() => [close(false), setModalData(null), getUserDetails()]} />
            </CloseContainer>
            <InnerConatiner>
              <Head1>{modalData?.content}</Head1>
              <ButtonContainer>
                <Button onClick={() => [close(false), setModalData(null), getUserDetails()]}>Cancel</Button>
                <Button bg={1} txt={1} onClick={() => [close(false), setModalData(null), getUserDetails()]}>Ok</Button>
              </ButtonContainer>
            </InnerConatiner>
          </Container>
        </Overlay>
      </Modal>
    </ModalContainer>
  )
}

export default DefaultAddressChangeModal;


