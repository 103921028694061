import styled from "styled-components";
import { colors, fonts } from "../../../consts/themes";

const ScreenDiv = styled.div`
width: 100%;
display: flex;
justify-content: center;

@media screen and (max-width: 1500px){
    width: 100%;
}
@media screen and (max-width: 1100px){
    width: 100%;
}
@media screen and (max-width: 800px){
width: 100%;
margin-top: 4rem;
}
@media screen and (max-width: 500px){
    margin-top: 0rem;
}

`
const ScreenCont = styled.div`
display: grid;
grid-template-columns: auto auto auto auto;
// width: 55%;
// height: 58%;
padding: 20px;
justify-content: center;
align-items: center;
justify-content: space-around;
@media (min-width: 1000px) and (max-width: 1500px){
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: center;
    align-items: center;
    width: 100%;
}
@media screen and (max-width: 1050px){
    justify-content: center;
    align-items: center;
    // width: 100%;
    // height: 46%;
}
@media screen and (max-width: 800px){
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto auto ;
    align-items: center;
    // width: 100%;
    // height:45%;
   
}
@media screen and (max-width: 500px){
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto;
    align-items: center;
    // width: 100%;
    // height:45%;
   
}
@media screen and (max-width: 500px){
    padding:5px;
}


   
`
const ImgCard = styled.div`
 background-color: #ccc; 
width: 190px;
height: 190px;
background-color:#ccc;
// margin-right:30px;
justify-content: center;
align-items: center;
display:flex;
flex-direction:column;
border: .5px solid #ccc;


img{
   
    width: 190px;
    height: 190px;
    justify-content: center;
    align-items: center;
    object-fit: cover;
    transition :all 0.3s ;
}
p{
    color: white;
    font-size: 16px;
    margin-top: 0px;
    padding-inline:10px;
    text-align:center;
    font-weight: ${fonts.fontWeight5};
    letter-spacing: 0.04rem;
   
}
@media screen and (max-width: 500px){

    justify-content: center;
    align-items: center;
    justify-content: space-around; 
    width: 140px;
    height: 140px;
    img{
   
    justify-content: center;
    align-items: center;
    width: 140px;
    height: 140px;
}
p{
    color: white;
    font-size: 1rem;
    font-weight: 450;
    margin-top: 0px;

}
}
@media screen and (max-width: 320px){

justify-content: center;
align-items: center;
justify-content: space-around; 
width: 130px;
height: 130px;

img{

justify-content: center;
align-items: center;
width: 130px;
height: 130px;
}
p{
color: white;
font-size: 1rem;
font-weight: 450;
margin-top: 0px;

}
}

`
const CardImag = styled.div`
// width: 190px;
// height: 210px;
margin-right:20px;
justify-content: center;
    align-items: center;
@media screen and (min-width: 2000px){
    // padding: 25px 0;
}
@media screen and (max-width: 1500px){
   
    padding:0 10px;
    justify-content: center;
    align-items: center;
   
}
@media screen and (max-width: 1050px){
    // width: 160px;
    // height: 180px;
    justify-content: space-around;
    justify-content: center;
    align-items: center;
   
}
@media screen and (max-width: 800px){
    // width: 160px;
    // height: 178px;
    justify-content: center;
    align-items: center;
   
}
// @media screen and (max-width: 800px)
// {
//     width: 145px;
//     height: 200px;
// }
@media screen and (max-width: 500px){
    margin-right:0px;
    // width: 150px;
    // height: 165px;
    justify-content: space-around;
    justify-content: center;
    align-items: center;
   
}
@media screen and (max-width: 350px){
    // width: 120px;
    // height: 150px;
    justify-content: center;
    align-items: center;
    margin-left:10px;
    // margin-bottom:13px;
    margin-right:0px;
    padding:0 0px;
    margin-right:10px;
   
}
p{
    font-size:12px;
    color:${colors.black};
    margin-top:2px;
    // margin-left:10px;
    margin-right:0;
    font-weight:${fonts.fontWeight5};
}
`
export {
    CardImag,
    ImgCard,
    ScreenCont,
    ScreenDiv


}