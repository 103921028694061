import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PurchaseType } from "../../../api/api";
import axios from "axios";
import { AuthContext } from "../../../consts/AuthContext";
import { NoDisplay, colors, fonts } from "../../../consts/themes";
import LoadingSpinner from "../../../components/Button/Loading/LoadingSpinner";
import ErrorComponent from "../../../components/Button/error/ErrorComponent";
import axiosInstance from "../../../consts/axiosInstance";
import styled from "styled-components"

const TypeofSale = () => {

  const navigation = useNavigate();
  const [purchase, setPurchase] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [authUser, setAuthUser] = React.useContext(AuthContext);






  const validationfunction = (val) => {
    // if (val?.id === 1 || val?.id === 2) {
    //   navigation("/sales/productcard");
    // }
    // else if (val?.id === 3) {
    //   navigation("/form");
    // }
    // else if (val?.id === 20) {
    //   navigation("/form");
    // }
    navigation("/sales/productcard");
  };








  const getPurchaseType = async () => {
    try {
      setLoading(true);
      // const response = await axiosInstance.get('getPurchaseType');
      const response = await axios.get(`${PurchaseType}`);
      setPurchase(response.data)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPurchaseType();
  }, [])

  if (loading) {
    return <LoadingSpinner />
  }
  if (error) {
    return <ErrorComponent error={error.message} />
  }


  return (
    <MainDiv>
      <Screen>
        <Content>
          <h5>Choose Your Segment</h5>
          <p>
            Please select from the following categories to specify the products you require.
          </p>
        </Content>

        <Box>
          {purchase?.length === 0 ? <NoDisplay>Nothing to display</NoDisplay> : null}
          {purchase?.map((val, i) => {
            return (
              <Cards key={i}
                onClick={() => [
                  localStorage.getItem("userid")
                    ? validationfunction(val)
                    : navigation("/login"),
                  localStorage.setItem("codeid", JSON.stringify(val?.id)),
                  localStorage.setItem("datacode", JSON.stringify(val?.code)),
                ]
                }
              >

                {/* <Card>
                  <img src={val?.type_image} />
                </Card>
                <Textbox>
                  <p style={{ color: "black" }}>{val.type_name}</p>
                </Textbox> */}

                <CardContainer>
                  <Img src={val?.type_image} />
                  <P>{val.type_name}</P>
                </CardContainer>
              </Cards>
            );
          })}
        </Box>




      </Screen>
    </MainDiv>
  );
};

export default TypeofSale;



const P = styled.p`
font-weight: 500;
font-size:12px;
@media only screen and (min-width: 250px) and (max-width: 550px) {font-size:10px;}
`;


export const Screen = styled.div`
/* height: calc(100vh - 5rem); */
overflow-y: auto;
padding:0 0.8rem;
`

// new 
export const MainDiv = styled.div`
display: flex;
justify-content: center;
padding-top: 5rem;
height: calc(100vh - 8.7rem);
`

export const Content = styled.div`
h5{
    font-size: 18px;
    margin-bottom: 0px;
    text-align: center;
    font-weight: ${fonts.fontWeight5};
    padding:0;
}
p{
    font-size: 12px;
    color: ${colors.ash};
    margin-top: 9px;
    margin-bottom:10px;
    text-align: center;
}
@media (min-width: 1024px) {
padding:0 0;
}

`

export const Box = styled.div`
display: grid; 
grid-template-columns: repeat(12, minmax(0, 1fr)); 
gap:0.813rem;
`
export const Cards = styled.div`
@media only screen and (min-width: 250px) and (max-width: 767px) {grid-column: span 6 / span 6;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {grid-column: span 4 / span 4; }
@media (min-width: 1024px) {grid-column: span 3 / span 3;}
`

export const Card = styled.div`
width: 190px;
height: 190px;
justify-content: center;
align-items: center;
text-align:flex-start;
border: .5px solid #ccc;
img{
width: 190px;
height: 190px;
}

@media only screen and (min-width: 250px) and (max-width: 535px) {
width: 160px;
height: 160px;

img{
width: 160px;
height: 160px;
}
  }
`

export const Textbox = styled.div`
display:flex;
flex-direction:column;
align-items:center;`



const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size:0.9rem;
  p {
    @media only screen and (min-width: 250px) and (max-width: 379px) {
      font-size: 0.7rem;
    }
  }
`;


const Img = styled.img`
object-fit:contain;
width: 190px;
height: 190px;

@media only screen and (min-width: 380px) and (max-width: 535px) {
width: 160px;
height: 160px;
}
@media only screen and (min-width:250px) and (max-width:379px) {
width: 140px;
height: 140px;
}
`;


