import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalContainer, Modal, Overlay } from "../modal"
import styled from 'styled-components';
import { IoClose } from "react-icons/io5";
import { Button, ButtonContainer, CloseContainer, Head1, } from './ModalStyled';
import { colors } from '../../consts/themes';



const BulkListModal = ({ close, products, picker }) => {

  const truncate = (string) => {
    return string?.length > 15 ? string?.substr(0, 15 - 1) + '...' : string
  }
  

  const handleProductClick = (product) => {
    picker(product);
  };



  return (
    <ModalContainer>
      <Modal Modal>

        <Overlay>

          <Container>
            <CloseContainer>
              <IoClose size={22} onClick={() => close(false)} />
            </CloseContainer>

            <InnerConatiner>

              {products.map((item) => (
                <Product onClick={() => handleProductClick(item)}>
                  <Img src={JSON.parse(item?.images)[0]} alt="product" />
                  <Title>{truncate(item?.title)}</Title>
                </Product>
              ))}

            </InnerConatiner>

          </Container>
        </Overlay>
      </Modal>
    </ModalContainer>
  )
}

export default BulkListModal;



const Container = styled.div`
display: flex; 
flex-direction:column;
justify-content: center; 
align-items:center;
padding:1.5rem;
gap:1rem;
width: 70rem;
max-height: 29rem;
height:100%;
@media only screen and (min-width: 1024px) and (max-width: 1220px) {width:58rem;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {width:40rem;}
@media only screen and (min-width: 590px) and (max-width: 767px) {width:30rem;}
@media only screen and (min-width: 500px) and (max-width: 589px) {width:25rem;}
@media only screen and (min-width: 400px) and (max-width: 499px) {width:20rem;}
@media only screen and (min-width: 300px) and (max-width: 399px) {width:15rem; height:20rem}
`

const InnerConatiner = styled.div`
display:flex;
justify-content:center;
flex-direction: row;
flex-wrap: wrap;
gap:1rem;
overflow-y:scroll;
padding:0.5rem;
`


const Product = styled.div`
display:flex;
flex-direction: column;
align-items: center;
border: 1px solid ${colors.line};
`

const Img = styled.img`
height: 10rem;
width: 10rem;
object-fit: contain;
`

const Title = styled.div`
font-size:0.8rem;
font-weight:400;
`