import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../consts/axiosInstance";
import LoadingSpinner from "../../../components/Button/Loading/LoadingSpinner";
import Help from "../../../components/Help/help";
import { AuthContext } from "../../../consts/AuthContext";
import GotoCartCustomPcModal from "../../../components/Modal/GotoCartCustomPcModal";
import styled from "styled-components";
import { colors, fonts } from "../../../consts/themes";





const CustomPcDetails = () => {

  const navigation = useNavigate();


  const [details, setDetails] = useState([]);
  const [description, setDescription] = useState('');
  const productid = JSON.parse(localStorage.getItem("Productid") || "[]");
  const [authUser, setAuthUser] = useContext(AuthContext);


  //details of product
  const responses1 = JSON.parse(localStorage.getItem("response") || "[]");
  const [selectedImg, setSelectedImg] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [productInCart, setProductInCart] = useState(false);
  const [prevReq, setPrevReq] = useState(false);




  const handleAddToCart = async () => {
    try {
      setLoading(true);

      const formData = {
        userId: authUser?.user?.id,
        productId: productid,
      };

      const options = {
        method: 'POST',
        url: 'buildPCComponentInsert',
        headers: {
          'Content-Type': 'application/json',
        },
        data: formData,
      };


      const response = await axiosInstance.request(options);

      console.log('Response for pc insertion', response.data.code);

      if (response?.data?.code === 200) {
        navigation('/custompc/home')

      } else if (response?.data?.code === 403) {
        setProductInCart(true);

      } else if (response?.data?.code === 405) {
        setPrevReq(true);
      }

    } catch (error) {
      console.error('Error in pc insertion:', error);
      setError('An error occurred while processing your request.');
    } finally {
      setLoading(false);

    }
  };





  const Explaindetails = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`getProductDetailedDisplay?id=${productid}`);
      setDetails(response?.data);
      setDescription(response?.data?.description);
      response.data?.images && setSelectedImg(JSON?.parse(response?.data?.images)[0]);
      localStorage.setItem("response", JSON.stringify(response?.data));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    Explaindetails();
  }, []);





  if (loading) {
    // Loading Spinner Component
    return <LoadingSpinner />
  }

  if (error) {
    // Error Component
    return <div>Error: {error}</div>;
  }


  return (
    <Screen>
      {productInCart && <GotoCartCustomPcModal close={setProductInCart} type={1} />}
      {prevReq && <GotoCartCustomPcModal close={setPrevReq} type={2} />}
      <ScreenContent>
        <Sub1>
          <SliderDiv>
            <Img src={selectedImg}></Img>
            <Sub>
              {details?.images &&
                JSON.parse(details?.images)?.map((i, k) => {
                  return (
                    <div
                      key={k}
                      style={{
                        width: "0.8rem",
                        height: "0.8rem",
                        borderRadius: "0.8rem",
                        background: selectedImg === i ? "#4669E8" : "#D9D9D9",
                      }}
                      onClick={() => setSelectedImg(i)}></div>
                  );
                })}
            </Sub>
          </SliderDiv>

        </Sub1>

        <Sub2>
          <ContentDiv>
            <ContMain>
              <Heading>
                {details?.title}
              </Heading>
              <KeyF>
                <p>Key Features</p>
                {/* { description?.split('\n').map((i, k) => { return (<li key={k}>{i.replace(":", " : ")}</li>)})} */}
                <span>{details?.description}</span>
              </KeyF>

              <Warrenty>
                <div style={{ fontSize: '1rem', fontWeight: 500 }} >5 Year warrenty</div>
                <div style={{ color: '#4669E8', fontSize: "0.75rem" }}>View Details</div>
              </Warrenty>

              <FooterDiv>
                <Cards1>
                  <Button>
                    <button onClick={() => handleAddToCart()}>Add to cart</button>
                  </Button>
                </Cards1>
              </FooterDiv>
            </ContMain>
          </ContentDiv>
        </Sub2>
      </ScreenContent>
    </Screen>
  );
};

export default CustomPcDetails;





const Screen = styled.div`
  margin-top: 5rem;
  height: calc(100vh - 5rem);
  max-height: calc(100vh - 5rem);
  overflow-y: auto;
`;

const ScreenContent = styled.div`
display: grid;
grid-template-columns: repeat(12, minmax(0, 1fr));
`;


const Sub1 = styled.div`
grid-column: span 5 / span 5;
@media only screen and (min-width:250px) and (max-width:1023px) {grid-column: span 12 / span 12;}
`;


const SliderDiv = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding-top:5rem;
padding-bottom:1rem;
@media only screen and (min-width:250px) and (max-width:1023px) {
padding-top:1rem;
padding-bottom:1rem;
}
`



const Sub = styled.div`
display: flex;
gap:0.625rem;
justify-content:center;
flex-direction: row;
margin-top: 1rem;
`;


const Sub2 = styled.div`
grid-column: span 7 / span 7; 
 /* background-color:orange; */
@media only screen and (min-width:250px) and (max-width:1023px) {grid-column: span 12 / span 12;}
`;


const Img = styled.img`
  width: 400px;
  height: 400px;
  object-fit: contain;
  @media only screen and (min-width:768px) and (max-width:1023px) {
  width: 300px;
  height: 300px;
  }
  @media only screen and (min-width:450px) and (max-width:767px) {
  width: 250px;
  height: 250px;
  }
  @media only screen and (min-width:250px) and (max-width:449px) {
  width: 220px;
  height: 220px;
  }
`;

const ContentDiv = styled.div`
display: flex;
flex-direction: column;
padding-top:5rem;
padding-bottom:1rem;
padding-left:1rem;
padding-right:1rem;
@media only screen and (min-width:250px) and (max-width:1023px) {
align-items:center;
padding:1rem 1rem;
}
`

const ContMain = styled.div`
`;


const Heading = styled.div`
font-size: 1.5rem;
font-weight: ${fonts.fontWeight5};
`;


const KeyF = styled.div`
display:flex;
flex-direction:column;
width: 100%;
max-width:750px;
  p {
    margin: 0px;
    padding:0.5rem 0;
    font-size:1.1rem;
    font-weight: ${fonts.fontWeight5};
  }
  span{
    margin:0;
    font-size:1rem;
    font-weight:300;
    @media only screen and (min-width:768px) and (max-width:1023px) {
        font-size:0.9rem;
}
    @media only screen and (min-width:250px) and (max-width:767px) {
        font-size:0.8rem;
}
  }
`;


const FooterDiv = styled.div``;

const Cards1 = styled.div``;

export const Button = styled.div`
  button {
    max-width: 12.75rem;
    padding: 12px 57px;
    background-color: ${colors.blue};
    color: ${colors.white};
    border: none;
    margin-top: 1.313rem;
  }
`;


const Warrenty = styled.div`
display:flex;
justify-content: space-between;
align-items: center;
border:1px solid #00000033;
max-width:22.625rem;
padding:0.75rem 0.813rem;
margin-top:1.563rem
`;

