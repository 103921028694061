import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalContainer, Modal, Overlay } from "../../components/modal"
import styled from 'styled-components';
import { IoClose } from "react-icons/io5";
import { Button, ButtonContainer, CloseContainer, Container, Head1, InnerConatiner } from './ModalStyled';

const GotoCartModal = ({ close, nav }) => {
  const navigation = useNavigate();
  return (
    <ModalContainer>
      <Modal Modal>
        <Overlay>
          <Container>
            <CloseContainer>
              <IoClose size={22} onClick={() => close(false)} />
            </CloseContainer>
            <InnerConatiner>
              <Head1>Product Already Exists in Cart</Head1>
              <ButtonContainer>
                <Button onClick={() => close(false)}>Cancel</Button>
                <Button bg={1} txt={1} onClick={() => navigation(`${nav}`)}>Cart</Button>
              </ButtonContainer>
            </InnerConatiner>
          </Container>
        </Overlay>
      </Modal>
    </ModalContainer>
  )
}

export default GotoCartModal;
