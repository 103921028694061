import { More } from '@mui/icons-material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components'
import { getBidProduct } from '../../api/api';
import LoadingSpinner from '../../components/Button/Loading/LoadingSpinner';
import ErrorComponent from '../../components/Button/error/ErrorComponent';
import axiosInstance from '../../consts/axiosInstance';



const AuctionProductDetail = ({ itemType }) => {

  const navigation = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [bidProducts, setBidProducts] = useState([])
  const [actualImg, setActaulImg] = useState([]);
  const [bidImg, setBidImg] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0)


  const entrollHandler = () => {
    // setClicked(1);
    // setOpenEntroll(true);
    navigation('/auction/entroll')
  }


  const buyHandler = () => {
    // setOpenEntroll(false);
    // setClicked(2);
  }



  const participateHandler = () => {
  }




  const handleImageChange = (index) => {
    setCurrentImageIndex(index)
  }



  const getBidProducts = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('customerBidProduct');
      setBidProducts(response.data);
      response.data?.actual_image && setActaulImg(JSON?.parse(response.data?.actual_image));
      response.data?.bonus_image && setBidImg(JSON?.parse(response.data?.bonus_image));
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getBidProducts();
  }, [])



  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }




  return (
    <Main>
      <Container1>
        <SubDiv>
          <Card>
            {itemType === "bonus" && (<img src={bidImg[currentImageIndex]} alt='bid-product' />)}
            {itemType === "actual" && (<img src={actualImg[currentImageIndex]} alt='actual-product' />)}
          </Card>
          <CircleContainer>
            {itemType === "bonus" && bidImg?.slice(0, 5).map((_, index) => (
              <Circle key={index} onClick={() => handleImageChange(index)} active={currentImageIndex === index} />
            ))}
            {itemType === "actual" && actualImg?.slice(0, 5).map((_, index) => (
              <Circle key={index} onClick={() => handleImageChange(index)} active={currentImageIndex === index} />
            ))}
          </CircleContainer>
        </SubDiv>
      </Container1>

      <Container2>
        <SubDiv2>
          <Header>Key Feautures</Header>
          <Points>
            {itemType === "bonus" && bidProducts?.bonus_description}
            {itemType === "actual" && bidProducts?.actual_description}
          </Points>
          <Warrenty>
            {itemType === "bonus" && <div style={{ fontSize: '1rem', fontWeight: 500 }} >{bidProducts?.bonus_warranty} Year warrenty</div>}
            {itemType === "actual" && <div style={{ fontSize: '1rem', fontWeight: 500 }} >{bidProducts?.actual_product_warranty} Year warrenty</div>}
            <div style={{ color: '#4669E8', fontSize: "0.75rem" }}>View Details</div>
          </Warrenty>
          {itemType === "bonus" && <Mrp>₹ {bidProducts?.bonus_price}</Mrp>}
          {itemType === "actual" && <Mrp>₹ {bidProducts?.actual_product_price}</Mrp>}
          <GetEntrolled onClick={() => navigation('/auction')}>Get Entrolled</GetEntrolled>
        </SubDiv2>
      </Container2>
    </Main>
  )
}
export default AuctionProductDetail;



const SubDiv2 = styled.div`
@media only screen and (min-width: 1024px) {
  width:30rem;
} 
@media only screen and (min-width: 250px) and (max-width: 374px) {padding:0 0.5rem 2rem} 
@media only screen and (min-width: 375px) and (max-width: 499px) {padding:0 1rem 2rem} 
@media only screen and (min-width: 500px) and (max-width: 767px) {padding:0 2rem 2rem}            
@media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0 6rem 2rem}
`
export const Card = styled.div`
margin-top:1.5rem;
display:flex;
justify-content:center;
align-items:center;
border:1px solid #00000033;
padding:0.5rem;
img{
object-fit: contain;
width:380px;
height:380px;
@media only screen and (min-width: 355px) and (max-width: 480px) {
width:300px;
height:300px;
}
@media only screen and (min-width: 250px) and (max-width: 354px) {
width:260px;
height:300px;
}
}
`

const GetEntrolled = styled.button`
margin-top:1rem;
font-family: Poppins;
font-size: 0.9rem;
font-weight: 400;
text-align: center;
color:white;
border:none;
background-color:blue;
@media only screen and (min-width: 250px) and (max-width: 767px) {padding:0.8rem 2rem;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0.8rem 4rem;}
@media only screen and (min-width: 1024px) and (max-width: 1199px) {padding:0.8rem 1rem;}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {padding:0.8rem 2rem}
@media only screen and (min-width: 1500px) {padding:0.8rem  3rem;}
`;
const Mrp = styled.div`
font-weight:700;
font-size:1.5rem;
margin-top:1rem;
`
const Warrenty = styled.div`
margin-top:1.563rem;
padding:0.75rem 0.813rem;
display:flex;
justify-content:space-between;
align-items:center;
border:1px solid #00000033;
`
const Points = styled.div`
margin-top:2rem;
`
const CircleContainer = styled.div`
margin-top:1.5rem;
display: flex;
gap:0.7rem;
`
const Circle = styled.div`
width: 0.8rem;
height: 0.8rem;
border-radius: 0.5rem;
background: ${(props) => (props.active ? '#4669E8' : '#B3B3B5')};
`
// main
const Main = styled.div`
@media (min-width: 1024px) { 
display: grid; 
grid-template-columns: repeat(12, minmax(0, 1fr)); 
}
height:100vh;
padding-top:5rem;
`
const Container1 = styled.div`
@media (min-width: 1024px) {
grid-column: span 5 / span 5;
background-color:#F5F5F5;
}`;

const Container2 = styled.div`
@media only screen and (min-width: 250px) and (max-width: 1023px) {
margin-top:2rem;
}
@media (min-width: 1024px) { 
grid-column: span 7 / span 7;
padding: 2.1rem 4rem;
}`;

const SubDiv = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
@media only screen and (min-width: 250px) and (max-width: 374px) {padding:0 0.5rem} 
@media only screen and (min-width: 375px) and (max-width: 499px) {padding:0 1rem} 
@media only screen and (min-width: 500px) and (max-width: 767px) {padding:0 2rem}            
@media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0 6rem}
@media only screen and (min-width: 1024px) and (max-width: 1500px) {padding:0 4rem}
@media only screen and (min-width: 1501px) {padding:0 8rem}
 `

const Price = styled.div`
margin-top: 1.5rem;
color:#4669E8;
font-weight:500;
font-size:1.563rem;
`
const Title = styled.div`
font-size:0.9rem;
line-height:1.375rem;
span{
color:#4669E8;
}`

const ImgContainer = styled.div`
margin-top:1.5rem;
            display:flex;
            justify-content:center;
            align-items:center;
          border:1px solid #00000033;
          padding:0.5rem;
            img{
           ${'' /*  width:100%;
            height:auto; */}

  ${'' /* display: block; */}
    ${'' /* margin-left: auto;
    margin-right: auto; */}
    width: 100%;
    height: auto; 
}
`


const BtnContainer = styled.div`
display:flex;
justify-content:space-between;
margin-top:2rem;
width:100%;
`
const BaseButton = styled.button`
font-family: Poppins;
 font-size: 15px;
font-weight: 400;
 line-height: 23px;
 letter-spacing: 0.05em;
 text-align: center;
 ${'' /* padding:0.8rem 4rem; */}
 color:white;
border:none;
@media only screen and (min-width: 375px){padding:0.8rem 2rem}
@media only screen and (min-width: 500px) and (max-width: 767px) {padding:0.8rem 3rem}            
@media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0.8rem 4rem}
@media only screen and (min-width: 1024px) and (max-width: 1500px) {padding:0.8rem 4rem}
@media only screen and (min-width: 1501px) {padding:0.8rem 4rem}
`
const EntrollButton = styled(BaseButton)`
  background-color: ${(props) => props.primary ? '#4669E8' : '#BCBCBC'};
`;
const ParticipateButton = styled(BaseButton)`
  background-color: ${(props) => props.secondary ? '#4669E8' : '#BCBCBC'};
`;

const BoxContainer = styled.div`
margin-top: ${props => props.mt || '0'};
${'' /* margin-top:1.6rem; */}
background-color: ${props => props.bg || '#FDFDFD'};
display:flex;
align-items: center;
${'' /* width:30rem; */}
width:100%;
img{
height:5.4rem;
width:5.4rem;
}
 `
const Footer = styled.div`
margin-top:1.3rem;
width:100%;
font-size:0.8rem;
font-weight: 400;
line-height:1.3rem;
`
// conatiner 2
const Header = styled.div`
  font-size:1.2rem;
  font-weight:400;
  line-height:1.5rem; 
  `
const EntrollSub = styled.div`
width:30rem;
margin-top:2rem;
display:flex;
flex-direction:column;
align-items:end;
`
const EntrollPrice = styled.div`
font-weight:500;
color:#4669E8;
font-size:1.5rem;
`
const Details = styled.div`
color:#4669E8;
`