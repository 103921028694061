import line from "../../../assets/sale/Line44.png";
import styled from "styled-components";
import { colors, fonts } from "../../../consts/themes";
import coin from "../../../assets/sale/images/clikarticon.png"



const AccessoriesCart = ({ data, removeAll, accRemove, parentRemove, handleAddQuantity, handleRemoveQuantity, nextHandler, total }) => {
  return (
    <Cdiv>

      <HeadDiv>
        <HeadText> Product Details</HeadText>
      </HeadDiv>

      <CenterDiv>

        <div>
          <CartItemsConatiner>
            <div>
              <ItemImg src={data?.productImages && (JSON.parse(data?.productImages)[0])} />
            </div>
            <CartDetailsConatiner>
              <ItemTitle>{data?.title}</ItemTitle>
              <ItemPrice>₹{data?.offer_price * data?.qnty}</ItemPrice>
              <ItemDetails>
                <ItemDetail>
                  <div>{data?.warranty} year warranty</div>
                  <div>{data?.reward_points} coins</div>
                </ItemDetail>
              </ItemDetails>


              <Bottom>
                <BSub1>
                  <Features>
                    <button onClick={() => handleRemoveQuantity(data)}>-</button>
                    <Count>{data?.qnty}</Count>
                    <button onClick={() => handleAddQuantity(data)}>+</button>
                  </Features>
                </BSub1>
                <BSub2 onClick={() => parentRemove(data)} >Remove</BSub2>
              </Bottom>

            </CartDetailsConatiner>
          </CartItemsConatiner>

          <Line>
            <img src={line} alt="line" />
          </Line>
        </div>



        {/* if accessories present in parent product */}
        {(data?.accessories) && (data?.accessories.length > 0) && (
          data.accessories.map((item) => {
            return (
              <div key={item?.productId}>
                <CartItemsConatiner>

                  <div>
                    <ItemImg src={item?.productImages && (JSON.parse(item?.productImages)[0])} />
                  </div>

                  <CartDetailsConatiner>
                    <ItemTitle>{item?.title}</ItemTitle>
                    <ItemPrice>₹{item.comboPrice * item.qnty}</ItemPrice>
                    <ItemDetails>

                      <ItemDetail>
                        <div>{item?.warranty} year warranty</div>
                        <div>{item?.reward_points} coins</div>
                      </ItemDetail>

                    </ItemDetails>

                    <Bottom>
                      <BSub1>
                        <Features>
                          <button onClick={() => handleRemoveQuantity(item)}>-</button>
                          <Count>{item?.qnty}</Count>
                          <button onClick={() => handleAddQuantity(item)} >+</button>
                        </Features>
                      </BSub1>
                      <BSub2 onClick={() => accRemove(item)}>Remove</BSub2>
                    </Bottom>

                  </CartDetailsConatiner>
                </CartItemsConatiner>

                <Line>
                  <img src={line} alt="line" />
                </Line>
              </div>
            )
          }))}

      </CenterDiv>
      <CloseButton onClick={nextHandler}>
        <div >Next</div>
      </CloseButton>
      <Footer>
        <Coin>
          <div>Total clikepoints</div>
          <img src={coin} />
        </Coin>
        <Total>Total Amount : <span>{total}</span></Total>
      </Footer>

    </Cdiv>
  );
};

export default AccessoriesCart;


const Total = styled.div`
span{
  font-family: Poppins;
font-size: 16px;
font-weight: 500;
color:#4669E8;
}
`

const Coin = styled.div`
display: flex;
align-items: center;
gap: 0.5rem;
img{
  width:1.166rem;
  height:1.271rem;
}
`


const Line = styled.div`
display: flex;
justify-content: center;
margin-top:0.5rem;
img{
  width:80%;
}
`



const Bottom = styled.div`
margin-top:0.3rem;
display:flex;
align-items:center;
justify-content:space-between;
/* background:red; */
`

const BSub1 = styled.div`
display:flex;
gap:1rem;
justify-content:center;
`


const BSub2 = styled.div`
color:red;
 cursor: pointer;
`


const Features = styled.div`
    display:flex;
    a{
        font-size:12px;
        color:${colors.line};
        font-weight:${fonts.fontWeight3};
        margin:0;
    }
  
    button{
        background-color:${colors.white};
        border:1px solid ${colors.light_grey};
        width:25px;
        height:25px;
        border-radius:30%;
        font-size:12px;
        outline:none;
    }
    /* @media (max-width:500px){
        display:none;
    }
    @media (min-width:1000px) and (max-width:1100px){
        display:none;
    } */
        `



const Count = styled.div`
        width:70px;
        margin:0 8px;
        border:1px solid ${colors.light_grey};
        height:25px;
        border-radius:8px;
        display:flex;
        align-items:center;
        justify-content:center;
        font-size:13px;
        @media (max-width:425px){
            width:40px;
        }
        `












const CloseButton = styled.button`
  color: #fff;
  border: none;
  cursor: pointer;
  margin:1rem;
  font-size:0.8rem;
  font-weight: bold;
  display:flex;
  justify-content: center;
  align-items: center;
  gap:0.5rem;
  border-radius:5px;
  background-color: #0095ff;
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
  div{
  padding:0.5rem;}
`;


const Cdiv = styled.div`
display:flex;
flex-direction:column;
height: calc(100vh - 5rem);
`

const HeadDiv = styled.div`
display:flex;
justify-content: center;
align-items: center;
letter-spacing: 0em;
font-family: Poppins;
font-size: 20px;
font-weight: 500;
line-height: 36px;
height: 4rem;
padding-top: 1rem;
padding-bottom: 1rem;
/* border:1px solid violet; */
`

const HeadText = styled.div`
flex-grow: 1;
display: flex;
justify-content: center;
align-items: center;

`

const CenterDiv = styled.div`
flex-grow: 1;
padding-top:1.5rem;
height: calc(100vh - 9rem);
overflow-y: auto;
/* border:1px solid blue; */
`

const Footer = styled.div`
padding: 1rem;
display: flex;
flex-direction:column;
gap:0.3rem;
/* background-color: pink; */
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const CartItemsConatiner = styled.div`
display: flex;
align-items: center;
gap:0.5rem;
padding: 0 0.5rem;
margin-top: 0.5rem;
`
const CartDetailsConatiner = styled.div`
flex-grow: 1;
/* border: 1px solid black; */
`

const ItemImg = styled.img`
height:6.6rem;
width:6.6rem;
background-color: white;
/* border: 1px solid black; */
`
const ItemTitle = styled.div`
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
`




const ItemPrice = styled.div`
margin-top:0.5rem;
font-family: Poppins;
font-size: 16px;
font-weight: 500;
color:#4669E8;
`



const ItemDetails = styled.div`
display:flex;
justify-content: space-between;

`

const ItemDetail = styled.div`
display:flex;
justify-content: space-between;
width: 100%;
margin: 0.1rem 0;
/* background: pink; */
/* border:1px solid black; */
`



