import styled from "styled-components";
import { colors } from "../../consts/themes";

export const Container = styled.div`
position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.2);
`
export const Modalp = styled.div`
z-index: 1;
  width: 400px;
  /* height: 600px; */
   ${'' /* background: red; */}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  position: relative; 
  margin-inline: 1rem;
  padding:15px 0px;
  /* box-shadow: 0px 4px 9px 1px rgba(0, 0, 0, 0.5); */
  @media screen and (max-width: 500px){
    width:350px;
  }  
  @media screen and (max-width: 400px){
    width:300px;
  }  
  @media screen and (max-width: 350px){
    width:280px;
  }  
`
export const DIv = styled.div`
width:100%;
cursor:pointer;
align-items:flex-end;
display:flex;
justify-content:flex-end;
// background-color:blue;
`

export const Heading = styled.div`
width: 100%;
/* height: 5vh; */
cursor:pointer;
padding-bottom: 10px;
border-bottom: .5px solid ${colors.line};
h4{
    margin-bottom: 5px;
    padding-left:18px ;
    font-size:18px;
    cursor:pointer;
}

`

export const Style = styled.div`
width: 100%;
margin-bottom: 0px;

`
export const DetailsDiv = styled.div`
width: 90%;
/* height: 80%; */
margin-top: 0px;

`
export const Details = styled.div`
display: flex;
flex-direction: column;
width:100%;
/* height: 260px; */
margin-top: 0px;

p{
    font-size: 14px;
    margin-top: 1rem;
    text-align: left;
}

`
export const InPut = styled.div`
height: 100%;
padding-top: 1rem;
input{
    ::-webkit-inner-spin-button,
    ::-webkit-outer-spin-button
    {
        -webkit-appearance: none;
        margin: 0;
    }
    width: 98%;
    height: 3vh;
    outline: none;
    border: .5px solid ${colors.line};
    border-radius: 8px;
    padding-left: 10px;
    margin-block: 10px;
    
}
`
export const Footer = styled.div`
width: 90%;
margin-top: 1rem;
margin-bottom: 1rem;
`
export const Button = styled.div`
justify-content: center;
p {
  font-size:14px;
}
button{
    width: 100%;
    height: 5vh;
    font-size:12px;
    background-color: ${colors.blue};
    border: none;
    border-radius: 8px;
    outline: none;
    cursor: pointer;
    color: ${colors.white};
    font-size: 16px;
    /* margin-inline: 1rem; */
}
`
export const CheckBox = styled.div`
display: flex;
flex-direction: row;
/* margin-bottom: 2rem; */
input{
    accent-color:${colors.blue};
    margin-top: 10px;
    margin-right: 10px;
}
p{
  font-size: 12px;
  margin-top: 1.2rem;
}
`







export const Radio = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    input {
      accent-color: ${colors.blue};
    }
    screen and (max-width: 500px) {
      display: flex;
      flex-direction: column;
    }
  `;

export const RadioInput = styled.div`
    display: flex;
    flex-direction: row;
    p {
      font-size: 12px;
    }
  `;

export const Ptag = styled.div`
    margin-right: 6px;
    p {
      font-size: 12px;
    }
  `;