import React, { useEffect, useState } from 'react'
import styled from 'styled-components'



const CountDown = ({ endTime, data }) => {

  // console.log("endTime", endTime)

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => clearInterval(timerInterval);
  }, []);

  function calculateTimeRemaining() {
    const now = new Date();
    const end = new Date(`${now.toDateString()} ${endTime}`);
    const difference = end - now;

    if (difference <= 0) {
      // Timer expired
      return { hours: 0, minutes: 0, seconds: 0 };
    }

    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { hours, minutes, seconds };
  }





  return (
    <TimeSection >
      <Timetext>
        <TimeStart>
          <TimeStartText>Bid start time </TimeStartText>
          <TimeStartText>{data?.start_time1}</TimeStartText>
        </TimeStart>

        <TimeStart>
          <TimeStartText>Bid end time </TimeStartText>
          <TimeStartText>{data?.end_time1}</TimeStartText>
        </TimeStart>
      </Timetext>

      <TimeSub>
        <Timeclock>
          <TimeStartText>Time left</TimeStartText>
          <ClockContent>
            <TimerWraper>
              <Timer>{timeRemaining.hours}</Timer>
              <TimerTitle>Hours</TimerTitle>
            </TimerWraper>
            <Timer>:</Timer>
            <TimerWraper>
              <Timer>{timeRemaining.minutes}</Timer>
              <TimerTitle>Minutes</TimerTitle>
            </TimerWraper>
            <Timer>:</Timer>
            <TimerWraper>
              <Timer>{timeRemaining.seconds}</Timer>
              <TimerTitle>Seconds</TimerTitle>
            </TimerWraper>
          </ClockContent>
        </Timeclock>
      </TimeSub>
    </TimeSection>

  )
}

export default CountDown









const TimeSection = styled.div`
${'' /* display:flex;
justify-content: space-between;
align-items: center;
padding:0.5rem 4rem;
border-bottom: 1px solid black;  */}

@media only screen and (min-width: 250px) and (max-width: 1023px) {
display:flex;
flex-direction: column;
gap:0.5rem;
}


@media (min-width: 1024px) { 
display:flex;
justify-content: space-between;
align-items: center;
border-bottom: 1px solid black;
}


@media only screen and (min-width: 1200px) and (max-width: 1450px) {
padding:0.5rem 1rem;
}
@media only screen and (min-width: 1451px) {
padding:0.5rem 4rem;
}



`

const DetailSection = styled.div`
`





const TimerWraper = styled.div`
display: flex;
flex-direction:column;
align-items: center;
`




const Timer = styled.div`
${'' /* line-height: 2.3rem; */}
font-size:1.25rem;
font-weight:500;

@media only screen and (min-width: 250px) and (max-width: 1450px) {
font-size:1rem;
}
`


const TimerTitle = styled.div`
line-height:0.9rem;
font-size:0.6rem;
font-weight:500;
`



const ClockContent = styled.div`
background-color:white;
display:flex;
gap:0.5rem;
padding:0 1.625rem;
${'' /* border:1px solid black; */}
`



const Timeclock = styled.div`
display:flex;
align-items:center;
gap:0.8rem;
`




const Timetext = styled.div`
display:flex;
gap:3.5rem;

@media only screen and (min-width: 250px) and (max-width: 1023px) {
flex-direction:column;
gap:0.5rem;
}


@media only screen and (min-width: 250px) and (max-width: 374px) {padding:0 0.5rem } 
@media only screen and (min-width: 375px) and (max-width: 499px) {padding:0 1rem} 
@media only screen and (min-width: 500px) and (max-width: 767px) {padding:0 2rem}            
@media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0 6rem}


`



const TimeStart = styled.div`
display: flex;

align-items: center;
gap:0.68rem;

@media only screen and (min-width: 1024px) {
  justify-content: space-between;
}

`

const TimeStartText = styled.div`
color:${props => props.blue ? '#4669E8' : ''};





@media only screen and (min-width: 1024px) and (max-width: 1199px) {
font-size:0.75rem;
}

@media only screen and (min-width: 1200px) and (max-width: 1450px) {
font-size:0.8rem;
}


@media only screen and (min-width: 1451px) {
font-weight:400;
font-size:1rem;
line-height:1.8rem;
}

`


const TimeSub = styled.div`
@media only screen and (min-width: 250px) and (max-width: 374px) {padding:0 0.5rem } 
@media only screen and (min-width: 375px) and (max-width: 499px) {padding:0 1rem} 
@media only screen and (min-width: 500px) and (max-width: 767px) {padding:0 2rem}            
@media only screen and (min-width: 768px) and (max-width: 1023px) {padding:0 6rem}
`
