import { React, useState } from "react";
import line from "../../../assets/sale/Line44.png";
import styled from "styled-components";
import { colors } from "../../../consts/themes";
import LoadingSpinner from "../../../components/Button/Loading/LoadingSpinner";
import ErrorComponent from "../../../components/Button/error/ErrorComponent";
import CustomPcSubmitModal from "../../../components/Modal/CustomPcSubmitModal";
import { RiDeleteBinLine } from "react-icons/ri";
import axiosInstance from "../../../consts/axiosInstance";




const CustomPcCart = ({ data, removeAll, submit }) => {
  return (
    <Cdiv>
      <HeadDiv>
        <HeadText> Product Details</HeadText>
        {data.length > 0 &&
          <CloseButton onClick={removeAll}>
            <span>Clear</span>
            <RiDeleteBinLine size={25} />
          </CloseButton>
        }
      </HeadDiv>
      <CenterDiv>
        <div>
          <div>
            {data.length > 0 && data.map((item) => (
              <div key={item.product_id}>
                <CartItemsConatiner>
                  <div>
                    <ItemImg src={item?.images && (JSON.parse(item?.images)[0])} />
                  </div>
                  <CartDetailsConatiner>
                    <ItemTitle>{item?.title}</ItemTitle>
                    <ItemDetails>
                      <ItemDetail>
                        {/* <div>1{item?.warranty_detail}</div> */}
                        <div>{item?.reward_points} coin</div>
                      </ItemDetail>
                      {/* <RemoveButton onClick={() => HandleRemoveComponent(item)}>Remove</RemoveButton> */}
                    </ItemDetails>
                  </CartDetailsConatiner>
                </CartItemsConatiner>
                <img src={line}></img>
              </div>
            ))}
          </div>
        </div>
      </CenterDiv>
      {data.length > 0 && (
        <Sbutton>
          <button onClick={submit}>Submit</button>
        </Sbutton>
      )}
    </Cdiv>
  );
};

export default CustomPcCart;


const CloseButton = styled.button`
  color: #fff;
  border: none;
  cursor: pointer;
  margin:1rem;
  font-size:0.8rem;
  font-weight: bold;
  display:flex;
  justify-content: center;
  align-items: center;
  gap:0.5rem;
  border-radius:5px;
  background-color: #0095ff;
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
`;


const Cdiv = styled.div`
display:flex;
flex-direction:column;
/* height: calc(100vh - 5rem); */
`

const HeadDiv = styled.div`
display:flex;
justify-content: center;
align-items: center;
letter-spacing: 0em;
font-family: Poppins;
font-size: 20px;
font-weight: 500;
line-height: 36px;
height: 2rem;
padding-top: 1rem;
padding-bottom: 1rem;
`

const HeadText = styled.div`
flex-grow: 1;
display: flex;
justify-content: center;
align-items: center;
`

const CenterDiv = styled.div`
flex-grow: 1;
padding-top:1.5rem;
height: calc(100vh - 9rem);
overflow-y: auto;
`

const Sbutton = styled.div`
flex-grow: 0;
display: flex;
justify-content: center;
align-items: center;
padding:1.5rem 0;
button{
  padding:1rem 0;
  width: 80%;
  color: ${colors.white};
    cursor: pointer;
    background-color: ${colors.blue};
    border: 1px solid ${colors.blue};
    font-size: 16px;
    border-radius: 8px;
}`

const CartItemsConatiner = styled.div`
display: flex;
align-items: center;
gap:3rem;
padding-left:1.5rem;
padding-right:3.8rem;
`
const CartDetailsConatiner = styled.div`
flex-grow: 1;
`

const ItemImg = styled.img`
height:6.6rem;
width:6.6rem;
background-color: white;
`
const ItemTitle = styled.div`
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
`
const ItemDetails = styled.div`
background-color: white;
display:flex;
justify-content: space-between;
padding-top:1rem;
`

const ItemDetail = styled.div`
display:flex;
gap:0.5rem;
`



