import React, { useState, useEffect } from 'react';


import { useNavigate } from 'react-router-dom';
import {
  getProductOfPrinter, productListBasedOnCategory,
} from '../../../api/api';


import { NoDisplay, colors } from '../../../consts/themes';
import Help from '../../../components/Help/help';

import LoadingSpinner from '../../../components/Button/Loading/LoadingSpinner';
import ErrorComponent from '../../../components/Button/error/ErrorComponent';
import axiosInstance from '../../../consts/axiosInstance';
import styled from 'styled-components';




const Accessoriespage = () => {

  const printercatId = JSON.parse(localStorage.getItem("printercatId"));

  const Typeids = JSON.parse(localStorage.getItem("Typeids"));




  console.log("printercatId88888",printercatId);
  console.log("Typeids8888888",Typeids)

  //printer id from printertype component
  const PrinterfilterId = JSON.parse(localStorage.getItem("PrinterfilterId"));



  const navigation = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [accessories, setAccessories] = useState([]);



  const printers = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`getProductListBasedOnCategory?product_category_id=${printercatId}&customer_category_id=${0}
      &customer_sub_category_id=${0}&type_id=${Typeids}`);
      console.log("alex ", response.data)
      setAccessories(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };





  const filteredProducts = (accessories || []).filter((product) => product.printerCate === PrinterfilterId);



  useEffect(() => {
    printers();
  }, []);




  const truncate = (string) => {
    return string?.length > 15 ? string?.substr(0, 15 - 1) + '...' : string
  }






  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }


  return (


    <Screen>

      {filteredProducts?.length === 0 ? <NoDisplay>No Products to display</NoDisplay> : null}


      <ScreenContent>

        {filteredProducts?.map((item, i) => (
          <CardImg key={i}>
            <Mappingcards
              onClick={() => [
                navigation('/sales/accessories/productsdetails', { state: { path: 'printer' } }),

                localStorage.setItem('accids', JSON.stringify(item?.productId)),
                localStorage.setItem('printerid', JSON.stringify(item?.product_category_id)),]}
            >
              <Maproute>
                <Lapimage>
                  <img src={JSON.parse(item?.productImages)[0]}></img>
                </Lapimage>

                <Laptext>{truncate(item.title)}</Laptext>

                <Price>₹ {item.offer_price}</Price>

                <Offer>
                  <Offerwrong>₹{item.price}</Offerwrong>
                </Offer>

                <Coin_warrenty>
                  <Warranty>
                    {/* <img src={warranty}></img> */}
                    {item.warranty} year warranty
                  </Warranty>

                  <Coin>
                    {/* <img src={coin}></img> */}
                    {item.reward_points}Clike points
                  </Coin>
                </Coin_warrenty>

                <Name2>{item.name2}</Name2>
                <Demand>
                  <p> {item?.stock_status}</p>
                </Demand>
              </Maproute>

            </Mappingcards>
          </CardImg>
        ))}

      </ScreenContent>
      <Help />
    </Screen>
  );
};

export default Accessoriespage;







const Screen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin:0;
  padding-top:5rem;
  height: calc(100vh - 5rem);
`;


const ScreenContent = styled.div`
display: grid;
grid-template-columns: repeat(12, minmax(0, 1fr));
overflow-y: scroll;
margin-top:1rem;
padding-bottom:2rem;
@media only screen and (min-width: 250px) and (max-width: 350px) {gap: 0.5rem;}
@media only screen and (min-width: 351px) and (max-width: 768px) {gap: 1rem;}
@media only screen and (min-width: 768px) and (max-width: 1023px) {gap: 1.5rem;}
@media only screen and (min-width: 1024px) {gap: 2rem;}
`;


const CardImg = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
@media only screen and (min-width: 250px) and (max-width: 569px) {grid-column: span 12 / span 12;}
@media only screen and (min-width: 570px) and (max-width: 887px) {grid-column: span 6 / span 6;}
@media only screen and (min-width: 888px) and (max-width: 1199px) {grid-column: span 4 / span 4;}
@media only screen and (min-width: 1200px) {grid-column: span 3 / span 3;}
border: 1px solid ${colors.line};
padding:0.5rem;
border-radius:5px;
`;

const Mappingcards = styled.div`
max-width:15rem;
width:15rem;
height:auto;
`;


const Image_one = styled.div`
display:flex;
justify-content: flex-end;
`;


const FavDiv = styled.div`
img{
  height:1rem;
  width:1.2rem;
}
`;


const Lapimage = styled.div`
  display: flex;
  justify-content: center;
  img {
    width: 190px;
    height: 190px;
    object-fit: contain;
  }
`;

const Laptext = styled.div`
margin-top: 0.4rem;
display: flex;
justify-content: center;
text-align: center;
font-family: Arial;
font-size: 1.125rem;
font-style: normal;
font-weight: 400;
line-height:1.575rem;
`;




const Offer = styled.div`
margin-top: 0.7rem;
display: flex;
justify-content: center;
font-family: Arial;
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height:0.8775rem;
color: ${colors.light_grey};
`;



const Offerwrong = styled.div`
  text-decoration-line: line-through;
`;



const Offercorrect = styled.div`
  font-size: 12px;
  color: red;
`;


const Price = styled.div`
margin-top: 0.4rem;
display: flex;
justify-content: center;
align-items: center;
color: ${colors.blue};
font-family: Arial;
font-size: 1.25rem;
font-style: normal;
font-weight: 700;
line-height:1.4625rem;
`;




const Coin_warrenty = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 12px;
  align-self: center;
`;



const Coin = styled.div`
  color: ${colors.blue};
  font-size: 12px;
  display: flex;
  text-align: center;

  img {
    width: 20px;
    height: 20px;
    padding-inline: 5px;

    @media (max-width: 1880px) {
      padding-inline: 4px;
    }
  }
`;
const Warranty = styled.div`
  font-size: 12px;
  display: flex;
  text-align: center;
  align-items: center;
  img {
    width: 18px;
    height: 18px;
    padding-inline: 5px;
  }
`;

const Demand = styled.div`
  margin-top: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
 
    color: ${colors.green};
    margin: 0;
text-align: center;
font-family: Arial;
font-size: 1rem;
font-style: normal;
font-weight: 700;
line-height: 1.17rem;

  }
`;

const Name2 = styled.div`
  margin-top: 10px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  color: ${colors.green};
`;
export const Mapping = styled.div`  background-color:pink`;
export const Maproute = styled.div``;