import React from 'react'
import styled from 'styled-components';
import { colors, fonts } from '../../../consts/themes';
import { GrFormClose } from "react-icons/gr";
import line from "../../../assets/Images/png/Line44.png"
import coin from "../../../assets/sale/images/clikarticon.png"


const AccessoriesMobileCart = ({ onClose, data, accRemove, parentRemove, handleAddQuantity, handleRemoveQuantity, nextHandler, total }) => {
  return (
    <CartWrapper>

      <CartHeader>
        <CloseButton onClick={onClose}>
          <GrFormClose size={25} />
        </CloseButton>
        <ButtonConatiner>
          <CloseButton onClick={nextHandler}>
            <span>Next</span>
          </CloseButton>
        </ButtonConatiner>
      </CartHeader>


      <CenterDiv>
        <div>
          <CartItemsConatiner>
            <div>
              <ItemImg src={data?.productImages && (JSON.parse(data?.productImages)[0])} />
            </div>
            <CartDetailsConatiner>
              <ItemTitle>{data?.title}</ItemTitle>
              <ItemPrice>₹{data?.offer_price * data?.qnty}</ItemPrice>
              <ItemDetails>
                <ItemDetail>
                  <div>{data?.warranty} year warranty</div>
                  <div>{data?.reward_points} coins</div>
                </ItemDetail>
              </ItemDetails>


              <Bottom>
                <BSub1>
                  <Features>
                    <button onClick={() => handleRemoveQuantity(data)}>-</button>
                    <Count>{data?.qnty}</Count>
                    <button onClick={() => handleAddQuantity(data)}>+</button>
                  </Features>
                </BSub1>
                <BSub2 onClick={() => parentRemove(data)} >Remove</BSub2>
              </Bottom>

            </CartDetailsConatiner>
          </CartItemsConatiner>

          <Line>
            <img src={line} alt="line" />
          </Line>
        </div>



        {/* if accessories present in parent product */}
        {(data?.accessories) && (data?.accessories.length > 0) && (
          data.accessories.map((item) => {
            return (
              <div key={item?.productId}>
                <CartItemsConatiner>

                  <div>
                    <ItemImg src={item?.productImages && (JSON.parse(item?.productImages)[0])} />
                  </div>

                  <CartDetailsConatiner>
                    <ItemTitle>{item?.title}</ItemTitle>
                    <ItemPrice>₹{item.comboPrice * item.qnty}</ItemPrice>
                    <ItemDetails>

                      <ItemDetail>
                        <div>{item?.warranty} year warranty</div>
                        <div>{item?.reward_points} coins</div>
                      </ItemDetail>

                    </ItemDetails>

                    <Bottom>
                      <BSub1>
                        <Features>
                          <button onClick={() => handleRemoveQuantity(item)}>-</button>
                          <Count>{item?.qnty}</Count>
                          <button onClick={() => handleAddQuantity(item)} >+</button>
                        </Features>
                      </BSub1>
                      <BSub2 onClick={() => accRemove(item)}>Remove</BSub2>
                    </Bottom>

                  </CartDetailsConatiner>
                </CartItemsConatiner>

                <Line>
                  <img src={line} alt="line" />
                </Line>
              </div>
            )
          }))}



      </CenterDiv>




      <Footer>
        <Coin>
          <div>Total clikepoints</div>
          <img src={coin} />
        </Coin>
        <Total>Total Amount : <span>{total}</span></Total>
      </Footer>

    </CartWrapper>
  )
}

export default AccessoriesMobileCart;



const CartWrapper = styled.div`
box-sizing:border-box;
position: fixed;
top: 0;
right: 0;
bottom: 0;
width: 95%;
background-color:#F8F8F8;
z-index: 2;
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
height: calc(100vh - 5rem);
margin-top:5rem;
display:flex;
flex-direction:column;
`;

const CenterDiv = styled.div`
flex-grow: 1;
height: calc(100vh - 9rem);
overflow-y: auto;
`

const Footer = styled.div`
padding: 1rem;
display: flex;
flex-direction:column;
gap:0.3rem;
/* background-color: pink; */
box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;







const CartHeader = styled.div`
display:flex;
justify-content: space-between;
align-items: center;
`;



const CloseButton = styled.button`
  color: #fff;
  border: none;
  cursor: pointer;
  margin:1rem;
  font-size:0.8rem;
  font-weight: bold;
  display:flex;
  justify-content: center;
  align-items: center;
  gap:0.5rem;
  border-radius:5px;
  background-color: #0095ff;
  box-shadow: 0 0 0 4px rgba(0, 149, 255, .15);
  height:2rem;
  padding:0 0.5rem;
`;


const ButtonConatiner = styled.div` 
display:flex;
gap:0.5rem;
align-items: center;
justify-content: center;
`;



const Bottom = styled.div`
margin-top:0.3rem;
display:flex;
align-items:center;
justify-content:space-between;
/* background:red; */
`

const BSub1 = styled.div`
display:flex;
gap:1rem;
justify-content:center;
`

const BSub2 = styled.div`
color:red;
 cursor: pointer;
`

const Features = styled.div`
    display:flex;
    a{
        font-size:12px;
        color:${colors.line};
        font-weight:${fonts.fontWeight3};
        margin:0;
    }
  
    button{
        background-color:${colors.white};
        border:1px solid ${colors.light_grey};
        width:25px;
        height:25px;
        border-radius:30%;
        font-size:12px;
        outline:none;
    }
    /* @media (max-width:500px){
        display:none;
    }
    @media (min-width:1000px) and (max-width:1100px){
        display:none;
    } */`;


const Count = styled.div`
    width:70px;
    margin:0 8px;
    border:1px solid ${colors.light_grey};
    height:25px;
    border-radius:8px;
    display:flex;
    align-items:center;
    justify-content:center;
    font-size:13px;
    @media (max-width:425px){
        width:40px;
    }
    `

const CartItemsConatiner = styled.div`
display: flex;
align-items: center;
gap:0.5rem;
padding: 0 0.5rem;
margin-top: 0.5rem;
`

const ItemImg = styled.img`
height:6.6rem;
width:6.6rem;
background-color: white;
`

const CartDetailsConatiner = styled.div`
flex-grow: 1;
`

const ItemTitle = styled.div`
font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 19px;
letter-spacing: 0em;
text-align: left;
`
const ItemPrice = styled.div`
margin-top:0.5rem;
font-family: Poppins;
font-size: 16px;
font-weight: 500;
color:#4669E8;
`
const ItemDetails = styled.div`
display:flex;
justify-content: space-between;
`
const ItemDetail = styled.div`
display:flex;
justify-content: space-between;
width: 100%;
margin: 0.1rem 0;
`


const Line = styled.div`
display: flex;
justify-content: center;
margin-top:0.5rem;
img{
  width:80%;
}
`




const Total = styled.div`
span{
  font-family: Poppins;
font-size: 16px;
font-weight: 500;
color:#4669E8;
}
`


const Coin = styled.div`
display: flex;
align-items: center;
gap: 0.5rem;
img{
  width:1.166rem;
  height:1.271rem;
}
`;

