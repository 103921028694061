import React, { useContext } from 'react'
import { SERVICE_DATA } from '../../../MappingDatas/ServicesData'
// import { Approve, Cell, FlexCol, NotApprove, Scroll, Cells, 
//   ServicesWrapper, Span, Table, Thead, Trow, View, 
//   Show, Pending } from './ServicesStyle'
import { getServiceReg, listQuotid } from '../../../api/api'
import { useState } from 'react'
import { useEffect } from 'react'
import moment from 'moment';
import FeedbackModal from '../../services/modal/FeedbackModal';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import { useNavigate } from 'react-router-dom'
import InvoiceModal from '../InvoiceModal/InvoiceModal'
import { AuthContext } from '../../../consts/AuthContext'
import styled from 'styled-components'
import { colors, fonts } from '../../../consts/themes'
import LoadingSpinner from '../../../components/Button/Loading/LoadingSpinner'
import ErrorComponent from '../../../components/Button/error/ErrorComponent'
import axios from 'axios'
import axiosInstance from '../../../consts/axiosInstance'



const Services = () => {


  const [list, setList] = useState([]);
  const [taken, setTaken] = useState([]);
  const [authUser, setAuthUser] = useContext(AuthContext)
  const [product, setProduct] = useState([]);
  const [feedback, setFeedback] = useState(false);
  const [quot, setQuot] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigation = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [activeQtn, setActiveQtn] = useState()








  // async function quotation(tickno) {
  //   console.log("tickno", `${listQuotid}/${tickno}`)
  //   await fetch(`${listQuotid}/${tickno}`)
  //     .then((response) => response.json())
  //     .then((response) => {
  //       console.log("quotations===>", response[0])

  //       setQuot(response[0]);

  //       setTimeout(() => {
  //         jsPdfGenerator()
  //       }, 2000)
  //     })
  // }





  const servicelist = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(`getServiceReg/${authUser?.user?.id}`);
      setList(response?.data)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };



  const serviceTaken = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get('productTakenAckn');
      setTaken(response?.data)
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    servicelist();
    serviceTaken();
  }, []);



  function jsPdfGenerator() {
    var doc = new jsPDF()
    doc.autoTable({
      html: '#table',
      tableLineColor: [0, 0, 0],
      tableLineWidth: 0.1,
      theme: 'plain',
      bodyStyles: { lineColor: [0, 0, 0], lineWidth: 0.1 },
      headStyles: { textColor: [255, 0, 0] },
    })

    var string = doc.output('datauristring')
    var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
    var x = window.open()
    x.document.open()
    x.document.write(embed)
    x.document.close()
  }




  const [ticketNo, setTicketNo] = useState(null);

  const handleFeedback = (ticketno) => {
    setTicketNo(ticketno);
    setFeedback(true);
  };




  const handleFeedbackClose = () => {
    setFeedback(false);
    setTicketNo(null);
    servicelist();
  };




  
  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return <ErrorComponent error={error.message} />
  }
  return (
    <>
      {isOpen && <InvoiceModal setIsOpen={setIsOpen} activeQtn={activeQtn} />}
      {feedback && <FeedbackModal setFeedbackmodal={setFeedback} ticketNo={ticketNo} onClose={handleFeedbackClose} />}


<ServicesWrapper>
<Span>Service Details</Span>
<Scroll>
<TableContainer>
<Table>
  <thead>
    <tr>
      <th>Ticket Number</th>
      <th>Product Category</th>
      <th>Date of Submission</th>
      <th>complaint</th>
      <th>Total Amount</th>
      <th>Ticket Status</th>
      <th>Payment Status</th>
      <th>Quotation Status</th>
    </tr>
  </thead>
  <tbody>
    {list.map((item) => (
      <tr key={item.ticket_no}>
        <td>{item?.ticket_no}</td>
        <td>{item?.product_category_name}</td>
        <td>{moment.parseZone(item?.created_at).format("DD-MMM-YY")}</td>
        <td>{item?.complaint === 'undefined' || item?.complaint === '' ? 'Not Described' : item?.complaint}</td>
        <td>
          <Cell>
            {item?.total_amount ?
              <CellText>₹ {item?.total_amount}</CellText> : ""}
            {(item?.payment_status === 0) && (item?.total_amount !== "") && (
              <StyledButton
                onClick={() => { localStorage.setItem('complaintid', item?.id); navigation('/payment'); }}>
                pay</StyledButton>)}
          </Cell>
        </td>
        <td>
          <Cell>
            <CellText>{item?.ticketStatus}</CellText>
            {(item?.payment_status === 1) && (item?.quot_id) && (item?.feedbackStatus === 0) && (
              <StyledButton  onClick={() => handleFeedback(item?.ticket_no)}>Feeedback</StyledButton>
            )}
          </Cell>
        </td>
        <td>
          {item?.payment_status === 1 ? <CellText color='#06B539'>Completed</CellText> : <CellText color='#FC1010'>Not Completed</CellText>}
        </td>
        <td>
          <Cell>
            {item.quot_id ? <><CellText color='#06B539'>Approved</CellText>
              <StyledButton onClick={() => [setIsOpen(true), setActiveQtn(item?.ticket_no)]} >view quatation</StyledButton></> : <CellText color='#FC1010'>Not Approved</CellText>}
          </Cell>
        </td>
      </tr>
    ))}
  </tbody>
</Table>
</TableContainer>
</Scroll>
</ServicesWrapper>


{taken.length > 0 &&
<ServicesWrapper>
<Span>Product Taken History</Span>
<Scroll>
<TableContainer>
<Table>
  <thead>
    <tr>
      <th>Ticket number</th>
      <th>Reason</th>
      <th>Approximate cost</th>
      <th>Customer approval</th>
      <th>Return date</th>
      <th>Service engineer name</th>
    </tr>
  </thead>
  <tbody>
    {taken.map((item) => (
      <tr key={item.id}>
        <td>{item?.ticketno}</td>
        <td>{item?.reason_of_taking}</td>
        <td>{item?.approx_cost}</td>
        <td>{item?.customer_approval === 1 ? <CellText color='#06B539'>Yes</CellText> : <CellText color='#FC1010'>No</CellText>}</td>
        <td>{item?.Return_assured_date}</td>
        <td>{item?.Service_engineer}</td>
      </tr>
    ))}
  </tbody>
</Table>
</TableContainer>
</Scroll>
</ServicesWrapper>
}
    </>
  )
}

export default Services


const Table = styled.table`
width:100%;
border-collapse:collapse;
thead{box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;background-color:#8FC2FF}
tbody tr:nth-child(even) {background:#F8FBFB;}
tbody tr:nth-child(odd) {background:#EFF3F3;}
${'' /* tr{box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;} */}
td,th{text-align:center;padding:0.8rem;}

td,th{
  font-weight:normal;
  font-size:0.9rem;
  @media only screen and (min-width: 250px) and (max-width: 1023px) 
  { font-size:0.7rem;}
}
`;

const Cell = styled.div`
display:flex;
justify-content:center;
flex-direction:column;
align-items:center;
gap:0.5rem;
`;

const CellBtn = styled.div`
background-color:#FFDC13;
border-radius:5px;
width:fit-content; 
padding:0.2rem 1rem;
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
cursor:pointer;
`;

const CellText = styled.div`
${'' /* color:#0D8FF9; */}
font-weight:500;
color: ${props => props.color || '#0D8FF9'};
`;

const TableContainer = styled.div`
overflow: auto; 
margin:1rem;
`;


const StyledButton = styled.button`
  background-color: #e1ecf4;
  border-radius: 3px;
  border: 1px solid #7aa7c7;
  box-shadow: rgba(255, 255, 255, .7) 0 1px 0 0 inset;
  box-sizing: border-box;
  color: #39739d;
  cursor: pointer;
  display: inline-block;
  font-family: -apple-system, system-ui, "Segoe UI", "Liberation Sans", sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.15385;
  margin: 0;
  outline: none;
  padding:0.5rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  white-space: nowrap;
`;





const ServicesWrapper = styled.div`
    width:100%;
    margin-top:3rem;
`

const Span = styled.span`
    font-size:18px;
    color:${colors.black};
    font-weight:${fonts.fontWeight5};
    margin-bottom:2rem;
    display:block;
    @media (max-width:1200px){
    margin-top:2rem;
    }
`
const Show = styled.div`
    display: none;
`
const Scroll = styled.div`
    overflow-x:scroll;
    /* border:1px solid red;
    height: 30rem; */
`




































// old code
// import React, { useContext } from 'react'
// import { SERVICE_DATA } from '../../../MappingDatas/ServicesData'
// import { Approve, Cell, FlexCol, NotApprove, Scroll, Cells, ServicesWrapper, Span, Table, Thead, Trow, View, Show, Pending } from './ServicesStyle'
// import { getServiceReg, listQuotid } from '../../../api/api'
// import { useState } from 'react'
// import { useEffect } from 'react'
// import moment from 'moment';
// import FeedbackModal from '../../services/modal/FeedbackModal';
// import jsPDF from 'jspdf';
// import autoTable from 'jspdf-autotable'
// import { useNavigate } from 'react-router-dom'
// import InvoiceModal from '../InvoiceModal/InvoiceModal'
// import { AuthContext } from '../../../consts/AuthContext'



// const Services = () => {


//   const [list, setList] = useState([]);
//   const [authUser, setAuthUser] = useContext(AuthContext)
//   const [product, setProduct] = useState([]);
//   const [feedback, setFeedback] = useState(false);
//   const [quot, setQuot] = useState([]);
//   const navigation = useNavigate();


//   const [isOpen, setIsOpen] = useState(false);
//   const [activeQtn, setActiveQtn] = useState()




//   async function servicelist() {
//     await fetch(`${getServiceReg}/${authUser?.user?.id}`)
//       .then((response) => response.json())
//       .then((response) => {
//         setList(response)
//       })
//   }






//   async function quotation(tickno) {
//     console.log("tickno", `${listQuotid}/${tickno}`)
//     await fetch(`${listQuotid}/${tickno}`)
//       .then((response) => response.json())
//       .then((response) => {
//         console.log("quotations===>", response[0])

//         setQuot(response[0]);

//         setTimeout(() => {
//           jsPdfGenerator()
//         }, 2000)
//       })
//   }




//   useEffect(() => {
//     servicelist();

//   }, []);



//   function jsPdfGenerator() {
//     var doc = new jsPDF()
//     doc.autoTable({
//       html: '#table',
//       tableLineColor: [0, 0, 0],
//       tableLineWidth: 0.1,
//       theme: 'plain',
//       bodyStyles: { lineColor: [0, 0, 0], lineWidth: 0.1 },
//       headStyles: { textColor: [255, 0, 0] },
//     })

//     var string = doc.output('datauristring')
//     var embed = "<embed width='100%' height='100%' src='" + string + "'/>"
//     var x = window.open()
//     x.document.open()
//     x.document.write(embed)
//     x.document.close()
//   }




//   const [ticketNo, setTicketNo] = useState(null);

//   const handleFeedback = (ticketno) => {
//     setTicketNo(ticketno);
//     setFeedback(true);
//   };




//   const handleFeedbackClose = () => {
//     setFeedback(false);
//     setTicketNo(null);
//     servicelist();
//   };




//   return (
//     <>
//       {isOpen && <InvoiceModal setIsOpen={setIsOpen} activeQtn={activeQtn} />}

//       {feedback && <FeedbackModal setFeedbackmodal={setFeedback} ticketNo={ticketNo} onClose={handleFeedbackClose} />}

//       <ServicesWrapper>
//         <Span>Service Details</Span>
//         <Scroll>

//           <Table>
//             <thead>
//               <Thead>
//                 <Cells><p>Ticket Number</p></Cells>
//                 <Cells><p>Product Category</p></Cells>
//                 <Cells><p>Date of service</p></Cells>
//                 <Cells><p>Complaint</p></Cells>
//                 {/* <Cells><p>Advance Payment</p></Cells> */}
//                 <Cells><p>Total Amount</p></Cells>
//                 <Cells><p>Ticket Status</p></Cells>
//                 <Cells><p>Payment Status</p></Cells>
//                 <Cells><p>Quotation Status</p></Cells>
//               </Thead>
//             </thead>
//             <tbody>
//               {list.map((item) => {
//                 return (
//                   <Trow key={item.ticket_no}>

//                     <Cell><a>{item?.ticket_no}</a></Cell>
//                     <Cell><a>{item?.product_category_name}</a></Cell>
//                     <Cell><a>{moment.parseZone(item?.created_at).format("DD-MMM-YY")}</a></Cell>
//                     <Cell><a>{item?.complaint === 'undefined' || item?.complaint === '' ? 'Not Described' : item?.complaint}</a></Cell>


//                     {/* <Cell><a>{item?.total_amount}</a></Cell> */}
//                     {/* <Cell><a>{item?.ticketStatus}</a></Cell> */}


//                     <Cell>
//                       <FlexCol>
//                         <a>{item?.total_amount}</a>
//                         {(item?.payment_status === 0) && (item?.total_amount !== "") && (
//                           <View><p onClick={() => {
//                             localStorage.setItem('complaintid', item?.id);
//                             navigation('/payment');
//                           }}>pay</p></View>)}
//                       </FlexCol>
//                     </Cell>



//                     {/* <Cell><a>{item?.advance_payment == 'undefined' || item?.advance_payment == '' ?

//                       <Pending onClick={() => {
//                         localStorage.setItem('complaintid', item?.id);
//                         navigation('/payment');
//                       }}>Pending</Pending> : item?.advance_payment}</a>
//                     </Cell> */}


//                     <Cell>
//                       <FlexCol>
//                         <a>{item?.ticketStatus}</a>
                        
//                         {(item?.payment_status === 1) && (item?.quot_id) && (item?.feedbackStatus === 0) && (
//                           <View><p
//                             onClick={() => handleFeedback(item?.ticket_no)}
//                           >Enter Feedback</p></View>)}
//                       </FlexCol>
//                     </Cell>


//                     <Cell>{item?.payment_status === 1 ? <p>completed</p> : <span>Not Completed</span>}</Cell>



//                     <Cell>
//                       {item.quot_id ? <FlexCol><Approve><p>approved</p></Approve>
//                         <View>
//                           <p onClick={() => [setIsOpen(true), setActiveQtn(item?.ticket_no)]}>View Quotation</p></View>
//                       </FlexCol> :
//                         <NotApprove>Not Approved</NotApprove>}
//                     </Cell>



















//                     {/* <Cell><a>{item?.advance_payment == 'undefined' || item?.advance_payment == '' ?

//                       <Pending onClick={() => {
//                         localStorage.setItem('complaintid', item?.id);
//                         navigation('/payment');
//                       }}>Pending</Pending> : item?.advance_payment}</a></Cell> */}



//                     {/* <Cell>
//                       {item?.ticketStatus !== 'Closed' ? <a>{item?.ticketStatus}</a> : <p>{item?.ticketStatus}</p>}

//                       {(item.feedbackStatus === 1) && (item.payment_status === 1) &&
//                         <View View > <p onClick={() => [console.log(item?.ticket_no, "alx"),
//                         localStorage.setItem('ticketNo', item?.ticket_no), setFeedback(true)]}>feedback section</p>
//                         </View>
//                       }

//                     </Cell> */}


//                     {/* <Cell>
//                       {
//                         item?.ticketStatus !== 'Closed' ?
//                           item?.shipping_address_id && (item?.advance_payment !== '' && item?.advance_payment !== 'undefined') ?
//                             <div>Raised</div> :
//                             <>
//                               {item?.shipping_address_id === '' || item?.shipping_address_id === null ?
//                                 <View><p onClick={() => {
//                                   navigation('/addadress', { state: { user_id: item?.user_id, paymentStatus: item?.advance_payment !== '' && item?.advance_payment !== 'undefined' ? true : false } })
//                                   localStorage.setItem('complaintid', item?.id);
//                                 }}>Complete Registration</p></View> :
//                                 <View><p onClick={() => {
//                                   navigation('/payment', { state: { user_id: item?.user_id } })
//                                   localStorage.setItem('complaintid', item?.id);
//                                 }}>Complete Registration</p></View>
//                               }

//                             </> : <><p>Closed</p><View><p onClick={() => setFeedback(true)}>Enter Feedback</p></View></>
//                       }
//                       <a>{item?.ticketStatus}</a>
//                       {item?.ticketStatus ?
//                         <View><p onClick={() => [console.log(item?.ticket_no, "alx"),
//                         localStorage.setItem('ticketNo', item?.ticket_no), setFeedback(true)]}>Enter Feedback</p></View>
//                         : ("")}
//                     </Cell> */}

//                   </Trow>
//                 )
//               })}

//             </tbody>
//           </Table>
//         </Scroll>

//       </ServicesWrapper>
//     </>
//   )
// }

// export default Services





















































