export const datas = [
  {
    image: require('../assets/sale/images/Mouse1.png'),
  },
  {
    image: require('../assets/sale/images/Mouse2.png'),
  },
  {
    image: require('../assets/sale/images/Mouse3.png'),
  },
  {
    image: require('../assets/sale/images/Mouse4.png'),
  },
  {
    image: require('../assets/sale/images/Mouse5.png'),
  },

];
export const contents = [
  {
    heading: "Offbeate® - DASH 2.4GHz Wireless + Bluetooth 5.1 Mouse, Multi-Device Dual Mode Slim Rechargeable Silent Click Buttons Wireless Bluetooth Mouse",
    rate: "50",
    mrp: "1190",
    point: "15",
    cash1: "900.00",
    cash2: "650.00",
    rupa: "250",
    icon: require('../assets/sale/viewcart/coin.png'),
  },
];
export const Keyf = [
  {
    key: "Connector Type",
    discription: "Wireless,Bluetooth,USB",
  },
  {
    key: "Recommended",
    discription: "Office",
  },
  {
    key: "Brand",
    discription: "Offbeat",
  },
  {
    key: "Compatible Devices",
    discription: "Smart TV, Cpmputer, Laptop,Tablets, Macbook, Mavbook Air, Macbook Pro, Iphone,ipad,Phone - Android",
  },
  {
    key: "Model Name",
    discription: "DASH",
  },
];
export const Boxs = [
  {
    yr: "1",
  },


];
export const CardData = [
  {
    imges: require('../assets/sale/images/image 2.png'),
    Name: "Dell New Inspirion 7415 2in1 Laptop AMD Ryzen 5-5500U 14 inches(35cm) Touch....",
    Amount: "72,900",
    mrp: "64,490.00",
    off: "23 ",
  },


];

export const CardData2 = [
  {
    imges: require('../assets/sale/images/Mouse1.png'),
    Name: "Offbeate® - DASH 2.4GHz Wireless + Bluetooth 5.1 Mouse, Multi-Device Dual....",
    Amount: "690.00",
    mrp: "990.00",
    off: "12 ",
  },
];
  // ₹ ®