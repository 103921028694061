import styled from 'styled-components';
import { colors } from '../../../consts/themes';
import Topimg from '../../../assets/sale/images/Frame.png';
import Boximg from '../../../assets/sale/images/box-two.png';
import CartContainer from './CartContainer';

const ShopCart = () => {
  return (
    <Screen>
      <LeftSideContainer>
        <CartContainer />
      </LeftSideContainer>
      <RightContainer>
        <Wrapper>
          <Sub>
            <Blast>
              <img src={Topimg} alt='blast' />
            </Blast>
            <Textbar>
              Clikekart gives <br />life time service facility for all products
            </Textbar>
          </Sub>
          <Box>
            <img src={Boximg} alt='box' />
          </Box>
        </Wrapper>
      </RightContainer>
    </Screen>
  )
}
export default ShopCart;



const Screen = styled.div`
/* margin-top: 5rem; */
height: calc(100vh - 5rem);
display: flex;
`;

const LeftSideContainer = styled.div`
overflow-y: scroll;
width:70%;
@media only screen and (min-width:300px) and (max-width:1023px) {
width: 100%;
}
`
const RightContainer = styled.div`
background-color:${colors.blue};
width:30%;
height: 100%;
@media only screen and (min-width:300px) and (max-width:1023px) {
display: none;
}
`
const Wrapper = styled.div`
height: 100%;
margin-inline: 0.5rem;
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: space-between;
`;

const Sub = styled.div`
position: relative;
`
const Blast = styled.div`
display:flex;
flex-direction:column;
img{
        width:200px;
        height:200px;
        align-self: flex-end;
    }
`

const Textbar = styled.div`
color:${colors.white};
font-size:44px;
font-weight: 500;
position: absolute;
top:6rem;
z-index: 1;
color: white;
/* background: rgba(0, 0, 0, 0.5); */
`

const Box = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-end;
img{
max-width: 450px;
max-height: 600px;
width: 100%;
height: 100%;
object-fit: cover;
}
`

