// src/pages/Terms.js
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import axiosInstance from '../../../../consts/axiosInstance';
import parse from "html-react-parser";
import LoadingSpinner from '../../../../components/Button/Loading/LoadingSpinner';
import axios from 'axios';

const Wrapper = styled.div`
    padding: 35px 80PX;
    max-width: 950px;
    margin: 100px auto;
    line-height: 1.6;
    box-shadow: 0px 5px 11px 6px #80808087;
`;

const Header = styled.h1`
  color: #333;
`;

const Header4 = styled.h4`
  color: #595959;
`;

const AgreeToLegal = styled.div`
  margin-top: 40px;
`;

const Header2 = styled.h2`
  color: #555;
`;

const Header3 = styled.h3`
  color: #555;
`;

const Paragraph = styled.p`
  color: #595959;
  font-size: 14px;
`;

const TableofContents = styled.div`
  margin-top: 40px;
`;

const StyledList = styled.ol`
  list-style-type: decimal; /* Default numbering style */
  margin-left: 20px; /* Indentation for the list */
  padding-left: 10px; /* Additional padding */

  li {
    margin-bottom: 10px; /* Space between list items */
    line-height: 1.5; /* Line height for readability */
    color: #3a3af2;

    a {
      text-decoration: none; /* Remove underline from links */
      color: #3a3af2; /* Default link color */
      transition: color 0.3s ease; /* Smooth transition for hover effect */

      &:hover {
        color: #0056b3; /* Darker color on hover */
      }
    }
  }
`;

const ListContainer = styled.div`
  margin-top: 40px;
`;


const Terms = () => {
  const servicesRef = useRef(null)
  const [terms, setTerms] = useState(null)
  const scrollToServices = () => {
    if (servicesRef.current) {
      servicesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const getTerms = async () => {
    try {
      // const response = await axios.get("http://192.168.1.39:8001/api/getTermsAndConditions");
      const response = await axios.get("https://dev.clikekart.com/api/getTermsAndConditions");
      setTerms(response.data);
    } catch (error) {
      console.error("Failed to fetch terms and conditions:", error);
    }
  };

  useEffect(() => {
    getTerms();
  }, []); // Fetch only once on component mount
  return (
    <Wrapper>
      {terms ? parse(terms.description) : <LoadingSpinner />}
    </Wrapper>
  )
};

export default Terms;
