import React, { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import call from "../../assets/Images/png/Group 9278.png";
import chatbox from "../../assets/Images/png/Group 9279.png";
import video from "../../assets/Images/png/Group 9280.png";

import {
  Helppagecontent,
  Helppagetextone,
  Helppagetexttwo,
  Imagecall,
  Imagecall1,
  Imagecircleone,
  Imagecirclethree,
  Imagecircletwo,
  Images,
  Imagetext
} from "./Helppage.styled";
import { AuthContext } from "../../consts/AuthContext";
import axiosInstance from "../../consts/axiosInstance";

const colors = [
  "linear-gradient(45deg, #14567B 0%, #7AC9C2 100%)",
  "linear-gradient(45deg, #F36C9E 0%, #F090F5 100%)",
  "linear-gradient(45deg, #FE5D53 0%, #F0971A 100%)",
];

function Helppage() {
  const navigation = useNavigate();
  const { state } = useLocation();
  const homeid = JSON.parse(localStorage.getItem("homeid") || "[]");
  const [authUser, setAuthUser] = useContext(AuthContext);

  // const { id } = state;
  const id = { state };

  const closefunction = () => {
    id(false);
    console.log("id", state);
  };

  const callFunction =async()=>{
    console.log('authUser', authUser)
    try {
      const response = await axiosInstance.post('clickToCall',{
        'mob1':authUser?.user?.mob1
      })
      console.log('response.data', response.data)
    } catch (error) {
      console.log('error', error)
    }
  }

  return (
    // <ModalContainer>
    //     <Modal>
    //         <Overlay>

    <Helppagecontent>
      {/* <Div >
                <MdOutlineClose />
            </Div> */}
      <Helppagetextone>Do you need any help?</Helppagetextone>
      <Helppagetexttwo>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
          been the industry's standard.
        </p>
      </Helppagetexttwo>

      <Images>
        {homeid === "002" ? (
          <Imagecircleone>
            <Imagecall
            // style={{ background: 'linear-gradient(#529F7F 0%, #1FBEC1 100%)' }}
            >
              <img src={call}></img>
            </Imagecall>
            <Imagetext>Call advisor</Imagetext>
          </Imagecircleone>
        ) : (
          <>
            <Imagecircleone>
              <Imagecall
              // style={{ background: 'linear-gradient(#529F7F 0%, #1FBEC1 100%)' }}
              onClick={()=>callFunction()}
              >
                <img src={call}></img>
              </Imagecall>
              <Imagetext>Call advisor</Imagetext>
            </Imagecircleone>

            <Imagecircletwo>
              <Imagecall1
              // style={{ background: 'linear-gradient(#C48555 0%, #F26078 100%)' }}
              >
                <img src={chatbox}></img>
              </Imagecall1>
              <Imagetext>Chat with a advisor</Imagetext>
            </Imagecircletwo>

            <Imagecirclethree>
              <Imagecall1
              // style={{ background: 'linear-gradient(#2A9A36 0%, #629B29 100%)' }}
              >
                <img src={video}></img>
              </Imagecall1>
              <Imagetext>Video chat with a advisor</Imagetext>
            </Imagecirclethree>
          </>
        )}
      </Images>
      {/* <div>
        
        {homeid === "002" ? (
          <Button onClick={() => navigation(-1)}>
            <p>Skip</p> <MdDoubleArrow style={{ margin: "5px" }} />
          </Button>
        ) : (
          <Button
            onClick={() => [
              navigation("/sales/productcard/users", { state: false }),
              closefunction(),
            ]}>
            <p>Skip</p> <MdDoubleArrow style={{ margin: "5px" }} />
          </Button>
        )}
      </div> */}
    </Helppagecontent>

    //         </Overlay>
    //     </Modal>
    // </ModalContainer>
  );
}
export default Helppage;
