import React, { useEffect } from 'react';
import {
  Div,
  Buttondiv
} from './PaymentStyle';
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from 'react';
import BuildPcModal from '../modal/BuildPcModal';
import { updateAdvancePayment } from '../../../api/api'
import axiosInstance from '../../../consts/axiosInstance';

const Payment = () => {



  const navigation = useNavigate();
  const location = useLocation()
  const [payment, setPayment] = useState(false);
  const [conform, setConform] = useState(false);
  const [modalopen, setModalopen] = useState(false);


  console.log("State=>", location)
  const complaintid = JSON.parse(localStorage.getItem("complaintid") || "[]");
  console.log('complaintid: ', complaintid);



  // async function advancepayment() {

  //   await fetch(`${updateAdvancePayment}/${complaintid}`)
  //     .then(response => response.json())
  //     .then(response => {
  //       console.log("response", response)
  //       // setModalopen(response);
  //       alert(response.message);
  //       // setPayment(true);
  //       navigation('/profile/services')

  //     });
  // }


  async function advancepayment() {
    try {
      const response = await axiosInstance.get(`updateAdvancePayment/${complaintid}`);
      console.log("response", response.data);
      alert(response.data.message);
      navigation('/profile/services');
    } catch (error) {
      console.error("Error occurred while updating advance payment:", error);
      alert("An error occurred. Please try again.");
    }
  }







  // useEffect(() => {
  //   advancepayment()
  // }, []);

  const update = () => {
    setConform(true)



  }
  return (
    <Div>
      <Buttondiv>
        <button style={{ color: 'black', backgroundColor: '#E7E9F0' }} onClick={() => advancepayment()}> Complete payment</button>
      </Buttondiv>
    </Div>
  )
}

export default Payment






{/* <Div>
      {
        payment === true ?
          <Buttondiv>
            <button style={{ color: 'white', backgroundColor: '#4669E8' }}> Complete advance payment</button>
          </Buttondiv>


          :

          <Buttondiv>
            <button style={{ color: 'black', backgroundColor: '#E7E9F0' }} onClick={() => advancepayment()}> Complete advance payments alex</button>
          </Buttondiv>

      }
      {
        conform === true ?
          <BuildPcModal />
          :
          ("")
      }
    </Div> */}
