import React, { useContext } from 'react'
import { GrFormClose } from 'react-icons/gr';
import { ModalContainer, Modal, Overlay } from "../../../components/modal";
import Icon from '../../../assets/Images/png/Vector.png'
import { ModalButton, ModalDiv, ThankDiv } from './SubmitModalStyle';
import { useNavigate } from 'react-router-dom';


const SubmitModal = ({setIsOpen}) => {
    const navigate = useNavigate()
  return (
    <ModalContainer>
        <Modal>
            <Overlay>
                <ModalDiv>
                <div>
                    <img src={Icon}/>
                </div>
                <ThankDiv>
                    <p>Thank you, Clikekart will contact you.</p>
                </ThankDiv>
                <ModalButton>
                    <button onClick={() => {setIsOpen(false) ; navigate('/exchange/confirmation')} }>Close</button>
                </ModalButton>
                </ModalDiv>

            </Overlay>
        </Modal>
    </ModalContainer>
  )
}

export default SubmitModal