import styled from 'styled-components';
import Colors from "../../consts/Colors";
import { colors } from '../../consts/themes';
// export const ModalContainer = styled.div`
// position: absolute;
// top: 0;
// right: 0;
// width: 100%;
// height: 100vh;
// backdrop-filter: blur(0.5px);
// opacity: 5;
 
// @media(max-width:2560px)
// {
//     backdrop-filter: blur(1px);
// }
// @media(max-width:1440px)
// {
//     backdrop-filter: blur(0.5px);
// }

// `;

export const DIv = styled.div `
width:100%;
cursor:pointer;
align-items:flex-end;
margin:10px;
display:flex;
justify-content:flex-end;
// background-color:blue;
`

export const Modals = styled.div`
    padding-inline: 3rem;
    padding-bottom: 1rem;
p{
    color: ${colors.black};
    font-size: 18px;
    font-weight:500;
    margin:15px 0 0;
    text-align:center;
    
}

`
export const Downbar = styled.div`
display:flex;
flex-direction:column;
`

export const Downbars = styled.div`
display:flex;
flex-direction:column;
`

export const ModalContainer = styled.div`
  background-color: rgb(0, 0, 0, 0.01);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: scroll;
  display: flex;
  backdrop-filter: blur(0.66px);
  
`;

export const Modal = styled.div`
  display: flex;
  margin: 5rem;
  background-color: ${colors.white};
  height: auto;
  position: relative;
  border-radius: 10px;
  
  @media (max-width: 1024px) {
    width: auto;
  }
  /* @media (max-width: 768px) {
    width: 60%;
  } */
  @media (max-width: 425px) {
    width: 90%;
  }
`;

export const Overlay = styled.div`
  display:flex;
  background-color:${colors.white};
  border-radius:10px;
  z-index:1000;
  transition:0.5s;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 25px 2px rgba(204,204,204,0.45);
  /* box-shadow: 0px 0px 25px 1px rgba(204,204,204,0.45); */
  
  @media (max-width: 425px) {
    width: 100%;
  }
`;
export const Images = styled.div`

display:flex;
flex-direction:row;
padding: 2rem;
column-gap:60px;
justify-content:center;
@media(max-width:430px){
    column-gap: 30px;
}
@media(max-width:375px){
    column-gap: 10px;
}
`
export const Imageone = styled.div`
border: .5px solid #ccc;
height:11vw;
justify-content: center;
    display: flex;
    align-items: center;
justify-content: center;
    display: flex;
    align-items: center;
background: ${Colors.business};
// border-radius:8px;
width: 11vw;
@media(max-width:1440px)
{ 
width:11vw;
    height: 11vw;
}
@media(max-width:1024px)
{
    width: 15vw;
    height: 15vw;
    
}
@media(max-width:768px)
{
    width: 20vw;
    height: 20vw;
}
@media(max-width:425px)
{
    width:40vw;
    height:35vw;

}
@media(max-width:320px)
{
    width:40vw;
    height:38vw;
}

img{
    width: 190px;
    height: 190px;
    object-fit: contain;
    border: .5px solid #ccc;
@media(max-width:1024px){
    width: 10vw;
    height: 8vw;
}
@media(max-width:770px){
width: 15vw;
    height: 12vw;
}
@media(max-width:425px)
{
    width:22vw;
    height:16vw;
}
}
`
export const Imagetwo = styled.div`

width:9vw;
justify-content: center;
display: flex;
align-items: center;
height:9vw;
background: linear-gradient(45deg, #FE5D53 0%, #F0971A 100%);
// border-radius:8px;
@media(max-width:1440px)
{ 
width:11vw;
    height: 11vw;
}
@media(max-width:1024px)
{
    width: 15vw;
    height: 15vw;
}
@media(max-width:768px)
{
    width: 20vw;
    height: 20vw;
}
@media(max-width:425px)
{
    width:40vw;
    height:35vw;
}
@media(max-width:320px)
{
    width:40vw;
    height:38vw;
}
img
{
    width:8vw;
height:6vw;
margin-top: 1rem;
@media(max-width:1024px){
    width: 10vw;
    height: 8vw;
}
@media(max-width:770px){
width: 15vw;
    height: 12vw;
}
@media(max-width:425px)
{
    width:22vw;
    height:16vw;
}
}
`
export const Imagetext = styled.div`

color:black;
margin-top: 30px;
font-size: 12px;
font-weight: 400;
`